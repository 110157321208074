import { makeStyles } from '@material-ui/core/styles';
import { JOB_OFFER_CARD_BREAKPOINT } from '../../config/constants';

export const useStyles = makeStyles(theme => ({
    salaryWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 26,
        padding: '0 0 0 26px',
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            padding: '0 23px 0 0',
        },
    },
    salary: {
        color: '#000000',
        fontSize: 12,
        marginRight: 4,
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            marginRight: 0,
            color: '#000000',
            fontSize: 18,
            lineHeight: '16px',
        },
    },
    salaryUnit: {
        color: '#616161',
        fontSize: '0.75rem',
        lineHeight: '1rem',
    },
}));
