import axios, { AxiosResponse } from 'axios';
import { removeFromLocalStorage, writeToLocalStorage } from './LocalStorage';
import { Token } from '../../models/enums/Token';

export const login = async (username: string, password: string): Promise<AxiosResponse> => {
    // eslint-disable-next-line no-return-await
    return await axios.post(`${process.env.REACT_APP_API_NO_API_VER}login`, { username, password });
};

export const loginWithRefreshToken = async (refreshToken: string): Promise<boolean> => {
    try {
        const response: AxiosResponse = await axios.post(`${process.env.REACT_APP_API}login`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        if (response.status !== 200) return false;

        writeToLocalStorage(Token.REFRESH_TOKEN, { ...response.data, time_received: Date.now() });
        return true;
    } catch (e) {
        return false;
    }
};

export const logoutUser = (): void => {
    removeFromLocalStorage(Token.TOKEN);
    removeFromLocalStorage(Token.REFRESH_TOKEN);
    removeFromLocalStorage(Token.ROLE);
    removeFromLocalStorage(Token.USER);
    window.location.replace(window.location.origin);
};

export const linkedInLogin = async (code: string, redirectUrl?: string | null): Promise<AxiosResponse> => {
    return axios.post('/oauth2/linkedin/signin', { code, redirectUrl });
};
