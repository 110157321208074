import * as yup from 'yup';
import i18n from 'i18next';

export const initialValues = {
    email: '',
    name: '',
};

export const validationSchema = yup.object().shape({
    email: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Email is required'))
        .email(i18n.t('Email must be valid')),
    name: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(200, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
});
