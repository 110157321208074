// @ts-nocheck

import React, { FC } from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { useField } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import FormikErrorText from '../FormikErrorText';

interface Props extends Partial<KeyboardDatePickerProps>, OutlinedTextFieldProps {
    name: string;
    label?: string;
    disableLabel?: string;
}

const FormikDatePicker: FC<Props> = ({ disableLabel, name, label, ...otherProps }) => {
    const formatDate = (date: Date): string => dayjs(date).format('YYYY-MM-DD');

    const { value } = useField(name)[0];
    const { setValue } = useField(name)[2];

    return (
        <div>
            {!disableLabel && <InputLabel>{label || name}</InputLabel>}
            <KeyboardDatePicker
                placeholder={formatDate(new Date())}
                value={value}
                fullWidth
                maxDateMessage=""
                minDateMessage=""
                invalidDateMessage=""
                invalidLabel=""
                onChange={date => setValue(formatDate(date))}
                format="YYYY-MM-DD"
                inputVariant="outlined"
                {...otherProps}
            />
            <FormikErrorText name={name} />
        </div>
    );
};

export default FormikDatePicker;
