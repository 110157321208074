import { makeStyles } from '@material-ui/core/styles';
import { HEADER_BREAKPOINT, LOGO_FONT } from '../../../../config/constants';

export const useStyles = makeStyles(theme => ({
    bottomNavigation: {
        backgroundColor: '#F7FBFF',
        position: 'fixed',
        padding: '0 2rem',
        boxShadow: '0 16px 23px 2px #8FACC7',
        zIndex: 1199,
        bottom: 0,
        left: 0,
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 60,
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            display: 'none',
        },
    },
    headerDrawer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    linkedButton: {
        width: 235,
    },
    buttonWrapper: {
        padding: '24px 72px',
    },
    button: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
    },
    drawerPaper: {
        minWidth: '100vw',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            minWidth: '20rem',
        },
    },
    logoFont: {
        fontFamily: LOGO_FONT,
        fontSize: '1.5rem',
        textDecoration: 'none',
        fontWeight: 700,
        color: '#15416D',
    },
    mobileLogo: {
        marginTop: 5,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    link: {
        color: 'darkgrey',
        fontSize: '11px',
        lineHeight: '1rem',
        fontWeight: 700,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'auto',

        '&:after': {
            bottom: -16,
            content: '""',
            display: 'block',
            height: 3,
            left: '50%',
            borderRadius: 3,
            position: 'absolute',
            background: '#15416D',
            transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
            width: 0,
        },
        '&:hover:after': {
            width: '100%',
            left: 0,
        },
    },
    linkLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeLink: {
        color: '#007FFC',
        '&:after': {
            width: '100%',
            left: 0,
            backgroundColor: '#007FFC',
        },
    },
    linkText: {
        padding: 0,
    },
}));
