import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStyles } from './styles';
import FormikImageUpload from '../../../../components/formik/FormikImageUpload';
import CustomFormikTextField from '../../../../components/CustomFormikTextField';
import CompanyTeamFormikTags from '../../../employer/EmployerCompanyPage/CompanyTeamFormikTags';
import FormikRichTextEditor from '../../../../components/formik/FormikRichTextEditor';

const AdminCreateCompanyForm: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { handleSubmit, isSubmitting } = useFormikContext();

    return (
        <Container className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div className={classes.rteWrapper}>
                    <CustomFormikTextField
                        name="username"
                        placeholder={t('Employer email')}
                        label={t('Employer email')}
                    />
                </div>
                <div className={classes.form}>
                    <div className={classes.uploadWrapper}>
                        <FormikImageUpload name="logo" />
                    </div>
                    <CustomFormikTextField
                        name="name"
                        placeholder={t('company name')}
                        label={t('Company name')}
                    />
                    <CustomFormikTextField
                        name="industry"
                        placeholder={t('industry')}
                        label={t('Industry')}
                    />
                    <div className={classes.companySize}>
                        <CustomFormikTextField
                            name="companySizeMin"
                            type="number"
                            placeholder={t('Size min')}
                            label={t('Size min')}
                        />
                        <CustomFormikTextField
                            name="companySizeMax"
                            type="number"
                            placeholder={t('Size max')}
                            label={t('Size max')}
                        />
                    </div>
                    <CustomFormikTextField name="url" placeholder={t('url')} label={t('Url')} />
                </div>
                <div className={classes.rteWrapper}>
                    <CustomFormikTextField
                        name="contactEmail"
                        placeholder={t('Email')}
                        label={t('Contact email')}
                    />
                </div>
                <div className={classes.rteWrapper}>
                    <CompanyTeamFormikTags name="teamMembers" label={t('Team')} dontFind />
                </div>
                <div className={classes.rteWrapper}>
                    <FormikRichTextEditor name="description" label={t('Description')} editorHeight={192} />
                </div>
                <div className={classes.rteWrapper}>
                    <FormikRichTextEditor name="mission" label={t('Mission')} editorHeight={192} />
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
        </Container>
    );
};

export default AdminCreateCompanyForm;
