import { FC } from 'react';
import clsx from 'clsx';
import { NavLink as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';
import HeaderActions from './HeaderActions';
import LinkedInIcon from '../../assets/linkedIn.svg';
import { ROUTES } from '../../config/constants';
import { UserRoles } from '../../models/enums/UserRoles';
import { NavLinkConfig, navLinksConfig } from './config';
import { useStyles } from './styles';
import { loginRedirectToLinkedIn } from '../../config/utils';
import UserAvatar from '../../components/UserAvatar';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import HeaderMobile from './HeaderMobile';

const Header: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const {
        user: { role, avatarUrl },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const getJobIdIfExists = (): string | null => {
        const onJobDetailsPage = matchPath(pathname, {
            path: ROUTES.JOB_OFFER_DETAILS,
        });
        const params: { id?: string } | undefined = onJobDetailsPage?.params;

        return onJobDetailsPage?.isExact === true && params?.id ? params.id : null;
    };

    const renderLinkedInButton = () => (
        <Button
            color="primary"
            variant="contained"
            classes={{ root: classes.linkedButton, label: classes.linkedLabel }}
            onClick={() => loginRedirectToLinkedIn(getJobIdIfExists())}
        >
            <img src={LinkedInIcon} alt="LinkedIn" />
            <Typography>{`${t('Sign in with')} LinkedIn`}</Typography>
        </Button>
    );

    const renderEmployerSignButton = () => (
        <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to={ROUTES.EMPLOYER_SIGN_IN}
            classes={{ root: classes.signButton }}
        >
            {t('Sign in')}
        </Button>
    );

    const renderPostNewJobButton = () => (
        <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to={ROUTES.EMPLOYER_POST_JOB}
            classes={{ root: classes.signButton }}
        >
            {t('Post a new job')}
        </Button>
    );

    const renderLinks = (linksConfig: NavLinkConfig[]): JSX.Element[] => {
        return linksConfig.map(({ to, label, activeMatch }) => (
            <Link
                key={to}
                component={RouterLink}
                to={to}
                className={clsx(classes.link, {
                    [classes.activeLink]: activeMatch.some(url => url === pathname),
                })}
            >
                {t(label)}
            </Link>
        ));
    };

    const renderPublicNav = () => (
        <>
            {renderLinks(navLinksConfig.PUBLIC)}
            {[ROUTES.EMPLOYER_LANDING_PAGE, ROUTES.EMPLOYER_SIGN_UP, ROUTES.EMPLOYER_SIGN_IN].some(
                url => url === pathname,
            )
                ? renderEmployerSignButton()
                : renderLinkedInButton()}
        </>
    );

    const renderEmployerNav = () => (
        <>
            {renderLinks(navLinksConfig[UserRoles.EMPLOYER])}
            {renderPostNewJobButton()}
            <UserAvatar size={33} url={avatarUrl} className={classes.userActions} />
            <HeaderActions />
        </>
    );

    const renderCandidateNav = () => (
        <>
            {renderLinks(navLinksConfig[UserRoles.CANDIDATE])}
            <UserAvatar size={33} url={avatarUrl} className={classes.userActions} />
            <HeaderActions />
        </>
    );

    const renderAdminNav = () => (
        <>
            {renderLinks(navLinksConfig[UserRoles.ADMIN])}
            <UserAvatar size={33} url={avatarUrl} className={classes.userActions} />
            <HeaderActions />
        </>
    );

    const renderSupportNav = () => (
        <>
            {renderLinks(navLinksConfig[UserRoles.SUPPORT])}
            <UserAvatar size={33} url={avatarUrl} className={classes.userActions} />
            <HeaderActions />
        </>
    );

    const renderContent = (role?: UserRoles) => {
        switch (role) {
            case UserRoles.EMPLOYER:
                return renderEmployerNav();
            case UserRoles.CANDIDATE:
                return renderCandidateNav();
            case UserRoles.ADMIN:
                return renderAdminNav();
            case UserRoles.SUPPORT:
                return renderSupportNav();
            default:
                return renderPublicNav();
        }
    };

    const renderDesktop = () => (
        <div
            className={clsx(classes.desktopWrapper, {
                [classes.whiteBackground]: pathname === ROUTES.EMPLOYER_LANDING_PAGE,
            })}
        >
            <Container className={classes.container}>
                <RouterLink to={ROUTES.LANDING} className={classes.logoFont}>
                    <div title="Onboard logo">Onboard</div>
                </RouterLink>
                <div className={classes.linksWrapper}>{renderContent(role)}</div>
            </Container>
        </div>
    );

    const renderMobile = () => <HeaderMobile />;

    return (
        <>
            {renderMobile()}
            {renderDesktop()}
        </>
    );
};

export default Header;
