import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import LandingPageSection from '../../../parts/LandingPageSection';
import HowItWorksImg from '../../../assets/landing/how-it-works.png';

import { findJobSteps } from './config';

const HowWorksPanel: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderSteps = (): JSX.Element[] => {
        return findJobSteps.map(({ title, description }, index) => (
            <div key={title} className={classes.step}>
                <div className={classes.stepNumber}>{index + 1}</div>
                <div>
                    <Typography className={classes.title}>{t(title)}</Typography>
                    <Typography className={classes.description}>{t(description)}</Typography>
                </div>
            </div>
        ));
    };

    return (
        <LandingPageSection title="How it works" titleColor="#15416D" sectionBackgroundColor="#FFFFFF">
            <Container className={classes.container}>
                <img className={classes.image} src={HowItWorksImg} alt="how it works" />
                <div className={classes.stepsWrapper}>{renderSteps()}</div>
            </Container>
        </LandingPageSection>
    );
};

export default HowWorksPanel;
