import { FC } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';

import { useStyles } from './styles';
import FormikSelect from '../formik/FormikSelect';
import { FormikSelectProps } from '../formik/FormikSelect/FormikSelect';

const BlurredStyleFormikSelect: FC<FormikSelectProps> = ({ name, InputProps, ...otherProps }) => {
    const classes = useStyles();
    const { error, touched } = useField(name)[1];
    const displayError = touched && error !== undefined;

    return (
        <FormikSelect
            name={name}
            variant="outlined"
            InputLabelProps={{
                classes: {
                    root: classes.labelRoot,
                },
            }}
            margin="none"
            classes={{
                root: classes.rootTextField,
                outlined: classes.input,
            }}
            containerClassName={clsx(classes.select, { [classes.error]: displayError })}
            {...otherProps}
        />
    );
};

export default BlurredStyleFormikSelect;
