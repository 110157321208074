// @ts-nocheck
import { FC, useEffect, useRef, useState } from 'react';
import getBlobDuration from 'get-blob-duration';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReactWaves from '@dschoon/react-waves';

import IconButton from '@material-ui/core/IconButton';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import LinearProgress from '@material-ui/core/LinearProgress';

import { debounce } from '@material-ui/core';
import { useStyles } from './styles';

interface AudioPlayerProps {
    src: string;
    disabled?: boolean;
}

const AudioPlayer: FC<AudioPlayerProps> = ({ src, disabled }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const ref = useRef();
    const [random] = useState(Math.random());

    const [playing, setPlaying] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [duration, setDuration] = useState<number>(0);

    useEffect(() => {
        if (src) {
            setLoading(true);
            fetch(src)
                .then(r => r.blob())
                .then(async blobFile => {
                    // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome
                    const duration = await getBlobDuration(blobFile);
                    setDuration(duration);
                    return blobFile;
                })
                .then(blobFile => new File([blobFile], 'audioRecord.webm', { type: 'audio/webm' }))
                .then(file => {
                    setFileUrl(URL.createObjectURL(file));
                })
                .finally(() => setLoading(false));
        }
    }, [src]);

    const handlePlayPause = () => {
        if (!disabled) {
            ref.current.play();
            ref.current.pause();
            setPlaying(playing => !playing);
        }
    };

    const handlePosChange = (e: unknown) => {
        if (duration <= (e as number)) {
            const debouncedSave = debounce(() => setPlaying(false), 1000);
            debouncedSave();
        }
    };

    if (loading) {
        return (
            <div className={clsx(classes.controlsWrapper, classes.loading)}>
                <LinearProgress className={classes.progress} />
            </div>
        );
    }

    if (!fileUrl) return null;

    return (
        <div>
            <div className={classes.controlsWrapper}>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={handlePlayPause}
                    title={t(playing ? 'Pause' : 'Play')}
                    disabled={disabled}
                >
                    {playing ? (
                        <PauseCircleFilledIcon className={classes.controlButton} />
                    ) : (
                        <PlayCircleFilledIcon className={classes.controlButton} />
                    )}
                </IconButton>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio
                    id={`audio-ref-${random}`}
                    ref={ref}
                    src={src}
                    className={classes.audioPlayer}
                    controls
                />
                <ReactWaves
                    audioFile={fileUrl}
                    className={classes.reactWaves}
                    options={{
                        hideScrollbar: true,
                        responsive: true,
                        waveColor: '#D9DCFF',
                        progressColor: '#007FFC',
                        barWidth: 2,
                        barHeight: 30,
                        barRadius: 3,
                        cursorWidth: 0,
                        height: 60,
                        barGap: 1,
                        mediaControls: true,
                    }}
                    volume={1}
                    zoom={1}
                    playing={playing && !disabled}
                    onPosChange={handlePosChange}
                    controls
                />
            </div>
        </div>
    );
};

export default AudioPlayer;
