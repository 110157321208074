import { FC, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import EmployerCompanyForm from './EmployerCompanyForm';
import { EmployerCompanyFormValues, initialValues, validationSchema } from './config';
import { editCurrentEmployerCompany, getCurrentEmployerCompany } from '../../../services/UserService';
import { getBackendFormikErrors } from '../../../config/utils';

const EmployerCompanyPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [currentCompany, setCurrentCompany] = useState<EmployerCompanyFormValues | null>(null);

    const fetchCurrentCompany = () => {
        getCurrentEmployerCompany()
            .then(response => {
                const { url, industry, companySizeMin, companySizeMax, contactEmail, ...others } = response;
                setCurrentCompany({
                    companySizeMin: companySizeMin || 0,
                    companySizeMax: companySizeMax || 10,
                    url: url || '',
                    industry: industry || '',
                    contactEmail: contactEmail || '',
                    ...others,
                });
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch static content, try again later'), { variant: 'error' }),
            );
    };

    useEffect(() => {
        fetchCurrentCompany();
        // eslint-disable-next-line
    }, []);

    const handleSubmitForm = async (
        values: EmployerCompanyFormValues,
        { setSubmitting, setErrors }: FormikHelpers<EmployerCompanyFormValues>,
    ) => {
        try {
            await editCurrentEmployerCompany(values);
            enqueueSnackbar(t('Changes saved'), { variant: 'success' });
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Submit fail', { variant: 'error' });
        } finally {
            setSubmitting(false);
            fetchCurrentCompany();
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentCompany || initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <EmployerCompanyForm currentCompany={currentCompany} />
        </Formik>
    );
};

export default EmployerCompanyPage;
