// @ts-nocheck

import * as yup from 'yup';
import i18n from 'i18next';
import dayjs from 'dayjs';

export interface ScheduleCandidateMeetingDialog {
    name: string | '';
    callUrl: string;
    message: string;
    dateProposals: [
        {
            date: Date;
            startTime: Date;
            endTime: Date;
        },
    ];
}

export const initialValues: ScheduleCandidateMeetingDialog = {
    name: '',
    callUrl: '',
    message: '',
    dateProposals: [
        {
            date: dayjs(new Date()).format('YYYY-MM-DD'),
            startTime: new Date(),
            endTime: new Date(),
        },
    ],
};

export const validationSchema = yup.object().shape({
    name: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    message: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(1000, `${i18n.t('Maximum number of characters is')} \${max}`),
    callUrl: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .matches(
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
            i18n.t('Enter correct url'),
        ),
    dateProposals: yup
        .array()
        .min(1, i18n.t('At least one date is required'))
        .of(
            yup.object().shape({
                date: yup
                    .date()
                    .min(dayjs().format('YYYY-MM-DD'), 'Date must be in the future')
                    .required(i18n.t('Field is required'))
                    .typeError('Valid date is required'),
                startTime: yup.date().required().typeError('Valid start time required'),
                endTime: yup
                    .date()
                    .min(yup.ref('startTime'), 'End time has to be after start time')
                    .required()
                    .typeError('Valid end time required'),
            }),
        ),
});
