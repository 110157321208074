import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useStyles } from './styles';
import { JobOffer } from '../../models/JobOffer';
import CategoryChip from '../../assets/landing/categoryChip.svg';
import LocationIcon from '../../assets/landing/gps.svg';
import ProfileIcon from '../../assets/landing/profileIcon.svg';
import { UserRoles } from '../../models/enums/UserRoles';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';

import { trimJobOfferName } from '../../config/utils';
import CompanyLogoImage from '../CompanyLogoImage';
import { getCoverByCompanyId } from '../JobOfferCard/config';

interface FeaturedJobCardProps {
    job: JobOffer | undefined;
    jobDetailsPage?: boolean;
}

const FeaturedJobCard: FC<FeaturedJobCardProps> = ({ job, jobDetailsPage }) => {
    const classes = useStyles({
        backgroundUrl: job?.backgroundUrl || getCoverByCompanyId(job?.companyId),
    });
    const { t } = useTranslation();

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const renderLoader = () => (
        <ContentLoader
            speed={2}
            width={256}
            height={256}
            viewBox="0 0 256 256"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="113" />
            <rect x="24" y="135" rx="8" ry="8" width="200" height="18" />
            <rect x="24" y="170" rx="8" ry="8" width="90" height="16" />
            <rect x="24" y="193" rx="8" ry="8" width="60" height="16" />
            <rect x="24" y="219" rx="8" ry="8" width="140" height="16" />
        </ContentLoader>
    );

    const renderCompanyBackground = () => {
        return (
            <div className={classes.companyBackground}>
                <CompanyLogoImage
                    alt={job?.companyName}
                    src={job?.logoUrl}
                    classNameWrapper={classes.companyLogoWrapper}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (job) {
            return (
                <>
                    {renderCompanyBackground()}
                    <div className={classes.detailsWrapper}>
                        <Typography className={classes.position} title={job.title}>
                            {trimJobOfferName(job.title, 19)}
                        </Typography>
                        {job.category && (
                            <div className={classes.detailsRow}>
                                <img src={CategoryChip} alt="category" className={classes.detailsIcon} />
                                <Typography className={classes.detailsText}>{job.category}</Typography>
                            </div>
                        )}
                        <div className={classes.detailsRow}>
                            <img src={LocationIcon} alt="location" className={classes.detailsIcon} />
                            <Typography className={classes.detailsText}>{job.location}</Typography>
                        </div>
                        {job.companySizeMin !== null && job.companySizeMax !== null && (
                            <div className={classes.detailsRow}>
                                <img src={ProfileIcon} alt="company size" className={classes.detailsIcon} />
                                <Typography className={classes.detailsText}>{`${job.companySizeMin} - ${
                                    job.companySizeMax
                                } ${t('employees')}`}</Typography>
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return renderLoader();
    };

    if (role === UserRoles.SUPPORT) {
        return (
            <RouterLink
                to={`/edit-job/${job?.id}`}
                className={clsx({
                    [classes.wrapper]: !jobDetailsPage,
                    [classes.paperWrapper]: jobDetailsPage,
                })}
            >
                {renderContent()}
            </RouterLink>
        );
    }
    return (
        <RouterLink
            to={`/jobs/${job?.id}`}
            className={clsx({
                [classes.wrapper]: !jobDetailsPage,
                [classes.paperWrapper]: jobDetailsPage,
            })}
        >
            {renderContent()}
        </RouterLink>
    );
};

export default FeaturedJobCard;
