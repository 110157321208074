import axios from 'axios';
import ClearbitLogo from '../models/Clearbit';

export const findLogoSuggestionsByInput = async (inputValue: string): Promise<ClearbitLogo[]> => {
    const axiosInstance = axios.create({
        baseURL: 'https://autocomplete.clearbit.com/v1/',
    });
    const { data } = await axiosInstance.get(`companies/suggest?query=${inputValue}`);
    return data;
};
