import { FC } from 'react';
import UsersPanel from './UsersPanel';
import ResourceManagementPage from '../../../parts/ResourceManagementPage';

const AdminUsersPage: FC = () => {
    return (
        <ResourceManagementPage
            tabs={[
                { label: 'Support', tab: <UsersPanel usersType="support" /> },
                { label: 'Employers', tab: <UsersPanel usersType="employers" /> },
                { label: 'Candidates', tab: <UsersPanel usersType="candidates" /> },
            ]}
        />
    );
};

export default AdminUsersPage;
