import { makeStyles } from '@material-ui/core/styles';

const HOW_TO_BREAKPOINT = 1100;

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(HOW_TO_BREAKPOINT)]: {
            flexDirection: 'row',
        },
    },
    stepsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2rem',
        [theme.breakpoints.up(HOW_TO_BREAKPOINT)]: {
            marginTop: 0,
            marginLeft: '3rem',
        },
    },
    step: {
        display: 'grid',
        gridTemplateColumns: '58px 1fr',
        gridGap: 34,
        marginBottom: '1rem',
    },
    stepNumber: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#007FFC',
        fontSize: '1.5rem',
        fontWeight: 400,
        height: 58,
        width: 58,
        borderRadius: '29px 29px 29px 0',
        boxShadow: '0 0 48px 0 rgba(0,0,0,0.1)',
        marginTop: '0.25rem',
    },
    title: {
        color: '#007FFC',
        fontSize: '1.25rem',
        fontWeight: 500,
        letterSpacing: '0.17px',
        marginBottom: '0.5rem',
        [theme.breakpoints.up(HOW_TO_BREAKPOINT)]: {
            marginBottom: '1rem',
        },
    },
    description: {
        color: '#15416D',
        fontSize: '0.815rem',
    },
    image: {
        width: 500,
        maxWidth: '100%',
        objectFit: 'contain',
        mixBlendMode: 'multiply',
    },
}));
