import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import BusinessIcon from '@material-ui/icons/Business';

import { ROUTES } from '../../../../config/constants';
import { UserRoles } from '../../../../models/enums/UserRoles';

export interface BottomNavLinkConfig {
    to: string;
    label: string;
    activeMatch: string[];
    icon: unknown;
}

interface BottomNavLinksConfig {
    [key: string]: BottomNavLinkConfig[];
}

export const bottomNavLinksConfig: BottomNavLinksConfig = {
    [UserRoles.ADMIN]: [
        {
            to: ROUTES.ADMIN_USERS,
            label: 'Users',
            activeMatch: [ROUTES.ADMIN_USERS],
            icon: <PeopleIcon />,
        },
        {
            to: ROUTES.ADMIN_COMPANIES,
            label: 'Companies',
            activeMatch: [ROUTES.ADMIN_COMPANIES],
            icon: <BusinessIcon />,
        },
        {
            to: ROUTES.ADMIN_JOBS,
            label: 'Job offers',
            activeMatch: [ROUTES.ADMIN_JOBS],
            icon: <WorkIcon />,
        },
    ],
    [UserRoles.EMPLOYER]: [
        {
            to: ROUTES.EMPLOYER_HOME,
            label: 'Jobs',
            activeMatch: [ROUTES.EMPLOYER_HOME, ROUTES.EMPLOYER_POST_JOB],
            icon: <WorkIcon />,
        },
        {
            to: ROUTES.EMPLOYER_JOB_CANDIDATES,
            label: 'Candidates',
            activeMatch: [ROUTES.EMPLOYER_JOB_CANDIDATES],
            icon: <PeopleIcon />,
        },
        {
            to: ROUTES.EMPLOYER_POST_JOB,
            label: 'Post new job',
            activeMatch: [ROUTES.EMPLOYER_POST_JOB],
            icon: <AddIcon />,
        },
    ],
    [UserRoles.CANDIDATE]: [
        {
            to: ROUTES.LANDING,
            label: 'Home',
            activeMatch: [ROUTES.LANDING],
            icon: <HomeIcon />,
        },
        {
            to: ROUTES.CANDIDATE_MY_ROLES,
            label: 'My roles',
            activeMatch: [ROUTES.CANDIDATE_MY_ROLES],
            icon: <WorkIcon />,
        },
        {
            to: ROUTES.CANDIDATE_FIND_A_JOB,
            label: 'Search',
            activeMatch: [ROUTES.CANDIDATE_FIND_A_JOB],
            icon: <SearchIcon />,
        },
    ],
    [UserRoles.SUPPORT]: [
        {
            to: ROUTES.SUPPORT_COMPANIES,
            label: 'Companies',
            activeMatch: [ROUTES.SUPPORT_COMPANIES],
            icon: <BusinessIcon />,
        },
        {
            to: ROUTES.SUPPORT_JOBS,
            label: 'Job offers',
            activeMatch: [ROUTES.SUPPORT_JOBS],
            icon: <WorkIcon />,
        },
    ],
};
