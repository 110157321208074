// @ts-nocheck

import React, { FC } from 'react';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import FormHelperText from '@material-ui/core/FormHelperText';
import { InputProps } from '@material-ui/core/Input';

import throttle from 'lodash/throttle';
import { InputLabelProps as InputLabelPropsTypes } from '@material-ui/core/InputLabel/InputLabel';
import { ErrorMessage, useField } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles } from './styles';
import { asyncLoadScript } from '../../config/utils';
import { PlaceType } from './config';
import CustomTextField from '../CustomTextField';

interface Props extends BaseTextFieldProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
    InputProps?: Partial<InputProps>;
    InputLabelProps?: InputLabelPropsTypes;
    placeholder?: string;
}

const autocompleteService = { current: null };

const GoogleLocationFormikAutocomplete: FC<Props> = ({
    name,
    label,
    disableLabel,
    InputProps,
    InputLabelProps,
    placeholder,
}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [options, setOptions] = React.useState<PlaceType[]>([]);
    const loaded = React.useRef(false);
    const { value: inputValue } = useField(name)[1];
    const { setValue: setInputValue } = useField(name)[2];
    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            asyncLoadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle(
                (request: { input: string; types: string[] }, callback: (results?: PlaceType[]) => void) => {
                    request.types = ['(cities)'];
                    autocompleteService.current.getPlacePredictions(request, callback);
                },
                200,
            ),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <div>
            {!disableLabel && (
                <InputLabel htmlFor={name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <Autocomplete
                getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                filterOptions={x => x}
                options={options}
                classes={{ inputRoot: classes.inputRoot, root: classes.root }}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value || inputValue}
                onChange={(event, newValue: PlaceType | null) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={params => (
                    <CustomTextField
                        {...params}
                        placeholder={placeholder}
                        InputProps={{ ...params.InputProps, ...InputProps }}
                        fullWidth
                    />
                )}
                renderOption={option => {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map(match => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item xs={3} sm={2}>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs={9} sm={10}>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />
            <ErrorMessage
                name={name}
                render={errorMessage => (
                    <FormHelperText variant="outlined" error>
                        {errorMessage}
                    </FormHelperText>
                )}
            />
        </div>
    );
};

export default GoogleLocationFormikAutocomplete;
