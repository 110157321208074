import { makeStyles } from '@material-ui/core/styles';

import { SEARCH_PANEL_FILTERS_BREAKPOINT } from '../../../config/constants';

export const useStyles = makeStyles(theme => ({
    container: {},
    divider: {
        color: '#F7FBFF',
        marginBottom: '0.75rem',
    },
    headerWrapper: {
        display: 'flex',
        marginBottom: '0.5rem',
        flexDirection: 'column',
        [theme.breakpoints.up(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            flexDirection: 'row',
        },
    },
    headerButton: {
        color: '#616161',
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: '0.3px',
        padding: '32px 8px',
        [theme.breakpoints.up(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            marginRight: '2rem',
            padding: '6px 8px',
        },
    },
    headerButtonActive: {
        color: '#007FFC',
        fontWeight: 500,
    },
    filterButton: {
        color: '#15416D',
        fontWeight: 700,
    },
    filtersWrapper: {
        maxHeight: 250,
        height: '100%',
        padding: 8,
    },
    expandIcon: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandIconExpanded: {
        transform: 'rotate(180deg)',
    },
    drawerPaper: {
        minWidth: '100vw',
        [theme.breakpoints.up(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            minWidth: '20rem',
        },
    },
    mobileButton: {
        justifyContent: 'flex-start',
        paddingLeft: '2rem',
        color: '#15416D',
        fontWeight: 500,
        fontSize: 18,
    },
    mobileWrapper: {
        marginBottom: '1rem',
        [theme.breakpoints.up(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
    },
    desktopWrapper: {
        [theme.breakpoints.down(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
    },
    checkboxMobileLabel: {
        padding: '20px 8px',
        fontSize: 16,
    },
    controlLabel: {
        paddingLeft: '2rem',
    },
    headerDrawer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logoDrawer: {
        marginTop: 8,
        marginLeft: 12,
    },
    iconButton: {
        justifySelf: 'flex-end',
    },
}));
