import React, { FC, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { isEqual } from 'lodash';
import NavigationPrompt from 'react-router-navigation-prompt';
import CustomFormikSelect from '../../components/CustomFormikSelect';
import CustomFormikSwitch from '../../components/CustomFormikSwitch';
import CustomFormikTextField from '../../components/CustomFormikTextField';
import FormikRichTextEditor from '../../components/formik/FormikRichTextEditor';
import TagsFormikAutocomplete from '../../components/TagsFormikAutocomplete';
import TeamFormikAutocomplete from '../../components/TeamFormikAutocomplete';

import { getJobOfferStaticContent } from '../../services/JobOfferService';
import { useStyles } from './styles';
import { defaultInitialValues, validationSchema } from './config';
import { JobOfferStaticContent, PostJobOfferFormValues } from '../../models/JobOffer';
import ReactiveDialog from '../../components/ReactiveDialog';
import GoogleLocationFormikAutocomplete from '../../components/GoogleLocationFormikAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';

interface EmployerPostOfferFormProps {
    initialValues?: PostJobOfferFormValues | null;
    onSubmit: (values: PostJobOfferFormValues, formikHelpers: FormikHelpers<PostJobOfferFormValues>) => void;
    reactiveOffer?: boolean;
    edit?: boolean;
    additionalElement?: JSX.Element;
    companyId?: string | number;
    isAdmin?: boolean;
}

const EmployerPostOfferForm: FC<EmployerPostOfferFormProps> = ({
    initialValues,
    onSubmit,
    reactiveOffer,
    edit,
    additionalElement,
    isAdmin,
    companyId,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [staticContent, setStaticContent] = useState<JobOfferStaticContent>();

    useEffect(() => {
        getJobOfferStaticContent()
            .then(response => {
                setStaticContent(response);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch static content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, t]);

    const formikInitialValues = initialValues || defaultInitialValues;

    return (
        <Container className={classes.container}>
            <Formik
                enableReinitialize
                initialValues={formikInitialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, isSubmitting, submitForm, values, initialValues }) => {
                    return (
                        <>
                            <NavigationPrompt when={!isEqual(initialValues, values) && !isSubmitting}>
                                {({ onConfirm, onCancel }) => (
                                    <ConfirmDialog
                                        open={!isEqual(initialValues, values)}
                                        confirmLabel={t('Yes, leave')}
                                        cancelLabel={t('No, stay')}
                                        title={t('You have unsaved changes, are you sure you want to leave?')}
                                        onClickConfirm={onConfirm}
                                        onClickCancel={onCancel}
                                    />
                                )}
                            </NavigationPrompt>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.form}>
                                    <CustomFormikTextField
                                        name="title"
                                        placeholder={t('Job title')}
                                        label={t('Job title')}
                                    />
                                    <CustomFormikSelect
                                        name="experienceLevelId"
                                        label={t('Experience level')}
                                    >
                                        <MenuItem key="empty" value="" disabled>
                                            {t('Choose')}
                                        </MenuItem>
                                        {staticContent?.experienceLevels.map(({ id, name }) => (
                                            <MenuItem key={id} value={id}>
                                                {t(name)}
                                            </MenuItem>
                                        ))}
                                    </CustomFormikSelect>
                                    <CustomFormikSelect name="employmentTypeId" label={t('Employment type')}>
                                        <MenuItem key="empty" value="" disabled>
                                            {t('Choose')}
                                        </MenuItem>
                                        {staticContent?.employmentTypes.map(({ id, name }) => (
                                            <MenuItem key={id} value={id}>
                                                {t(name)}
                                            </MenuItem>
                                        ))}
                                    </CustomFormikSelect>
                                    <div>
                                        <div className={classes.locationLabelWrapper}>
                                            <InputLabel>{t('Location')}</InputLabel>
                                            <div className={classes.switchWrapper}>
                                                <Typography className={classes.remotely}>
                                                    {t('Remotely')}
                                                </Typography>
                                                <CustomFormikSwitch disableLabel name="remotely" />
                                            </div>
                                        </div>
                                        <GoogleLocationFormikAutocomplete
                                            name="location"
                                            placeholder={t('Location')}
                                            disableLabel
                                        />
                                    </div>
                                    <div className={classes.moneyWrapper}>
                                        <CustomFormikTextField
                                            name="salaryMin"
                                            type="number"
                                            placeholder={t('Salary min')}
                                            label={t('Salary min')}
                                        />
                                        <CustomFormikTextField
                                            name="salaryMax"
                                            type="number"
                                            placeholder={t('Salary max')}
                                            label={t('Salary max')}
                                        />
                                    </div>
                                    <div className={classes.moneyWrapper}>
                                        <CustomFormikSelect label={t('Currency')} name="currencyId">
                                            <MenuItem key="empty" value="" disabled>
                                                {t('Choose')}
                                            </MenuItem>
                                            {staticContent?.currencies.map(({ id, code }) => (
                                                <MenuItem key={id} value={id}>
                                                    {t(code)}
                                                </MenuItem>
                                            ))}
                                        </CustomFormikSelect>
                                        <CustomFormikSelect name="billingCycleId" label={t('Per')}>
                                            <MenuItem key="empty" value="" disabled>
                                                {t('Choose')}
                                            </MenuItem>
                                            {staticContent?.billingCycles.map(({ period, id }) => (
                                                <MenuItem key={id} value={id}>
                                                    {t(period)}
                                                </MenuItem>
                                            ))}
                                        </CustomFormikSelect>
                                    </div>
                                </div>
                                <div className={classes.tagsAndCategoriesWrapper}>
                                    <TagsFormikAutocomplete name="tags" />
                                    <CustomFormikSelect name="categoryId" label={t('Category')}>
                                        <MenuItem key="empty" value="" disabled>
                                            {t('Choose')}
                                        </MenuItem>
                                        {staticContent?.categories.map(({ name, id }) => (
                                            <MenuItem key={id} value={id}>
                                                {t(name)}
                                            </MenuItem>
                                        ))}
                                    </CustomFormikSelect>
                                </div>
                                <div className={classes.rteWrapper}>
                                    <TeamFormikAutocomplete
                                        isAdmin={isAdmin}
                                        companyId={companyId}
                                        name="teamMembers"
                                        label={t('Team')}
                                    />
                                </div>
                                <div className={classes.rteWrapper}>
                                    <CustomFormikTextField
                                        name="question"
                                        placeholder={t('First Question')}
                                        label={t('First Question')}
                                    />
                                </div>
                                <CustomFormikTextField
                                    name="questionTwo"
                                    placeholder={t('Second Question')}
                                    label={t('Second Question')}
                                />
                                <div className={classes.rteWrapper}>
                                    <FormikRichTextEditor
                                        name="description"
                                        label={t('Job description')}
                                        editorHeight={300}
                                    />
                                </div>
                                <div className={classes.buttonsContainer}>
                                    {reactiveOffer && (
                                        <ReactiveDialog
                                            title={formikInitialValues.title}
                                            id={formikInitialValues.id as number}
                                            archived={formikInitialValues?.archived}
                                            reactiveCallback={() => {
                                                submitForm();
                                            }}
                                        />
                                    )}
                                    <Button
                                        onClick={submitForm}
                                        color="primary"
                                        variant="contained"
                                        className={classes.submitButton}
                                        disabled={isSubmitting}
                                    >
                                        {t(edit ? 'Save' : 'Publish')}
                                    </Button>
                                </div>
                            </form>
                            {additionalElement}
                        </>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default EmployerPostOfferForm;
