import { makeStyles } from '@material-ui/core/styles';

const ELLIPSIS_WIDTH = 130;

export const useStyles = makeStyles({
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    primaryListItem: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '-0.19px',
        color: '#323C47',
        width: ELLIPSIS_WIDTH,
    },
    secondaryListItem: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '28px',
        letterSpacing: '0.2px',
        color: 'rgba(50,60,71,0.5)',
        width: ELLIPSIS_WIDTH,
    },
    thirdListItem: {
        fontSize: '0.8125rem',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.1px',
        color: '#007FFC',
        width: ELLIPSIS_WIDTH,
    },
    container: {
        width: 269,
        borderRadius: 6,
        margin: '0 16px 16px 0',
        padding: '1.5rem 1.25rem',
        boxShadow: '0 2px 9px 0 rgba(0,0,0,0.02)',
        border: '2px solid #F7FBFF',
        '&:hover': {
            border: '2px solid #007FFC',
        },
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0.75rem',
    },
    cardHeader: {
        marginTop: 4,
        marginRight: 4,
        padding: 8,
    },
    profile: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
    },
});
