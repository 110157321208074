import React, { FC, useEffect, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Container from '@material-ui/core/Container';

import { getJobOfferById, updateJobOffer } from '../../../services/AdminService';
import {
    getBackendFormikErrors,
    parseJobOfferToPostOfferForm,
    parsePostOfferFormToJobOffer,
} from '../../../config/utils';
import DeleteJobDialog from '../SupportJobsPage/DeleteJobDialog';
import EmployerPostOfferForm from '../../../parts/EmployerPostOfferForm';
import { PostJobOfferFormValues } from '../../../models/JobOffer';

const SupportEditJobPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const [currentJobOffer, setCurrentJobOffer] = useState<PostJobOfferFormValues>();

    useEffect(() => {
        getJobOfferById(id)
            .then(response => {
                setCurrentJobOffer(parseJobOfferToPostOfferForm(response));
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch static content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, id, t]);

    const handleSubmitForm = async (
        values: PostJobOfferFormValues,
        { setSubmitting, setErrors }: FormikHelpers<PostJobOfferFormValues>,
    ) => {
        try {
            await updateJobOffer(id, parsePostOfferFormToJobOffer(values));
            enqueueSnackbar(t('Job offer successfully saved.'), { variant: 'success' });
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Submit fail', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container>
            {!!currentJobOffer && (
                <EmployerPostOfferForm
                    edit
                    isAdmin
                    companyId={currentJobOffer.companyId}
                    initialValues={currentJobOffer}
                    onSubmit={handleSubmitForm}
                    additionalElement={!currentJobOffer.archived ? <DeleteJobDialog id={id} /> : <></>}
                />
            )}
        </Container>
    );
};

export default SupportEditJobPage;
