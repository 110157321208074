import { FC } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { FormikSelectProps } from '../formik/FormikSelect/FormikSelect';

import { useStyles } from './styles';
import FormikSelect from '../formik/FormikSelect';

const CustomFormikSelect: FC<FormikSelectProps> = ({ name, InputProps, ...otherProps }) => {
    const classes = useStyles();
    const { error, touched } = useField(name)[1];
    const displayError = touched && error !== undefined;

    return (
        <FormikSelect
            name={name}
            variant="outlined"
            InputLabelProps={{
                classes: {
                    root: classes.labelRoot,
                },
            }}
            margin="none"
            classes={{
                root: classes.rootTextField,
                iconOutlined: classes.iconOutlined,
            }}
            containerClassName={clsx({ [classes.error]: displayError })}
            IconComponent={props => (
                <div {...props}>
                    <KeyboardArrowDownIcon />
                </div>
            )}
            {...otherProps}
        />
    );
};

export default CustomFormikSelect;
