import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',
        gridGap: 5,
        justifyContent: 'center',
        [theme.breakpoints.up(340)]: {
            gridGap: '1.75rem',
        },
        [theme.breakpoints.up(700)]: {
            gridTemplateColumns: 'repeat(3, auto)',
        },
        [theme.breakpoints.up(1200)]: {
            gridGap: 20,
            gridTemplateColumns: 'repeat(6, auto)',
        },
    },
}));
