import { FC } from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { useStyles } from './styles';

interface ConfirmDialogProps {
    open: boolean;
    confirmLabel: string;
    cancelLabel: string;
    title: string;
    // @ts-ignore
    onClickConfirm: (any) => void;
    // @ts-ignore
    onClickCancel: (any) => void;
    className?: string;
    submitDisabled?: boolean;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
    cancelLabel,
    children,
    className,
    confirmLabel,
    onClickCancel,
    onClickConfirm,
    open,
    title,
    submitDisabled,
}) => {
    const classes = useStyles();

    return (
        <Dialog classes={{ paperWidthSm: className }} onClose={onClickCancel} open={open}>
            <DialogTitle>{title}</DialogTitle>
            {!!children && <DialogContent>{children}</DialogContent>}
            <div className={classes.buttonsWrapper}>
                <Button
                    disabled={submitDisabled}
                    color="primary"
                    className={classes.nextButton}
                    onClick={onClickCancel}
                >
                    {cancelLabel}
                </Button>
                <Button
                    disabled={submitDisabled}
                    variant="contained"
                    color="primary"
                    onClick={onClickConfirm}
                    className={classes.nextButton}
                >
                    {confirmLabel}
                </Button>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
