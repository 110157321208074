import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    paperWrapper: {
        paddingBottom: '1rem',
        borderRadius: '0.75rem',
        boxShadow: '0 16px 23px -13px #8FACC7',
        border: '1px solid #bce8f1',
    },
    paperTitle: {
        color: '#1F3874',
        backgroundColor: '#d9edf7',
        padding: '1rem',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '19px',
        letterSpacing: '0.57px',
        marginBottom: '0.75rem',
        borderRadius: '0.75rem 0.75rem  0 0',
    },
    historyContainer: {
        marginTop: '1rem',
    },
    paperContainer: {
        padding: '0 1rem',
    },
    statusText: {
        fontSize: '14px',
    },
    status: {
        marginRight: 5,
        color: '#1F3874',
        fontWeight: 700,
    },
    flex: {
        display: 'flex',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    showDialogButton: {
        borderRadius: 4,
        fontSize: '0.8125rem',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        minWidth: '8.5rem',
        marginTop: '1rem',
    },
});
