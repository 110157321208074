import { makeStyles } from '@material-ui/core/styles';

const DATE_PROPOSAL_BREAKPOINT = 700;

export const useStyles = makeStyles(theme => ({
    dateProposal: {
        display: 'grid',
        gridGap: '0.25rem',
        margin: '15px 0',
        color: '#616161',

        [theme.breakpoints.up(DATE_PROPOSAL_BREAKPOINT)]: {
            gridGap: '1rem',
            margin: 'auto',
            gridTemplateColumns: '280px auto',
        },
    },
    date: {
        display: 'flex',
        alignItems: 'center',
    },
    times: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        marginLeft: '0.5rem',
    },
}));
