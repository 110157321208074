import * as yup from 'yup';
import i18n from 'i18next';
import { Company } from '../../../models/Company';

export default interface AdminCreateCompanyFormValues extends Omit<Company, 'id'> {
    username: string;
}

export const initialValues: AdminCreateCompanyFormValues = {
    username: '',
    logo: null,
    backgroundImage: null,
    name: '',
    industry: '',
    companySizeMin: 0,
    companySizeMax: 10,
    url: '',
    contactEmail: '',
    mission: '',
    description: '',
    teamMembers: [],
};

export const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Email is required'))
        .email(i18n.t('Email must be valid')),
    name: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required')),
    industry: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required')),
    url: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            i18n.t('Enter correct url'),
        )
        .required(i18n.t('Field is required')),
    contactEmail: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .email(i18n.t('Email must be valid')),
    companySizeMin: yup
        .number()
        .min(0, `${i18n.t('Value must be more than ')} \${min}`)
        .max(1000000, `${i18n.t('Value must be less than ')} \${max}`)
        .required(i18n.t('Field is required'))
        .typeError(i18n.t('Valid number is required')),
    companySizeMax: yup
        .number()
        .min(1, `${i18n.t('Value must be more than')} \${min}`)
        .max(1000000, `${i18n.t('Value must be less than')} \${max}`)
        .required(i18n.t('Field is required'))
        .typeError(i18n.t('Valid number is required')),
});
