import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import clsx from 'clsx';

import { useWindowSize } from '../../config/hooks/useWindowSize';
import { useStyles } from './styles';

interface JobOfferCardLoaderProps {
    amount?: number;
}

const JobOfferCardLoader: FC<JobOfferCardLoaderProps> = ({ amount = 1 }) => {
    const classes = useStyles();
    const { width } = useWindowSize();
    const loaderWidth = width ? Math.min(1172, width) : 0;

    return (
        <div className={clsx({ [classes.grid]: amount > 1 })}>
            {Array.from(Array(amount).keys()).map(item => (
                <ContentLoader
                    key={item}
                    speed={2}
                    width={loaderWidth}
                    height={113}
                    viewBox={`0 0 ${loaderWidth} 113`}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="8" ry="8" width={loaderWidth} height="113" />
                </ContentLoader>
            ))}
        </div>
    );
};

export default JobOfferCardLoader;
