import { FC } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import Error404Image from '../../../assets/404.svg';

const Error404Page: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container className={classes.container}>
            <div className={classes.firstPanel}>
                <div className={classes.panelWrapper}>
                    <Typography gutterBottom className={classes.title}>
                        {t('Whoops')}
                    </Typography>
                    <Typography className={classes.description}>
                        {t('The requested page could not be found.')}
                    </Typography>
                </div>
                <img src={Error404Image} className={classes.firstPanelImage} alt="Error 404" />
            </div>
        </Container>
    );
};

export default Error404Page;
