import axios from 'axios';
import { CreateMeeting, MeetingDateProposal } from '../models/Meeting';

export const createMeeting = async (
    applicationId: number | string,
    params: CreateMeeting,
): Promise<unknown> => {
    const { data } = await axios.post(`job-offer/applications/${applicationId}/meeting`, params);
    return data;
};

export const updateDateProposal = async (
    meetingId: number | string,
    dateProposal: MeetingDateProposal,
): Promise<unknown> => {
    const { data } = await axios.put(`job-offer/applications/meeting/${meetingId}/date`, dateProposal);
    return data;
};
