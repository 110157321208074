import { FC } from 'react';

import FormikSwitch from '../formik/FormikSwitch';
import { FormikSwitchProps } from '../formik/FormikSwitch/FormikSwitch';
import { useStyles } from './styles';

const CustomFormikSwitch: FC<FormikSwitchProps> = props => {
    const classes = useStyles();

    return (
        <FormikSwitch
            classes={{
                root: classes.root,
                // @ts-ignore
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
};

export default CustomFormikSwitch;
