import { makeStyles } from '@material-ui/core/styles';
import { HEADER_BREAKPOINT } from '../../config/constants';

const JOB_OFFER_DETAILS_BREAKPOINT = 650;

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        gridGap: '1.5rem',
        marginBottom: '1rem',
        padding: '0 32px',
        gridTemplateColumns: 'minmax(0, 1fr)',
        [theme.breakpoints.up(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            marginBottom: '2.5rem',
            gridGap: '2.25rem',
            gridTemplateColumns: 'initial',
        },
    },
    companyLogo: {
        maxWidth: 120,
        maxHeight: 120,
    },
    memberWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    description: {
        color: '#616161',
        fontSize: '0.875rem',
        fontWeight: 400,
        letterSpacing: '0.5px',
        lineHeight: '1.25rem',
        maxWidth: '100%',
        fontFamily: theme.typography.fontFamily,
        '& *': {
            marginBlock: 0,
        },
        [theme.breakpoints.down(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            alignSelf: 'flex-start',
            overflowWrap: 'break-word',
        },
    },
    headerDescription: {
        fontSize: '0.75rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.43px',
        color: '#616161',
        '& *': {
            marginBlock: 0,
        },
        [theme.breakpoints.up(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.57px',
        },
    },
    paperWrapper: {
        padding: '1.5rem 2rem 2rem',
        borderRadius: '0.75rem',
        boxShadow: '0 16px 23px -13px #8FACC7',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            height: '100%',
            padding: 22,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    paperTitle: {
        color: '#1F3874',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '19px',
        letterSpacing: '0.57px',
        marginBottom: '1rem',
        [theme.breakpoints.up(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            fontSize: '1.3rem',
            marginBottom: '1.5rem',
        },
    },
    applyButton: {
        fontSize: '0.875rem',
        fontWeight: 500,
        padding: '0.8125rem 3rem',
        letterSpacing: '0.46px',
        marginTop: '2rem',
        [theme.breakpoints.down(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            padding: '0.8125rem 1rem',
            width: '100%',
        },
    },
    jobTitle: {
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '68px',
        letterSpacing: '0.82px',
        color: '#15416D',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            fontSize: '44px',
        },
    },
    detailText: {
        display: 'flex',
        alignItems: 'center',
        color: '#616161',
        fontSize: '0.75rem',
        lineHeight: '19px',
        letterSpacing: '0.43px',
        marginBottom: '0.5rem',
        [theme.breakpoints.up(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            fontSize: '0.875rem',
        },
    },
    detailsIcon: {
        marginRight: '0.5rem',
    },
    stackChip: {
        padding: '6px 13px',
        borderRadius: 4,
        backgroundColor: 'rgba(0,252,41,0.17)',
        marginRight: 8,
        marginBottom: 8,
        color: '#26A425',
        fontSize: 9,
        lineHeight: '11px',
    },
    tagsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0.5rem 0 1.25rem',
    },
    flag: {
        marginRight: 6,
        marginBottom: -1,
    },
    headerButtons: {
        display: 'flex',
        width: '8rem',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerButton: {
        padding: 0,
        alignSelf: 'center',
    },
    headerFlex: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    infoWrapper: {
        marginLeft: 13,
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        color: '#1F3874',
        fontSize: '1rem',
        lineHeight: '2rem',
        letterSpacing: '0.21px',
    },
    position: {
        color: '#616161',
        letterSpacing: '0.43px',
        fontSize: '0.75rem',
        lineHeight: '20px',
    },
    teamWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1.5rem',
        [theme.breakpoints.up(550)]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up(790)]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    similarJob: {
        marginBottom: '1rem',
    },
    similarJobDetailsContainer: {
        marginTop: '1rem',
        padding: '0 32px',

        [theme.breakpoints.up(JOB_OFFER_DETAILS_BREAKPOINT)]: {
            padding: 'initial',
        },
    },
    similarJobsTitle: {
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: '#1F3874',
        letterSpacing: '0.57px',
        paddingBottom: '1.5rem',
    },
}));
