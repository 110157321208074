import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import { useStyles } from './styles';
import { initialValues, validationSchema } from './config';
import CustomFormikTextField from '../CustomFormikTextField';
import FormikImageUpload from '../formik/FormikImageUpload';
import { TeamMember } from '../../models/Company';

interface AddTeamMemberDialogProps {
    onSubmitTeamMember: (teamMember: TeamMember) => void;
}

const AddTeamMemberDialog: FC<AddTeamMemberDialogProps> = ({ onSubmitTeamMember }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);

    // @ts-ignore
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'add-member-popover' : undefined;

    const renderAddButton = () => {
        return (
            <Button
                aria-describedby={id}
                color="primary"
                variant="outlined"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={handleClick}
            >
                {t('Add new member')}
            </Button>
        );
    };

    const handleSubmitForm = async (values: TeamMember) => {
        handleClose();
        onSubmitTeamMember(values);
    };

    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <CustomFormikTextField name="name" placeholder={t('name')} label={t('Name')} />
                        <CustomFormikTextField
                            name="position"
                            placeholder={t('position')}
                            label={t('Position')}
                        />
                        <FormikImageUpload name="avatar" />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.submitButton}
                            disabled={isSubmitting}
                        >
                            {t('Add new member')}
                        </Button>
                    </form>
                )}
            </Formik>
        );
    };

    const renderPopover = () => {
        return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {renderForm()}
            </Popover>
        );
    };

    return (
        <>
            {renderAddButton()}
            {renderPopover()}
        </>
    );
};

export default AddTeamMemberDialog;
