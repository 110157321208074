import { FC } from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

interface Props {
    alt?: string;
    src?: string;
    classNameImage?: string;
    classNameWrapper?: string;
}

const CompanyLogoImage: FC<Props> = ({ classNameImage, classNameWrapper, alt, src }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.companyLogoWrapper, classNameWrapper)}>
            {src ? (
                <img alt={alt} src={src} className={clsx(classes.companyLogo, classNameImage)} />
            ) : (
                <div className={classes.companyName}>{alt || 'N/A'}</div>
            )}
        </div>
    );
};

export default CompanyLogoImage;
