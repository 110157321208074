import * as yup from 'yup';
import i18n from 'i18next';
import dayjs from 'dayjs';
import Media from '../../../../models/Media.model';

export interface CandidateEducationForm {
    schoolName: string;
    schoolLogo: Media | null;
    specialization: string;
    grade: string;
    startDate: string;
    endDate: string;
    stillStudyingHere: boolean;
    userProfileId?: number;
    id?: number;
}

export const defaultInitialValues: CandidateEducationForm = {
    schoolName: '',
    schoolLogo: null,
    specialization: '',
    grade: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    stillStudyingHere: false,
};

export const validationSchema = yup.object().shape({
    schoolName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('School name is required')),
    specialization: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    grade: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    startDate: yup.date().required(i18n.t('Field is required')).typeError('Valid date is required'),
    endDate: yup
        .date()
        .min(yup.ref('startDate'), 'End date has to be after start date')
        .required(i18n.t('Field is required'))
        .typeError('Valid date is required'),
    stillStudyingHere: yup.boolean().required(i18n.t('Field is required')),
});
