import React, { FC } from 'react';
import { RadioGroup } from 'formik-material-ui';
import { useFormikContext, Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core/Input';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import FormikErrorText from '../FormikErrorText';

export interface FormikSwitchProps extends BaseTextFieldProps {
    name: string;
    label?: string;
    InputProps?: Partial<InputProps>;
    disableLabel?: boolean;
    options: FormControlLabelProps[];
}
const FormikRadioGroup: FC<FormikSwitchProps> = ({ id, label, name, disableLabel, options, disabled }) => {
    const { isSubmitting } = useFormikContext();

    return (
        <div>
            {!disableLabel && <InputLabel htmlFor={id || name}>{label || name}</InputLabel>}
            <Field component={RadioGroup} name={name}>
                {options.map((option, index) => (
                    <FormControlLabel key={index} disabled={disabled || isSubmitting} {...option} />
                ))}
            </Field>
            <FormikErrorText name={name} />
        </div>
    );
};
export default FormikRadioGroup;
