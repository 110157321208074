import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import EmployerPostOfferForm from '../../../parts/EmployerPostOfferForm';
import { editJobOffer, getJobOfferForEdit } from '../../../services/JobOfferService';
import { PostJobOfferFormValues } from '../../../models/JobOffer';
import {
    getBackendFormikErrors,
    parseJobOfferToPostOfferForm,
    parsePostOfferFormToJobOffer,
} from '../../../config/utils';
import { ROUTES } from '../../../config/constants';

const EmployerEditOfferPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [initialValues, setInitialValues] = useState<PostJobOfferFormValues | null>(null);

    useEffect(() => {
        getJobOfferForEdit(id)
            .then(response => {
                setInitialValues(parseJobOfferToPostOfferForm(response));
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch offer data, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, id, t]);

    const handleSubmit = async (
        values: PostJobOfferFormValues,
        { setSubmitting, setErrors }: FormikHelpers<PostJobOfferFormValues>,
    ) => {
        try {
            await editJobOffer(id, parsePostOfferFormToJobOffer(values));
            enqueueSnackbar(t('Successfully post job offer.'), { variant: 'success' });
            history.push(ROUTES.EMPLOYER_HOME);
        } catch (error) {
            setErrors(getBackendFormikErrors<PostJobOfferFormValues>(error));
            enqueueSnackbar('Submit fail', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container>
            <EmployerPostOfferForm edit reactiveOffer initialValues={initialValues} onSubmit={handleSubmit} />
        </Container>
    );
};

export default EmployerEditOfferPage;
