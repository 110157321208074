import React, { FC } from 'react';
import { getNameInitials } from '../../config/utils';
import { useStyles } from './styles';

interface TeamMemberAvatarProps {
    name: string;
    url: string | null | undefined;
    size: number;
    fontSize: number;
}

const TeamMemberAvatar: FC<TeamMemberAvatarProps> = ({ name, url, size, fontSize }) => {
    const classes = useStyles({ size, fontSize });

    const renderContent = () => {
        return url ? (
            <img src={url} alt="team member avatar" className={classes.image} />
        ) : (
            <div className={classes.noImage}>{getNameInitials(name)}</div>
        );
    };

    return <div className={classes.boxSize}>{renderContent()}</div>;
};

export default TeamMemberAvatar;
