import { ROUTES } from '../../config/constants';

export const FooterLinks = [
    {
        name: 'Jobs',
        path: ROUTES.CANDIDATE_FIND_A_JOB,
        target: '_self',
    },
    {
        name: 'Privacy Policy',
        path: 'https://app.termly.io/document/privacy-policy/a4dd304b-5c44-4e5e-9c2b-925bc28add42',
        target: '_blank',
    },
    {
        name: 'Terms of Use',
        path: 'https://app.termly.io/document/terms-of-use-for-website/eba1e1d2-ac6f-434b-8c2b-f4df3a72c80b',
        target: '_blank',
    },
    {
        name: 'Contact',
        path: ROUTES.CONTACT,
        target: '_self',
    },
    {
        name: 'About',
        path: ROUTES.ABOUT,
        target: '_self',
    },
];
