import { FC, useState, ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';

interface Props {
    tabs: { tab: ReactElement; label: string }[];
}

const ResourceManagementPage: FC<Props> = ({ tabs }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container className={classes.container}>
            <Tabs
                classes={{ indicator: classes.indicator }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabs.map(({ label }) => (
                    <Tab key={label} classes={{ root: classes.tabRoot }} label={t(label)} />
                ))}
            </Tabs>
            {tabs.map(({ label, tab }, index) => (
                <div key={label} className={classes.wrapper}>
                    {value === index && tab}
                </div>
            ))}
        </Container>
    );
};

export default ResourceManagementPage;
