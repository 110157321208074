import { FC } from 'react';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { PaginatedResponse, usePaginationFetch } from '../../../../config/hooks/usePaginationFetch';
import { ListJobOfferApplication } from '../../../../models/JobOfferApplication';
import FilterData from '../../../../models/FilterData';
import JobOfferApplicationStatusCard from '../../../../components/JobOfferApplicationStatusCard';
import CustomPagination from '../../../../components/CustomPagination';
import JobOfferCardsWrapper from '../../../../components/JobOfferCardsWrapper';

interface Props {
    serviceProp: (params: FilterData) => Promise<PaginatedResponse<ListJobOfferApplication>>;
}

const MyRolesContent: FC<Props> = ({ serviceProp }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        content,
        totalPages,
        totalElements,
        setRefetch,
        setDataFilters,
    } = usePaginationFetch<ListJobOfferApplication>({
        service: serviceProp,
    });

    const renderContent = () => {
        return (
            <JobOfferCardsWrapper>
                {content.map(jobOfferApplication => (
                    <JobOfferApplicationStatusCard
                        key={jobOfferApplication.id}
                        removeCallback={() => {
                            setRefetch(update => !update);
                        }}
                        jobOfferButtonState={jobOfferApplication.buttonState}
                        {...jobOfferApplication}
                    />
                ))}
            </JobOfferCardsWrapper>
        );
    };

    const renderPagination = () => {
        if (totalPages > 1) {
            return (
                <CustomPagination
                    count={totalPages}
                    onChange={(event, newPage) => {
                        setDataFilters(dataFilters => ({ ...dataFilters, page: newPage - 1 }));
                    }}
                    variant="outlined"
                    color="primary"
                />
            );
        }
        return null;
    };

    if (totalElements === null) {
        return (
            <Fade in timeout={1000}>
                <div className={classes.centerWrapper}>
                    <CircularProgress />
                </div>
            </Fade>
        );
    }
    if (totalElements === 0) {
        return (
            <Fade in>
                <Container>
                    <Alert severity="info" className={classes.alert}>
                        {t('No results found for your search')}
                    </Alert>
                </Container>
            </Fade>
        );
    }

    return (
        <div className={classes.contentWrapper}>
            <Container>
                {renderContent()}
                {renderPagination()}
            </Container>
        </div>
    );
};

export default MyRolesContent;
