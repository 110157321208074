import { makeStyles, Theme } from '@material-ui/core/styles';

interface UserAvatarStyleProps {
    size: number;
}

export const useStyles = makeStyles<Theme, UserAvatarStyleProps>({
    imageContainer: ({ size }) => ({
        borderRadius: '50%',
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    image: ({ size }) => ({
        borderRadius: '50%',
        width: size,
        height: size,
        objectFit: 'contain',
    }),
    defaultAvatar: ({ size }) => ({
        color: '#007FFC',
        fontSize: size,
    }),
});
