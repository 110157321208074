import { makeStyles } from '@material-ui/core/styles';

const BUTTONS_WRAPPER_BREAKPOINT = 600;

export const useStyles = makeStyles(theme => ({
    cookiesContainer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: 9999,
    },
    cookiesWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1000px',
        opacity: 1,
        boxShadow: 'rgb(0 0 0 / 10%) 0px 24px 32px, rgb(0 0 0 / 10%) 0px 8px 32px',
        margin: '0px auto 20px',
        background: 'rgb(255, 255, 255)',
        padding: '16px',
        borderRadius: '32px',
    },
    cookieIcon: {
        '&::before': {
            content: "'🍪'",
            fontSize: '30px',
        },
    },
    text: {
        letterSpacing: '0.3px',
        fontWeight: 700,
        color: '#15416D',
        fontSize: '16px',
        margin: '0px 20px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(BUTTONS_WRAPPER_BREAKPOINT)]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    acceptButton: {
        borderStyle: 'none',
        color: '#15416D',
        width: '100%',
        fontSize: '16px',
        background: 'rgb(255, 255, 255)',
        borderRadius: '24px',
        padding: '12px 24px',
        boxShadow:
            'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
        transition: 'all 0.5s ease 0s',
        cursor: 'pointer',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        [theme.breakpoints.down(BUTTONS_WRAPPER_BREAKPOINT)]: {
            marginBottom: '8px',
            width: '105px',
        },
    },
    readMoreButton: {
        marginLeft: '8px',
        fontSize: '16px',
        height: 'auto',
        color: '#15416D',
        padding: '10px 18px',
        border: '1px solid rgb(181, 181, 181)',
        borderRadius: '24px',
        boxShadow: 'none',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        transition: 'all 0.5s ease 0s',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        [theme.breakpoints.down(BUTTONS_WRAPPER_BREAKPOINT)]: {
            marginLeft: 0,
        },
    },
}));
