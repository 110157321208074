import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        flex: 1,
        marginBottom: '3rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    processing: {
        marginTop: '1rem',
        color: '#616161',
        fontSize: '1.25rem',
        fontWeight: 500,
        letterSpacing: '0.5px',
    },
});
