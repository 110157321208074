import { makeStyles } from '@material-ui/core/styles';
import { HEADER_BREAKPOINT } from '../../config/constants';

export const useStyles = makeStyles(theme => ({
    button: {
        width: 102,
        margin: '2rem 0 0 0.5rem',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F7FBFF',
        padding: '2rem 3.25rem',
        maxWidth: '40rem',
        width: '100%',
    },
    form: {
        maxWidth: '32rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    center: {
        textAlign: 'center',
    },
    title: {
        fontSize: '1.25rem',
        fontWeight: 700,
        letterSpacing: '0.48px',
        color: '#15416D',
        marginBottom: 20,
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            fontSize: '1.625rem',
            lineHeight: '4.5rem',
            marginBottom: 0,
        },
    },
    subTitle: {
        fontSize: '0.75rem',
        fontWeight: 700,
        letterSpacing: '0.48px',
    },
    infoContainer: {
        paddingTop: '1.5rem',
    },
    companyLogo: {
        maxWidth: 100,
        maxHeight: 50,
    },
    bold: {
        fontWeight: 700,
    },
    dateFields: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        display: 'grid',
        gridGap: '0.75rem',
        gridTemplateColumns: 'repeat(4, auto)',
        alignItems: 'flex-start',
    },
    removeButton: {
        marginTop: 5,
    },
    addButton: {
        width: '120px',
        height: '35px',
    },
    sendButton: {
        margin: '2rem auto 0',
        width: 149,
    },
}));
