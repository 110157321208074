import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    button: {
        width: 102,
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F7FBFF',
        maxWidth: '40rem',
        width: '100%',
        padding: '0 0.5rem 2.25rem',
    },
    title: {
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '4.25rem',
        letterSpacing: '0.48px',
        color: '#15416D',
    },
    formControlRoot: {
        padding: '0.5rem',
    },
    formControlLabel: {
        fontSize: '0.875rem',
    },
});
