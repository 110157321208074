import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import { CheckboxWithLabel } from 'formik-material-ui';
import { FormHelperText } from '@material-ui/core';
import { ROUTES } from '../../../../config/constants';
import { createEmployer } from '../../../../services/UserService';
import BlurredStyleFormikTextField from '../../../../components/BlurredStyleFormikTextField';

import EmployerLoginFormValues, { initialValues, TermsLinks, validationSchema } from './config';
import { useStyles } from './styles';
import { getBackendFormikErrors } from '../../../../config/utils';

const EmployerSignUpForm: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { t } = useTranslation();

    const handleSubmitForm = async (
        values: EmployerLoginFormValues,
        { setErrors }: FormikHelpers<EmployerLoginFormValues>,
    ) => {
        const {
            username,
            password,
            passwordConfirmation,
            firstName,
            lastName,
            companyName,
            termsOfUseAccepted,
        } = values;
        createEmployer({
            username,
            password,
            passwordConfirmation,
            firstName,
            lastName,
            companyName,
            termsOfUseAccepted,
        })
            .then(() => {
                enqueueSnackbar(t('Signed up successfully! Please proceed to login.'), {
                    variant: 'success',
                });
                history.push(ROUTES.EMPLOYER_SIGN_IN);
            })
            .catch(error => {
                setErrors(getBackendFormikErrors<EmployerLoginFormValues>(error));
                enqueueSnackbar(t('Failed to create account. Please try again.'), { variant: 'error' });
            });
    };

    return (
        <Container className={classes.container}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {formik => {
                    const termsOfUseAcceptedError = formik.getFieldMeta('termsOfUseAccepted').error;
                    return (
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                            <div className={classes.flexFields}>
                                <BlurredStyleFormikTextField
                                    name="firstName"
                                    placeholder={t('First name')}
                                    label={t('First name')}
                                />

                                <BlurredStyleFormikTextField
                                    name="lastName"
                                    placeholder={t('Last name')}
                                    label={t('Last name')}
                                />
                            </div>
                            <BlurredStyleFormikTextField
                                name="username"
                                placeholder={t('Email')}
                                label={t('Email')}
                            />
                            <BlurredStyleFormikTextField
                                name="password"
                                type="password"
                                placeholder={t('Password')}
                            />
                            <BlurredStyleFormikTextField
                                name="passwordConfirmation"
                                type="password"
                                placeholder={t('Repeat password')}
                                label={t('Repeat password')}
                            />
                            <BlurredStyleFormikTextField
                                name="companyName"
                                placeholder={t('Company name')}
                                label={t('Company name')}
                            />
                            <Field
                                component={CheckboxWithLabel}
                                color="primary"
                                type="checkbox"
                                name="termsOfUseAccepted"
                                Label={{
                                    label: (
                                        <p className={classes.termsOfUseText}>
                                            {t('By checking this field you agree that you have read our ')}
                                            <Link
                                                component={RouterLink}
                                                to={{ pathname: TermsLinks.privacyPolicy.path }}
                                                target={TermsLinks.privacyPolicy.target}
                                            >
                                                {t(TermsLinks.privacyPolicy.name)}
                                            </Link>
                                            {t(', and agree to both our ')}
                                            <Link
                                                key={TermsLinks.generalTerms.name}
                                                component={RouterLink}
                                                to={{ pathname: TermsLinks.generalTerms.path }}
                                                target={TermsLinks.generalTerms.target}
                                            >
                                                {t(TermsLinks.generalTerms.name)}
                                            </Link>
                                            {t(' and also our ')}
                                            <Link
                                                key={TermsLinks.employersTerms.name}
                                                component={RouterLink}
                                                to={{ pathname: TermsLinks.employersTerms.path }}
                                                target={TermsLinks.employersTerms.target}
                                            >
                                                {t(TermsLinks.employersTerms.name)}
                                            </Link>
                                        </p>
                                    ),
                                }}
                            />
                            {termsOfUseAcceptedError ? (
                                <FormHelperText error>{termsOfUseAcceptedError}</FormHelperText>
                            ) : null}
                            <div className={classes.bottomContainer}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={classes.submitButton}
                                >
                                    {t('Sign up')}
                                </Button>
                                <Link component={RouterLink} to={ROUTES.EMPLOYER_SIGN_IN}>
                                    {t('Sign in')}
                                </Link>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default EmployerSignUpForm;
