import { makeStyles, Theme } from '@material-ui/core/styles';

import { JOB_OFFER_CARD_BREAKPOINT, LANDING_BREAKPOINT_FIRST } from '../../config/constants';
import { LandingPageSectionStyleProps } from './types';

export const useStyles = makeStyles<Theme, LandingPageSectionStyleProps>(theme => ({
    container: {
        backgroundColor: ({ sectionBackgroundColor }) => sectionBackgroundColor,
        padding: '2rem 0',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            padding: '3.75rem 0',
        },
    },
    title: {
        fontSize: 30,
        letterSpacing: '1.61px',
        fontWeight: 500,
        color: ({ titleColor }) => titleColor,
        textAlign: 'center',
        marginBottom: '2.5rem',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            fontSize: 35,
            marginBottom: '3rem',
        },
    },
    sliderWrapper: {
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            padding: 0,
            overflowX: 'hidden',
        },
    },
}));
