import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../../../../components/ConfirmDialog';
import { useStyles } from './styles';
import { ROUTES } from '../../../../config/constants';
import { deleteJobOffer } from '../../../../services/AdminService';

interface Props {
    id: number | string;
}

const DeleteJobDialog: FC<Props> = ({ id }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await deleteJobOffer(id);
            history.push(ROUTES.SUPPORT_JOBS);
            enqueueSnackbar(t('Job offer deactivated'), { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(t('Unable to deactivate, try again later'), { variant: 'error' });
        } finally {
            setOpen(false);
            setLoading(false);
        }
    };

    const renderDialog = () => (
        <ConfirmDialog
            open={open}
            confirmLabel={t('Yes')}
            cancelLabel={t('No')}
            title={t('Are you sure you want to remove this job offer?')}
            onClickConfirm={handleConfirm}
            onClickCancel={() => {
                setOpen(false);
            }}
        />
    );

    const renderButton = () => (
        <Button
            color="primary"
            variant="outlined"
            className={classes.addButton}
            startIcon={<DeleteIcon />}
            onClick={handleClick}
            disabled={loading}
        >
            {t('Delete')}
        </Button>
    );

    return (
        <>
            {renderButton()}
            {renderDialog()}
        </>
    );
};

export default DeleteJobDialog;
