import { makeStyles } from '@material-ui/core/styles';
import { JOB_OFFER_CARD_BREAKPOINT, JOB_OFFER_CARD_MOBILE_WIDTH } from '../../config/constants';

export const useStyles = makeStyles(theme => ({
    jobsWrapper: {
        display: 'grid',
        gridGap: 18,
        justifyItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: 'auto',
        [theme.breakpoints.up(JOB_OFFER_CARD_MOBILE_WIDTH)]: {
            gridTemplateColumns: `${JOB_OFFER_CARD_MOBILE_WIDTH}px`,
        },
        [theme.breakpoints.up(800)]: {
            gridTemplateColumns: `${JOB_OFFER_CARD_MOBILE_WIDTH}px ${JOB_OFFER_CARD_MOBILE_WIDTH}px`,
            justifyContent: 'space-around',
        },
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            justifyItems: 'normal',
            gridTemplateColumns: '1fr',
            justifyContent: 'center',
        },
    },
}));
