import axios from 'axios';

import EmployerLoginFormValues from '../pages/employer/EmployerSignUpPage/EmployerSignUpForm/config';
import { Company, TeamMember } from '../models/Company';
import { CurrentUserResponse } from '../models/User';
import UserProfile from '../models/UserProfile';

export const createEmployer = async (formValues: EmployerLoginFormValues): Promise<unknown> => {
    const { data } = await axios.post('users', formValues);
    return data;
};

export const getCurrentUser = async (): Promise<CurrentUserResponse> => {
    const { data } = await axios.get('users/current');
    const { authorities, ...other } = data;
    return { ...other, role: authorities[0] };
};

export const getCurrentUserProfile = async (): Promise<UserProfile> => {
    const { data } = await axios.get('users/current/profile');
    return data;
};

export const getUserProfile = async (id: string | number): Promise<UserProfile> => {
    const { data } = await axios.get(`users/profiles/${id}`);
    return data;
};

export const editCurrentUserProfile = async (userProfile: UserProfile): Promise<UserProfile> => {
    const { data } = await axios.put('users/current/profile', userProfile);
    return data;
};

export const deleteUser = async (id: number | string): Promise<boolean> => {
    const { data } = await axios.delete(`users/${id}`);
    return data;
};

export const editUserProfile = async (
    id: number | string,
    userProfile: UserProfile,
): Promise<UserProfile> => {
    const { data } = await axios.put(`users/profiles/${id}`, userProfile);
    return data;
};

export const getCurrentEmployerCompany = async (): Promise<Company> => {
    const { data } = await axios.get('users/current/company');
    return data;
};

export const editCurrentEmployerCompany = async (company: Omit<Company, 'id'>): Promise<Company> => {
    const { data } = await axios.put('users/current/company', company);
    return data;
};

export const getCurrentEmployerCompanyTeamMembers = async (): Promise<TeamMember[]> => {
    const { data } = await axios.get('users/current/company/team-members');
    return data;
};

export const forgotPassword = async (email: string): Promise<boolean> => {
    const { data } = await axios.post(`credentials/forgot`, { email });
    return data;
};
