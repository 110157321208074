import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    selected: {},
    outlined: {
        border: '2px solid rgba(0, 0, 0, 0.23)',
        fontWeight: 500,
    },
    outlinedPrimary: {
        '&$selected': {
            border: `2px solid ${fade(theme.palette.primary.main, 0.5)}`,
        },
    },
    ul: {
        margin: '1.5rem auto',
        justifyContent: 'center',
    },
}));
