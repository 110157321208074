import { FC } from 'react';
import { FormikTextFieldProps } from '../formik/FormikTextField/types';
import FormikTextField from '../formik/FormikTextField';
import { useStyles } from './styles';

const BlurredStyleFormikTextField: FC<FormikTextFieldProps> = ({
    InputProps,
    InputLabelProps,
    ...otherProps
}) => {
    const classes = useStyles();

    return (
        <FormikTextField
            InputProps={{
                classes: {
                    focused: classes.focused,
                    // @ts-ignore
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                    root: classes.root,
                },
                ...InputProps,
            }}
            InputLabelProps={{
                classes: { root: classes.labelRoot },
                ...InputLabelProps,
            }}
            margin="none"
            variant="outlined"
            {...otherProps}
        />
    );
};

export default BlurredStyleFormikTextField;
