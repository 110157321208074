import { makeStyles } from '@material-ui/core/styles';

const ANALYTICS_CARD_BREAKPOINT = 500;

export const useStyles = makeStyles(theme => ({
    primaryListItem: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '-0.19px',
        color: '#323C47',
        [theme.breakpoints.down(ANALYTICS_CARD_BREAKPOINT)]: {
            fontSize: '0.85rem',
        },
    },
    secondaryListItem: {
        fontSize: '1.4rem',
        fontWeight: 500,
        lineHeight: '40px',
        letterSpacing: '0.2px',
        color: 'rgba(50,60,71,0.5)',
        [theme.breakpoints.down(ANALYTICS_CARD_BREAKPOINT)]: {
            fontSize: '1rem',
        },
    },
    container: {
        width: '415px',
        borderRadius: 6,
        margin: '0 16px 16px 0',
        padding: '1.5rem 1.25rem',
        boxShadow: '0 2px 9px 0 rgba(0,0,0,0.02)',
        border: '2px solid #F7FBFF',
        '&:hover': {
            border: '2px solid #007FFC',
        },
        [theme.breakpoints.down(ANALYTICS_CARD_BREAKPOINT)]: {
            width: '300px',
        },
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0.75rem',
    },
}));
