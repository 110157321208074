import React, { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isArray, toString } from 'lodash';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { InView } from 'react-intersection-observer';
import LinearProgress from '@material-ui/core/LinearProgress';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { usePaginationFetch } from '../../config/hooks/usePaginationFetch';
import BlurredStyleFormikTextField from '../../components/BlurredStyleFormikTextField';
import CompanyCard from '../../pages/admin/AdminCompaniesPage/CompanyCard';
import { getPaginatedCompanies } from '../../services/AdminService';
import { Company } from '../../models/Company';
import { AdminCompaniesPanelFormValues, initialValues } from '../../pages/admin/AdminCompaniesPage/config';
import { ROUTES } from '../../config/constants';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { UserRoles } from '../../models/enums/UserRoles';

const CompaniesPanel: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const { content, error, loading, setDataFilters, last } = usePaginationFetch<Company>({
        service: getPaginatedCompanies,
        initialSize: 12,
        accumulateData: true,
    });

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const handleSubmitForm = async (values: AdminCompaniesPanelFormValues) => {
        const filters = Object.entries(values)
            .map(([key, value]) => {
                const newValue = key === 'search' ? value.trim() : value;
                return [key, newValue];
            })
            .map(([key, value]) => {
                const newValues = isArray(value) ? value.map(item => toString(item)) : [toString(value)];
                return {
                    column: key,
                    values: newValues,
                };
            });

        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
            filters,
        }));
    };

    const getNextPage = () => {
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: dataFilters.page + 1,
        }));
    };

    const renderLoader = () => {
        if (last || loading || error) return null;

        return (
            <InView
                threshold={1}
                onChange={inView => {
                    if (inView) {
                        getNextPage();
                    }
                }}
            >
                <LinearProgress className={classes.loader} />
            </InView>
        );
    };

    const renderCompany = () => content.map(company => <CompanyCard key={company.id} company={company} />);

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <div className={classes.candidatesWrapper}>{renderCompany()}</div>
            </div>
        );
    };

    const renderCreateButton = () => {
        return (
            <Button
                color="primary"
                variant="outlined"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() =>
                    history.push(
                        role === UserRoles.ADMIN
                            ? ROUTES.ADMIN_CREATE_COMPANY
                            : ROUTES.SUPPORT_CREATE_COMPANY,
                    )
                }
            >
                {t('New company')}
            </Button>
        );
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
            {({ handleSubmit, submitForm }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.searchWrapper}>
                        <BlurredStyleFormikTextField
                            name="search"
                            placeholder={t('Search')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={classes.searchIcon}
                                        onClick={submitForm}
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disableLabel
                        />
                        {renderCreateButton()}
                    </div>
                    {renderContent()}
                    {renderLoader()}
                </form>
            )}
        </Formik>
    );
};

export default CompaniesPanel;
