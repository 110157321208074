import { FC } from 'react';

import Pagination, { PaginationProps } from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { useStyles } from './styles';

const CustomPagination: FC<PaginationProps> = ({ classes: customClasses, ...otherProps }) => {
    const classes = useStyles();

    return (
        <Pagination
            classes={{ ul: classes.ul, ...customClasses }}
            renderItem={item => (
                <PaginationItem
                    classes={{
                        selected: classes.selected,
                        outlined: classes.outlined,
                        outlinedPrimary: classes.outlinedPrimary,
                    }}
                    {...item}
                />
            )}
            variant="outlined"
            color="primary"
            {...otherProps}
        />
    );
};

export default CustomPagination;
