import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import { isEqual } from 'lodash';
import NavigationPrompt from 'react-router-navigation-prompt';
import CustomFormikTextField from '../../components/CustomFormikTextField';
import { useStyles } from './styles';
import { getNameInitials } from '../../config/utils';
import UserProfile from '../../models/UserProfile';
import FormikAvatarUpload from '../../components/formik/FormikAvatarUpload';
import ConfirmDialog from '../../components/ConfirmDialog';

interface Props {
    currentProfile: UserProfile | null;
    additionalElement?: JSX.Element;
}

const EmployerProfileForm: FC<Props> = ({ currentProfile, additionalElement }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        initialValues,
    } = useFormikContext<UserProfile>();

    const getInitials = (): string => {
        if (!currentProfile?.firstName && !currentProfile?.lastName) {
            return 'N/A';
        }
        return getNameInitials(`${currentProfile?.firstName || ''} ${currentProfile?.lastName || ''}`);
    };

    const renderProfileDetails = () => (
        <div className={classes.profileDetails}>
            <CustomFormikTextField name="firstName" placeholder={t('First name')} label={t('First name')} />
            <CustomFormikTextField name="lastName" placeholder={t('Last name')} label={t('Last name')} />

            <CustomFormikTextField
                name="contactPhoneNumber"
                placeholder={t('Phone number')}
                label={t('Phone number')}
            />
            <CustomFormikTextField
                name="contactEmail"
                placeholder={t('Contact email')}
                label={t('Contact email')}
            />
        </div>
    );

    return (
        <Container className={classes.container}>
            <form onSubmit={handleSubmit}>
                <NavigationPrompt when={!isEqual(initialValues, values)}>
                    {({ onConfirm, onCancel }) => (
                        <ConfirmDialog
                            open={!isEqual(initialValues, values)}
                            confirmLabel={t('Yes, leave')}
                            cancelLabel={t('No, stay')}
                            title={t('You have unsaved changes, are you sure you want to leave?')}
                            onClickConfirm={onConfirm}
                            onClickCancel={onCancel}
                        />
                    )}
                </NavigationPrompt>
                <div className={classes.form}>
                    {renderProfileDetails()}
                    <div className={classes.avatarUpload}>
                        <FormikAvatarUpload
                            name="avatar"
                            label={t('Profile Photo')}
                            initials={getInitials()}
                        />
                        {/* Please check if the file is being removed from S3 */}
                        <Button
                            color="primary"
                            className={classes.removeAvatarButton}
                            onClick={() => setFieldValue('avatar', null)}
                        >
                            {t('Remove photo')}
                        </Button>
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
            {additionalElement}
        </Container>
    );
};

export default EmployerProfileForm;
