import * as yup from 'yup';
import i18n from 'i18next';

export interface RejectCandidateDialogForm {
    statusId: number | '';
    feedbackMessage: string;
    rejectionMessage: string;
    allowingRejectionMessage: boolean;
}

export const initialValues: RejectCandidateDialogForm = {
    statusId: '',
    feedbackMessage: '',
    rejectionMessage: '',
    allowingRejectionMessage: false,
};

export const validationSchema = yup.object().shape({
    statusId: yup.number().required(i18n.t('Field is required')),
    feedbackMessage: yup.string().max(1000, `${i18n.t('Maximum number of characters is')} \${max}`),
    rejectionMessage: yup
        .string()
        .nullable()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .when('allowingRejectionMessage', {
            is: true,
            then: yup.string().required(i18n.t('Field is required')).typeError(i18n.t('Field is required')),
        })
        .max(1000, `${i18n.t('Maximum number of characters is')} \${max}`),
});
