import { FC } from 'react';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';
import { useStyles } from './styles';
import RemoteJobIcon from '../../assets/remoteJobIcon.svg';
import LocationIcon from '../../assets/landing/gps.svg';
import StackFlag from '../../assets/stackFlag.svg';

interface Props {
    companyName?: string;
    remotely: boolean;
    title: string;
    tags: string[];
    location: string;
    jobDetailsPage?: boolean;
}

const JobOfferCardInfo: FC<Props> = ({ companyName, remotely, title, tags, location, jobDetailsPage }) => {
    const classes = useStyles();

    const renderCompany = () => {
        if (companyName) {
            return <Typography className={classes.company}>{companyName}</Typography>;
        }
        return null;
    };

    const renderTags = () =>
        tags.map(tag => (
            <div key={tag} className={classes.stackChip}>
                <img src={StackFlag} alt="stack flag" width="7" height="9" className={classes.flag} />
                {tag}
            </div>
        ));

    const renderRemotely = () => {
        if (remotely) {
            return (
                <div
                    className={clsx(classes.remoteJob, {
                        [classes.remoteJobJobDetails]: jobDetailsPage,
                    })}
                >
                    <img src={RemoteJobIcon} alt="remote job" width="17" height="12" />
                    <Typography className={classes.remoteJobText}>Remote</Typography>
                </div>
            );
        }
        return null;
    };

    return (
        <div className={classes.container}>
            {renderCompany()}
            <Typography className={classes.position} title={title}>
                {jobDetailsPage && title.length > 50 ? `${title.substr(0, 50)}...` : title}
            </Typography>
            {renderRemotely()}
            <div className={classes.locationRow}>
                <img
                    src={LocationIcon}
                    alt="location"
                    width="14"
                    height="14"
                    className={classes.locationIcon}
                />
                <Typography className={classes.location}>{location}</Typography>
            </div>
            <div className={classes.tagsWrapper}>{renderTags()}</div>
        </div>
    );
};

export default JobOfferCardInfo;
