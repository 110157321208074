import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { useStyles } from './styles';

interface Props {
    label: string;
    value: number;
}

const AnalyticsCard: FC<Props> = ({ label, value }) => {
    const classes = useStyles();

    return (
        <Card className={classes.container}>
            <div className={classes.textWrapper}>
                <Typography className={classes.primaryListItem}>{label}:</Typography>
                <Typography className={classes.secondaryListItem}>{value}</Typography>
            </div>
        </Card>
    );
};

export default AnalyticsCard;
