import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    audioWrapper: {
        backgroundColor: '#C1DFFE',
        height: 156,
        width: 156,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        [theme.breakpoints.up(600)]: {
            margin: 'none',
        },
    },
    outlinedButton: {
        marginTop: '1rem',
        height: '22px',
        borderRadius: '22px',
        border: '2px solid #007FFC',
        fontSize: 11,
        lineHeight: '1rem',
        fontWeight: 500,
        color: '#007FFC',
    },
    timeLeft: {
        color: '#ff3b3b',
        fontWeight: 700,
        fontSize: '0.75rem',
        textAlign: 'center',
        marginTop: 8,
    },
}));
