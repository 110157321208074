import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    widget: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 12px',
        borderRadius: 3,
        marginTop: 5,
    },
    text: {
        marginLeft: 5,
    },
    rejected: {
        color: '#EC4E4E',
        backgroundColor: 'rgba(236,78,78,0.17)',
    },
    accepted: {
        color: '#26A425',
        backgroundColor: 'rgba(0,252,41,0.17)',
    },
    processing: {
        color: '#005b9c',
        backgroundColor: 'rgb(173 213 255)',
    },
    interview: {
        color: '#f1a501',
        backgroundColor: 'rgb(255 246 224)',
    },
});
