import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import { isEqual } from 'lodash';
import NavigationPrompt from 'react-router-navigation-prompt';
import CustomFormikTextField from '../../../../components/CustomFormikTextField';
import FormikRichTextEditor from '../../../../components/formik/FormikRichTextEditor';
import FormikImageUpload from '../../../../components/formik/FormikImageUpload';
import CompanyTeamFormikTags from '../CompanyTeamFormikTags';

import { useStyles } from './styles';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { EmployerCompanyFormValues } from '../config';

interface Props {
    id?: number | string;
    additionalElement?: JSX.Element;
    currentCompany?: EmployerCompanyFormValues | null;
}

const EmployerCompanyForm: FC<Props> = ({ additionalElement, id, currentCompany = null }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { handleSubmit, isSubmitting, values, setFieldValue } = useFormikContext();

    return (
        <Container className={classes.container}>
            <NavigationPrompt when={!isEqual(currentCompany, values)}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmDialog
                        open
                        confirmLabel={t('Yes, leave')}
                        cancelLabel={t('No, stay')}
                        title={t('You have unsaved changes, are you sure you want to leave?')}
                        onClickConfirm={onConfirm}
                        onClickCancel={onCancel}
                    />
                )}
            </NavigationPrompt>
            <form onSubmit={handleSubmit}>
                <div className={classes.uploadWrapper}>
                    <div className={classes.photoWrapper}>
                        <FormikImageUpload name="logo" fullWidth />
                        {/* Please check if the file is being removed from S3 */}
                        <Button
                            color="primary"
                            className={classes.removePhotoButton}
                            onClick={() => setFieldValue('logo', null)}
                        >
                            {t('Remove photo')}
                        </Button>
                    </div>
                    <div className={classes.photoWrapper}>
                        <FormikImageUpload
                            name="backgroundImage"
                            label="Background"
                            suggestionMessage="Suggested background image size is 400px to 200px (width x height)"
                            fullWidth
                        />
                        {/* Please check if the file is being removed from S3 */}
                        <Button
                            color="primary"
                            className={classes.removePhotoButton}
                            onClick={() => setFieldValue('backgroundImage', null)}
                        >
                            {t('Remove photo')}
                        </Button>
                    </div>
                </div>
                <div className={classes.form}>
                    <CustomFormikTextField
                        name="name"
                        placeholder={t('company name')}
                        label={t('Company name')}
                    />
                    <CustomFormikTextField
                        name="industry"
                        placeholder={t('industry')}
                        label={t('Industry')}
                    />
                    <CustomFormikTextField name="url" placeholder={t('url')} label={t('Url')} />

                    <div className={classes.companySize}>
                        <CustomFormikTextField
                            name="companySizeMin"
                            type="number"
                            placeholder={t('Size min')}
                            label={t('Size min')}
                        />
                        <CustomFormikTextField
                            name="companySizeMax"
                            type="number"
                            placeholder={t('Size max')}
                            label={t('Size max')}
                        />
                    </div>
                </div>
                <div className={classes.rteWrapper}>
                    <CustomFormikTextField
                        name="contactEmail"
                        placeholder={t('Email')}
                        label={t('Contact email')}
                    />
                </div>
                <div className={classes.rteWrapper}>
                    <CompanyTeamFormikTags name="teamMembers" label={t('Team')} id={id} />
                </div>
                <div className={classes.rteWrapper}>
                    <FormikRichTextEditor name="description" label={t('Description')} editorHeight={192} />
                </div>
                <div className={classes.rteWrapper}>
                    <FormikRichTextEditor name="mission" label={t('Mission')} editorHeight={192} />
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
            {additionalElement}
        </Container>
    );
};

export default EmployerCompanyForm;
