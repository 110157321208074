import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import AdminCreateCompanyFormValues, { initialValues, validationSchema } from './config';
import AdminCreateCompanyForm from './AdminCreateCompanyForm';
import { createCompanyWithEmployer } from '../../../services/AdminService';
import { getBackendFormikErrors } from '../../../config/utils';
import { ROUTES } from '../../../config/constants';

const AdminCreateCompanyPage: FC = () => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleSubmitForm = async (
        values: AdminCreateCompanyFormValues,
        { setSubmitting, setErrors }: FormikHelpers<AdminCreateCompanyFormValues>,
    ) => {
        try {
            const { username, ...otherValues } = values;
            await createCompanyWithEmployer({
                emailAddress: username,
                company: { ...otherValues },
            });
            enqueueSnackbar(t('Company with an initial employer successfully created.'), {
                variant: 'success',
            });
            history.push(ROUTES.ADMIN_COMPANIES);
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Submit fail', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <AdminCreateCompanyForm />
        </Formik>
    );
};

export default AdminCreateCompanyPage;
