import { makeStyles, Theme } from '@material-ui/core/styles';
import { LANDING_BREAKPOINT_FIRST } from '../../../config/constants';

export const useStyles = makeStyles((theme: Theme) => ({
    container: { color: 'black', fontWeight: 700, fontSize: '2rem', marginBottom: '3rem' },
    title: {
        fontSize: '2.1rem',
        letterSpacing: '1.61px',
        fontWeight: 500,
        color: 'rgb(21, 65, 109);',
        textAlign: 'center',
        marginBottom: '2.5rem',
        marginTop: '5rem',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            fontSize: '2.5rem',
            marginBottom: '3rem',
        },
    },
    subtitle: {
        fontSize: '1.5rem',
        letterSpacing: '1.61px',
        fontWeight: 500,
        color: 'rgb(21, 65, 109);',
        textAlign: 'center',
        margin: '2rem 0',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            fontSize: '1.7rem',
        },
    },
    text: {
        color: 'rgb(21, 65, 109);',
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: '0.17px',
        marginBottom: '0.4rem',
    },
}));
