import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {},
    loader: {
        marginTop: '1rem',
    },
    searchIcon: {
        cursor: 'pointer',
    },
    candidatesWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridGap: 16,
        [theme.breakpoints.up(650)]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up(920)]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up(1300)]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    contentWrapper: {
        marginTop: 42,
    },
    searchWrapper: {
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: '1fr 175px',
        [theme.breakpoints.up(650)]: {
            gridTemplateColumns: '1fr 200px',
        },
    },
    addEmployersWrapper: {
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
    },
    addButton: {
        marginTop: '0.75rem',
        borderRadius: 4,
        borderWidth: 2,
        fontSize: '0.8125rem',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        minWidth: '8.5rem',
        '&:focus, &:hover': {
            borderWidth: 2,
        },
    },
}));
