import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventIcon from '@material-ui/icons/Event';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import { JobOfferApplicationStatus } from '../../../models/JobOfferApplication';
import { useStyles } from './styles';
import StarIcon from '../../../assets/landing/starIcon.svg';
import StarIconInverted from '../../../assets/landing/starIconInverted.svg';
import { bookmarkJobOffer } from '../../../services/JobOfferService';
import { JobOfferButtonState } from '../../../models/enums/JobOfferButtonState';
import { ROUTES } from '../../../config/constants';

interface Props {
    id: number;
    status: JobOfferApplicationStatus | null;
    interviewDate: Date;
    interviewStartTime: string;
    appliedDate: Date;
    bookmark?: boolean;
    jobOfferButtonState: JobOfferButtonState;
}

const CandidateStatusWidget: FC<Props> = ({
    status,
    interviewDate,
    interviewStartTime,
    appliedDate,
    id,
    bookmark,
    jobOfferButtonState,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [bookmarked, setBookmarked] = useState(bookmark);

    useEffect(() => {
        setBookmarked(bookmark);
    }, [bookmark]);

    const getDaysAgoText = (days: number) => {
        let ago = 'today';
        if (days === -1) {
            ago = 'day ago';
        } else if (days < -1) {
            ago = 'days ago';
        }
        return ago;
    };

    const renderAppliedDaysAgo = () => {
        const days = dayjs(appliedDate).diff(dayjs(new Date()), 'days');
        return (
            <span className={classes.appliedOn}>
                {t(`Applied ${days < 0 ? Math.abs(days) : ''} ${getDaysAgoText(days)}`)}
            </span>
        );
    };

    const handleSaveClicked = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            await bookmarkJobOffer(id);
            setBookmarked(bookmarked => !bookmarked);
        } catch (error) {
            enqueueSnackbar(t('Bookmarking failed, try again later'), { variant: 'error' });
        }
    };

    if (status === JobOfferApplicationStatus.ROLE_FILLED) {
        return (
            <div className={clsx(classes.widget, classes.rejected)}>
                <CancelIcon />
                <span className={classes.text}>{t('Role filled')}</span>
            </div>
        );
    }
    if (status === JobOfferApplicationStatus.CONTRACT_DECLINED) {
        return (
            <div className={clsx(classes.widget, classes.rejected)}>
                <CancelIcon />
                <span className={classes.text}>{t('Contract declined')}</span>
            </div>
        );
    }
    if (status === JobOfferApplicationStatus.INTERVIEW_PROPOSED) {
        return (
            <div className={clsx(classes.widget, classes.interview)}>
                <EventIcon />
                <span className={classes.text}>{t('Proposed')}</span>
            </div>
        );
    }
    if (status === JobOfferApplicationStatus.INTERVIEW_ACCEPTED) {
        return (
            <>
                <div className={clsx(classes.widget, classes.interview)}>
                    <EventIcon />
                    <span className={classes.text}>{t('Scheduled')}</span>
                </div>
                <span className={classes.flexBetween}>
                    <span>{!!interviewDate && dayjs(interviewDate).format('DD MMM YYYY')}</span>
                    <span>{interviewStartTime}</span>
                </span>
            </>
        );
    }
    if (status === JobOfferApplicationStatus.CONTRACT_PROPOSED) {
        return (
            <>
                <div className={clsx(classes.widget, classes.processing)}>
                    <EventIcon />
                    <span className={classes.text}>{t('Contract proposed')}</span>
                </div>
            </>
        );
    }
    if (status === JobOfferApplicationStatus.CONTRACT_ACCEPTED) {
        return (
            <>
                <div className={clsx(classes.widget, classes.accepted)}>
                    <CheckCircleIcon />
                    <span className={classes.text}>{t('Accepted')}</span>
                </div>
            </>
        );
    }

    if (!status) {
        return (
            <div className={classes.actionsWrapper}>
                <Button
                    color="primary"
                    className={clsx({
                        [classes.saveButton]: true,
                        [classes.saveButtonBookmarked]: bookmarked,
                    })}
                    startIcon={
                        !bookmarked ? (
                            <img src={StarIcon} width="16" height="15" alt="Save" />
                        ) : (
                            <img src={StarIconInverted} width="16" height="15" alt="Saved" />
                        )
                    }
                    onClick={handleSaveClicked}
                >
                    {!bookmarked ? t('Save') : t('Saved')}
                </Button>
                {jobOfferButtonState === JobOfferButtonState.APPLY && (
                    <Button
                        component={RouterLink}
                        to={`/jobs/${id}`}
                        variant="outlined"
                        color="primary"
                        className={classes.applyButton}
                    >
                        {t('Apply')}
                    </Button>
                )}
                {jobOfferButtonState === JobOfferButtonState.MISSING_PROFILE_INFORMATION && (
                    <Button
                        component={RouterLink}
                        to={`${ROUTES.CANDIDATE_PROFILE}?jobId=${id}`}
                        variant="outlined"
                        color="primary"
                        className={classes.applyButton}
                    >
                        {t('Apply')}
                    </Button>
                )}
            </div>
        );
    }

    return (
        <>
            <div className={clsx(classes.widget, classes.processing)}>
                <WatchLaterIcon />
                <span className={classes.text}>{t('Submitted')}</span>
            </div>
            {!!status && renderAppliedDaysAgo()}
        </>
    );
};

export default CandidateStatusWidget;
