import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import CustomFormikTextField from '../../components/CustomFormikTextField';
import FormikAvatarUpload from '../../components/formik/FormikAvatarUpload';
import { CreateSupportFormValues } from '../../pages/admin/AdminCreateSupportPage/config';

interface Props {
    additionalElement?: JSX.Element;
}

const SupportProfileForm: FC<Props> = ({ additionalElement }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { handleSubmit, isSubmitting, setFieldValue } = useFormikContext<CreateSupportFormValues>();

    const renderProfileDetails = () => (
        <div className={classes.profileDetails}>
            <CustomFormikTextField
                name="username"
                placeholder={t('Username email')}
                label={t('Username email')}
            />
            <CustomFormikTextField
                name="contactEmail"
                placeholder={t('Contact email address')}
                label={t('Contact email address')}
            />

            <CustomFormikTextField name="firstName" placeholder={t('First name')} label={t('First name')} />
            <CustomFormikTextField name="lastName" placeholder={t('Last name')} label={t('Last name')} />
        </div>
    );

    return (
        <Container className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div className={classes.form}>
                    {renderProfileDetails()}
                    <div className={classes.avatarUpload}>
                        <FormikAvatarUpload name="avatar" label={t('Profile Photo')} />
                        {/* Please check if the file is being removed from S3 */}
                        <Button
                            color="primary"
                            className={classes.removeAvatarButton}
                            onClick={() => setFieldValue('avatar', null)}
                        >
                            {t('Remove photo')}
                        </Button>
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
            {additionalElement}
        </Container>
    );
};

export default SupportProfileForm;
