import { FC, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import AboutUsImage from '../../../assets/aboutUs.svg';

const AboutPage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={classes.container}>
            <div className={classes.firstPanel}>
                <div className={classes.panelWrapper}>
                    <Typography gutterBottom className={classes.title}>
                        {t('About Onboard')}
                    </Typography>
                    <Typography className={classes.description}>
                        {t(
                            'Applying for jobs online has never been easier. Unfortunately, this has made it harder to stand out as a candidate and harder to spot mission-driven talent as an employer.',
                        )}
                    </Typography>
                    <Typography className={classes.description}>
                        {t(
                            'Hiring doesn’t have to be a headache. We bridge the gap between the job application and interview stage, by letting candidates apply for jobs using their voice.',
                        )}
                    </Typography>
                </div>
                <img src={AboutUsImage} className={classes.firstPanelImage} alt="About Onboard" />
            </div>
            <Typography className={classes.title}>FAQ</Typography>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className={classes.faqTitle}>
                        What makes Onboard different from other online job sites?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.faqDescription}>
                        These days it can be hard to stand out among thousands of generic online job
                        applications. With Onboard, you apply for jobs by answering motivation questions using
                        your voice. Our system also provides feedback on every job application so candidates
                        never get ghosted.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography className={classes.faqTitle}>
                        What makes Onboard better for candidates than established sites?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.faqDescription}>
                        We asked hundreds of jobseekers what mattered to them when looking for jobs. Every job
                        on Onboard has full salary info, shows your future team and company mission. To keep
                        things transparent, we don’t let recruiters post jobs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    <Typography className={classes.faqTitle}>
                        What makes Onboard better for employers than existing platforms?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.faqDescription}>
                        We built Onboard to make life easier both for employers and candidates. Early stage
                        recruitment is a mundane and repetitive chore. Rather than sifting through thousands
                        of generic applications, Onboard lets employers hear from passionate candidates
                        straight away. This saves you time so you can focus on interviewing the applicants
                        that stand out.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                >
                    <Typography className={classes.faqTitle}>Is Onboard free?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.faqDescription}>
                        Onboard is 100% free for candidates.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                >
                    <Typography className={classes.faqTitle}>How do you handle my voice data?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.faqDescription}>
                        Your voice is an intimate part of your identity. We will never sell or share any of
                        your data with a third party. We will only ever use anonymized application data to
                        improve how we suggest jobs and to make the platform better for our users.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
};

export default AboutPage;
