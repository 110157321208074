import { makeStyles } from '@material-ui/core/styles';

import backgroundImage from '../../assets/landing/background.png';

export const useStyles = makeStyles(theme => ({
    backgroundImage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '19rem',
        maxWidth: '1440px',
        backgroundSize: 'contain',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1440px',
        },
    },
    contentWrapper: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '3rem',
        flexGrow: 1,
    },
    containerLanding: {
        backgroundColor: '#F7FBFF',
        padding: '1.25rem 0 3.25rem',
    },
    container: {
        backgroundColor: '#F7FBFF',
        paddingTop: '1.25rem',
    },
    title: {
        fontSize: '44px',
        fontWeight: 700,
        lineHeight: '68px',
        letterSpacing: '0.82px',
        textAlign: 'center',
        color: '#15416D',
    },
    centerWrapper: {
        flex: 1,
        marginBottom: '3rem',
        marginTop: '3rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    alert: {
        marginTop: '1.5rem',
    },
    latestJob: {
        color: '#15416D',
        fontSize: '0.875rem',
        lineHeight: '4.25rem',
        fontWeight: 500,
        letterSpacing: '0.64px',
    },
    jobsWrapper: {
        display: 'grid',
        gridGap: 18,
    },
    loader: {
        marginTop: '1rem',
    },
}));
