import * as yup from 'yup';
import i18n from 'i18next';
import { ref } from 'yup';
import Reference from 'yup/es/Reference';
import { PostJobOfferFormValues } from '../../models/JobOffer';

export const defaultInitialValues: PostJobOfferFormValues = {
    title: '',
    experienceLevelId: '',
    employmentTypeId: '',
    location: '',
    salaryMin: 0,
    salaryMax: 25000,
    currencyId: '',
    question: '',
    questionTwo: '',
    description: '',
    remotely: false,
    billingCycleId: '',
    tags: [],
    teamMembers: [],
    categoryId: '',
};

export const validationSchema = yup.object().shape({
    title: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(200, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    experienceLevelId: yup.number().required(i18n.t('Field is required')),
    employmentTypeId: yup.number().required(i18n.t('Field is required')),
    categoryId: yup.number().required(i18n.t('Field is required')),
    location: yup.string().required(i18n.t('Field is required')),
    currencyId: yup.number().required(i18n.t('Field is required')),
    billingCycleId: yup.number().required(i18n.t('Field is required')),
    question: yup
        .string()
        .max(500, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    questionTwo: yup
        .string()
        .max(500, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    salaryMin: yup
        .number()
        .min(0, `${i18n.t('Value must be more than ')} \${min}`)
        .max(200000, `${i18n.t('Value must be less than ')} \${max}`)
        .required(i18n.t('Field is required'))
        .typeError(i18n.t('Valid number is required')),
    salaryMax: yup
        .number()
        .min(
            ref('salaryMin') as Reference<number>,
            `${i18n.t('Value must be the same or more than ')} \${min}`,
        )
        .max(200000, `${i18n.t('Value must be less than ')} \${max}`)
        .required(i18n.t('Field is required'))
        .typeError(i18n.t('Valid number is required')),
});
