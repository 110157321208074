import { makeStyles, Theme } from '@material-ui/core/styles';

interface RichTextEditorStyleProps {
    editorHeight: number;
}

export const useStyles = makeStyles<Theme, RichTextEditorStyleProps>({
    container: {
        height: ({ editorHeight }) => editorHeight,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > button': {
            color: '#000000',
        },
    },
    editorContainer: {
        border: '1px solid rgba(151,151,151,0.14)',
        padding: '1rem',
        height: ({ editorHeight }) => editorHeight - 30,
        overflowY: 'auto',
    },
    inputLabel: {
        position: 'absolute',
    },
    errorMessage: {
        marginTop: '0.75rem',
    },
});
