import { makeStyles } from '@material-ui/core/styles';

const DATES_BREAKPOINT = 600;

export const useStyles = makeStyles(theme => ({
    button: {
        width: 102,
        margin: '2rem 0 0 0.5rem',
    },
    divider: {
        height: 2,
        margin: '1rem 0',
        [theme.breakpoints.up(DATES_BREAKPOINT)]: {
            display: 'none',
        },
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F7FBFF',
        maxWidth: '40rem',
        width: '100%',
        padding: '2.25rem 0.5rem',
    },
    form: {
        padding: '0 1rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(DATES_BREAKPOINT)]: {
            maxWidth: '34rem',
            padding: '1.25rem 1rem 0',
        },
    },
    feedback: {
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '3rem',
        letterSpacing: '0.48px',
        color: '#15416D',
        [theme.breakpoints.up(DATES_BREAKPOINT)]: {
            lineHeight: '4.25rem',
        },
    },
    datePickWrapper: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        display: 'grid',
        gridGap: '0.75rem',
        alignItems: 'flex-start',
        gridTemplateColumns: 'auto',
        [theme.breakpoints.up(DATES_BREAKPOINT)]: {
            gridTemplateColumns: '11rem 1fr',
        },
    },
    timePickWrapper: {
        display: 'grid',
        gridGap: '0.75rem',
        alignItems: 'flex-start',
        gridTemplateColumns: '8rem 8rem auto',
    },
    removeButton: {
        marginTop: 5,
        justifySelf: 'flex-start',
    },
    addButton: {
        width: '120px',
        height: '35px',
    },
    sendButton: {
        margin: '2rem auto 0',
        width: 149,
    },
}));
