import { FC, useState } from 'react';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import { useField } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';

import FormikErrorText from '../FormikErrorText';

interface Props extends Partial<KeyboardTimePickerProps> {
    name: string;
    label?: string;
    disableLabel?: string;
}

const FormikTimePicker: FC<Props> = ({ name, label, disableLabel, ...otherProps }) => {
    const { value } = useField(name)[0];
    const { setValue } = useField(name)[2];

    const [internalValue, setInternalValue] = useState(value);

    return (
        <div>
            {!disableLabel && <InputLabel>{label || name}</InputLabel>}
            <KeyboardTimePicker
                inputVariant="outlined"
                ampm={false}
                variant="inline"
                fullWidth
                value={value}
                invalidDateMessage=""
                invalidLabel=""
                onChange={date => setInternalValue(date)}
                onBlur={() => setValue(internalValue)}
                onAccept={date => setValue(date)}
                {...otherProps}
            />
            <FormikErrorText name={name} />
        </div>
    );
};

export default FormikTimePicker;
