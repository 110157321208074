import { makeStyles, Theme } from '@material-ui/core/styles';
import { JOB_OFFER_CARD_BREAKPOINT, JOB_OFFER_CARD_MOBILE_WIDTH } from '../../config/constants';

interface Props {
    backgroundUrl?: string;
}

export const useStyles = makeStyles<Theme, Props>(theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        minHeight: '7rem',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            gridTemplateRows: 'auto 1fr',
            border: '3px solid rgba(0,0,0,0.06)',
            overflow: 'hidden',
            '&:hover': {
                border: '3px solid rgba(0,0,0,0.1)',
            },
        },
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            width: '100%',
            gridTemplateColumns: 'auto 1fr',
            minHeight: 142,
            boxShadow: '0 0 48px 0 rgba(0,0,0,0.1)',
            '&:hover': {
                boxShadow: '0 0 48px 0 rgba(0,0,0,0.2)',
            },
        },
    },
    link: {
        textDecoration: 'none',
        display: 'flex',
        flexWrap: 'nowrap',
        borderRadius: 8,
        maxWidth: '100vw',
    },
    companyBackground: ({ backgroundUrl }) => ({
        position: 'relative',
        height: '100%',
        width: JOB_OFFER_CARD_MOBILE_WIDTH,
        paddingTop: '43.2%',
        background: `url(${backgroundUrl}) 0% 0% / cover`,
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            borderRadius: '8px 0 0 8px',
        },
    }),
    wrapper: {
        position: 'relative',
        width: '100%',
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            gridTemplateColumns: '1fr',
            minHeight: 142,
            height: '100%',
            gridTemplateRows: 'auto 1fr',
            paddingBottom: 16,
            alignItems: 'flex-start',
        },
    },
    paperWrapper: {
        // borderRadius: '0.75rem',
        // boxShadow: '0 16px 23px -13px #8FACC7',
        // '&:hover': {
        //     boxShadow: '0 16px 23px -13px #8FACC7',
        // },
    },
    wrapperEditable: {
        gridTemplateColumns: '1fr 180px auto 125px',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto auto 1fr',
            paddingBottom: 0,
        },
    },
    companyLogoWrapper: {
        position: 'absolute',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            left: 36,
            bottom: -20,
            width: 92,
            height: 92,
        },
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            top: '50%',
            transform: 'translateY(-50%)',
            right: -42,
            width: 84,
            height: 84,
        },
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 23px',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            alignSelf: 'flex-end',
        },

        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    applyButton: {
        textTransform: 'none',
        borderRadius: '0.25rem',
        fontSize: '0.625rem',
        fontWeight: 700,
        height: '1.5rem',
    },
    editButton: {
        textTransform: 'none',
        borderRadius: '0.25rem',
        fontSize: '0.75rem',
        fontWeight: 700,
        height: '1.5rem',
        color: theme.palette.grey[600],
    },
    tagsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    saveButton: {
        color: '#4E4E4E',
        fontSize: '0.75rem',
        fontWeight: 700,
        height: '1.5rem',
        lineHeight: '1rem',
        textTransform: 'none',
        borderRadius: 4,
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            marginBottom: '0.5rem',
        },
    },
    saveButtonBookmarked: {},
    save: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: '#DFE3E9',
        width: 1,
        height: '100%',
        padding: '26px 0',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            display: 'none',
        },
    },
}));
