import { makeStyles } from '@material-ui/core/styles';
import { HEADER_BREAKPOINT } from '../../config/constants';

export const useStyles = makeStyles(theme => ({
    snackbarMargin: {
        bottom: 60,
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            bottom: 14,
        },
    },
}));
