import React, { FC } from 'react';
import { ArrayHelpers } from 'formik';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';

import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';
import { formattedTimeDiff } from '../../config/utils';
import AddEducationDialog from '../../pages/candidate/CandidateProfilePage/AddEducationDialog';
import { CandidateEducationForm } from '../../pages/candidate/CandidateProfilePage/AddEducationDialog/config';

interface Props {
    educations: CandidateEducationForm[];
    editable?: boolean;
    arrayHelpers?: ArrayHelpers;
}

const CandidateEducationInfo: FC<Props> = ({ educations, arrayHelpers, editable }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onEditAction = (education: CandidateEducationForm | null, index?: number): void => {
        if (isNumber(index)) {
            arrayHelpers?.replace(index, education);
        }
    };

    const onDelete = (index: number): void => {
        arrayHelpers?.remove(index);
    };

    const renderActionButtons = (index: number) => {
        if (editable) {
            return (
                <div>
                    <IconButton size="small" onClick={() => onDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                    <AddEducationDialog
                        action="edit"
                        initialValues={educations[index]}
                        index={index}
                        onSubmitAction={onEditAction}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            {educations.map(
                (
                    {
                        startDate,
                        endDate,
                        id,
                        grade,
                        schoolName,
                        schoolLogo,
                        specialization,
                        stillStudyingHere,
                    },
                    index,
                    array,
                ) => (
                    <div key={id} className={clsx({ [classes.wrapper]: editable })}>
                        {renderActionButtons(index)}
                        <div className={classes.experienceContainer}>
                            <div
                                className={clsx(classes.experienceLogoContainer, {
                                    [classes.experienceLogoContainerBackground]: !schoolLogo,
                                })}
                            >
                                {schoolLogo && (
                                    <img
                                        alt={schoolName}
                                        src={schoolLogo.url}
                                        className={classes.experienceLogo}
                                    />
                                )}
                            </div>
                            <div>
                                <Typography className={classes.position}>{schoolName}</Typography>
                                <Typography
                                    className={classes.companyName}
                                >{`${specialization}, ${grade}`}</Typography>
                                <Typography className={classes.experienceText}>{`${dayjs(startDate).format(
                                    'MMM YYYY',
                                )} - ${
                                    stillStudyingHere ? t('PRESENT') : dayjs(endDate).format('MMM YYYY')
                                } ${formattedTimeDiff(
                                    startDate,
                                    stillStudyingHere ? new Date() : endDate,
                                )}`}</Typography>
                            </div>
                        </div>
                        {index + 1 !== array.length && <Divider className={classes.divider} />}
                    </div>
                ),
            )}
        </div>
    );
};

export default CandidateEducationInfo;
