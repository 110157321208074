import axios from 'axios';

import {
    Category,
    JobOffer,
    JobOfferApplication,
    JobOfferCreate,
    JobOfferEdit,
    JobOfferStaticContent,
} from '../models/JobOffer';
import FilterData from '../models/FilterData';
import { ListTeamMember } from '../models/Company';
import { JobOfferButtonState } from '../models/enums/JobOfferButtonState';
import { PaginatedResponse } from '../config/hooks/usePaginationFetch';
import { InterviewProposal } from '../models/InterviewProposal';
import { CreateMeeting } from '../models/Meeting';
import { ContractProposal } from '../models/JobOfferApplication';

interface PaginatedJobOfferFetchParams {
    page: number;
    size: number;
}

export type PaginatedJobOfferResponse = PaginatedResponse<JobOffer>;

export interface JobOfferDetailsResponse {
    billingCycle: string;
    companyDescription: string;
    companyId: string;
    companyMission: string;
    companyName: string;
    companyLogoUrl: string;
    currency: { id: number; code: string };
    description: string;
    employmentType: string;
    experienceLevel: string;
    category: string;
    id: number;
    location: string;
    questions: string[];
    remotely: boolean;
    salaryMax: number;
    salaryMin: number;
    title: string;
    teamMembers: ListTeamMember[];
    tags: string[];
    archived?: boolean;
    jobOfferButtonState: JobOfferButtonState;
    bookmark: boolean;
    application?: JobOfferApplication;
    meeting?: CreateMeeting;
    applicationTimeslotSelect?: InterviewProposal;
    interviewDate?: Date;
    interviewStartTime?: string;
    contractProposal?: ContractProposal;
}

export const postJobOffer = async (formValues: JobOfferCreate): Promise<JobOfferCreate> => {
    const { data } = await axios.post('job-offer', formValues);
    return data;
};

// for editing purposes
export const getJobOfferForEdit = async (id: string | number): Promise<JobOfferEdit> => {
    const { data } = await axios.get(`/job-offer/${id}/views/edit`);
    return data;
};

export const editJobOffer = async (
    id: string | number,
    jobOffer: JobOfferCreate,
): Promise<JobOfferCreate> => {
    const { data } = await axios.put(`/job-offer/${id}`, jobOffer);
    return data;
};

export const getJobOfferDetails = async (id: number | string): Promise<JobOfferDetailsResponse> => {
    const { data } = await axios.get(`/job-offer/${id}`);
    return data;
};

export const getJobOfferStaticContent = async (): Promise<JobOfferStaticContent> => {
    const { data } = await axios.get('job-offer/static-content');
    return data;
};

export const getPaginatedJobOffers = async (
    params: PaginatedJobOfferFetchParams,
): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.post('job-offer/views/paged', params);
    return data;
};

export const getSearchCandidateJobOffers = async (params: FilterData): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.post('job-offer/views/candidate-search', params);
    return data;
};

export const getSearchEmployerJobOffers = async (params: FilterData): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.post('job-offer/views/employer-search', params);
    return data;
};

export const getSearchEmployerArchivedJobOffers = async (
    params: FilterData,
): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.post('job-offer/views/archived', params);
    return data;
};

export const getLatestJobOffers = async (params: FilterData): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.post('job-offer/views/latest', params);
    return data;
};

export const getFeaturedJobOffers = async (): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.get('job-offer/views/featured');
    return data;
};

export const archiveJobOffer = async (id: number): Promise<unknown> => {
    const { data } = await axios.delete(`job-offer/${id}`);
    return data;
};

export const unarchiveJobOffer = async (id: number): Promise<unknown> => {
    const { data } = await axios.patch(`job-offer/${id}/unarchive`);
    return data;
};

export const bookmarkJobOffer = async (id: number): Promise<unknown> => {
    const { data } = await axios.post(`job-offer/${id}/bookmark`);
    return data;
};

export const getTags = async (): Promise<string[]> => {
    const { data } = await axios.get('tags');
    return data;
};

export const getSuggestedJobOfferDetails = async (id: number | string): Promise<JobOffer[]> => {
    const { data } = await axios.get(`/job-offer/${id}/suggested`);
    return data;
};

export const getCategories = async (): Promise<Category[]> => {
    const { data } = await axios.get(`/job-offer/categories`);
    return data;
};
