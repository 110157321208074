import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    button: {
        margin: '2rem 0 0 0.5rem',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F7FBFF',
        maxWidth: '40rem',
        width: '100%',
        padding: '2.25rem 0.5rem 2.25rem',
    },
    title: {
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '4.25rem',
        letterSpacing: '0.48px',
        color: '#15416D',
    },
    form: {
        maxWidth: '34rem',
        padding: '1.25rem 1rem 0',
        width: '100%',
        display: 'grid',
        gridGap: '0.5rem',
        flexDirection: 'column',
    },
    message: {
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '4.25rem',
        letterSpacing: '0.48px',
        color: '#15416D',
    },
    saveButton: {
        margin: '2rem auto 0',
        width: 149,
    },
    datePicker: {
        backgroundColor: '#FFFFFF',
    },
});
