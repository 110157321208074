import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1.5rem 3rem',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 400px',
        },
    },
    uploadWrapper: {
        gridRow: '1/3',
        minHeight: '6rem',
    },
    container: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #DFE3E9',
        padding: '24px 22px 26px',
        margin: '1.5rem auto 3rem',
    },
    moneyWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '1rem',
    },
    companySize: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',
        gridGap: '0 1rem',
    },
    locationLabelWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    remotely: {
        fontSize: '0.8125rem',
        fontWeight: 500,
        marginRight: '0.5rem',
    },
    switchWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    submitButton: {
        fontSize: '0.875rem',
        fontWeight: 700,
        padding: '1rem 1.5rem',
        letterSpacing: '0.46px',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: '1rem 0',
    },
    rteWrapper: {
        margin: '1.5rem 0',
    },
}));
