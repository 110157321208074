import { makeStyles } from '@material-ui/core/styles';

const PADDING_BREAKPOINT = 600;

export const useStyles = makeStyles(theme => ({
    button: {
        width: 102,
        margin: '2rem 0 0 0.5rem',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F7FBFF',
        padding: '1rem 1.5rem',
        maxWidth: '40rem',
        width: '100%',
        [theme.breakpoints.up(PADDING_BREAKPOINT)]: {
            padding: '2rem 3.25rem',
        },
    },
    form: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1rem',
    },
    center: {
        textAlign: 'center',
    },
    title: {
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '4.5rem',
        letterSpacing: '0.48px',
        color: '#15416D',
    },
    subTitle: {
        fontSize: '0.75rem',
        fontWeight: 700,
        letterSpacing: '0.48px',
    },
    infoContainer: {
        paddingTop: '1.5rem',
    },
    companyLogo: {
        maxWidth: 100,
        maxHeight: 50,
    },
    buttonsWrapper: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    bold: {
        fontWeight: 700,
    },
    downloadWrapper: {
        display: 'grid',
        gridGap: '0.5rem',
    },
    sendButton: {
        marginLeft: 10,
        width: 201,
    },
}));
