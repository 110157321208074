export const findJobSteps = [
    {
        title: 'Sign up',
        description:
            'Sign up instantly with LinkedIn. Your profile is only visible to employers you apply to.',
    },
    {
        title: 'Find your calling',
        description: 'Every job we feature shows full salary info, your future team and company mission.',
    },
    {
        title: 'Speak for yourself',
        description:
            'When you find the perfect role, answer a couple of motivation questions by recording your voice.',
    },
];
