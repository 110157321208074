import { JobOfferApplicationStatus } from '../../../models/JobOfferApplication';

export interface EmployerCandidatesPageFormValues {
    search: string;
    onlyNew: boolean;
    status: JobOfferApplicationStatus[] | null;
}

export const initialValues: EmployerCandidatesPageFormValues = {
    search: '',
    onlyNew: false,
    status: [],
};
