import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',

        '& p': {
            marginLeft: 10,
        },
    },
    uploadButton: {
        height: '2rem',
        width: '9rem',
        fontSize: '0.8125rem',
        borderRadius: 4,
    },
    dropzoneArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: 2,
        color: '#bdbdbd',
        cursor: 'pointer',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    logo: {
        maxHeight: '4rem',
        marginTop: '0.5rem',
    },
}));
