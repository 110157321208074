import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import { useStyles } from './styles';
import UserAvatar from '../../../../components/UserAvatar';
import { AdminPanelUser } from '../../../../models/Admin';

interface Props {
    user: AdminPanelUser;
    usersType: 'candidates' | 'employers' | 'companies' | 'support';
}

const UserCard: FC<Props> = ({ user, usersType }) => {
    const { avatarUrl, firstName, lastName, id, emailAddress, position } = user;
    const classes = useStyles();

    const getEditUserLink = (id: string | number) => {
        if (usersType === 'candidates') {
            return `/admin/edit-candidate/${id}`;
        }
        if (usersType === 'support') {
            return `/admin/edit-support/${id}`;
        }
        return `/admin/edit-employer/${id}`;
    };

    return (
        <RouterLink to={getEditUserLink(id)} className={classes.link}>
            <Card className={classes.container}>
                <div className={classes.profile}>
                    <UserAvatar size={66} url={avatarUrl} />
                    <div className={classes.textWrapper}>
                        <Typography
                            title={`${firstName} ${lastName}`}
                            className={clsx(classes.primaryListItem, classes.textEllipsis)}
                        >{`${firstName} ${lastName}`}</Typography>
                        {!!emailAddress && (
                            <Typography
                                title={emailAddress}
                                className={clsx(classes.secondaryListItem, classes.textEllipsis)}
                            >
                                {emailAddress}
                            </Typography>
                        )}
                        {!!position && (
                            <Typography
                                title={position}
                                className={clsx(classes.thirdListItem, classes.textEllipsis)}
                            >
                                {position}
                            </Typography>
                        )}
                    </div>
                </div>
            </Card>
        </RouterLink>
    );
};

export default UserCard;
