import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Radio } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { isEmpty, toNumber } from 'lodash';
import dayjs from 'dayjs';
import { useStyles } from './styles';
import { initialValues, InterviewPropositionDialogValues, validationSchema } from './config';
import { InterviewProposal } from '../../models/InterviewProposal';
import FormikRadioGroup from '../formik/FormikRadioGroup';
import DateProposalLabel from './DateProposalLabel';
import { updateDateProposal } from '../../services/JobOfferMeetingService';
import { getBackendErrorMessage, getClickableLink } from '../../config/utils';
import CloseDialogElement from '../CloseDialogElement';

interface Props {
    open: boolean;
    onClose?: () => void;
    onStatusUpdated?: () => void;
    interviewProposal: InterviewProposal;
}

const InterviewPropositionDialog: FC<Props> = ({
    open: propsOpen,
    onClose,
    onStatusUpdated,
    interviewProposal,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { t } = useTranslation();

    const [open, setOpen] = useState(propsOpen);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const handleSubmitForm = (
        dialogFormData: InterviewPropositionDialogValues,
        { setSubmitting }: FormikHelpers<InterviewPropositionDialogValues>,
    ) => {
        updateDateProposal(interviewProposal.meetingId, {
            ...interviewProposal.dateProposals[toNumber(dialogFormData.selectedDate)],
            accepted: true,
        })
            .then(() => {
                handleClose();
                if (onStatusUpdated) {
                    onStatusUpdated();
                }
                enqueueSnackbar('Successfully accepted interview date proposed', { variant: 'success' });
            })
            .catch(error => {
                enqueueSnackbar(`Error accepting interview date proposed: ${getBackendErrorMessage(error)}`, {
                    variant: 'error',
                });
                setSubmitting(false);
            });
    };

    const renderDateFields = () => {
        let dateProposalsFormatted = interviewProposal.dateProposals.map((proposal, index) => {
            const disabled = dayjs(`${proposal.date} ${proposal.startTime}`).isBefore(dayjs());
            return {
                key: index,
                value: index.toString(),
                disabled,
                control: <Radio color="primary" />,
                label: <DateProposalLabel dateProposal={proposal} disabled={disabled} />,
            };
        });

        dateProposalsFormatted = dateProposalsFormatted.filter(dateProposal => !dateProposal.disabled);

        return (
            <FormikRadioGroup options={dateProposalsFormatted} name="selectedDate" label="Select a date" />
        );
    };

    return (
        <>
            <Dialog onClose={handleClose} open={open} classes={{ paperWidthSm: classes.dialog }}>
                <CloseDialogElement onClick={handleClose} />

                <div className={classes.center}>
                    <Typography className={classes.title}>{t('Accept a meeting')}</Typography>
                    <Typography className={classes.subTitle}>
                        {t('Please select a corresponding date of the meeting')}
                    </Typography>
                </div>

                <div className={classes.infoContainer}>
                    {!!interviewProposal.logoUrl && (
                        <img
                            className={classes.companyLogo}
                            src={interviewProposal.logoUrl}
                            alt={interviewProposal.companyName}
                        />
                    )}
                    <p>
                        <span className={classes.bold}>{t('Organizer')}:</span>{' '}
                        {interviewProposal.organizerFirstName} {interviewProposal.organizerLastName}
                    </p>
                    <p>
                        <span className={classes.bold}>{t('Job')}:</span> {interviewProposal.jobTitle}
                    </p>
                    {!!interviewProposal.meetingUrl && (
                        <p>
                            <span className={classes.bold}>{t('Where')}: </span>
                            <a
                                href={getClickableLink(interviewProposal.meetingUrl)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {interviewProposal.meetingUrl}
                            </a>
                        </p>
                    )}
                </div>

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}
                >
                    {({ errors, handleSubmit, isSubmitting }) => {
                        return (
                            <form onSubmit={handleSubmit} className={classes.form}>
                                {renderDateFields()}

                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className={classes.sendButton}
                                    disabled={!isEmpty(errors) || isSubmitting}
                                >
                                    {t('Submit')}
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};

export default InterviewPropositionDialog;
