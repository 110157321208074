import Media from './Media.model';
import { PaginatedResponse } from '../config/hooks/usePaginationFetch';
import { JobOfferButtonState } from './enums/JobOfferButtonState';
import User from './User';
import { CreateMeeting, MeetingDateProposal } from './Meeting';

export interface JobOfferApplication {
    jobOfferId: number;
    answers: Media[];
}

export type PaginatedListJobOfferApplicationResponse = PaginatedResponse<ListJobOfferApplication>;

export interface ListJobOfferApplication {
    id: number;
    title: string;
    location: string;
    remotely: boolean;
    logoUrl: string;
    companyName?: string;
    companyId?: number;
    backgroundUrl?: string;
    currencySign: string;
    salaryMin: number;
    salaryMax: number;
    billingCycle: string;
    tags: string[];
    status: JobOfferApplicationStatus | null;
    meetingId: number;
    buttonState: JobOfferButtonState;
    interviewDate: Date;
    interviewStartTime: string;
    bookmark: boolean;
    appliedDate: Date;
}

export interface CandidateEducation {
    endDate: string;
    grade: string;
    id: number;
    schoolName: string;
    schoolLogo: Media;
    specialization: string;
    startDate: string;
    stillStudyingHere: boolean;
    useProfileId: number;
}

export interface CandidateJobExperience {
    companyName: string;
    companyLogo: Media;
    endDate: string;
    id: number;
    location: string;
    position: string;
    startDate: string;
    stillWorkingHere: boolean;
    userProfileId: number;
}

export enum JobOfferApplicationStatus {
    NEW = 'NEW',
    ROLE_FILLED = 'ROLE_FILLED',
    INTERVIEW_PROPOSED = 'INTERVIEW_PROPOSED',
    INTERVIEW_ACCEPTED = 'INTERVIEW_ACCEPTED',
    CONTRACT_PROPOSED = 'CONTRACT_PROPOSED',
    CONTRACT_DECLINED = 'CONTRACT_DECLINED',
    CONTRACT_ACCEPTED = 'CONTRACT_ACCEPTED',
}

export interface PaginatedJobOfferApplication {
    answers: Media[];
    appliedDateTime: string;
    candidate: {
        avatar: Media | null;
        firstName: string;
        lastName: string;
        userId: number;
        educations: CandidateEducation[];
        jobExperiences: CandidateJobExperience[];
        currentPosition: string;
        contactEmail: string;
        contactPhoneNumber: string;
        cvFile: Media | null;
    };
    id: number;
    jobOfferId: number;
    jobOfferTitle: string;
    questions: { content: string; id: number }[];
    jobOfferApplicationStatus: JobOfferApplicationStatus;
}

export type PaginatedJobOfferApplicationResponse = PaginatedResponse<PaginatedJobOfferApplication>;

export interface RejectApplicationStatus {
    allowingRejectionMessage: boolean;
    id: number;
    name: string;
}

export interface JobOfferToCandidate {
    jobOfferApplicationId: number;
    candidateId: number;
    salary: number;
    currencyId: number;
    billingCycleId: number;
    message: string;
    jobStartDate: Date;
    contractFile?: Media;
}

export interface ContractProposal {
    billingCycle: string;
    contractFile: Media;
    signedContractFile: Media;
    currencySign: string;
    id: number;
    employerMessage: string;
    candidateMessage: string;
    salary: number;
}

export interface JobOfferApplicationEvent {
    id: number;
    eventType: JobOfferApplicationStatus;
    user: User;
    previousStatus: JobOfferApplicationStatus;
    currentStatus: JobOfferApplicationStatus;
    rejectionDetailsDto?: RejectApplicationStatus;
    rejectionMessage?: string;
    feedbackMessage?: string;
    meeting?: CreateMeeting;
    dateProposal?: MeetingDateProposal;
    contractProposal: ContractProposal;
    created: Date;
}
