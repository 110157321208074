import { makeStyles } from '@material-ui/core/styles';
import { JOB_OFFER_CARD_BREAKPOINT } from '../../config/constants';

export const useStyles = makeStyles(theme => ({
    container: {
        padding: '32px 26px 0',
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            padding: '0 0 0 93px',
        },
    },
    company: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        color: '#616161',
        fontWeight: 400,
    },
    remoteJob: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
    },
    remoteJobJobDetails: {
        marginLeft: 0,
    },
    remoteJobText: {
        color: '#F82E8B',
        fontSize: 12,
        lineHeight: '16px',
        marginLeft: 6,
    },
    positionWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    positionWrapperJobDetails: {
        display: 'grid',
        gridGap: '0.5rem',
        marginBottom: '1rem',
    },
    position: {
        color: '#16133E',
        fontSize: 19,
        lineHeight: '26px',
        fontWeight: 500,
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            color: '#1F3874',
            fontSize: '1.5rem',
            lineHeight: '2rem',
            fontWeight: 500,
        },
    },
    locationRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.25rem',
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            marginBottom: '0.5rem',
        },
    },
    location: {
        color: '#616161',
        fontSize: '0.75rem',
        lineHeight: '1rem',
    },
    locationIcon: {
        height: 14,
        width: 14,
        marginRight: 4,
    },
    tagsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    stackChip: {
        padding: '6px 13px',
        borderRadius: 4,
        backgroundColor: 'rgba(0,252,41,0.17)',
        marginRight: 8,
        marginBottom: 8,
        color: '#26A425',
        fontSize: 9,
        lineHeight: '11px',
    },
    flag: {
        marginRight: 6,
        marginBottom: -1,
    },
}));
