import { FC } from 'react';
import { useCookieConsents } from '@enzsft/react-cookie-consents';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const CookiesDisclaimer: FC = () => {
    const cookieConsents = useCookieConsents();
    const classes = useStyles();
    const { t } = useTranslation();

    if (cookieConsents) {
        if (cookieConsents.get().length > 0) {
            return null;
        }

        return (
            <div className={classes.cookiesContainer}>
                <div className={classes.cookiesWrapper}>
                    <div className={classes.cookieIcon} />
                    <span className={classes.text}>
                        {t('We use cookies to give you the best experience on our site.')}
                    </span>
                    <div className={classes.buttonsWrapper}>
                        <button
                            className={classes.acceptButton}
                            type="button"
                            onClick={event => {
                                event.stopPropagation();
                                cookieConsents.add('analytics');
                            }}
                        >
                            <span>{t('Accept')}</span>
                        </button>
                        <a
                            className={classes.readMoreButton}
                            href="https://app.termly.io/document/cookie-policy/346465cf-ef17-47a9-9d32-cd0dd968e91a"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Read more')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default CookiesDisclaimer;
