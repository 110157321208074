import { makeStyles } from '@material-ui/core/styles';

const BORDER_RADIUS_WIDTH = '8px';

export const useStyles = makeStyles(theme => ({
    input: {
        height: 45,
        boxSizing: 'border-box',
        fontSize: 13,
        color: '#15416D',
        lineHeight: '16px',
        fontWeight: 400,
        borderRadius: BORDER_RADIUS_WIDTH,
        '&::placeholder': {
            color: '#616161',
            fontSize: 13,
            lineHeight: '16px',
            letterSpacing: '0.5px',
            fontWeight: 400,
            opacity: 0.6,
        },
        '&:focus, &:hover': {
            border: 'none',
        },
    },
    select: {
        '& fieldset': {
            '&:focus, &:hover': {
                border: 'none',
            },
        },
    },
    rootTextField: {
        backgroundColor: '#FFFFFF',
        fontSize: '0.8125rem',
        boxSizing: 'border-box',
        height: 48,
        boxShadow: '0 0 17px 0 rgba(0,0,0,0.06)',
        '&:focus, &:hover': {
            boxShadow: '0 0 17px 0 rgba(0,0,0,0.18)',
            backgroundColor: '#FFFFFF',
            border: 'none',
        },
    },
    labelRoot: {
        marginTop: 20,
        lineHeight: '18px',
        color: '#16133E',
        letterSpacing: '0.5px',
        marginBottom: '0.75rem',
    },
    error: {
        '& fieldset': {
            borderColor: theme.palette.error.main,
        },
    },
}));
