import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

import ConfirmDialog from '../ConfirmDialog';
import { archiveJobOffer, unarchiveJobOffer } from '../../services/JobOfferService';
import { useStyles } from './styles';
import { deleteJobOffer, unarchiveJobOfferAdmin } from '../../services/AdminService';

interface ReactiveDialogProps {
    archived?: boolean;
    id: number;
    removeCallback?: () => void;
    reactiveCallback?: () => void;
    title: string;
    isAdmin?: boolean;
}

const ReactiveDialog: FC<ReactiveDialogProps> = ({
    archived: initialArchived,
    id,
    title,
    removeCallback,
    reactiveCallback,
    isAdmin,
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [archived, setArchived] = useState<boolean | undefined>(initialArchived);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setArchived(initialArchived);
    }, [initialArchived]);

    const archive = isAdmin ? deleteJobOffer : archiveJobOffer;
    const unarchive = isAdmin ? unarchiveJobOfferAdmin : unarchiveJobOffer;

    const handleArchive = async () => {
        setLoading(true);
        try {
            await archive(id);
            setArchived(true);
            enqueueSnackbar(t('Job offer successfully archived'), { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(t('Archiving failed, try again later'), { variant: 'error' });
        } finally {
            setLoading(false);
            if (removeCallback) {
                await removeCallback();
            }
            setOpen(false);
        }
    };

    const handleUnarchived = async () => {
        setLoading(true);
        try {
            await unarchive(id);
            setArchived(false);
            enqueueSnackbar(t('Job offer successfully unarchived'), { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(t('Un-archiving failed, try again later'), { variant: 'error' });
        } finally {
            setLoading(false);
            if (reactiveCallback) {
                await reactiveCallback();
            }
            setOpen(false);
        }
    };

    return (
        <>
            <ConfirmDialog
                open={open}
                submitDisabled={loading}
                confirmLabel={t('Yes')}
                cancelLabel={t('No')}
                title={
                    archived
                        ? `${t('You are about to reactivate the job posting')} ${title}, ${t(
                              'which will make it available for other users. Do you want to proceed?',
                          )}`
                        : t(
                              'Are you sure you want to archive this job? Archived jobs are taken offline, and you can reactivate them at a later date.',
                          )
                }
                onClickConfirm={e => {
                    e.preventDefault();
                    if (archived) {
                        handleUnarchived();
                    } else {
                        handleArchive();
                    }
                }}
                onClickCancel={e => {
                    e.preventDefault();
                    setOpen(false);
                }}
            />
            <Button
                onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}
                classes={{ root: classes.editButton }}
            >
                {archived ? t('Reactivate') : t('Remove')}
            </Button>
        </>
    );
};

export default ReactiveDialog;
