// @ts-nocheck

import * as yup from 'yup';
import i18n from 'i18next';
import dayjs from 'dayjs';
import Media from '../../../../models/Media.model';

export interface ProposeContractDialogValues {
    jobOfferApplicationId: number;
    candidateId: number;
    message: string;
    salary: number;
    currencyId: number | '';
    billingCycleId: number | '';
    jobStartDate: Date;
    contractFile: Media;
}

export const initialValues: ProposeContractDialogValues = {
    message: '',
    salary: null,
    currencyId: '',
    billingCycleId: '',
    jobStartDate: dayjs(new Date()).add(7, 'days').format('YYYY-MM-DD'),
    contractFile: null,
};

export const validationSchema = yup.object().shape({
    message: yup.string().max(1000, `${i18n.t('Maximum number of characters is')} \${max}`),
    salary: yup
        .number()
        .min(0, i18n.t('Salary has to be more than zero'))
        .max(200000, `${i18n.t('Salary has to be less than')} \${max}`)
        .required(i18n.t('Salary is required'))
        .typeError(i18n.t('Valid number is required')),
    currencyId: yup
        .number()
        .required(i18n.t('Currency is required'))
        .typeError(i18n.t('Valid number is required')),
    billingCycleId: yup
        .number()
        .required(i18n.t('Billing cycle is required'))
        .typeError(i18n.t('Valid number is required')),
    jobStartDate: yup
        .date()
        .min(dayjs().format('YYYY-MM-DD'), i18n.t('Date must be in the future'))
        .required(i18n.t('Field is required'))
        .typeError(i18n.t('Valid date is required')),
    contractFile: yup
        .object()
        .required(i18n.t('Contract file is required'))
        .typeError(i18n.t('Contract file is required')),
});
