import { AxiosError } from 'axios';
import { FormikErrors } from 'formik';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { PostJobOfferFormValues, JobOfferCreate } from '../models/JobOffer';
import { writeToLocalStorage } from '../services/config/LocalStorage';
import { LINKEDIN_STATE_KEY, ROUTES } from './constants';
import { MeetingDateProposal } from '../models/Meeting';

type BackendFormikError = { field: string; errorCode: string }[];

export const getBackendErrorMessage = <T,>(error: AxiosError): string | FormikErrors<T> => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (typeof error.response.data === 'string') {
            return error.response.data;
        }
        return error.response.data.message;
    }
    if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return error.request;
    }
    // Something happened in setting up the request that triggered an Error
    return error.message;
};

export const getBackendFormikErrors = <T,>(error: AxiosError): FormikErrors<T> => {
    if (error?.response?.data.errors) {
        return (error.response.data.errors as BackendFormikError).reduce(
            (prev, { field, errorCode }) => ({
                ...prev,
                [field]: errorCode === 'NotNull' ? 'Field is required' : errorCode,
            }),
            {},
        );
    }
    return {};
};

export const parseJobOfferToPostOfferForm = (jobOffer: JobOfferCreate): PostJobOfferFormValues => {
    const { questions, ...other } = jobOffer;

    return {
        ...other,
        question: questions[0].content,
        questionTwo: questions[1].content,
    };
};

export const parsePostOfferFormToJobOffer = (postOfferFormValues: PostJobOfferFormValues): JobOfferCreate => {
    const { question, questionTwo, ...otherValues } = postOfferFormValues;

    return {
        ...otherValues,
        questions: [{ content: question }, { content: questionTwo }],
    } as JobOfferCreate;
};

export const getNameInitials = (name: string): string =>
    name
        .split(' ')
        .slice(0, 2)
        .map(text => text.substring(0, 1))
        .join('')
        .toUpperCase();

export const formattedTimeDiff = (timeStart: string | Date, timeEnd: string | Date): string => {
    const monthsDiff = dayjs(timeEnd).diff(dayjs(timeStart), 'month');

    if (Math.floor(monthsDiff / 12)) {
        const months = monthsDiff % 12;
        return `${Math.floor(monthsDiff / 12)} ${i18n.t('yr')} ${months || ''} ${
            months ? i18n.t('mos.') : ''
        }`;
    }
    return `${monthsDiff || 1} ${i18n.t('mos.')}`;
};

export const getRandomString = (): string => Math.random().toString(36).substring(7);

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const loginRedirectToLinkedIn = (jobId?: string | null): void => {
    const state = getRandomString();
    writeToLocalStorage(LINKEDIN_STATE_KEY, state);
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${state}&scope=r_liteprofile&client_id=${
        process.env.REACT_APP_LINKEDIN_APP_ID
    }&redirect_uri=${encodeURI(
        `${window.location.origin}${ROUTES.CANDIDATE_LINKEDIN_AUTH}${jobId ? `?jobId=${jobId}` : ''}`,
    )}`;
};

export const trimJobOfferName = (name: string, maxSize: number): string => {
    if (name.length > maxSize) {
        return `${name.substring(0, maxSize)}...`;
    }
    return name;
};

// Formats thousands into k format - for example: 1000 into 1k, 100,000 into 100k etc.
export const kFormatter = (value: number): string | number => {
    return Math.abs(value) > 999
        ? `${Math.sign(value) * +(Math.abs(value) / 1000).toFixed(1)}k`
        : Math.sign(value) * Math.abs(value);
};

export const getClickableLink = (link: string): string => {
    return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
};

export const asyncLoadScript = (src: string, position: HTMLElement | null, id: string): void => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
};

export const hasProposalsAvailable = (dateProposals: MeetingDateProposal[] | undefined): boolean => {
    if (dateProposals) {
        let hasProposalsAvailable = false;

        dateProposals.forEach(dateProposal => {
            if (!hasProposalsAvailable)
                hasProposalsAvailable = !dayjs(`${dateProposal.date} ${dateProposal.startTime}`).isBefore(
                    dayjs(),
                );
        });

        return hasProposalsAvailable;
    }
    return false;
};
