import React, { FC } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';

import InputLabel from '@material-ui/core/InputLabel';

import { FormikTextFieldProps } from './types';

const FormikTextField: FC<FormikTextFieldProps> = ({
    disableLabel,
    id,
    label,
    name,
    component = TextField,
    InputLabelProps,
    ...otherProps
}) => {
    const { setFieldValue } = useFormikContext();

    const trimWhitespace = (e: React.BaseSyntheticEvent) => {
        setFieldValue(name, e.target.value.trim());
    };

    return (
        <div>
            {!disableLabel && (
                <InputLabel htmlFor={id || name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <Field
                component={component}
                fullWidth
                autoComplete="off"
                variant="outlined"
                onBlur={trimWhitespace}
                id={id || name}
                name={name}
                {...otherProps}
            />
        </div>
    );
};

export default FormikTextField;
