import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    exitButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 5,
        margin: 10,
    },
});
