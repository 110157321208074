import React, { FC, useCallback, useState } from 'react';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

import Cropper from 'react-easy-crop';
import { Modal } from '@material-ui/core';
import { uploadImageBlob } from '../../../services/MediaService';
import { dataURItoBlob, getCroppedImg } from '../../../services/config/ImageUtils';
import { useStyles } from './styles';

interface FormikAvatarUploadProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
    initials?: string;
}

const FormikAvatarUpload: FC<FormikAvatarUploadProps> = ({ name, label, disableLabel, initials }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { value } = useField(name)[1];
    const { setValue } = useField(name)[2];

    const [originalImageURL, setOriginalImageURL] = useState('');

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({ width: 0, height: 0, x: 0, y: 0 });
    const [modalOpen, setModalOpen] = useState(false);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(originalImageURL, croppedAreaPixels);

            if (croppedImage) {
                const blob = dataURItoBlob(croppedImage);
                uploadImageBlob(blob).then(response => {
                    setValue(response);
                });
            }
        } catch (e) {
            enqueueSnackbar(t('Failed to crop an image.'), { variant: 'error' });
        }
    }, [croppedAreaPixels, enqueueSnackbar, originalImageURL, setValue, t]);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = async () => {
        await showCroppedImage();
        setModalOpen(false);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleFileCrop = (file: File) => {
        if (['image/png', 'image/jpg', 'image/jpeg'].some(type => file?.type?.includes(type))) {
            const localImageURL = URL.createObjectURL(file);
            setOriginalImageURL(localImageURL);
            handleOpen();
        } else {
            enqueueSnackbar(t('Please upload a png or jpg file type for the avatar.'), { variant: 'error' });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        noDrag: true,
        accept: 'image/png,image/jpg,image/jpeg',
        onDrop: files => handleFileCrop(files[0]),
    });

    return (
        <>
            <Modal open={modalOpen} onClose={handleClose}>
                <>
                    <Cropper
                        image={originalImageURL}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        cropShape="round"
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        style={{ containerStyle: { height: '80%', width: '80%', margin: 'auto' } }}
                    />
                    <Button
                        className={classes.confirmCropButton}
                        color="primary"
                        variant="contained"
                        onClick={handleClose}
                    >
                        {t('Confirm')}
                    </Button>
                </>
            </Modal>

            <div className={classes.wrapper}>
                {!disableLabel && <InputLabel>{label || name}</InputLabel>}
                <div {...getRootProps()} className={classes.dropzoneArea}>
                    <input {...getInputProps()} />

                    {value?.url ? (
                        <img src={value.url} alt={name} className={classes.avatarPreview} />
                    ) : (
                        <div className={classes.avatarPreview}>
                            <p>{initials}</p>
                        </div>
                    )}

                    <Button
                        className={classes.uploadButton}
                        color="primary"
                        variant="outlined"
                        startIcon={<CloudUploadOutlinedIcon />}
                    >
                        {t('Upload image')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FormikAvatarUpload;
