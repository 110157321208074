import { UserRoles } from '../models/enums/UserRoles';
import { ROUTES } from './constants';

import AdminCompaniesPage from '../pages/admin/AdminCompaniesPage';
import AdminEditCompanyProfilePage from '../pages/admin/AdminEditCompanyProfilePage';
import AdminUsersPage from '../pages/admin/AdminUsersPage';
import CandidateApplyPage from '../pages/candidate/CandidateApplyPage';
import CandidateLinkedInAuthPage from '../pages/candidate/CandidateLinkedInAuthPage';
import CandidateMyRolesPage from '../pages/candidate/CandidateMyRolesPage';
import CandidateProfilePage from '../pages/candidate/CandidateProfilePage';
import EmployerCandidatesPage from '../pages/employer/EmployerCandidatesPage';
import EmployerCompanyPage from '../pages/employer/EmployerCompanyPage';
import EmployerEditOfferPage from '../pages/employer/EmployerEditOfferPage';
import EmployerHomePage from '../pages/employer/EmployerHomePage';
import EmployerLandingPage from '../pages/employer/EmployerLandingPage';
import EmployerLoginPage from '../pages/employer/EmployerLoginPage';
import EmployerPostOfferPage from '../pages/employer/EmployerPostOfferPage';
import EmployerProfilePage from '../pages/employer/EmployerProfilePage';
import EmployerSignUpPage from '../pages/employer/EmployerSignUpPage';
import JobOfferDetailsPage from '../pages/JobOfferDetailsPage';
import LandingPage from '../pages/LandingPage';
import TemporaryPage from '../pages/TemporaryPage';
import AdminEditCandidateProfilePage from '../pages/admin/AdminEditCandidateProfilePage';
import AdminEditEmployerProfilePage from '../pages/admin/AdminEditEmployerProfilePage';
import EmployerForgotPasswordPage from '../pages/employer/EmployerForgotPasswordPage';
import AdminCreateEmployerPage from '../pages/admin/AdminCreateEmployerPage';
import AdminCreateCompanyPage from '../pages/admin/AdminCreateCompanyPage';
import SupportCompaniesPage from '../pages/support/SupportCompaniesPage';
import SupportJobsPage from '../pages/support/SupportJobsPage';
import SupportEditJobPage from '../pages/support/SupportEditJobPage/SupportEditJobPage';
import AdminCreateSupportPage from '../pages/admin/AdminCreateSupportPage';
import AdminEditSupportProfilePage from '../pages/admin/AdminEditSupportProfilePage';
import AboutPage from '../pages/static/AboutPage';
import SearchPanel from '../parts/SearchPanel';
import ContactPage from '../pages/static/ContactPage';
import AdminAnalyticsPage from '../pages/admin/AdminAnalyticsPage';
import TermsOfUsePage from '../pages/static/TermsOfUsePage';

export const publicRoutes = [
    {
        path: ROUTES.LANDING,
        component: LandingPage,
    },
    {
        path: ROUTES.CONTACT,
        component: ContactPage,
    },
    {
        path: ROUTES.EMPLOYER_LANDING_PAGE,
        component: EmployerLandingPage,
    },
    {
        path: ROUTES.EMPLOYER_SIGN_IN,
        component: EmployerLoginPage,
    },
    {
        path: ROUTES.EMPLOYER_SIGN_UP,
        component: EmployerSignUpPage,
    },
    {
        path: ROUTES.EMPLOYER_FORGOT_PASSWORD,
        component: EmployerForgotPasswordPage,
    },
    {
        path: ROUTES.CANDIDATE_SIGN_IN,
        component: TemporaryPage,
    },
    {
        path: ROUTES.CANDIDATE_LINKEDIN_AUTH,
        component: CandidateLinkedInAuthPage,
    },
    {
        path: ROUTES.JOB_OFFER_DETAILS,
        component: JobOfferDetailsPage,
        title: 'Job details',
    },
    {
        path: ROUTES.ABOUT,
        component: AboutPage,
    },
    {
        path: ROUTES.TERMS_OF_USE,
        component: TermsOfUsePage,
    },
    {
        path: ROUTES.CANDIDATE_FIND_A_JOB,
        component: SearchPanel,
    },
];

export const authorizedRoutes = [
    {
        path: ROUTES.ADMIN_USERS,
        component: AdminUsersPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_COMPANIES,
        component: AdminCompaniesPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_JOBS,
        component: SupportJobsPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_CREATE_COMPANY,
        component: AdminCreateCompanyPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.SUPPORT],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_EDIT_CANDIDATE_PROFILE,
        component: AdminEditCandidateProfilePage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_CREATE_EMPLOYER,
        component: AdminCreateEmployerPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_CREATE_SUPPORT,
        component: AdminCreateSupportPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_EDIT_EMPLOYER_PROFILE,
        component: AdminEditEmployerProfilePage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_ANALYTICS,
        component: AdminAnalyticsPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_EDIT_SUPPORT_PROFILE,
        component: AdminEditSupportProfilePage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_SUPPORT_EDIT_COMPANY_PROFILE,
        component: AdminEditCompanyProfilePage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.SUPPORT],
        exact: true,
    },
    {
        path: ROUTES.ADMIN_SUPPORT_EDIT_JOB_OFFER,
        component: SupportEditJobPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.SUPPORT],
        exact: true,
    },
    {
        path: ROUTES.EMPLOYER_HOME,
        component: EmployerHomePage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
    },
    {
        path: ROUTES.EMPLOYER_POST_JOB,
        component: EmployerPostOfferPage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
    },
    {
        path: ROUTES.EMPLOYER_EDIT_JOB_OFFER,
        component: EmployerEditOfferPage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
    },
    {
        path: ROUTES.EMPLOYER_COMPANY_PROFILE,
        component: EmployerCompanyPage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
    },
    {
        path: ROUTES.EMPLOYER_JOB_CANDIDATES,
        component: EmployerCandidatesPage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
        title: 'Candidates',
    },
    {
        path: ROUTES.EMPLOYER_COMPANY_PROFILE,
        component: EmployerCompanyPage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
    },
    {
        path: ROUTES.EMPLOYER_PROFILE,
        component: EmployerProfilePage,
        requiredRoles: [UserRoles.EMPLOYER],
        exact: true,
        title: 'Profile',
    },
    {
        path: ROUTES.CANDIDATE_APPLY_JOB,
        component: CandidateApplyPage,
        requiredRoles: [UserRoles.CANDIDATE],
        exact: true,
    },
    {
        path: ROUTES.CANDIDATE_PROFILE,
        component: CandidateProfilePage,
        requiredRoles: [UserRoles.CANDIDATE],
        exact: true,
        title: 'Profile',
    },
    {
        path: ROUTES.CANDIDATE_MY_ROLES,
        component: CandidateMyRolesPage,
        requiredRoles: [UserRoles.CANDIDATE],
        exact: true,
    },
    {
        path: ROUTES.SUPPORT_COMPANIES,
        component: SupportCompaniesPage,
        requiredRoles: [UserRoles.SUPPORT],
        exact: true,
    },
    {
        path: ROUTES.SUPPORT_CREATE_COMPANY,
        component: AdminCreateCompanyPage,
        requiredRoles: [UserRoles.SUPPORT],
        exact: true,
    },
    {
        path: ROUTES.SUPPORT_JOBS,
        component: SupportJobsPage,
        requiredRoles: [UserRoles.SUPPORT],
        exact: true,
    },
];
