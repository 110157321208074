import {
    getPaginatedCandidates,
    getPaginatedEmployers,
    getPaginatedSupportMembers,
} from '../../../../services/AdminService';

export const userPaginatedDataService = {
    support: getPaginatedSupportMembers,
    candidates: getPaginatedCandidates,
    employers: getPaginatedEmployers,
};
