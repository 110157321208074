import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './styles';
import ArrowRight from '../../../assets/landing/arrowRight.svg';
import { CurrentUserReducer, RootReducer } from '../../../models/Redux';
import { UserRoles } from '../../../models/enums/UserRoles';
import { ROUTES } from '../../../config/constants';

import EmployerLandingImage from '../../../assets/employerLanding/peopleDoTasks.svg';
import SaveTimeImage from '../../../assets/employerLanding/saveTime.jpg';
import MakeAnOfferImage from '../../../assets/launch/make-an-offer.jpg';
import HearFromTheBestImage from '../../../assets/launch/hear-from-the-best.jpg';
import WelcomeImage from '../../../assets/launch/welcome.jpg';
import { submitMailchimp } from '../../../services/MailchimpService';
import { getBackendFormikErrors } from '../../../config/utils';
import { initialValues, validationSchema } from './config';
import FormikTextField from '../../../components/formik/FormikTextField';

const EmployerLandingPage: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    if (role === UserRoles.EMPLOYER) {
        history.push(ROUTES.EMPLOYER_HOME);
    }

    if (role === UserRoles.ADMIN) {
        history.push(ROUTES.ADMIN_USERS);
    }

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const renderCards = () => {
        return (
            <div className={classes.howItWorks}>
                <Typography className={classes.titleHowWorks}>{t('How it works')}</Typography>
                <div className={classes.cardsContainer}>
                    <div className={classes.card}>
                        <div className={classes.stepNumber}>1</div>
                        <img src={MakeAnOfferImage} alt={t('Welcome Onboard')} className={classes.image} />
                        <Typography className={classes.cardTitle}>{t('Welcome Onboard.')}</Typography>
                        <Typography className={classes.cardDescription}>
                            {t(
                                'Our team will set up your job post and send you an account activation email. You can make changes to the post at any time.',
                            )}
                        </Typography>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.stepNumber}>2</div>
                        <img
                            src={HearFromTheBestImage}
                            alt={t('Hear from the best')}
                            className={classes.image}
                        />
                        <Typography className={classes.cardTitle}>{t('Hear from the best.')}</Typography>
                        <Typography className={classes.cardDescription}>
                            {t(
                                'Start listening to individual voice applications from qualified candidates. Schedule interviews with the best applicants.',
                            )}
                        </Typography>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.stepNumber}>3</div>
                        <img src={WelcomeImage} alt={t('Make an offer')} className={classes.image} />
                        <Typography className={classes.cardTitle}>{t('Make an offer.')}</Typography>
                        <Typography className={clsx(classes.cardDescription, classes.bold)}>
                            {t('Hire your perfect candidate 100% free.')}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    // eslint-disable-next-line
    const handleSubmitForm = async (values: any, { setErrors }: any) => {
        submitMailchimp(values)
            .then(() => setFormSubmitted(true))
            .catch(error => {
                setErrors(getBackendFormikErrors(error));
                enqueueSnackbar('Submit fail', { variant: 'error' });
                setFormSubmitted(true);
            });
    };

    const renderFormik = () => {
        return (
            <div className={classes.formContainer}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.formText}>Your email</div>
                                <FormikTextField
                                    name="email"
                                    placeholder={t('Email')}
                                    InputProps={{
                                        classes: {
                                            // @ts-ignore
                                            notchedOutline: classes.inputRoot,
                                            root: classes.input,
                                        },
                                    }}
                                    disableLabel
                                />

                                <div className={classes.formText}>Your name</div>
                                <FormikTextField
                                    name="name"
                                    placeholder={t('Name')}
                                    InputProps={{
                                        classes: {
                                            // @ts-ignore
                                            notchedOutline: classes.inputRoot,
                                            root: classes.input,
                                        },
                                    }}
                                    disableLabel
                                />
                            </div>

                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                classes={{ root: classes.submitButton, label: classes.submitButtonLabel }}
                                disabled={isSubmitting}
                            >
                                {t('Submit')}
                            </Button>
                        </form>
                    )}
                </Formik>
            </div>
        );
    };

    const renderThankYou = () => {
        return (
            <div className={classes.successWrapper}>
                <CheckCircleIcon className={classes.checkIcon} color="primary" />
                <Typography className={classes.thankYouTitle}>{t('Thanks for signing up!')}</Typography>
                <Typography className={classes.thankYouTitle}>
                    {t('Our team will send you a confirmation email shortly.')}
                </Typography>
            </div>
        );
    };

    const renderSignupForm = () => (
        <div className={classes.signupFormContainer}>
            <div className={classes.overlay} />
            <div className={classes.signupFormColumn}>
                <div className={classes.signupForm}>
                    <div className={classes.title}>{t('A better way to hire')}</div>
                    <Typography className={classes.description}>
                        {t('Join our launch program today to hire your first candidate ')}
                        <strong>{t('100% free.')}</strong>
                    </Typography>
                    {!formSubmitted && renderFormik()}
                    {formSubmitted && renderThankYou()}
                </div>
            </div>
        </div>
    );

    const renderWhyNotEasy = () => (
        <Container>
            <div className={classes.firstPanel}>
                <div className={classes.panelWrapper}>
                    <Typography gutterBottom className={classes.titleEasy}>
                        {t('Why isn’t hiring simple?')}
                    </Typography>
                    <Typography className={classes.descriptionEasy}>
                        {t(
                            'Online recruitment is often quantity over quality. Finding mission driven talent from a stack of resumes is time consuming and repetitive. With Onboard you hear from pre-filtered candidates before you commit to an interview.',
                        )}
                    </Typography>
                    <Button
                        component={RouterLink}
                        to={role === UserRoles.EMPLOYER ? ROUTES.EMPLOYER_POST_JOB : ROUTES.EMPLOYER_SIGN_UP}
                        color="primary"
                        variant="contained"
                        className={classes.submitButtonEasy}
                        endIcon={<img src={ArrowRight} alt="submit" width="16" height="12" />}
                    >
                        {t('Post a job')}
                    </Button>
                </div>
                <img src={EmployerLandingImage} className={classes.firstPanelImage} alt="Working people" />
            </div>
        </Container>
    );

    const renderBetterTalent = () => (
        <Container>
            <div className={classes.savePanel}>
                <div className={classes.panelWrapper}>
                    <Typography className={clsx(classes.titleEasy, classes.marginBottom)}>
                        {t('We wanted a better way to hire talent, so we built one ourselves')}
                    </Typography>
                    <Typography className={classes.descriptionEasy}>
                        {t(
                            'Nobody wants to go through hundreds of generic job applications any more. We tune out the noise, so you can hear from qualified candidates who share your mission. Welcome Onboard.',
                        )}
                    </Typography>
                    <Button
                        component={RouterLink}
                        to={role === UserRoles.EMPLOYER ? ROUTES.EMPLOYER_POST_JOB : ROUTES.EMPLOYER_SIGN_UP}
                        color="primary"
                        variant="contained"
                        className={classes.submitButtonEasy}
                        endIcon={<img src={ArrowRight} alt="submit" width="16" height="12" />}
                    >
                        {t('Post a job')}
                    </Button>
                </div>
                <img src={SaveTimeImage} className={classes.saveTimeImage} alt="Save time" />
            </div>
        </Container>
    );

    return (
        <div className={classes.container}>
            {renderSignupForm()}

            {renderWhyNotEasy()}

            {renderCards()}

            {renderBetterTalent()}
        </div>
    );
};

export default EmployerLandingPage;
