import React, { FC, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import EmployerCompanyForm from '../../employer/EmployerCompanyPage/EmployerCompanyForm';
import {
    EmployerCompanyFormValues,
    initialValues,
    validationSchema,
} from '../../employer/EmployerCompanyPage/config';
import { getEmployerCompany, editEmployerCompany } from '../../../services/AdminService';
import { getBackendFormikErrors } from '../../../config/utils';
import DeleteUserDialog from '../AdminUsersPage/DeleteUserDialog';

const EmployerCompanyPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const [currentCompany, setCurrentCompany] = useState<EmployerCompanyFormValues | null>(null);

    useEffect(() => {
        getEmployerCompany(id)
            .then(response => {
                const { url, industry, companySizeMin, companySizeMax, contactEmail, ...others } = response;
                setCurrentCompany({
                    companySizeMin: companySizeMin || 0,
                    companySizeMax: companySizeMax || 10,
                    url: url || '',
                    industry: industry || '',
                    contactEmail: contactEmail || '',
                    ...others,
                });
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch static content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, id, t]);

    const handleSubmitForm = async (
        values: EmployerCompanyFormValues,
        { setSubmitting, setErrors }: FormikHelpers<EmployerCompanyFormValues>,
    ) => {
        try {
            await editEmployerCompany(id, values);
            enqueueSnackbar(t('Changes saved.'), { variant: 'success' });
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Submit fail', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentCompany || initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <EmployerCompanyForm
                id={id}
                additionalElement={<DeleteUserDialog id={id} usersType="companies" />}
            />
        </Formik>
    );
};

export default EmployerCompanyPage;
