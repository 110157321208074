import { FC, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import TagManager from 'react-gtm-module';
import { useStyles } from './styles';
import { publicRoutes, authorizedRoutes } from '../../config/routing';
import AuthorizedRoute from '../AuthorizedRoute';
import PublicRoute from '../PublicRoute';
import Header from '../../parts/Header';
import Footer from '../../parts/Footer';
import { thunkGetCurrentUser } from '../../store/actions';
import { readFromLocalStorage } from '../../services/config/LocalStorage';
import { Token } from '../../models/enums/Token';
import { ROUTES } from '../../config/constants';
import SearchPanel from '../../parts/SearchPanel';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import Error404Page from '../../pages/static/Error404Page';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGING !== 'true') {
    const tagManagerArgs = {
        gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_KEY}`,
    };

    TagManager.initialize(tagManagerArgs);
}

const App: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    useEffect(() => {
        const token = readFromLocalStorage(Token.TOKEN);

        if (token) {
            dispatch(thunkGetCurrentUser());
        }
    }, [dispatch]);

    return (
        <Router>
            <div className={classes.app}>
                <Header />
                <main
                    className={clsx({
                        [classes.main]: isEmpty(role),
                        [classes.mainAuthorized]: !isEmpty(role),
                    })}
                >
                    <Switch>
                        <PublicRoute
                            key="searchPanelRoutes"
                            path={[ROUTES.LANDING]}
                            exact
                            component={SearchPanel}
                        />
                    </Switch>
                    <Switch>
                        {publicRoutes.map(route => (
                            <PublicRoute
                                key={route.path}
                                path={route.path}
                                exact
                                component={route.component}
                            />
                        ))}
                        {authorizedRoutes.map(route => (
                            <AuthorizedRoute
                                path={route.path}
                                component={route.component}
                                key={route.path}
                                exact={route.exact}
                                requiredRoles={route.requiredRoles}
                            />
                        ))}
                        <PublicRoute component={Error404Page} />
                    </Switch>
                </main>
                <Footer authorized={!isEmpty(role)} />
            </div>
        </Router>
    );
};

export default App;
