import { makeStyles } from '@material-ui/core/styles';
import { SEARCH_PANEL_FILTERS_BREAKPOINT } from '../../../config/constants';

const BORDER_RADIUS_WIDTH = '8px';
const SECOND_BREAKPOINT = 1200;
const FIRST_BREAKPOINT = 700;

export const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        margin: '1rem 0',
        [theme.breakpoints.down(SECOND_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
        },
    },
    submitButtonLabel: {
        [theme.breakpoints.down(FIRST_BREAKPOINT)]: {
            fontSize: 0,
        },
    },
    buttonIcon: {
        [theme.breakpoints.down(FIRST_BREAKPOINT)]: {
            margin: 0,
        },
    },
    fieldsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: BORDER_RADIUS_WIDTH,
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 16px 16px rgba(0,0,0,0.06)',
        transition: 'box-shadow 0.5s ease-out',
        '&:hover': {
            boxShadow: '0 0 16px 16px rgba(0,0,0,0.1)',
        },
        [theme.breakpoints.down(SECOND_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '3fr auto 2fr',
        },
        [theme.breakpoints.down(FIRST_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr',
        },
    },
    fieldsWrapperCandidate: {
        boxShadow: '0 0 16px 0 rgba(0,0,0,0.06)',
        '&:hover': {
            boxShadow: '0 0 16px 0 rgba(0,0,0,0.16)',
        },
    },
    submitButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
        marginLeft: '1.875rem',
        width: 151,
        [theme.breakpoints.down(FIRST_BREAKPOINT)]: {
            width: '3rem',
            marginLeft: '0.25rem',
        },
    },
    textFieldInput: {
        height: '3rem',
        boxSizing: 'border-box',
        padding: '5px',
        fontSize: 13,
        color: '#15416D',
        lineHeight: '16px',
        fontWeight: 400,
        backgroundColor: '#FFFFFF',
        '&::placeholder': {
            color: '#616161',
            fontSize: 13,
            lineHeight: '16px',
            letterSpacing: '0.5px',
            fontWeight: 400,
            opacity: 1,
        },
        [theme.breakpoints.up(FIRST_BREAKPOINT)]: {
            padding: '1rem 0 1rem 1rem',
        },
    },
    textFieldRoot: {
        borderRadius: BORDER_RADIUS_WIDTH,
        border: 'none',
        '&$focused $notchedOutline': {
            border: 'none',
        },
        '&:hover $notchedOutline': {
            border: 'none',
        },
        [theme.breakpoints.up(FIRST_BREAKPOINT)]: {
            width: '15rem',
        },
    },
    focused: {},
    notchedOutline: {
        border: 'none',
    },
    informationInputCandidate: {
        [theme.breakpoints.up(SECOND_BREAKPOINT)]: {
            width: '40rem !important',
        },
    },
    informationInput: {
        borderRadius: `${BORDER_RADIUS_WIDTH} 0 0 ${BORDER_RADIUS_WIDTH}`,
    },
    locationInput: {
        borderRadius: `0 ${BORDER_RADIUS_WIDTH} ${BORDER_RADIUS_WIDTH} 0`,
    },
    verticalDividerInner: {
        height: '1.5rem',
        width: 1,
        backgroundColor: 'rgba(0,0,0,0.06)',
    },
    verticalDividerOuter: {
        backgroundColor: '#FFFFFF',
        padding: '0.75rem 0.5rem',
    },
    featuredButton: {
        color: '#616161',
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: '0.3px',
        padding: '32px 8px',
        [theme.breakpoints.up(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            marginRight: '2rem',
            padding: '6px 8px',
        },
    },
}));
