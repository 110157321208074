import { FC } from 'react';

import { FormikTextFieldProps } from '../formik/FormikTextField/types';
import FormikTextField from '../formik/FormikTextField';

import { useStyles } from './styles';

const CustomFormikTextField: FC<FormikTextFieldProps> = ({ InputProps, ...otherProps }) => {
    const classes = useStyles();

    return (
        <FormikTextField
            InputProps={{
                classes: {
                    input: classes.input,
                    root: classes.root,
                    focused: classes.focused,
                    // @ts-ignore
                    notchedOutline: classes.notchedOutline,
                },
                ...InputProps,
            }}
            margin="none"
            variant="outlined"
            classes={{ root: classes.rootTextField }}
            {...otherProps}
        />
    );
};

export default CustomFormikTextField;
