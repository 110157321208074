import { FC, useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import MyRolesContent from './MyRolesContent';
import {
    getCandidateAppliedJobOfferApplications,
    getCandidateSavedJobOfferApplications,
    getCandidateSuggestedJobOfferApplications,
    getMeetingOffers,
} from '../../../services/JobOfferApplicationsService';
import { InterviewProposal } from '../../../models/InterviewProposal';
import InterviewPropositionDialog from '../../../components/InterviewPropositionDialog';
import { hasProposalsAvailable } from '../../../config/utils';

type OpenedFilters = 'applied' | 'saved' | 'suggested' | null;

const CandidateMyRolesPage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState<OpenedFilters>('applied');
    const [offers, setOffers] = useState<InterviewProposal[]>([]);

    useEffect(() => {
        getMeetingOffers()
            .then(response => {
                setOffers(response);
            })
            .catch(() => {
                enqueueSnackbar(t('Unable to fetch offers, try again later'), { variant: 'error' });
            });
    }, [enqueueSnackbar, t]);

    const changeFilters = (filter: OpenedFilters): void => {
        setOpen(open => {
            if (filter === null && !open) {
                return 'applied';
            }
            return filter;
        });
    };

    const renderHeader = () => {
        return (
            <div className={classes.headerWrapper}>
                <div className={classes.headerButtons}>
                    <Button
                        className={clsx(classes.headerButton, {
                            [classes.headerButtonActive]: open === 'applied',
                        })}
                        startIcon={<CheckCircleOutlineOutlinedIcon />}
                        onClick={() => changeFilters('applied')}
                    >
                        {t('Applied')}
                    </Button>
                    <div
                        className={clsx(classes.buttonsDivider, {
                            [classes.buttonsDividerActive]: open === 'applied',
                        })}
                    />
                    <Button
                        className={clsx(classes.headerButton, {
                            [classes.headerButtonActive]: open === 'saved',
                        })}
                        startIcon={<StarBorderOutlinedIcon />}
                        onClick={() => changeFilters('saved')}
                    >
                        {t('Saved')}
                    </Button>
                    <div
                        className={clsx(classes.buttonsDivider, {
                            [classes.buttonsDividerActive]: open === 'saved',
                        })}
                    />
                    <Button
                        className={clsx(classes.headerButton, {
                            [classes.headerButtonActive]: open === 'suggested',
                        })}
                        startIcon={<EmojiObjectsOutlinedIcon />}
                        onClick={() => changeFilters('suggested')}
                    >
                        {t('Suggested')}
                    </Button>
                </div>
            </div>
        );
    };

    const getServicePropBasedOnTab = () => {
        switch (open) {
            case 'applied':
                return getCandidateAppliedJobOfferApplications;
            case 'saved':
                return getCandidateSavedJobOfferApplications;
            case 'suggested':
                return getCandidateSuggestedJobOfferApplications;
            default:
                return getCandidateAppliedJobOfferApplications;
        }
    };

    const renderOffers = () => {
        if (offers.length === 0) {
            return '';
        }

        return offers.map(offer => {
            return hasProposalsAvailable(offer.dateProposals) ? (
                <InterviewPropositionDialog interviewProposal={offer} open />
            ) : null;
        });
    };

    return (
        <>
            <Container>
                <Typography className={classes.title}>{t('My Roles')}</Typography>
                {renderHeader()}
            </Container>
            <MyRolesContent serviceProp={getServicePropBasedOnTab()} />
            {renderOffers()}
        </>
    );
};

export default CandidateMyRolesPage;
