import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {},
    form: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1rem',
    },
    buttonsWrapper: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    bold: {
        fontWeight: 700,
    },
    downloadWrapper: {
        display: 'grid',
        gridGap: '0.5rem',
    },
    downloadButton: {
        fontSize: 14,
    },
    sendButton: {
        marginLeft: 10,
        width: 201,
    },
});
