import { FC, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import FeaturedJobCard from '../../../components/FeaturedJobCard';
import LandingPageSection from '../../../parts/LandingPageSection';
import { getFeaturedJobOffers, PaginatedJobOfferResponse } from '../../../services/JobOfferService';
import { FEATURED_JOB_CARD_WIDTH } from '../../../config/constants';

const FeaturedJobsPanel: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const classes = useStyles();
    const [jobs, setJobs] = useState<PaginatedJobOfferResponse>();

    useEffect(() => {
        if (!jobs) {
            getFeaturedJobOffers()
                .then(featuredJobs => {
                    setJobs(featuredJobs);
                })
                .catch(() =>
                    enqueueSnackbar(t('Unable to fetch featured job offers, try again later'), {
                        variant: 'error',
                    }),
                );
        }
    });

    const slideStyle = {
        padding: 15,
        minHeight: 100,
        color: '#fff',
        height: '100%',
    };

    const slideWrapperStyle = {
        overflow: 'visible',
        width: FEATURED_JOB_CARD_WIDTH,
    };

    const renderFeaturedJobs = () => {
        if (jobs?.content.length) {
            return (
                <SwipeableViews
                    enableMouseEvents
                    style={{
                        width: FEATURED_JOB_CARD_WIDTH,
                        overflow: 'visible',
                        marginLeft: `calc(50vw - ${FEATURED_JOB_CARD_WIDTH / 2}px)`,
                    }}
                    index={0}
                    slideStyle={slideWrapperStyle}
                >
                    <div style={slideStyle}>
                        <FeaturedJobCard job={jobs.content[0]} />
                    </div>
                    <div style={slideStyle}>
                        <FeaturedJobCard job={jobs.content[1]} />
                    </div>
                    <div style={slideStyle}>
                        <FeaturedJobCard job={jobs.content[2]} />
                    </div>
                    <div style={slideStyle}>
                        <FeaturedJobCard job={jobs.content[3]} />
                    </div>
                </SwipeableViews>
            );
        }

        return (
            <SwipeableViews
                enableMouseEvents
                index={1}
                style={{
                    width: FEATURED_JOB_CARD_WIDTH,
                    overflow: 'visible',
                    marginLeft: `calc(50vw - ${FEATURED_JOB_CARD_WIDTH / 2})`,
                }}
                slideStyle={slideWrapperStyle}
            >
                <div style={slideStyle}>
                    <FeaturedJobCard job={undefined} />
                </div>
                <div style={slideStyle}>
                    <FeaturedJobCard job={undefined} />
                </div>
                <div style={slideStyle}>
                    <FeaturedJobCard job={undefined} />
                </div>
                <div style={slideStyle}>
                    <FeaturedJobCard job={undefined} />
                </div>
            </SwipeableViews>
        );
    };

    const renderMobile = () => {
        return <div className={classes.jobsContainerMobile}>{renderFeaturedJobs()}</div>;
    };

    const renderDesktop = () => {
        return (
            <div className={classes.jobsContainer}>
                <FeaturedJobCard job={jobs?.content[0]} />
                <FeaturedJobCard job={jobs?.content[1]} />
                <FeaturedJobCard job={jobs?.content[2]} />
                <FeaturedJobCard job={jobs?.content[3]} />
            </div>
        );
    };

    return (
        <LandingPageSection
            sliderWrapper
            title="Featured jobs"
            titleColor="#FFFFFF"
            sectionBackgroundColor="#1F3874"
        >
            {renderMobile()}
            {renderDesktop()}
        </LandingPageSection>
    );
};

export default FeaturedJobsPanel;
