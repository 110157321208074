import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router';

import CandidateProfileForm from '../../../parts/CandidateProfileForm';
import { initialValues, validationSchema } from './config';
import UserProfile from '../../../models/UserProfile';
import { getBackendFormikErrors } from '../../../config/utils';
import { editCandidateUserProfile, getCandidateUserProfile } from '../../../services/AdminService';
import DeleteUserDialog from '../AdminUsersPage/DeleteUserDialog';

const AdminEditCandidateProfilePage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const [currentlyEmployed, setCurrentlyEmployed] = useState<boolean>(false);
    const [currentProfile, setCurrentProfile] = useState<UserProfile | null>(null);

    const updateCurrentProfile = useCallback((newValues: UserProfile) => {
        const {
            firstName,
            lastName,
            linkedinUrl,
            location,
            contactPhoneNumber,
            contactEmail,
            currentPosition,
            ...others
        } = newValues;

        setCurrentProfile({
            firstName: firstName || '',
            lastName: lastName || '',
            location: location || '',
            linkedinUrl: linkedinUrl || '',
            contactPhoneNumber: contactPhoneNumber || '',
            contactEmail: contactEmail || '',
            currentPosition: currentPosition || '',
            ...others,
        });
        setCurrentlyEmployed(newValues.currentlyEmployed || false);
    }, []);

    useEffect(() => {
        getCandidateUserProfile(id)
            .then((response: UserProfile) => {
                updateCurrentProfile(response);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch user profile, try again later'), {
                    variant: 'error',
                }),
            );
    }, [enqueueSnackbar, id, t, updateCurrentProfile]);

    const handleSubmitForm = async (
        values: UserProfile,
        { setSubmitting, setErrors }: FormikHelpers<UserProfile>,
    ) => {
        try {
            const response = await editCandidateUserProfile(id, values);
            setCurrentProfile(null);
            updateCurrentProfile(response);
            enqueueSnackbar(t('Profile successfully saved.'), { variant: 'success' });
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentProfile || initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            {!!currentProfile && (
                <CandidateProfileForm
                    setCurrentlyEmployed={setCurrentlyEmployed}
                    currentProfile={currentProfile}
                    currentlyEmployed={currentlyEmployed}
                    additionalElement={<DeleteUserDialog id={id} usersType="candidates" />}
                />
            )}
        </Formik>
    );
};

export default AdminEditCandidateProfilePage;
