import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    form: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        gridGap: '2rem',
    },
    container: {
        marginBottom: '3rem',
    },
    containerAddJob: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    centerWrapper: {
        flex: 1,
        marginBottom: '3rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    addJob: {
        marginTop: 20,
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchButton: {
        marginTop: '0.75rem',
        border: '2px solid #007FFC',
        fontSize: '1rem',
        fontWeight: 500,
        '&:focus, &:hover': {
            border: '2px solid #007FFC',
            boxShadow: '0 0px 8px 0 rgba(0,127,252,0.52)',
        },
    },
    formControlLabel: {
        padding: '0.5rem',
    },
});
