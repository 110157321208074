import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { initialValues, validationSchema } from './config';
import UserProfile from '../../../models/UserProfile';
import { editCurrentUserProfile, getCurrentUserProfile } from '../../../services/UserService';
import { thunkGetCurrentUser } from '../../../store/actions';
import { getBackendFormikErrors } from '../../../config/utils';
import EmployerProfileForm from '../../../parts/EmployerProfileForm';

const EmployerProfilePage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentProfile, setCurrentProfile] = useState<UserProfile | null>(null);

    useEffect(() => {
        getCurrentUserProfile()
            .then((response: UserProfile) => {
                const { firstName, lastName, contactEmail, contactPhoneNumber, ...others } = response;

                setCurrentProfile({
                    firstName: firstName || '',
                    lastName: lastName || '',
                    contactPhoneNumber: contactPhoneNumber || '',
                    contactEmail: contactEmail || '',
                    ...others,
                });
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch current user profile, try again later'), {
                    variant: 'error',
                }),
            );
    }, [enqueueSnackbar, t]);

    const handleSubmitForm = async (
        values: UserProfile,
        { setSubmitting, setErrors }: FormikHelpers<UserProfile>,
    ) => {
        try {
            await editCurrentUserProfile(values).then(() => dispatch(thunkGetCurrentUser()));
            enqueueSnackbar(t('Profile successfully saved.'), { variant: 'success' });
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentProfile || initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <EmployerProfileForm currentProfile={currentProfile} />
        </Formik>
    );
};

export default EmployerProfilePage;
