import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    input: {
        height: 45,
        boxSizing: 'border-box',
        fontSize: 13,
        color: '#15416D',
        lineHeight: '16px',
        fontWeight: 400,
        '&::placeholder': {
            color: '#8A8A8A',
            fontSize: 13,
            lineHeight: '16px',
            letterSpacing: '0.1px',
            fontWeight: 400,
        },
    },
    focused: {},
    notchedOutline: {
        border: '1px solid rgba(151,151,151,0.14)',
    },
    root: {
        backgroundColor: '#FFFFFF',
        '&$focused $notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
        '&:hover $notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
    },
    rootTextField: {
        marginTop: '0.25rem',
        backgroundColor: '#FFFFFF',
    },
});
