import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#15416D',
        textAlign: 'center',
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    headerButtons: {
        position: 'relative',
        display: 'inline-flex',
        marginBottom: '0.5rem',
        borderRadius: 8,
        overflow: 'hidden',
        backgroundColor: '#fff',
        boxShadow: '0 0px 17px 0 rgba(0,0,0,0.06)',
    },
    headerButton: {
        position: 'relative',
        color: '#616161',
        fontWeight: 400,
        fontSize: '13px',
        letterSpacing: '0.3px',
        padding: '15px 40px',
        borderRadius: 0,
    },
    headerButtonActive: {
        color: '#fff',
        backgroundColor: '#007FFC',
        fontWeight: 500,

        '&:hover': {
            color: '#fff',
            backgroundColor: '#007FFC',
        },
    },
    buttonsDivider: {
        content: "''",
        display: 'inline-block',
        width: 1,
        height: 25,
        backgroundColor: 'grey',
        transform: 'translateY(50%)',
        marginLeft: '-1px',
    },
    buttonsDividerActive: {
        backgroundColor: 'white',
    },
});
