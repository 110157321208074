import { FC } from 'react';
import { Field, Formik, FormikHelpers } from 'formik';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import BlurredStyleFormikTextField from '../../../../components/BlurredStyleFormikTextField';
import { ROUTES } from '../../../../config/constants';
import { login } from '../../../../services/config/AuthenticationService';
import { writeToLocalStorage } from '../../../../services/config/LocalStorage';
import { Token } from '../../../../models/enums/Token';

import { useStyles } from './styles';
import { initialValues, validationSchema, EmployerLoginFormValues } from './config';
import { getBackendFormikErrors } from '../../../../config/utils';
import { thunkGetCurrentUser } from '../../../../store/actions';
import { CurrentUserReducer, RootReducer } from '../../../../models/Redux';
import { UserRoles } from '../../../../models/enums/UserRoles';

const EmployerLoginForm: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    if (role === UserRoles.EMPLOYER) {
        history.push(ROUTES.EMPLOYER_LANDING_PAGE);
    }

    if (role === UserRoles.ADMIN) {
        history.push(ROUTES.ADMIN_USERS);
    }

    if (role === UserRoles.SUPPORT) {
        history.push(ROUTES.SUPPORT_COMPANIES);
    }

    const handleSubmitForm = async (
        values: EmployerLoginFormValues,
        { setErrors }: FormikHelpers<EmployerLoginFormValues>,
    ) => {
        const { username, password } = values;
        login(username, password)
            .then(response => {
                writeToLocalStorage(Token.TOKEN, response.headers.authorization);
                dispatch(thunkGetCurrentUser());
            })
            .catch(error => {
                setErrors(getBackendFormikErrors<EmployerLoginFormValues>(error));
                enqueueSnackbar(t('Failed to login. Please try again.'), { variant: 'error' });
            });
    };

    return (
        <Container classes={{ root: classes.container }}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
            >
                {formik => (
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <BlurredStyleFormikTextField
                            name="username"
                            placeholder={t('Email')}
                            label={t('Email')}
                        />

                        <BlurredStyleFormikTextField
                            name="password"
                            type="password"
                            placeholder={t('Password')}
                        />
                        <div className={classes.bottomContainer}>
                            <div className={classes.rememberMe}>
                                <Field
                                    type="checkbox"
                                    name="remember"
                                    component={Checkbox}
                                    value={t('Remember me')}
                                    color="primary"
                                />
                                <Typography>{t('Remember me')}</Typography>
                            </div>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.submitButton}
                            >
                                {t('Sign in')}
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
            <div className={classes.signUpButtonsContainer}>
                <Link component={RouterLink} to={ROUTES.EMPLOYER_SIGN_UP}>
                    {t('Sign up')}
                </Link>
                <div className={classes.signUpButtonsDivider} />
                <Link component={RouterLink} to={ROUTES.EMPLOYER_FORGOT_PASSWORD}>
                    {t('Forgot password?')}
                </Link>
            </div>
        </Container>
    );
};

export default EmployerLoginForm;
