import { FC, useState } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Formik } from 'formik';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import { CurrentUserReducer, RootReducer } from '../../../../models/Redux';
import { UserRoles } from '../../../../models/enums/UserRoles';
import { ROUTES } from '../../../../config/constants';
import { EmployerForgotPasswordFormValues, initialValues, validationSchema } from './config';
import BlurredStyleFormikTextField from '../../../../components/BlurredStyleFormikTextField';
import { useStyles } from './styles';
import { forgotPassword } from '../../../../services/UserService';

const EmployerForgotPasswordForm: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [success, setSuccess] = useState<boolean>(false);

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    if (role === UserRoles.EMPLOYER) {
        history.push(ROUTES.EMPLOYER_LANDING_PAGE);
    }

    if (role === UserRoles.ADMIN) {
        history.push(ROUTES.ADMIN_USERS);
    }

    const handleSubmitForm = async (values: EmployerForgotPasswordFormValues) => {
        const { username } = values;
        try {
            await forgotPassword(username);
            setSuccess(true);
        } catch (error) {
            enqueueSnackbar(`Error resetting password. Please check if the email you entered was correct.`, {
                variant: 'error',
            });
        }
    };

    if (success) {
        return (
            <Container className={classes.containerSubmitted}>
                <div className={classes.successWrapper}>
                    <CheckCircleIcon className={classes.checkIcon} color="primary" />
                    <Typography className={classes.okTitle}>{t('Submitted successfully')}</Typography>
                </div>
                <div className={classes.thanksWrapper}>
                    <Typography className={classes.thanksText}>
                        {t(
                            `You will shortly receive an email with a link to change your password to a new one.`,
                        )}
                    </Typography>
                </div>
                <Button
                    component={RouterLink}
                    to={ROUTES.EMPLOYER_SIGN_IN}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                >
                    {t('OK')}
                </Button>
            </Container>
        );
    }

    return (
        <Container classes={{ root: classes.container }}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
            >
                {formik => (
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <BlurredStyleFormikTextField
                            name="username"
                            placeholder={t('Email')}
                            label={t('Email')}
                        />
                        <div className={classes.bottomContainer}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.submitButton}
                            >
                                {t('Reset password')}
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
            <div className={classes.signUpButtonsContainer}>
                <Link component={RouterLink} className={classes.link} to={ROUTES.EMPLOYER_SIGN_IN}>
                    {t('Log in')}
                </Link>
                <div className={classes.signUpButtonsDivider} />
                <Link
                    component={RouterLink}
                    className={clsx(classes.link, classes.colorGrey)}
                    to={ROUTES.EMPLOYER_SIGN_UP}
                >
                    {t('Sign up')}
                </Link>
            </div>
        </Container>
    );
};

export default EmployerForgotPasswordForm;
