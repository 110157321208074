export type JobCategory =
    | 'Architecture and Construction'
    | 'Education and Training'
    | 'Officials and Administrators'
    | 'Hospitality and Tourism'
    | 'Information and Technology';

export const LINKEDIN_STATE_KEY = 'linkedin_state_key';

export const ONBOARD_QUESTIONS_INFO_DONT_SHOW = 'ONBOARD_QUESTIONS_INFO_DONT_SHOW';

export const ROUTES = {
    // Unauthenticated
    LANDING: '/',
    CONTACT: '/contact',
    EMPLOYER_LANDING_PAGE: '/for-employer',
    EMPLOYER_SIGN_IN: '/employer/sign-in',
    EMPLOYER_SIGN_UP: '/employer/sign-up',
    EMPLOYER_FORGOT_PASSWORD: '/employer/forgot-password',
    CANDIDATE_SIGN_IN: '/candidate/sign-in',
    CANDIDATE_LINKEDIN_AUTH: '/linkedin-redirect',
    JOB_OFFER_DETAILS: '/jobs/:id',
    // Admin logged in
    ADMIN_USERS: '/admin/users',
    ADMIN_CREATE_EMPLOYER: '/admin/create-employer',
    ADMIN_CREATE_SUPPORT: '/admin/create-support',
    ADMIN_COMPANIES: '/admin/companies',
    ADMIN_JOBS: '/admin/jobs',
    ADMIN_ANALYTICS: '/admin/analytics',
    ADMIN_CREATE_COMPANY: '/admin/create-company',
    ADMIN_EDIT_EMPLOYER_PROFILE: '/admin/edit-employer/:id',
    ADMIN_EDIT_SUPPORT_PROFILE: '/admin/edit-support/:id',
    ADMIN_EDIT_CANDIDATE_PROFILE: '/admin/edit-candidate/:id',
    ADMIN_SUPPORT_EDIT_COMPANY_PROFILE: '/edit-company/:id',
    ADMIN_SUPPORT_EDIT_JOB_OFFER: '/edit-job/:id',
    // Support logged in
    SUPPORT_COMPANIES: '/support/companies',
    SUPPORT_CREATE_COMPANY: '/support/create-company',
    SUPPORT_JOBS: '/support/jobs',
    // Employer logged in
    EMPLOYER_HOME: '/employer/jobs',
    EMPLOYER_POST_JOB: '/employer/post-job',
    EMPLOYER_COMPANY_PROFILE: '/employer/company',
    EMPLOYER_PROFILE: '/employer/profile',
    EMPLOYER_JOB_CANDIDATES: '/employer/candidates',
    EMPLOYER_EDIT_JOB_OFFER: '/employer/edit-jobs/:id',

    // Candidate logged in
    CANDIDATE_MY_ROLES: '/my-roles',
    CANDIDATE_FIND_A_JOB: '/jobs',
    CANDIDATE_APPLY_JOB: '/candidate/apply-job/:id',
    CANDIDATE_PROFILE: '/candidate/profile',
    // Static pages
    ABOUT: '/about',
    TERMS_OF_USE: '/terms-of-use',
};

export const JOB_OFFER_CARD_BREAKPOINT = 1100;

export const SEARCH_PANEL_FILTERS_BREAKPOINT = 800;

export const HEADER_BREAKPOINT = 900;

export const LANDING_BREAKPOINT_FIRST = 700;

export const EMPLOYER_CANDIDATES_BREAKPOINTS = 900;

export const JOB_OFFER_CARD_MOBILE_WIDTH = 328;

export const LOGO_FONT = '"Courgette", cursive';

export const FEATURED_JOB_CARD_WIDTH = 284;
