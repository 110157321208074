import React, { FC } from 'react';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import Media from '../../../models/Media.model';
import { uploadMediaFile } from '../../../services/MediaService';
import { useStyles } from './styles';

interface FormikImageUploadProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
    suggestionMessage?: string;
    withBorder?: boolean;
    fullWidth?: boolean;
}

const FormikImageUpload: FC<FormikImageUploadProps> = ({
    name,
    label,
    disableLabel,
    suggestionMessage,
    withBorder = true,
    fullWidth = false,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { value } = useField(name)[1];
    const { setValue } = useField(name)[2];

    const handleUploadFile = (file: File) => {
        uploadMediaFile(file).then((response: Media) => {
            setValue(response);
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        noDrag: true,
        accept: 'image/*',
        onDrop: files => handleUploadFile(files[0]),
    });

    return (
        <div
            className={clsx({
                [classes.wrapper]: true,
                [classes.fullWidth]: fullWidth,
            })}
        >
            {!disableLabel && <InputLabel>{label || name}</InputLabel>}
            {suggestionMessage && (
                <Alert severity="info" className={classes.suggestionMessage}>
                    {t(suggestionMessage)}
                </Alert>
            )}
            <div
                {...getRootProps()}
                className={clsx({
                    [classes.dropzoneArea]: true,
                    [classes.withBorder]: withBorder,
                })}
            >
                <input {...getInputProps()} />
                {!!value?.url && <img src={value.url} alt={name} className={classes.logo} />}
                <Button
                    className={classes.uploadButton}
                    color="primary"
                    variant="outlined"
                    startIcon={<CloudUploadOutlinedIcon />}
                >
                    {t('Upload image')}
                </Button>
            </div>
        </div>
    );
};

export default FormikImageUpload;
