import { FC } from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Token } from '../../models/enums/Token';
import { UserRoles } from '../../models/enums/UserRoles';
import { useLocalStorage } from '../../config/hooks/useLocalStorage';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { ROUTES } from '../../config/constants';

interface AuthorizedRouteProps extends RouteProps {
    component: FC<RouteComponentProps>;
    requiredRoles: UserRoles[];
}

const AuthorizedRoute: FC<AuthorizedRouteProps> = ({ component: Component, requiredRoles, ...rest }) => {
    const [token] = useLocalStorage(Token.TOKEN, null);
    const {
        user: { role },
        loading,
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const isAuthed = !!token;
    const userHasRequiredRole = requiredRoles.includes(role as UserRoles);

    if (!isAuthed) {
        if (
            requiredRoles.includes(UserRoles.EMPLOYER) ||
            requiredRoles.includes(UserRoles.ADMIN) ||
            requiredRoles.includes(UserRoles.SUPPORT)
        ) {
            return <Redirect to={{ pathname: ROUTES.EMPLOYER_SIGN_IN }} />;
        }
        return <Redirect to={{ pathname: ROUTES.LANDING }} />;
    }

    if (loading) return null;

    return (
        <div>
            <Route
                {...rest}
                render={routeProps =>
                    isAuthed && userHasRequiredRole ? (
                        <Component {...routeProps} />
                    ) : (
                        <Redirect to={{ pathname: ROUTES.LANDING, state: { from: routeProps.location } }} />
                    )
                }
            />
        </div>
    );
};

export default AuthorizedRoute;
