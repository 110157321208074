import { makeStyles } from '@material-ui/core/styles';
import {
    HEADER_BREAKPOINT,
    JOB_OFFER_CARD_BREAKPOINT,
    LANDING_BREAKPOINT_FIRST,
} from '../../../config/constants';
import background from '../../../assets/launch/rocket.jpg';

const POST_JOB_BREAKPOINT = 900;
const HOW_IT_WORKS_BREAKPOINT = 800;
const HOW_IT_WORKS_BREAKPOINT_TWO = 1200;
const SAVE_TIME_BREAKPOINT = 800;

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#fff',
        overflowX: 'hidden',
    },
    formContainer: {
        padding: '2rem 0',
        width: '100%',
    },
    title: {
        fontSize: '2rem',
        fontWeight: 700,
        margin: '1rem auto',
        letterSpacing: '0.82px',
        color: '#15416D',
        textAlign: 'center',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.up(1500)]: {
            fontSize: '4rem',
        },
    },
    description: {
        fontSize: 16,
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing: '0.57px',
        color: '#616161',
        textAlign: 'center',

        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            fontSize: 22,
        },

        [theme.breakpoints.up(1500)]: {
            fontSize: 29,
            lineHeight: '2rem',
        },
    },
    fieldsContainer: {
        // display: 'grid',
        // gridTemplateColumns: '1fr 1fr',
        // justifyContent: 'center',
        // alignItems: 'center',
        // gridGap: 10,
        // [theme.breakpoints.up(800)]: {
        //     display: 'flex',
        // },
        width: '100%',
    },
    formText: {
        fontSize: 16,
        fontWeight: 600,
        color: '#616161',
        margin: 10,
        [theme.breakpoints.down(1250)]: {
            color: 'black',
        },
        [theme.breakpoints.up(1500)]: {
            fontSize: 20,
        },
    },
    cardsContainer: {
        margin: '0 auto 3rem',
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridGap: '1.5rem',
        maxWidth: '73.5rem',

        [theme.breakpoints.up(800)]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.between(800, 1200)]: {
            maxWidth: 'calc(100vw - 4rem - 48px)',
        },
        [theme.breakpoints.down(800)]: {
            maxWidth: 'calc(100vw - 32px)',
        },
        [theme.breakpoints.up(1200)]: {
            gridGap: '3rem',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 20px 16px 12px',
        borderRadius: '1rem',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        boxShadow: '0 0 77px 0 rgba(0,0,0,0.06)',
        border: '3px solid #FFFFFF',
        '&:hover': {
            border: '3px solid #007FFC',
            boxShadow: '0 0 77px 0 rgba(0,0,0,0.16)',
        },

        [theme.breakpoints.up(1200)]: {
            padding: '25px 30px 30px 30px',
            width: '21.5rem',
        },
    },
    stepNumber: {
        width: 35,
        height: 35,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#007FFC',
        color: '#FFFFFF',
        fontSize: '1rem',
        lineHeight: '2rem',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',

        [theme.breakpoints.up(800)]: {
            marginBottom: '1rem',
        },

        [theme.breakpoints.up(1200)]: {
            width: 58,
            height: 58,
            fontSize: '1.5rem',
            marginBottom: '2rem',
        },
    },
    image: {
        maxHeight: 160,
        [theme.breakpoints.down(600)]: {
            maxHeight: 130,
        },
        [theme.breakpoints.down(400)]: {
            maxHeight: 110,
        },
    },
    cardTitle: {
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
        fontWeight: 500,
        color: '#007FFC',
        textAlign: 'center',
        marginTop: 10,
    },
    cardDescription: {
        fontSize: '0.8125rem',
        lineHeight: '21px',
        fontWeight: 400,
        color: '#15416D',
        padding: '1rem 10px',
    },
    bold: {
        fontWeight: 600,
    },
    successWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '4rem auto',
    },
    checkIcon: {
        fontSize: '3rem',
    },
    thankYouTitle: {
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
        fontWeight: 500,
        color: '#007FFC',
        marginLeft: '1rem',
    },
    inputRoot: {
        border: '1px solid #007FFC',
        borderRadius: 4,
        width: '100%',
    },
    signupFormContainer: {
        position: 'relative',
        width: '100vw',
        marginBottom: '3rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up(1250)]: {
            justifyContent: 'flex-end',
            height: 'calc(100vh - 115px)',
            backgroundPosition: 'contain',
        },
        [theme.breakpoints.down(1250)]: {
            backgroundColor: '#F7FBFF',
            paddingTop: '3rem',
        },
        [theme.breakpoints.down(900)]: {
            backgroundPositionX: '-100px',
        },
    },
    overlay: {
        [theme.breakpoints.down(1250)]: {
            content: '',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgb(247, 251, 255, 0.7)',
        },
    },
    signupFormColumn: {
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up(1250)]: {
            flexBasis: '50%',
        },
        [theme.breakpoints.up(600)]: {
            padding: '0 24px 24px 24px',
        },
        [theme.breakpoints.down(600)]: {
            padding: 16,
        },
    },
    signupForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 450,
        width: '100%',
        [theme.breakpoints.up(1400)]: {
            marginLeft: '-200px',
            maxWidth: 600,
        },
    },
    input: {
        height: '3rem',
        backgroundColor: 'white',
        [theme.breakpoints.up(1500)]: {
            height: '4rem',
            fontSize: 18,
        },
    },
    submitButton: {
        minWidth: 100,
        display: 'block',
        margin: '2rem auto',
        backgroundColor: '#F05C44 !important',
        '&:hover': {
            boxShadow: '0 2px 17px 0 rgb(252 116 0 / 52%)',
        },
        [theme.breakpoints.up(1500)]: {
            height: '4rem',
            minWidth: 130,
            margin: '3rem auto',
        },
    },
    submitButtonLabel: {
        [theme.breakpoints.up(1500)]: {
            fontSize: '1.5rem',
        },
    },
    panelWrapper: {
        [theme.breakpoints.up(SAVE_TIME_BREAKPOINT)]: {
            marginLeft: '2rem',
        },
    },
    firstPanel: {
        padding: '1.75rem 0 3.75rem',
        display: 'flex',
        flexDirection: 'column-reverse',

        [theme.breakpoints.up(POST_JOB_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridGap: '6.5rem',
            alignItems: 'center',
        },
    },
    firstPanelImage: {
        width: 500,
        maxWidth: '100%',
        objectFit: 'contain',
    },
    titleEasy: {
        fontSize: '2rem',
        fontWeight: 700,
        margin: '1rem auto',
        letterSpacing: '0.82px',
        color: '#15416D',

        [theme.breakpoints.up(POST_JOB_BREAKPOINT)]: {
            fontSize: '2.75rem',
        },
    },
    descriptionEasy: {
        fontSize: '14px',
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing: '0.57px',
        color: '#616161',

        [theme.breakpoints.up(POST_JOB_BREAKPOINT)]: {
            fontSize: '1rem',
        },
    },
    submitButtonEasy: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
        marginTop: '1.5rem',
        width: 151,
    },
    stepsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridGap: '1.5rem',

        [theme.breakpoints.up(HOW_IT_WORKS_BREAKPOINT)]: {
            gridTemplateColumns: 'repeat(3, auto)',
        },

        [theme.breakpoints.up(HOW_IT_WORKS_BREAKPOINT_TWO)]: {
            gridGap: '3rem',
        },
    },
    stepWrapper: {
        padding: '16px 20px 16px 12px',
        borderRadius: '1rem',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        boxShadow: '0 0 77px 0 rgba(0,0,0,0.06)',
        border: '3px solid #FFFFFF',
        '&:hover': {
            border: '3px solid #007FFC',
            boxShadow: '0 0 77px 0 rgba(0,0,0,0.16)',
        },

        [theme.breakpoints.up(HOW_IT_WORKS_BREAKPOINT_TWO)]: {
            padding: '36px 40px 36px 32px',
            width: '21.5rem',
            height: '16.75rem',
        },
    },
    stepDescription: {
        fontSize: '0.8125rem',
        lineHeight: '21px',
        fontWeight: 400,
        color: '#15416D',
    },
    stepTitle: {
        fontSize: '1.25rem',
        lineHeight: '2.25rem',
        fontWeight: 500,
        color: '#007FFC',
    },
    titleHowWorks: {
        fontSize: 30,
        letterSpacing: '1.61px',
        fontWeight: 500,
        color: '#15416D',
        textAlign: 'center',
        marginBottom: '2.5rem',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            fontSize: 35,
            marginBottom: '3rem',
        },
    },
    savePanel: {
        padding: '1.75rem 0 3.75rem',
        display: 'flex',
        flexDirection: 'column-reverse',

        [theme.breakpoints.up(SAVE_TIME_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridGap: '5.5rem',
            alignItems: 'center',
            padding: '3.25rem 0 3.75rem',
        },
    },
    marginBottom: {
        marginBottom: '2rem',
    },
    saveTimeImage: {
        width: 393,
        maxWidth: '100%',
        objectFit: 'contain',
    },
    howItWorks: {
        backgroundColor: '#F7FBFF',
        padding: '2rem 0',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            padding: '3.75rem 0',
        },
    },
    sliderWrapper: {
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            padding: 0,
            overflowX: 'hidden',
        },
    },
}));
