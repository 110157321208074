import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import clsx from 'clsx';
import { useField } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { InputLabelProps as InputLabelPropsTypes } from '@material-ui/core/InputLabel/InputLabel';

import CustomTextField from '../CustomTextField';
import TeamMemberAvatar from '../TeamMemberAvatar';
import { getCurrentEmployerCompanyTeamMembers } from '../../services/UserService';
import { TeamMember } from '../../models/Company';
import { useStyles } from './styles';
import AddTeamMemberDialog from '../AddTeamMemberDialog';
import { getEmployerCompanyTeamMembers } from '../../services/AdminService';

interface TeamFormikAutocompleteProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
    InputLabelProps?: InputLabelPropsTypes;
    id?: string | number;
    companyId?: string | number;
    isAdmin?: boolean;
}

const TeamFormikAutocomplete: FC<TeamFormikAutocompleteProps> = ({
    id,
    name,
    label,
    disableLabel,
    InputLabelProps,
    isAdmin,
    companyId,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { value } = useField<TeamMember[]>(name)[1];
    const { setValue } = useField(name)[2];
    const [savedTeamMember, setSavedTeamMembers] = useState<TeamMember[]>([]);

    const getCompanyTeamMember = isAdmin
        ? getEmployerCompanyTeamMembers
        : getCurrentEmployerCompanyTeamMembers;
    const searchById = isAdmin ? companyId : id;

    useEffect(() => {
        getCompanyTeamMember(searchById as string | number)
            .then(response => {
                setSavedTeamMembers(response);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch available team members, try again later'), {
                    variant: 'error',
                }),
            );
    }, [enqueueSnackbar, getCompanyTeamMember, searchById, t]);

    const addNewTeamMember = (teamMember: TeamMember): void => {
        setValue([...value, teamMember]);
    };

    const deleteMemberByIndex = (index: number) => {
        setValue(value.filter((member, memberIndex) => memberIndex !== index));
    };

    const renderDeleteTeamMember = (index: number) => (
        <CancelIcon
            className={classes.cancelIcon}
            color="primary"
            onClick={() => deleteMemberByIndex(index)}
        />
    );

    const getNameInitials = (name: string) =>
        name
            .split(' ')
            .slice(0, 2)
            .map(text => text.substring(0, 1))
            .join('')
            .toUpperCase();

    const renderDefaultAvatar = ({ name, position }: TeamMember, index: number) => {
        const nameInitials = getNameInitials(name);

        return (
            <div
                title={`${name} - ${position}`}
                key={`${name}${index}`}
                className={clsx(classes.defaultAvatar, classes.avatarBase)}
            >
                {nameInitials}
                {renderDeleteTeamMember(index)}
            </div>
        );
    };

    const renderMemberAvatar = (teamMember: TeamMember, index: number) => {
        const url = teamMember.avatar?.url;
        if (url) {
            return (
                <div
                    key={`${teamMember.name}${index}`}
                    className={classes.avatarBase}
                    title={`${teamMember.name} - ${teamMember.position}`}
                >
                    {renderDeleteTeamMember(index)}
                    <img src={url} alt="team member avatar" className={classes.userAvatar} />
                </div>
            );
        }
        return renderDefaultAvatar(teamMember, index);
    };

    const renderTeamMembers = () => {
        if (value.length) {
            return (
                <div className={classes.membersWrapper}>
                    {value.map((member, index) => renderMemberAvatar(member, index))}
                </div>
            );
        }
        return null;
    };

    const renderAutocomplete = () => {
        return (
            <Autocomplete
                key={value.length}
                options={savedTeamMember.filter(
                    ({ name, position }) =>
                        !value.some(member => member.name === name && member.position === position),
                )}
                classes={{ inputRoot: classes.inputRoot, root: classes.root }}
                getOptionLabel={option => option.name}
                clearOnEscape
                renderInput={params => (
                    <CustomTextField
                        {...params}
                        variant="outlined"
                        placeholder={t('Choose team member')}
                        InputProps={{
                            classes: {
                                // @ts-ignore
                                input: classes.inputTextFieldInput,
                                notchedOutline: classes.inputTextFieldRootNotched,
                            },
                            ...params.InputProps,
                        }}
                    />
                )}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setValue([...value, newValue]);
                    }
                }}
                renderOption={(option, { inputValue }) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);

                    return (
                        <div className={classes.optionWrapper}>
                            <TeamMemberAvatar
                                name={option.name}
                                url={option.avatar?.url}
                                size={32}
                                fontSize={10}
                            />
                            <div className={classes.textWrapper}>
                                <div>
                                    {parts.map((part, index) => (
                                        <Typography
                                            className={clsx(classes.name, {
                                                [classes.nameHighlight]: part.highlight,
                                            })}
                                            key={`${option.name}${index}`}
                                        >
                                            {part.text}
                                        </Typography>
                                    ))}
                                </div>
                                <Typography className={classes.position}>{option.position}</Typography>
                            </div>
                        </div>
                    );
                }}
            />
        );
    };

    return (
        <div>
            {!disableLabel && (
                <InputLabel htmlFor={name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <div className={classes.contentWrapper}>
                {renderAutocomplete()}
                <AddTeamMemberDialog onSubmitTeamMember={addNewTeamMember} />
                {renderTeamMembers()}
            </div>
        </div>
    );
};

export default TeamFormikAutocomplete;
