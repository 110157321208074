import * as yup from 'yup';
import i18n from 'i18next';

export const initialValues = {
    firstName: '',
    lastName: '',
    avatar: null,
    linkedinUrl: '',
    location: '',
    currentlyEmployed: false,
    cvFile: null,
    contactPhoneNumber: '',
    contactEmail: '',
    educations: [],
    jobExperiences: [],
    currentPosition: '',
};

export const validationSchema = yup.object().shape({
    firstName: yup
        .string()
        .trim(i18n.t('First name cannot begin and end with a whitespace'))
        .required(i18n.t('First name is required'))
        .min(3, `${i18n.t('Minimum number of characters is ')} \${min}`)
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    lastName: yup
        .string()
        .trim(i18n.t('Last name cannot begin and end with a whitespace'))
        .required(i18n.t('Last name is required'))
        .min(3, `${i18n.t('Minimum number of characters is ')} \${min}`)
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    currentPosition: yup
        .string()
        .nullable()
        .trim(i18n.t('Current position cannot begin and end with a whitespace'))
        .when('currentlyEmployed', {
            is: true,
            then: yup
                .string()
                .required(i18n.t('Current position is required'))
                .typeError(i18n.t('Current position is required')),
        })
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    contactPhoneNumber: yup
        .string()
        .trim(i18n.t('Contact phone number cannot begin and end with a whitespace'))
        .min(6, `${i18n.t('Minimum number of characters is ')} \${min}`)
        .max(50, `${i18n.t('Maximum number of characters is')} \${max}`)
        .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, i18n.t('Enter a correct phone number')),
    contactEmail: yup
        .string()
        .trim(i18n.t('Contact email cannot begin and end with a whitespace'))
        .email(i18n.t('Email must be valid')),
    linkedinUrl: yup
        .string()
        .trim(i18n.t('Linkedin url cannot begin and end with a whitespace'))
        .matches(
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
            i18n.t('Enter correct url'),
        ),
});
