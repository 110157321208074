import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import { useStyles } from './styles';
import { JobOfferApplicationEvent, JobOfferApplicationStatus } from '../../models/JobOfferApplication';
import {
    ApplicationStatusToInfo,
    applicationStatusToInfo,
} from '../../pages/employer/EmployerCandidatesPage/ApplicationStatusWidget/config';
import { getClickableLink } from '../../config/utils';

interface Props {
    justText?: boolean;
    jobOfferApplicationEvents: JobOfferApplicationEvent[];
}

const JobOfferApplicationEventsHistory: FC<Props> = ({ justText, jobOfferApplicationEvents }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderEvent = (jobOfferApplicationEvent: JobOfferApplicationEvent) => {
        const statusText = applicationStatusToInfo.find(
            (it: ApplicationStatusToInfo) => it.status === jobOfferApplicationEvent.eventType,
        );

        return (
            <div className={classes.eventContainer}>
                <div className={classes.flexCenter}>
                    <div className={classes.status}>{t('Update')}:</div>
                    <Typography className={classes.statusText}>
                        {dayjs(jobOfferApplicationEvent.created).format('DD-MM-YYYY')}
                    </Typography>
                </div>
                {statusText && statusText.status !== JobOfferApplicationStatus.NEW ? (
                    <div className={classes.flexCenter}>
                        <div className={classes.status}>{t('Application Status')}:</div>
                        <Typography className={classes.statusText}>{t(statusText.text)}</Typography>
                    </div>
                ) : (
                    <div className={classes.flexCenter}>
                        <div className={classes.status}>{t('Application Status')}:</div>
                        <Typography className={classes.statusText}>{t('Application made')}</Typography>
                    </div>
                )}
                {jobOfferApplicationEvent.rejectionMessage && (
                    <div className={classes.flex}>
                        <div className={classes.status}>{t('Rejection message')}:</div>
                        <Typography className={classes.statusText}>
                            {jobOfferApplicationEvent.rejectionMessage || t('No message')}
                        </Typography>
                    </div>
                )}
                {jobOfferApplicationEvent.feedbackMessage && (
                    <div className={classes.flex}>
                        <div className={classes.status}>{t('Feedback')}:</div>
                        <Typography className={classes.statusText}>
                            {jobOfferApplicationEvent.feedbackMessage || t('No feedback')}
                        </Typography>
                    </div>
                )}
                {jobOfferApplicationEvent.dateProposal && (
                    <div className={classes.flexCenter}>
                        <div className={classes.status}>{t('Selected date')}:</div>
                        <Typography className={classes.statusText}>
                            {`${dayjs(jobOfferApplicationEvent.dateProposal.date).format('DD-MM-YYYY')} (${
                                jobOfferApplicationEvent.dateProposal.startTime
                            } - ${jobOfferApplicationEvent.dateProposal.endTime})`}
                        </Typography>
                    </div>
                )}
                {jobOfferApplicationEvent.meeting &&
                    jobOfferApplicationEvent.meeting.dateProposals?.length >= 1 && (
                        <>
                            <div className={classes.status}>{t('Interview proposed dates')}:</div>
                            <ul className={classes.dateProposals}>
                                {jobOfferApplicationEvent.meeting.dateProposals.map(dateProposal => (
                                    <li>
                                        <Typography className={classes.statusText}>
                                            {`${dayjs(dateProposal.date).format('DD-MM-YYYY')} (${
                                                dateProposal.startTime
                                            } - ${dateProposal.endTime})`}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                {statusText?.status === JobOfferApplicationStatus.CONTRACT_PROPOSED &&
                    jobOfferApplicationEvent.contractProposal && (
                        <>
                            {jobOfferApplicationEvent.contractProposal.contractFile ? (
                                <div className={classes.flex}>
                                    <div className={classes.status}>{t('Contract file')}:</div>
                                    <a
                                        href={getClickableLink(
                                            jobOfferApplicationEvent.contractProposal.contractFile.url,
                                        )}
                                        download="contract"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Download
                                    </a>
                                </div>
                            ) : (
                                t('No contract file')
                            )}
                            <div className={classes.flex}>
                                <div className={classes.status}>{t('Employer message')}:</div>
                                <Typography className={classes.statusText}>
                                    {jobOfferApplicationEvent.contractProposal.employerMessage ||
                                        t('No message')}
                                </Typography>
                            </div>
                        </>
                    )}
                {statusText?.status === JobOfferApplicationStatus.CONTRACT_ACCEPTED &&
                    jobOfferApplicationEvent.contractProposal && (
                        <>
                            <div className={classes.flex}>
                                <div className={classes.status}>{t('Signed contract file')}:</div>
                                {jobOfferApplicationEvent.contractProposal.signedContractFile ? (
                                    <a
                                        href={getClickableLink(
                                            jobOfferApplicationEvent.contractProposal.signedContractFile.url,
                                        )}
                                        download="contract"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t('Download')}
                                    </a>
                                ) : (
                                    t('No signed contract file')
                                )}
                            </div>
                            <div className={classes.flex}>
                                <div className={classes.status}>{t('Candidate message')}:</div>
                                <Typography className={classes.statusText}>
                                    {jobOfferApplicationEvent.contractProposal.candidateMessage ||
                                        t('No message')}
                                </Typography>
                            </div>
                        </>
                    )}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <>
                {justText && <div className={classes.status}>{t('APPLICATION HISTORY')}:</div>}
                {jobOfferApplicationEvents.map(jobOfferApplicationEvent =>
                    renderEvent(jobOfferApplicationEvent),
                )}
            </>
        );
    };

    if (justText) {
        return <div className={classes.paperContainer}>{renderContent()}</div>;
    }

    return (
        <div className={classes.paperWrapper}>
            <div className={classes.paperTitle}>{t('APPLICATION HISTORY')}</div>
            <div className={classes.paperContainer}>{renderContent()}</div>
        </div>
    );
};

export default JobOfferApplicationEventsHistory;
