import { FC } from 'react';
import Container from '@material-ui/core/Container';
import { startCase } from 'lodash';
import ContentLoader from 'react-content-loader';
import { useStyles } from './styles';
import { CandidateAnalytics, CompanyAnalytics, GeneralAnalytics } from '../../../../models/Analytics';
import AnalyticsCard from '../AnalyticsCard';

interface Props {
    title: string;
    analyticsData: GeneralAnalytics | CompanyAnalytics | CandidateAnalytics | undefined;
}

const AnalyticsContent: FC<Props> = ({ analyticsData }) => {
    const classes = useStyles();

    const renderLoader = () => (
        <ContentLoader
            speed={2}
            width={415}
            height={415}
            viewBox="0 0 415 415"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="113" />
            <rect x="24" y="135" rx="8" ry="8" width="200" height="18" />
            <rect x="24" y="170" rx="8" ry="8" width="90" height="16" />
            <rect x="24" y="193" rx="8" ry="8" width="60" height="16" />
            <rect x="24" y="219" rx="8" ry="8" width="140" height="16" />
        </ContentLoader>
    );

    return (
        <Container className={classes.container}>
            {analyticsData
                ? Object.entries(analyticsData).map(([key, value]) => (
                      <AnalyticsCard label={startCase(key)} value={value} />
                  ))
                : renderLoader()}
        </Container>
    );
};

export default AnalyticsContent;
