import * as yup from 'yup';
import i18n from 'i18next';
import dayjs from 'dayjs';
import Media from '../../../../models/Media.model';

export interface CandidateJobExperienceForm {
    companyName: string;
    companyLogo: Media | null;
    location: string;
    position: string;
    startDate: string;
    endDate: string;
    stillWorkingHere: boolean;
    userProfileId?: number;
    id?: number;
}

export const defaultInitialValues: CandidateJobExperienceForm = {
    companyName: '',
    companyLogo: null,
    location: '',
    position: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    stillWorkingHere: false,
};

export const validationSchema = yup.object().shape({
    companyName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Company name is required')),
    location: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    position: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(200, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    startDate: yup.date().required(i18n.t('Field is required')).typeError('Valid date is required'),
    endDate: yup
        .date()
        .min(yup.ref('startDate'), 'End date has to be after start date')
        .required(i18n.t('Field is required'))
        .typeError('Valid date is required'),
    stillWorkingHere: yup.boolean().required(i18n.t('Field is required')),
});
