import { makeStyles } from '@material-ui/core/styles';

const CONTENT_WRAPPER_BREAKPOINT = 800;

export const useStyles = makeStyles(theme => ({
    contentWrapper: {
        display: 'grid',
        gridTemplateRows: '1fr',
        gridGap: 8,
        alignItems: 'flex-end',
        [theme.breakpoints.up(CONTENT_WRAPPER_BREAKPOINT)]: {
            gridTemplateColumns: 'minmax(200px, auto) auto 1fr',
        },
    },
    membersWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        order: 2,
        [theme.breakpoints.up(CONTENT_WRAPPER_BREAKPOINT)]: {
            order: 0,
            marginRight: '0.5rem',
        },
    },
    root: {
        width: '100%',
        [theme.breakpoints.up(CONTENT_WRAPPER_BREAKPOINT)]: {
            width: '20rem',
        },
    },
    name: {
        display: 'inline',
        color: '#4E4E4E',
        fontSize: '0.875rem',
        letterSpacing: '0.15px',
    },
    nameHighlight: {
        fontWeight: 700,
    },
    position: {
        color: '#9E9E9E',
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        letterSpacing: '0.32px',
        marginBottom: '0.25rem',
    },
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
            padding: '1.5px 0 1.5px 10px',
        },
    },
    inputTextFieldInput: {
        height: 26,
        fontSize: 13,
    },
    inputTextFieldRootNotched: {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: 'rgba(0, 127, 252, 0.5) !important',
        '&$focused': {
            borderColor: '#007FFC !important',
        },
        '&:hover': {
            borderColor: '#007FFC !important',
        },
    },
    userAvatar: {
        borderRadius: '50%',
        maxWidth: 48,
        maxHeight: 48,
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '0.5rem',
    },
    cancelIcon: {
        position: 'absolute',
        top: -4,
        right: -4,
        fontSize: '1rem',
        display: 'none',
    },
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '3rem',
    },
    avatarBase: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        marginRight: '0.5rem',
        borderRadius: '50%',
        '&:focus, &:hover': {
            boxShadow: '0 0 6px 0 rgba(0,127,252,0.3)',
            '& svg': {
                display: 'block',
            },
        },
    },
    defaultAvatar: {
        fontSize: '0.75rem',
        fontWeight: 600,
        color: '#4DA1FF',
        backgroundColor: 'rgba(77,161,255,0.1)',
    },
}));
