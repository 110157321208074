import React, { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isArray, isEmpty, toString } from 'lodash';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { useStyles } from './styles';
import { usePaginationFetch } from '../../config/hooks/usePaginationFetch';
import { getAllJobOffersPaginated } from '../../services/AdminService';
import { AdminCompaniesPanelFormValues, initialValues } from '../../pages/admin/AdminCompaniesPage/config';
import { JobOffer } from '../../models/JobOffer';
import { validationSchema } from '../SearchPanel/config';
import CustomPagination from '../../components/CustomPagination';
import SearchPanelHeader from '../SearchPanel/SearchPanelHeader';
import JobOfferCard from '../../components/JobOfferCard';

const JobsPanel: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { content, totalPages, totalElements, setRefetch, setDataFilters } = usePaginationFetch<JobOffer>({
        service: getAllJobOffersPaginated,
        initialSize: 8,
    });

    const handleSubmitForm = async (values: AdminCompaniesPanelFormValues) => {
        const filters = Object.entries(values)
            .map(([key, value]) => {
                const newValue = key === 'search' || key === 'location' ? value.trim() : value;
                return [key, newValue];
            })
            .filter(item => {
                return !isEmpty(item[1]) || item[1] === true;
            })
            .map(([key, value]) => {
                const newValues = isArray(value) ? value.map(item => toString(item)) : [toString(value)];
                return {
                    column: key,
                    values: newValues,
                };
            });
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
            filters,
        }));
    };

    const renderPagination = () => {
        if (totalPages > 1) {
            return (
                <CustomPagination
                    count={totalPages}
                    onChange={(event, newPage) => {
                        setDataFilters(dataFilters => ({ ...dataFilters, page: newPage - 1 }));
                    }}
                    variant="outlined"
                    color="primary"
                />
            );
        }
        return null;
    };

    const getFormik = () => (
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm} validationSchema={validationSchema}>
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit}>
                        <SearchPanelHeader />
                    </form>
                );
            }}
        </Formik>
    );

    const renderContent = () => {
        if (totalElements === null) {
            return (
                <Fade in timeout={1000}>
                    <div className={classes.centerWrapper}>
                        <CircularProgress />
                    </div>
                </Fade>
            );
        }
        if (totalElements === 0) {
            return (
                <Fade in>
                    <Alert severity="info" className={classes.alert}>
                        {t('No results found for your search')}
                    </Alert>
                </Fade>
            );
        }
        return (
            <>
                <Typography className={classes.latestJob}>{t('ALL JOB OFFERS')}</Typography>
                <div className={classes.jobsWrapper}>
                    {content.map(job => (
                        <JobOfferCard
                            key={job.id}
                            editable
                            removeCallback={() => {
                                setRefetch(update => !update);
                            }}
                            {...job}
                        />
                    ))}
                </div>
            </>
        );
    };

    return (
        <>
            <Container>
                {getFormik()}
                {renderContent()}
                {renderPagination()}
            </Container>
        </>
    );
};

export default JobsPanel;
