import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    option: {
        display: 'flex',
        alignItems: 'center',
    },
    optionImage: {
        maxWidth: 30,
        maxHeight: 30,
    },
    optionName: {
        marginLeft: 10,
    },
});
