import React, { ComponentType, FC } from 'react';
import { Field } from 'formik';
import { Switch } from 'formik-material-ui';

import InputLabel from '@material-ui/core/InputLabel';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core/Input';

export interface FormikSwitchProps extends BaseTextFieldProps {
    name: string;
    label?: string;
    InputProps?: Partial<InputProps>;
    component?: string | ComponentType;
    disableLabel?: boolean;
}

const FormikSwitch: FC<FormikSwitchProps> = ({
    id,
    label,
    name,
    margin = 'normal',
    component = Switch,
    disableLabel,
    ...otherProps
}) => (
    <div>
        {!disableLabel && <InputLabel htmlFor={id || name}>{label || name}</InputLabel>}
        <Field
            component={component}
            type="checkbox"
            color="primary"
            margin={margin}
            id={id || name}
            name={name}
            {...otherProps}
        />
    </div>
);

export default FormikSwitch;
