import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    uploadButton: {
        height: '2rem',
        width: '9rem',
        fontSize: '0.8125rem',
        borderRadius: 4,
        marginTop: 20,
    },
    avatarPreview: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 150,
        width: 150,
        border: '2px dashed #979797',
        borderRadius: '50%',
        fontSize: '3rem',
        color: '#979797',
        objectFit: 'contain',

        '&:hover': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
    dropzoneArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
        height: '100%',
        backgroundColor: '#FFFFFF',
        color: '#bdbdbd',
        cursor: 'pointer',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    confirmCropButton: {
        position: 'absolute',
        bottom: '15%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '0.875rem',
        fontWeight: 700,
        padding: '1rem 1.5rem',
        letterSpacing: '0.46px',
        color: '#fff',
        backgroundColor: '#007FFC',
    },
}));
