import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';

import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import Container from '@material-ui/core/Container';
import CandidateProfileForm from '../../../parts/CandidateProfileForm';
import { initialValues, validationSchema } from './config';
import { editCurrentUserProfile, getCurrentUserProfile } from '../../../services/UserService';
import UserProfile from '../../../models/UserProfile';
import { thunkGetCurrentUser } from '../../../store/actions';
import { getBackendFormikErrors } from '../../../config/utils';

const CandidateProfilePage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const { jobId } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    const [currentlyEmployed, setCurrentlyEmployed] = useState<boolean>(false);
    const [currentProfile, setCurrentProfile] = useState<UserProfile | null>(null);

    useEffect(() => {
        getCurrentUserProfile()
            .then((response: UserProfile) => {
                const {
                    firstName,
                    lastName,
                    linkedinUrl,
                    location,
                    contactPhoneNumber,
                    contactEmail,
                    currentPosition,
                    ...others
                } = response;

                setCurrentProfile({
                    firstName: firstName || '',
                    lastName: lastName || '',
                    location: location || '',
                    linkedinUrl: linkedinUrl || '',
                    contactPhoneNumber: contactPhoneNumber || '',
                    contactEmail: contactEmail || '',
                    currentPosition: currentPosition || '',
                    ...others,
                });
                setCurrentlyEmployed(response.currentlyEmployed || false);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch current user profile, try again later'), {
                    variant: 'error',
                }),
            );
    }, [enqueueSnackbar, t]);

    const handleSubmitForm = async (
        values: UserProfile,
        { setSubmitting, setErrors }: FormikHelpers<UserProfile>,
        jobId?: string,
    ) => {
        try {
            if (!values.currentlyEmployed) {
                values.currentPosition = undefined;
            }
            await editCurrentUserProfile(values).then(() => dispatch(thunkGetCurrentUser()));
            enqueueSnackbar(t('Profile successfully saved.'), { variant: 'success' });
            if (!isEmpty(jobId)) {
                history.push(`/jobs/${jobId}`);
            }
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    const renderLoader = () => (
        <Container>
            <ContentLoader
                speed={2}
                width="100%"
                height={128}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="500" />
            </ContentLoader>
            <ContentLoader
                speed={2}
                width="100%"
                height={256}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="500" />
            </ContentLoader>
        </Container>
    );

    if (isEmpty(currentProfile)) {
        return renderLoader();
    }

    return (
        <Formik
            enableReinitialize
            initialValues={currentProfile || initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <CandidateProfileForm
                setCurrentlyEmployed={setCurrentlyEmployed}
                currentProfile={currentProfile}
                currentlyEmployed={currentlyEmployed}
                jobId={jobId as string}
                handleSubmitAndRedirect={handleSubmitForm}
            />
        </Formik>
    );
};

export default CandidateProfilePage;
