import { Autocomplete } from 'formik-material-ui-lab';
import React, { FC } from 'react';
import { Field, useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { InputLabelProps as InputLabelPropsTypes } from '@material-ui/core/InputLabel/InputLabel';

export interface FormikAutocompleteProps {
    id?: string;
    name: string;
    label?: string;
    disableLabel?: boolean;
    placeholder?: string;
    InputProps?: Partial<InputProps>;
    options: unknown[];
    displayIcon?: boolean;
    InputLabelProps?: InputLabelPropsTypes;
    renderOption?: (option: unknown) => JSX.Element;
}

const FormikAutocomplete: FC<FormikAutocompleteProps> = ({
    id,
    name,
    disableLabel,
    label,
    placeholder = '',
    options,
    InputProps,
    InputLabelProps,
    ...otherProps
}) => {
    const meta = useField(name)[1];
    const { touched, error } = meta;

    return (
        <div>
            {!disableLabel && (
                <InputLabel htmlFor={id || name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <Field
                name={name}
                component={Autocomplete}
                options={options}
                disableClearable
                renderInput={(params: unknown) => (
                    <TextField
                        {...params}
                        id={name}
                        placeholder={placeholder}
                        error={touched && !!error}
                        variant="outlined"
                        helperText={error}
                        // @ts-ignore
                        InputProps={{ ...params.InputProps, ...InputProps }}
                    />
                )}
                {...otherProps}
            />
        </div>
    );
};

export default FormikAutocomplete;
