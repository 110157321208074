import * as yup from 'yup';
import i18n from 'i18next';

export interface CreateCompanyEmployerFormValues {
    username: string;
    firstName: string;
    lastName: string;
    companyId: number | string;
}

export const initialValues: CreateCompanyEmployerFormValues = {
    username: '',
    firstName: '',
    lastName: '',
    companyId: '',
};

export const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Email is required'))
        .email(i18n.t('Email must be valid')),
    firstName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    lastName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    companyId: yup.number().required(i18n.t('Company has to be selected for an employer')),
});
