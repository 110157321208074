import { FC } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FeaturedJobsPanel from './FeaturedJobsPanel';
import LatestJobsPanel from './LatestJobsPanel';
import PopularCategoriesPanel from './PopularCategoriesPanel';
import HowWorksPanel from './HowWorksPanel';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { UserRoles } from '../../models/enums/UserRoles';
import { ROUTES } from '../../config/constants';

const LandingPage: FC = () => {
    const history = useHistory();

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    if (role === UserRoles.EMPLOYER) {
        history.push(ROUTES.EMPLOYER_LANDING_PAGE);
    }

    if (role === UserRoles.ADMIN) {
        history.push(ROUTES.ADMIN_USERS);
    }

    return (
        <div>
            <FeaturedJobsPanel />
            <LatestJobsPanel />
            <PopularCategoriesPanel />
            <HowWorksPanel />
        </div>
    );
};

export default LandingPage;
