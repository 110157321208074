import { FC, Fragment, useEffect, useState } from 'react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';

import { isEmpty } from 'lodash';
import Link from '@material-ui/core/Link';
import { ROUTES } from '../../../config/constants';
import { useStyles } from './styles';
import { authorizedRoutes, publicRoutes } from '../../../config/routing';
import { UserRoles } from '../../../models/enums/UserRoles';
import { NavLinkConfig, navLinksConfig } from '../config';
import LinkedInIcon from '../../../assets/linkedIn.svg';
import { CurrentUserReducer, RootReducer } from '../../../models/Redux';
import { getNameInitials, loginRedirectToLinkedIn } from '../../../config/utils';
import { logoutUser } from '../../../services/config/AuthenticationService';
import UserAvatar from '../../../components/UserAvatar';
import BottomNavigationMobile from './BottomNavigationMobile';

const HeaderMobile: FC = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);

    const goBackAvailable =
        history.length > 1 && pathname !== ROUTES.LANDING && pathname !== ROUTES.EMPLOYER_LANDING_PAGE;

    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    const title = [...publicRoutes, ...authorizedRoutes].find(({ path }) => path === pathname)?.title;

    const {
        user: { role, avatarUrl, firstName, lastName },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const getInitials = (): string => {
        if (!firstName && !lastName) {
            return 'N/A';
        }
        return getNameInitials(`${firstName || ''} ${lastName || ''}`);
    };

    const getJobIdIfExists = (): string | null => {
        const onJobDetailsPage = matchPath(pathname, {
            path: ROUTES.JOB_OFFER_DETAILS,
        });
        const params: { id?: string } | undefined = onJobDetailsPage?.params;

        return onJobDetailsPage?.isExact === true && params?.id ? params.id : null;
    };

    const renderLinkedInButton = () => (
        <ListItem className={classes.buttonWrapper}>
            <Button
                color="primary"
                variant="contained"
                classes={{ root: clsx(classes.button, classes.linkedButton), label: classes.linkedLabel }}
                onClick={() => loginRedirectToLinkedIn(getJobIdIfExists())}
            >
                <img src={LinkedInIcon} alt="LinkedIn" />
                <Typography>{`${t('Sign in with')} LinkedIn`}</Typography>
            </Button>
        </ListItem>
    );

    const renderEmployerSignButton = () => (
        <ListItem className={classes.buttonWrapper}>
            <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to={ROUTES.EMPLOYER_SIGN_IN}
                classes={{ root: classes.button }}
            >
                {t('Sign in')}
            </Button>
        </ListItem>
    );

    const renderLinks = (linksConfig: NavLinkConfig[]): JSX.Element[] => {
        return linksConfig.map(({ to, label, activeMatch }) => (
            <ListItem
                button
                key={to}
                component={RouterLink}
                to={to}
                className={clsx(classes.link, {
                    [classes.activeLink]: activeMatch.some(url => url === pathname),
                })}
            >
                {t(label)}
            </ListItem>
        ));
    };

    const renderPublicNav = (): JSX.Element[] => [
        [ROUTES.EMPLOYER_LANDING_PAGE, ROUTES.EMPLOYER_SIGN_UP, ROUTES.EMPLOYER_SIGN_IN].some(
            url => url === pathname,
        )
            ? renderEmployerSignButton()
            : renderLinkedInButton(),
        ...renderLinks(navLinksConfig.PUBLIC),
    ];

    const renderPostNewJobButton = () => (
        <ListItem className={classes.buttonWrapper}>
            <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to={ROUTES.EMPLOYER_POST_JOB}
                classes={{ root: classes.button }}
            >
                {t('Post a new job')}
            </Button>
        </ListItem>
    );

    const renderLogoutItem = () => (
        <ListItem className={classes.buttonWrapper}>
            <Button
                color="primary"
                variant="outlined"
                onClick={logoutUser}
                classes={{ root: classes.button }}
            >
                {t('Log out')}
            </Button>
        </ListItem>
    );

    const renderEmployerNav = (): JSX.Element[] => [
        ...renderLinks(navLinksConfig[UserRoles.EMPLOYER]),
        renderPostNewJobButton(),
        renderLogoutItem(),
    ];

    const renderCandidateNav = (): JSX.Element[] => [
        ...renderLinks(navLinksConfig[UserRoles.CANDIDATE]),
        renderLogoutItem(),
    ];

    const renderAdminNav = (): JSX.Element[] => [
        ...renderLinks(navLinksConfig[UserRoles.ADMIN]),
        renderLogoutItem(),
    ];

    const renderSupportNav = (): JSX.Element[] => [
        ...renderLinks(navLinksConfig[UserRoles.SUPPORT]),
        renderLogoutItem(),
    ];

    const renderContent = (role?: UserRoles): JSX.Element[] => {
        switch (role) {
            case UserRoles.EMPLOYER:
                return renderEmployerNav();
            case UserRoles.CANDIDATE:
                return renderCandidateNav();
            case UserRoles.ADMIN:
                return renderAdminNav();
            case UserRoles.SUPPORT:
                return renderSupportNav();
            default:
                return renderPublicNav();
        }
    };

    const renderList = () => (
        <SwipeableDrawer
            transitionDuration={100}
            classes={{ paper: classes.drawerPaper }}
            anchor="left"
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
        >
            <List component="div" disablePadding>
                <ListItem className={classes.headerDrawer}>
                    <RouterLink to={ROUTES.LANDING} className={clsx(classes.logoFont, classes.mobileLogo)}>
                        <div title="Onboard logo">Onboard</div>
                    </RouterLink>
                    <IconButton
                        onClick={() => {
                            setOpen(open => !open);
                        }}
                    >
                        {open ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </ListItem>
                {renderContent(role).map((item, index) => (
                    <Fragment key={index}>
                        {item}
                        <Divider />
                    </Fragment>
                ))}
            </List>
        </SwipeableDrawer>
    );

    const renderForUnauthorized = () => {
        return (
            <div className={clsx({ [classes.titleWrapper]: title, [classes.noTitleWrapper]: !title })}>
                <RouterLink
                    to={ROUTES.LANDING}
                    className={clsx(classes.logoFont, { [classes.mobileLogo]: !title })}
                >
                    <div title="Onboard logo">Onboard</div>
                </RouterLink>
                {!!title && <Typography className={classes.title}>{t(title)}</Typography>}
                <IconButton
                    onClick={() => {
                        setOpen(open => !open);
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </div>
        );
    };

    const getCurrentProfileUrl = () => {
        switch (role) {
            case UserRoles.EMPLOYER:
                return ROUTES.EMPLOYER_PROFILE;
            case UserRoles.CANDIDATE:
                return ROUTES.CANDIDATE_PROFILE;
            default:
                return ROUTES.EMPLOYER_PROFILE;
        }
    };

    const renderForAuthorized = () => {
        return (
            <div
                className={clsx({
                    [classes.noTitleWrapperAuthorizedNoBack]: !goBackAvailable,
                    [classes.noTitleWrapperAuthorized]: goBackAvailable,
                })}
            >
                {goBackAvailable && (
                    <IconButton color="primary" onClick={() => history.goBack()}>
                        <ArrowBackIosIcon />
                    </IconButton>
                )}
                <RouterLink to={ROUTES.LANDING} className={clsx(classes.logoFont, classes.mobileLogo)}>
                    <div title="Onboard logo">Onboard</div>
                </RouterLink>
                {!isEmpty(avatarUrl) && (
                    <Link component={RouterLink} className={classes.userAvatar} to={getCurrentProfileUrl()}>
                        <UserAvatar size={33} url={avatarUrl} />
                    </Link>
                )}
                {isEmpty(avatarUrl) && (
                    <Link component={RouterLink} className={classes.userAvatar} to={getCurrentProfileUrl()}>
                        {getInitials()}
                    </Link>
                )}
            </div>
        );
    };

    const renderHeader = () => {
        if (isEmpty(role)) {
            return renderForUnauthorized();
        }
        return renderForAuthorized();
    };

    return (
        <>
            <header
                className={clsx({
                    [classes.container]: isEmpty(role),
                    [classes.containerAuthorized]: !isEmpty(role),
                })}
            >
                {renderHeader()}
                {renderList()}
            </header>
            <BottomNavigationMobile onHamburgerClick={() => setOpen(true)} />
        </>
    );
};

export default HeaderMobile;
