import { makeStyles } from '@material-ui/core/styles';

import { EMPLOYER_CANDIDATES_BREAKPOINTS } from '../../../../config/constants';

export const useStyles = makeStyles(theme => ({
    basicInfo: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(EMPLOYER_CANDIDATES_BREAKPOINTS)]: {
            marginTop: 5,
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 10,
        },
    },
    container: {
        width: '100%',
        display: 'grid',
        gridGap: '2rem',
    },
    contactButton: {
        fontSize: '0.8125rem',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        color: '#000000',
        marginRight: '1rem',
        height: '2rem',
        fontWeight: 400,
    },
    candidateUserAvatar: {
        marginRight: '1rem',
        [theme.breakpoints.down(EMPLOYER_CANDIDATES_BREAKPOINTS)]: {
            margin: 'auto',
        },
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '19px',
        color: '#000000',
    },
    sectionTitle: {
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '19px',
        color: '#000000',
        marginBottom: '0.75rem',
    },
    indentationWrapper: {
        margin: '0 1rem',
    },
    profession: {
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.1px',
        color: '#000000',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'right',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up(EMPLOYER_CANDIDATES_BREAKPOINTS)]: {
            flexDirection: 'row',
        },
    },
    appInfo: {
        fontSize: '14px',
        lineHeight: '13px',
        letterSpacing: '0.08px',
        color: '#616161',
    },
    appJobTile: {
        fontWeight: 700,
        color: '#000000',
    },
    question: {
        fontSize: '0.8125rem',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        fontWeight: 700,
        fontStyle: 'italic',
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    button: {
        width: 102,
        margin: '2rem 0 0 0.5rem',
    },
    questionsWrapper: {
        display: 'grid',
        gridGap: '0.75rem',
    },
    downloadButton: {
        fontSize: '0.875rem',
        fontWeight: 500,
        padding: '0.8125rem 3rem',
        letterSpacing: '0.46px',
    },
}));
