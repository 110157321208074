import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    widget: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 12px',
        borderRadius: 3,
        marginTop: 5,
    },
    text: {
        marginLeft: 5,
    },
    rejected: {
        color: '#616161',
        backgroundColor: 'rgba(158,158,158,0.17)',
    },
    accepted: {
        color: '#26A425',
        backgroundColor: 'rgba(0,252,41,0.17)',
    },
    processing: {
        color: '#007FFC',
        backgroundColor: 'rgba(0,127,252,0.17)',
    },
    interview: {
        color: '#FACB66',
        backgroundColor: 'rgba(250,203,102,0.15)',
    },
    appliedOn: {
        color: '#616161',
        fontSize: '0.75rem',
        marginTop: 5,
    },
    applyButton: {
        fontSize: '0.875rem',
        fontWeight: 500,
        padding: '0.8125rem 1.5rem',
        letterSpacing: '0.46px',
    },
    bold: {
        fontWeight: 700,
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: 10,
        color: 'black',
    },
    actionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    saveButton: {
        color: '#16133E',
        fontSize: '0.625rem',
        fontWeight: 500,
        height: '1.5rem',
        lineHeight: '1rem',
        textTransform: 'none',
        borderRadius: 4,
        marginBottom: '0.5rem',
    },
    saveButtonBookmarked: {},
    save: {
        display: 'flex',
        alignItems: 'center',
    },
});
