import { FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { ListJobOfferApplication } from '../../models/JobOfferApplication';

import { useStyles } from './styles';
import CandidateStatusWidget from './CandidateStatusWidget';
import JobOfferCardInfo from '../../parts/JobOfferCardInfo';
import JobOfferCardSalary from '../../parts/JobOfferCardSalary';
import { JobOfferButtonState } from '../../models/enums/JobOfferButtonState';
import CompanyLogoImage from '../CompanyLogoImage';
import { getCoverByCompanyId } from '../JobOfferCard/config';

interface Props extends ListJobOfferApplication {
    removeCallback?: () => void;
    jobOfferButtonState: JobOfferButtonState;
}

const JobOfferApplicationStatusCard: FC<Props> = ({
    id,
    title,
    location,
    remotely,
    logoUrl,
    currencySign,
    companyName,
    companyId,
    salaryMin,
    salaryMax,
    billingCycle,
    backgroundUrl,
    tags,
    status,
    interviewDate,
    interviewStartTime,
    appliedDate,
    bookmark,
    jobOfferButtonState,
}) => {
    const classes = useStyles({
        backgroundUrl: backgroundUrl || getCoverByCompanyId(companyId),
    });

    const renderVerticalDivider = () => <div className={classes.divider} />;

    const renderStatus = () => {
        return (
            <div className={classes.actionsWrapper}>
                <CandidateStatusWidget
                    id={id}
                    status={status}
                    interviewDate={interviewDate}
                    interviewStartTime={interviewStartTime}
                    appliedDate={appliedDate}
                    bookmark={bookmark}
                    jobOfferButtonState={jobOfferButtonState}
                />
            </div>
        );
    };

    const renderCompanyBackground = () => {
        return (
            <div className={classes.companyBackground}>
                <CompanyLogoImage
                    alt={companyName}
                    src={logoUrl}
                    classNameWrapper={classes.companyLogoWrapper}
                />
            </div>
        );
    };

    const renderContent = () => (
        <div className={classes.container}>
            {renderCompanyBackground()}
            <div className={classes.wrapper}>
                <JobOfferCardInfo
                    companyName={companyName}
                    remotely={remotely}
                    title={title}
                    tags={tags}
                    location={location}
                />
                <JobOfferCardSalary
                    currencySign={currencySign}
                    salaryMin={salaryMin}
                    salaryMax={salaryMax}
                    billingCycle={billingCycle}
                />
                {renderVerticalDivider()}
                {renderStatus()}
            </div>
        </div>
    );

    return (
        <RouterLink to={`/jobs/${id}`} className={classes.link}>
            {renderContent()}
        </RouterLink>
    );
};

export default JobOfferApplicationStatusCard;
