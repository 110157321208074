import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    controlsWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '4rem',
        padding: '0.5rem 0.5rem',
        border: '2px solid #007FFC',
        borderRadius: '0.5rem',
        width: '100%',
    },
    controlButton: {
        height: '2rem',
        width: '2rem',
    },
    iconButton: {
        padding: 6,
    },
    reactPlayer: {
        display: 'none',
    },
    loading: {
        display: 'block',
    },
    progress: {
        marginTop: '0.75rem',
    },
    reactWaves: {
        padding: 0,
    },
    audioPlayer: {
        opacity: 0,
        position: 'absolute',
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        zIndex: -1,
    },
});
