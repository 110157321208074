import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import EmployerForgotPasswordForm from './EmployerForgotPasswordForm';

const EmployerForgotPasswordPage: FC = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Container>
                <Typography className={classes.title}>{t('Forgot password')}</Typography>
                <EmployerForgotPasswordForm />
            </Container>
        </div>
    );
};

export default EmployerForgotPasswordPage;
