import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from './styles';
import { readFromLocalStorage, writeToLocalStorage } from '../../../../services/config/LocalStorage';
import { ONBOARD_QUESTIONS_INFO_DONT_SHOW } from '../../../../config/constants';

const CandidateApplyInfoDialog: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [dontShow, setDontShow] = useState<boolean>(
        readFromLocalStorage(ONBOARD_QUESTIONS_INFO_DONT_SHOW) || false,
    );
    const [open, setOpen] = useState(!dontShow);

    const renderCheckbox = () => (
        <FormControlLabel
            control={<Checkbox onChange={() => setDontShow(!dontShow)} name="archived" color="primary" />}
            classes={{ root: classes.formControlRoot, label: classes.formControlLabel }}
            label={t("Don't show this information again")}
        />
    );

    const handleClose = () => {
        writeToLocalStorage(ONBOARD_QUESTIONS_INFO_DONT_SHOW, dontShow);
        setOpen(false);
    };

    return (
        <>
            <Dialog onClose={handleClose} open={open} classes={{ paperWidthSm: classes.dialog }}>
                <Typography className={classes.title}>{t('Recording answers')}</Typography>
                <p>{t('You can re-record the answers as many times as you need.')}</p>

                {renderCheckbox()}
                <Button color="primary" variant="contained" className={classes.button} onClick={handleClose}>
                    {t('Ok')}
                </Button>
            </Dialog>
        </>
    );
};

export default CandidateApplyInfoDialog;
