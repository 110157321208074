import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { initialValues, ProposeContractDialogValues, validationSchema } from './config';
import { useStyles } from './styles';
import { createOffer } from '../../../../services/JobOfferApplicationsService';
import { JobOfferStaticContent } from '../../../../models/JobOffer';
import { getJobOfferStaticContent } from '../../../../services/JobOfferService';
import CustomFormikSelect from '../../../../components/CustomFormikSelect';
import CustomFormikTextField from '../../../../components/CustomFormikTextField';
import FormikDocumentUpload from '../../../../components/formik/FormikDocumentUpload';
import FormikDatePicker from '../../../../components/formik/FormikDatePicker';
import CloseDialogElement from '../../../../components/CloseDialogElement';

interface Props {
    applicationId: number;
    candidateId: number;
    onStatusUpdate: () => void;
}

const ProposeContractDialog: FC<Props> = ({ applicationId, candidateId, onStatusUpdate }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [staticContent, setStaticContent] = useState<JobOfferStaticContent>();

    useEffect(() => {
        getJobOfferStaticContent()
            .then(response => {
                setStaticContent(response);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch static content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, t]);

    const handleSubmitForm = (
        dialogFormData: ProposeContractDialogValues,
        { setSubmitting }: FormikHelpers<ProposeContractDialogValues>,
    ) => {
        createOffer(applicationId, {
            jobOfferApplicationId: applicationId,
            candidateId,
            message: dialogFormData.message,
            salary: dialogFormData.salary,
            currencyId: dialogFormData.currencyId,
            billingCycleId: dialogFormData.billingCycleId,
            jobStartDate: dialogFormData.jobStartDate,
            contractFile: dialogFormData.contractFile,
        })
            .then(() => {
                onStatusUpdate();
                setOpen(false);
                enqueueSnackbar(t('Successfully proposed a contract.'), { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar(t('Failed to propose contract.'), { variant: 'error' });
                setSubmitting(false);
            });
    };

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    setOpen(true);
                }}
                className={classes.button}
            >
                {t('Propose a contract')}
            </Button>
            <Dialog
                onClose={() => {
                    setOpen(false);
                }}
                open={open}
                classes={{ paperWidthSm: classes.dialog }}
            >
                <CloseDialogElement onClick={() => setOpen(false)} />

                <Typography className={classes.title}>{t('Contract proposal')}</Typography>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit, isSubmitting }) => {
                        return (
                            <form onSubmit={handleSubmit} className={classes.form}>
                                <FormikDatePicker
                                    name="jobStartDate"
                                    placeholder={t('Start date')}
                                    label={t('Start date')}
                                    classes={{ root: classes.datePicker }}
                                    disablePast
                                />
                                <CustomFormikTextField
                                    name="salary"
                                    type="number"
                                    placeholder={t('Salary')}
                                    label={t('Salary')}
                                />
                                <CustomFormikSelect label={t('Currency')} name="currencyId">
                                    <MenuItem key="empty" value="" disabled>
                                        {t('Choose')}
                                    </MenuItem>
                                    {staticContent?.currencies.map(({ id, code }) => (
                                        <MenuItem key={id} value={id}>
                                            {t(code)}
                                        </MenuItem>
                                    ))}
                                </CustomFormikSelect>
                                <CustomFormikSelect name="billingCycleId" label={t('Per')}>
                                    <MenuItem key="empty" value="" disabled>
                                        {t('Choose')}
                                    </MenuItem>
                                    {staticContent?.billingCycles.map(({ period, id }) => (
                                        <MenuItem key={id} value={id}>
                                            {t(period)}
                                        </MenuItem>
                                    ))}
                                </CustomFormikSelect>
                                <FormikDocumentUpload name="contractFile" label={t('Upload contract file')} />
                                <CustomFormikTextField
                                    name="message"
                                    label={t('Message')}
                                    placeholder={t('Message')}
                                    multiline
                                    rows={4}
                                />

                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className={classes.saveButton}
                                    disabled={isSubmitting}
                                >
                                    {t('Submit')}
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};

export default ProposeContractDialog;
