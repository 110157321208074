import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    root: {
        width: '100%',
    },
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
            padding: '1.5px 0 1.5px 10px',
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: '1rem 0 1rem 1rem',
        },
    },
}));
