import * as yup from 'yup';
import i18n from 'i18next';
import { ROUTES } from '../../../../config/constants';

export default interface EmployerLoginFormValues {
    username: string;
    password: string;
    passwordConfirmation: string;
    firstName: string;
    lastName: string;
    companyName: string;
    termsOfUseAccepted: boolean;
}

export const initialValues: EmployerLoginFormValues = {
    username: '',
    password: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
    companyName: '',
    termsOfUseAccepted: false,
};

export const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Email is required'))
        .email(i18n.t('Email must be valid')),
    password: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Password is required')),
    passwordConfirmation: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Password confirmation is required'))
        .oneOf([yup.ref('password'), null], i18n.t('Passwords must match')),
    firstName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('First name is required')),
    lastName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Last name is required')),
    companyName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Company name is required')),
    termsOfUseAccepted: yup
        .boolean()
        .required(i18n.t('Terms of use must be checked'))
        .oneOf([true], i18n.t('Terms of use must be checked')),
});

export const TermsLinks = {
    privacyPolicy: {
        name: 'Privacy policy',
        path: 'https://app.termly.io/document/privacy-policy/a4dd304b-5c44-4e5e-9c2b-925bc28add42',
        target: '_blank',
    },
    generalTerms: {
        name: 'General terms of use',
        path: 'https://app.termly.io/document/terms-of-use-for-website/eba1e1d2-ac6f-434b-8c2b-f4df3a72c80b',
        target: '_blank',
    },
    employersTerms: {
        name: `Employer's terms of use`,
        path: ROUTES.TERMS_OF_USE,
        target: '_blank',
    },
};
