import { FC, useEffect, useState } from 'react';
import clsx, { ClassValue } from 'clsx';

import { useStyles } from './styles';
import { JobOfferApplicationStatus } from '../../../../models/JobOfferApplication';
import { applicationStatusToInfo, ApplicationStatusToInfo } from './config';

interface ApplicationStatusWidgetProps {
    status: JobOfferApplicationStatus;
}

const ApplicationStatusWidget: FC<ApplicationStatusWidgetProps> = ({ status }) => {
    const classes = useStyles();

    const [statusInfo, setStatusInfo] = useState<ApplicationStatusToInfo | null>();

    useEffect(() => {
        setStatusInfo(applicationStatusToInfo.find((it: ApplicationStatusToInfo) => it.status === status));
    }, [status]);

    if (!statusInfo || !statusInfo.display) {
        return <></>;
    }

    const mapClass = (className: string | null): ClassValue => {
        switch (className) {
            case 'rejected':
                return classes.rejected;
            case 'accepted':
                return classes.accepted;
            case 'interview':
                return classes.interview;
            case 'processing':
                return classes.processing;
            default:
                return classes.processing;
        }
    };

    return (
        <div className={clsx(classes.widget, mapClass(statusInfo.classes))}>
            {statusInfo.icon}
            <span className={classes.text}>{statusInfo.text}</span>
        </div>
    );
};

export default ApplicationStatusWidget;
