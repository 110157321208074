import React, { FC, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import {
    JobOfferApplicationEvent,
    JobOfferApplicationStatus,
    PaginatedJobOfferApplication,
    RejectApplicationStatus,
} from '../../../../models/JobOfferApplication';
import UserAvatar from '../../../../components/UserAvatar';
import AudioPlayer from '../../../../components/AudioPlayer';
import RejectCandidateDialog from '../RejectCandidateDialog';
import ScheduleCandidateDialog from '../ScheduleCandidateDialog';
import ApplicationStatusWidget from '../ApplicationStatusWidget';
import CandidateEducationInfo from '../../../../parts/CandidateEducationInfo';
import CandidateJobExperienceInfo from '../../../../parts/CandidateJobExperienceInfo';
import ProposeContractDialog from '../ProposeContractDialog';
import { getClickableLink } from '../../../../config/utils';
import { getApplicationEventHistory } from '../../../../services/JobOfferApplicationsService';
import JobOfferApplicationEventsHistory from '../../../../components/JobOfferApplicationEventsHistory';

interface EmployerCandidateProfileProps {
    application: PaginatedJobOfferApplication;
    rejectStatuses: RejectApplicationStatus[];
    onStatusUpdate: () => void;
}

const EmployerCandidateProfile: FC<EmployerCandidateProfileProps> = ({
    application: {
        answers,
        appliedDateTime,
        candidate: {
            avatar,
            firstName,
            lastName,
            educations,
            jobExperiences,
            contactEmail,
            currentPosition,
            contactPhoneNumber,
            userId,
            cvFile,
        },
        id,
        jobOfferTitle,
        questions,
        jobOfferApplicationStatus,
    },
    rejectStatuses,
    onStatusUpdate,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [jobApplicationEvents, setJobApplicationEvents] = useState<JobOfferApplicationEvent[] | null>(null);

    useEffect(() => {
        if (jobOfferApplicationStatus && id) {
            getApplicationEventHistory(id)
                .then(eventsHistory => {
                    setJobApplicationEvents(eventsHistory);
                })
                .catch(() =>
                    enqueueSnackbar(t('Unable to fetch events history, try again later'), {
                        variant: 'error',
                    }),
                );
        }
    }, [id, enqueueSnackbar, t, jobOfferApplicationStatus]);

    const renderBasicInfo = () => (
        <div className={classes.basicInfo}>
            <UserAvatar size={148} url={avatar?.url} className={classes.candidateUserAvatar} />
            <div>
                <Typography align="left" className={classes.title}>{`${firstName} ${lastName}`}</Typography>
                <Typography align="left" className={classes.profession}>
                    {currentPosition}
                </Typography>
            </div>
        </div>
    );

    const renderApplicationInfo = () => (
        <div>
            <div>
                <Typography className={classes.appInfo} component="span">
                    {t('Applied for ')}
                </Typography>
                <Typography className={clsx(classes.appInfo, classes.appJobTile)} component="span">
                    {jobOfferTitle}
                </Typography>
                <Typography className={classes.appInfo}>
                    {dayjs(appliedDateTime).format('DD.MM.YYYY')}
                </Typography>
            </div>
            <ApplicationStatusWidget status={jobOfferApplicationStatus} />
        </div>
    );

    const renderContactDetails = () => (
        <div>
            <Typography className={classes.sectionTitle}>{t('Contact details')}</Typography>
            <div className={classes.indentationWrapper}>
                <Button
                    className={classes.contactButton}
                    startIcon={<PhoneIcon />}
                    href={`tel:${contactPhoneNumber}`}
                    disabled={!contactPhoneNumber}
                >
                    {contactPhoneNumber || t('not added')}
                </Button>
                <Button
                    className={classes.contactButton}
                    startIcon={<MailOutlineIcon />}
                    href={`mailto:${contactEmail}`}
                    disabled={!contactEmail}
                >
                    {contactEmail || t('not added')}
                </Button>
            </div>
        </div>
    );

    const renderCV = () => {
        if (cvFile) {
            return (
                <div>
                    <div className={classes.indentationWrapper}>
                        <Button
                            href={getClickableLink(cvFile?.url)}
                            download="contract"
                            target="_blank"
                            rel="noreferrer"
                            variant="contained"
                            color="primary"
                            className={classes.downloadButton}
                        >
                            {t('Download candidate CV')}
                        </Button>
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderQuestion = () => (
        <div>
            <Typography className={classes.sectionTitle}>{t('Interview questions')}</Typography>
            <div className={classes.indentationWrapper}>
                <div className={classes.questionsWrapper}>
                    {questions.map(({ content, id }, index) => (
                        <div key={id}>
                            <Typography gutterBottom className={classes.question}>
                                {content}
                            </Typography>
                            <AudioPlayer src={answers[index]?.url} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const renderExperience = () => (
        <div>
            <Typography className={classes.sectionTitle}>{t('Experience')}</Typography>
            <div className={classes.indentationWrapper}>
                <CandidateJobExperienceInfo jobExperiences={jobExperiences} />
            </div>
        </div>
    );

    const renderEducation = () => (
        <div>
            <Typography className={classes.sectionTitle}>{t('Education')}</Typography>
            <div className={classes.indentationWrapper}>
                <CandidateEducationInfo educations={educations} />
            </div>
        </div>
    );

    const renderButtons = () => (
        <div className={classes.buttonsWrapper}>
            <RejectCandidateDialog
                applicationId={id}
                rejectStatuses={rejectStatuses}
                onStatusUpdate={onStatusUpdate}
            />
            <ScheduleCandidateDialog applicationId={id} onStatusUpdate={onStatusUpdate} />
        </div>
    );

    const renderOfferButtons = () => (
        <div className={classes.buttonsWrapper}>
            <RejectCandidateDialog
                applicationId={id}
                rejectStatuses={rejectStatuses}
                onStatusUpdate={onStatusUpdate}
            />
            <ProposeContractDialog applicationId={id} candidateId={userId} onStatusUpdate={onStatusUpdate} />
        </div>
    );

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                {renderBasicInfo()}
                {renderApplicationInfo()}
            </div>
            {renderContactDetails()}
            {!isEmpty(questions) && renderQuestion()}
            {renderCV()}
            {!isEmpty(jobExperiences) && renderExperience()}
            {!isEmpty(educations) && renderEducation()}
            {jobOfferApplicationStatus === JobOfferApplicationStatus.NEW ? renderButtons() : ''}
            {jobOfferApplicationStatus === JobOfferApplicationStatus.INTERVIEW_ACCEPTED
                ? renderOfferButtons()
                : ''}
            {!!jobApplicationEvents && !isEmpty(jobApplicationEvents) && (
                <JobOfferApplicationEventsHistory jobOfferApplicationEvents={jobApplicationEvents} />
            )}
        </div>
    );
};

export default EmployerCandidateProfile;
