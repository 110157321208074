import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { toNumber } from 'lodash';
import { getBackendFormikErrors } from '../../../config/utils';
import { CreateCompanyEmployerFormValues, initialValues, validationSchema } from './config';
import { createCompanyEmployer } from '../../../services/AdminService';
import CreateCompanyEmployerForm from '../../../parts/CreateCompanyEmployerForm';
import { ROUTES } from '../../../config/constants';

const AdminCreateEmployerPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const handleSubmitForm = async (
        values: CreateCompanyEmployerFormValues,
        { setSubmitting, setErrors }: FormikHelpers<CreateCompanyEmployerFormValues>,
    ) => {
        try {
            await createCompanyEmployer(toNumber(values.companyId), values);
            enqueueSnackbar(t('Profile successfully saved.'), { variant: 'success' });
            history.push(ROUTES.ADMIN_USERS);
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <CreateCompanyEmployerForm />
        </Formik>
    );
};

export default AdminCreateEmployerPage;
