import { combineReducers, AnyAction } from 'redux';
import { SET_LOADING, SET_CURRENT_USER, ERROR_FETCHING, RESET_CURRENT_USER } from '../actions';
import { CurrentUserReducer } from '../../models/Redux';

const initialState: CurrentUserReducer = {
    user: {},
    loading: true,
    error: false,
};

const currentUserReducer = (state = initialState, action: AnyAction): CurrentUserReducer => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true };
        case SET_CURRENT_USER:
            return { ...state, user: action.payload, loading: false };
        case ERROR_FETCHING:
            return { ...state, loading: false, error: true };
        case RESET_CURRENT_USER:
            return initialState;
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    currentUser: currentUserReducer,
});

export default rootReducer;
