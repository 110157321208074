import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        width: 120,
        height: 160,
        borderRadius: 13,
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 28px -13px #2B4967',
        display: 'grid',
        gridTemplateRows: '1fr 20px',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem 1rem 1rem',
        [theme.breakpoints.up(400)]: {
            width: 151,
            height: 197,
        },
        [theme.breakpoints.up(600)]: {
            width: '10.75rem',
            height: '13.5rem',
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    description: {
        fontSize: '0.75rem',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.11px',
        color: '#007FFC',
        marginTop: '1rem',
        textAlign: 'center',
    },
    image: {
        maxWidth: 150,
        [theme.breakpoints.down(600)]: {
            maxWidth: 130,
        },
        [theme.breakpoints.down(400)]: {
            maxWidth: 110,
        },
    },
}));
