import { FC } from 'react';
import { useStyles } from './styles';
import FormikAutocomplete, { FormikAutocompleteProps } from '../formik/FormikAutocomplete/FormikAutocomplete';

const CustomFormikAutocomplete: FC<FormikAutocompleteProps> = ({
    displayIcon,
    InputProps,
    ...otherProps
}) => {
    const classes = useStyles({ displayIcon });

    return (
        <FormikAutocomplete
            InputProps={{
                classes: {
                    input: classes.input,
                    root: classes.root,
                    focused: classes.focused,
                    // @ts-ignore
                    notchedOutline: classes.notchedOutline,
                    option: classes.option,
                },
                ...InputProps,
            }}
            classes={{
                root: classes.rootTextField,
            }}
            {...otherProps}
        />
    );
};

export default CustomFormikAutocomplete;
