import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router';

import { initialValues, validationSchema } from './config';
import UserProfile from '../../../models/UserProfile';
import { editUserProfile, getUserProfile } from '../../../services/UserService';
import { getBackendFormikErrors } from '../../../config/utils';
import EmployerProfileForm from '../../../parts/EmployerProfileForm';
import DeleteUserDialog from '../AdminUsersPage/DeleteUserDialog';

const AdminEditEmployerProfilePage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const [currentProfile, setCurrentProfile] = useState<UserProfile | null>(null);

    useEffect(() => {
        getUserProfile(id)
            .then((response: UserProfile) => {
                const { firstName, lastName, contactEmail, contactPhoneNumber, ...others } = response;

                setCurrentProfile({
                    firstName: firstName || '',
                    lastName: lastName || '',
                    contactPhoneNumber: contactPhoneNumber || '',
                    contactEmail: contactEmail || '',
                    ...others,
                });
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch user profile, try again later'), {
                    variant: 'error',
                }),
            );
    }, [enqueueSnackbar, id, t]);

    const handleSubmitForm = async (
        values: UserProfile,
        { setSubmitting, setErrors }: FormikHelpers<UserProfile>,
    ) => {
        try {
            await editUserProfile(id, values);
            enqueueSnackbar(t('Profile successfully saved.'), { variant: 'success' });
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentProfile || initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <EmployerProfileForm
                currentProfile={currentProfile}
                additionalElement={<DeleteUserDialog id={id} usersType="employers" />}
            />
        </Formik>
    );
};

export default AdminEditEmployerProfilePage;
