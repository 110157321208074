import { FC } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useStyles } from './styles';
import { TermsOfUseContent } from './config';

const AboutPage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container className={classes.container}>
            <Typography gutterBottom className={classes.title}>
                {t('Terms of use')}
            </Typography>
            {TermsOfUseContent.map(content => {
                return (
                    <Typography
                        className={clsx({
                            [classes.subtitle]: content.isTitle,
                            [classes.text]: !content.isTitle,
                        })}
                    >
                        {t(content.text, { nsSeparator: '|' })}
                    </Typography>
                );
            })}
        </Container>
    );
};

export default AboutPage;
