import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { useSelector } from 'react-redux';
import { EmailShareButton, WhatsappShareButton, WhatsappIcon, EmailIcon } from 'react-share';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ShareSocialIcon from '../../assets/jobOfferDetails/shareSocial.svg';

import SalaryIcon from '../../assets/jobOfferDetails/salaryIcon.svg';
import GpsOutlinedIcon from '../../assets/jobOfferDetails/gpsOutline.svg';
import RemoteOutlinedIcon from '../../assets/jobOfferDetails/RemoteOutline.svg';

import {
    bookmarkJobOffer,
    getJobOfferDetails,
    getSuggestedJobOfferDetails,
    JobOfferDetailsResponse,
} from '../../services/JobOfferService';
import { useStyles } from './styles';
import TeamMemberAvatar from '../../components/TeamMemberAvatar';
import StackFlag from '../../assets/stackFlag.svg';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { JobOfferButtonState } from '../../models/enums/JobOfferButtonState';
import { UserRoles } from '../../models/enums/UserRoles';
import StarIcon from '../../assets/landing/starIcon.svg';
import StarIconInverted from '../../assets/landing/starIconInverted.svg';
import InterviewPropositionDialog from '../../components/InterviewPropositionDialog';
import JobOfferDetailsStatus from './JobOfferDetailsStatus';
import { JobOfferApplicationEvent, JobOfferApplicationStatus } from '../../models/JobOfferApplication';
import ContractPropositionDialog from '../../components/ContractPropositionDialog';
import JobOfferCard from '../../components/JobOfferCard';
import { JobOffer } from '../../models/JobOffer';
import { hasProposalsAvailable, kFormatter, loginRedirectToLinkedIn } from '../../config/utils';
import JobOfferCardsWrapper from '../../components/JobOfferCardsWrapper';
import MissingProfileInformationDialog from '../../components/MissingProfileInformationDialog';
import { getApplicationEventHistory } from '../../services/JobOfferApplicationsService';
import { ROUTES } from '../../config/constants';

const JobOfferDetailsPage: FC = () => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const [jobDetails, setJobDetails] = useState<JobOfferDetailsResponse | null>(null);
    const [jobApplicationEvents, setJobApplicationEvents] = useState<JobOfferApplicationEvent[] | null>(null);
    const [similarJobDetails, setSimilarJobDetails] = useState<JobOffer[] | null>(null);
    const [interviewPropositionDialogOpen, setInterviewPropositionDialogOpen] = useState(true);
    const [contractPropositionDialogOpen, setContractPropositionDialogOpen] = useState(true);
    const [missingProfileInformationDialogOpen, setMissingProfileInformationDialogOpen] = useState(false);

    const shareUrl = `${process.env.REACT_APP_API_NO_API_VER}share/jobs/${id}`;
    const shareTitle = `${jobDetails?.title} - ${jobDetails?.companyName}`;

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const fetchData = useCallback(() => {
        getJobOfferDetails(id)
            .then(response => {
                setJobDetails(response);
                if (
                    response.application?.jobOfferApplicationStatus &&
                    [
                        JobOfferApplicationStatus.CONTRACT_DECLINED,
                        JobOfferApplicationStatus.ROLE_FILLED,
                    ].includes(response.application.jobOfferApplicationStatus)
                ) {
                    getSuggestedJobOfferDetails(response.id)
                        .then(similarJobDetailsResponse => setSimilarJobDetails(similarJobDetailsResponse))
                        .catch(() => {
                            enqueueSnackbar(t('Unable to fetch similar jobs content, try again later'), {
                                variant: 'error',
                            });
                        });
                }
                if (response.application) {
                    getApplicationEventHistory(response.application.id)
                        .then(eventsHistory => {
                            setJobApplicationEvents(eventsHistory);
                        })
                        .catch(() => {
                            enqueueSnackbar(t('Unable to fetch events history, try again later'), {
                                variant: 'error',
                            });
                        });
                }
            })
            .catch(() => {
                enqueueSnackbar(t('The job post you were trying to reach is unavailable'), {
                    variant: 'error',
                });
                history.push(ROUTES.LANDING);
            });
    }, [enqueueSnackbar, history, id, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSaveClicked = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (jobDetails) {
            try {
                await bookmarkJobOffer(jobDetails.id);
                setJobDetails({ ...jobDetails, bookmark: !jobDetails.bookmark });
            } catch (error) {
                enqueueSnackbar(t('Bookmarking failed, try again later'), { variant: 'error' });
            }
        }
    };

    const handleCopyToClipboardClicked = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        await navigator.clipboard.writeText(window.location.href);
        enqueueSnackbar(t('Link copied to clipboard'), { variant: 'success' });
    };

    // const handleLinkedinButtonClicked = () => {
    //     window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`);
    // };

    const renderHeaderButtons = () => (
        <div className={classes.headerButtons}>
            {!!jobDetails && role === UserRoles.CANDIDATE && (
                <IconButton className={classes.headerButton} onClick={handleSaveClicked}>
                    {!jobDetails?.bookmark ? (
                        <img src={StarIcon} width="16" height="15" alt="Save" />
                    ) : (
                        <img src={StarIconInverted} width="16" height="15" alt="Saved" />
                    )}
                </IconButton>
            )}

            <WhatsappShareButton url={shareUrl} title={shareTitle}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <EmailShareButton url={window.location.href} subject={shareTitle}>
                <EmailIcon size={32} round />
            </EmailShareButton>

            {/* <LinkedinIcon size={32} round onClick={handleLinkedinButtonClicked} /> */}

            <IconButton className={classes.headerButton} onClick={handleCopyToClipboardClicked}>
                <img src={ShareSocialIcon} alt="Copy to clipboard" />
            </IconButton>
        </div>
    );

    const renderCompanyInfo = () => {
        if (jobDetails) {
            const { companyLogoUrl, companyDescription } = jobDetails;

            return (
                <>
                    <div className={classes.headerFlex}>
                        {companyLogoUrl ? (
                            <img src={companyLogoUrl} alt="company logo" className={classes.companyLogo} />
                        ) : (
                            <div />
                        )}
                        {renderHeaderButtons()}
                    </div>
                    {!!companyDescription && (
                        <Typography
                            className={classes.headerDescription}
                            dangerouslySetInnerHTML={{
                                __html: stateToHTML(convertFromRaw(JSON.parse(companyDescription))),
                            }}
                        />
                    )}
                </>
            );
        }
        return null;
    };

    const renderTitle = () => {
        if (jobDetails) {
            return <Typography className={classes.jobTitle}>{jobDetails.title}</Typography>;
        }
        return null;
    };

    const renderCompanyMission = () => {
        if (jobDetails) {
            const { companyMission } = jobDetails;

            return (
                <div className={classes.paperWrapper}>
                    <Typography className={classes.paperTitle}>{t('MISSION')}</Typography>
                    {!!companyMission && (
                        <Typography
                            className={classes.description}
                            dangerouslySetInnerHTML={{
                                __html: stateToHTML(convertFromRaw(JSON.parse(companyMission))),
                            }}
                        />
                    )}
                </div>
            );
        }
        return null;
    };

    const renderTeam = () => {
        if (jobDetails?.teamMembers.length) {
            const { teamMembers } = jobDetails;

            return (
                <div className={classes.paperWrapper}>
                    <Typography className={classes.paperTitle}>{t('MEET YOUR TEAM')}</Typography>
                    <div className={classes.teamWrapper}>
                        {teamMembers.map(({ name, position, avatarUrl }, index) => (
                            <div key={index} className={classes.memberWrapper}>
                                <TeamMemberAvatar name={name} url={avatarUrl} size={60} fontSize={14} />
                                <div className={classes.infoWrapper}>
                                    <Typography className={classes.name}>{name}</Typography>
                                    <Typography className={classes.position}>{position}</Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderSubmitButton = () => {
        if (jobDetails?.jobOfferButtonState === JobOfferButtonState.EDIT) {
            return (
                <Button
                    color="primary"
                    variant="contained"
                    component={RouterLink}
                    to={
                        !!role && [UserRoles.SUPPORT, UserRoles.ADMIN].includes(role)
                            ? `/edit-job/${id}`
                            : `/employer/edit-jobs/${id}`
                    }
                    className={classes.applyButton}
                >
                    {t('Edit job post')}
                </Button>
            );
        }
        if (jobDetails?.jobOfferButtonState === JobOfferButtonState.APPLY_DISABLED) {
            return (
                <Button color="primary" variant="contained" className={classes.applyButton} disabled>
                    {t('APPLIED')}
                </Button>
            );
        }
        if (!!role && jobDetails?.jobOfferButtonState === JobOfferButtonState.MISSING_PROFILE_INFORMATION) {
            return (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setMissingProfileInformationDialogOpen(true)}
                    className={classes.applyButton}
                >
                    {t('APPLY FOR THIS ROLE')}
                </Button>
            );
        }
        if (!!role && jobDetails?.jobOfferButtonState === JobOfferButtonState.APPLY) {
            return (
                <Button
                    color="primary"
                    variant="contained"
                    component={RouterLink}
                    to={`/candidate/apply-job/${id}`}
                    className={classes.applyButton}
                >
                    {t('APPLY FOR THIS ROLE')}
                </Button>
            );
        }
        if (!role && jobDetails?.jobOfferButtonState === JobOfferButtonState.APPLY) {
            return (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => loginRedirectToLinkedIn(id)}
                    className={classes.applyButton}
                >
                    {t('APPLY FOR THIS ROLE')}
                </Button>
            );
        }
        return null;
    };

    const renderTags = (tags: string[]) =>
        tags.map(tag => (
            <div key={tag} className={classes.stackChip}>
                <img src={StackFlag} alt="stack flag" width="7" height="9" className={classes.flag} />
                {tag}
            </div>
        ));

    const showDialogClicked = () => {
        switch (jobDetails?.application?.jobOfferApplicationStatus) {
            case JobOfferApplicationStatus.INTERVIEW_PROPOSED:
                setInterviewPropositionDialogOpen(true);
                break;
            case JobOfferApplicationStatus.CONTRACT_PROPOSED:
                setContractPropositionDialogOpen(true);
                break;
            default:
                setInterviewPropositionDialogOpen(true);
                break;
        }
    };

    const renderStatus = () => {
        if (
            !!jobDetails?.application &&
            jobDetails?.application?.jobOfferApplicationStatus !== JobOfferApplicationStatus.NEW
        ) {
            return (
                <JobOfferDetailsStatus
                    application={jobDetails?.application}
                    meeting={jobDetails?.meeting}
                    handleShowDialogClicked={showDialogClicked}
                    jobApplicationEvents={jobApplicationEvents}
                    showEventsHistory
                />
            );
        }
        return '';
    };

    const renderInterviewOffer = () => {
        if (jobDetails?.applicationTimeslotSelect) {
            const { dateProposals } = jobDetails?.applicationTimeslotSelect;

            if (
                jobDetails?.applicationTimeslotSelect &&
                interviewPropositionDialogOpen &&
                hasProposalsAvailable(dateProposals)
            ) {
                return (
                    <InterviewPropositionDialog
                        interviewProposal={jobDetails.applicationTimeslotSelect}
                        open={interviewPropositionDialogOpen}
                        onClose={() => setInterviewPropositionDialogOpen(false)}
                        onStatusUpdated={fetchData}
                    />
                );
            }
            return '';
        }
        return '';
    };

    const renderContractOffer = () => {
        if (
            jobDetails?.application?.jobOfferApplicationStatus ===
                JobOfferApplicationStatus.CONTRACT_PROPOSED &&
            jobDetails?.contractProposal &&
            jobDetails?.application &&
            interviewPropositionDialogOpen
        ) {
            return (
                <ContractPropositionDialog
                    contractProposal={jobDetails.contractProposal}
                    applicationId={jobDetails.application.id}
                    open={contractPropositionDialogOpen}
                    onClose={() => setContractPropositionDialogOpen(false)}
                    onStatusUpdated={fetchData}
                />
            );
        }
        return '';
    };

    const renderJobDetails = () => {
        if (jobDetails) {
            const {
                billingCycle,
                currency,
                location,
                remotely,
                salaryMax,
                salaryMin,
                description,
                tags,
            } = jobDetails;

            return (
                <div className={classes.paperWrapper}>
                    <Typography className={classes.paperTitle}>{t('JOB DETAILS')}</Typography>
                    <Typography className={classes.detailText}>
                        <img
                            src={SalaryIcon}
                            alt="salary"
                            width={14}
                            height={14}
                            className={classes.detailsIcon}
                        />
                        {`${kFormatter(salaryMin)} - ${kFormatter(salaryMax)} ${currency.code} / ${t(
                            billingCycle,
                        )}`}
                    </Typography>
                    <Typography className={classes.detailText}>
                        <img
                            src={GpsOutlinedIcon}
                            alt="location"
                            width={14}
                            height={14}
                            className={classes.detailsIcon}
                        />
                        {location}
                    </Typography>
                    {remotely && (
                        <Typography className={classes.detailText}>
                            <img
                                src={RemoteOutlinedIcon}
                                alt="remote job"
                                width={14}
                                height={14}
                                className={classes.detailsIcon}
                            />
                            {t('Remote')}
                        </Typography>
                    )}
                    <div className={classes.tagsWrapper}>{renderTags(tags)}</div>
                    <Typography className={classes.paperTitle}>{t('ABOUT THE JOB')}</Typography>
                    {!!description && (
                        <Typography
                            className={classes.description}
                            dangerouslySetInnerHTML={{
                                __html: stateToHTML(convertFromRaw(JSON.parse(description))),
                            }}
                        />
                    )}
                    {renderSubmitButton()}
                </div>
            );
        }
        return null;
    };

    const renderSimilarJobs = () => {
        if (
            similarJobDetails?.length &&
            jobDetails?.application?.jobOfferApplicationStatus &&
            [JobOfferApplicationStatus.CONTRACT_DECLINED, JobOfferApplicationStatus.ROLE_FILLED].includes(
                jobDetails.application.jobOfferApplicationStatus,
            )
        ) {
            return (
                <div className={classes.similarJobDetailsContainer}>
                    <Typography className={classes.similarJobsTitle}>{t('Similar job offers')}: </Typography>
                    <JobOfferCardsWrapper>
                        {similarJobDetails.map(jobDetails => (
                            <div className={classes.similarJob}>
                                <JobOfferCard {...jobDetails} jobDetailsPage />
                            </div>
                        ))}
                    </JobOfferCardsWrapper>
                </div>
            );
        }
        return '';
    };

    const render = () => {
        return (
            <Container className={classes.container}>
                {renderCompanyMission()}
                {renderTeam()}
                {renderJobDetails()}
                {renderSimilarJobs()}
            </Container>
        );
    };

    const renderMissingProfileInformationDialog = () => {
        if (
            jobDetails?.jobOfferButtonState === JobOfferButtonState.MISSING_PROFILE_INFORMATION &&
            missingProfileInformationDialogOpen
        ) {
            return (
                <MissingProfileInformationDialog
                    open={missingProfileInformationDialogOpen}
                    jobId={id}
                    onClose={() => setMissingProfileInformationDialogOpen(false)}
                />
            );
        }
        return null;
    };

    return (
        <>
            {renderMissingProfileInformationDialog()}
            <Container className={classes.container}>
                {renderStatus()}
                {renderInterviewOffer()}
                {renderContractOffer()}
                {renderCompanyInfo()}
                {renderTitle()}
            </Container>
            {render()}
        </>
    );
};

export default JobOfferDetailsPage;
