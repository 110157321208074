import React, { FC } from 'react';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { useSnackbar } from 'notistack';
import Media from '../../../models/Media.model';
import { uploadDocument } from '../../../services/MediaService';
import { useStyles } from './styles';
import FormikErrorText from '../FormikErrorText';

interface FormikDocumentUploadProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
}

const FormikDocumentUpload: FC<FormikDocumentUploadProps> = ({ name, label, disableLabel }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const { value, touched, error } = useField(name)[1];
    const { setValue, setTouched } = useField(name)[2];

    const handleUploadFile = (file: File) => {
        if (
            ['application/pdf', 'application/msword', 'officedocument', 'opendocument'].some(type =>
                file?.type?.includes(type),
            )
        ) {
            uploadDocument(file)
                .then((response: Media) => {
                    setValue(response);
                    enqueueSnackbar(t('Successfully uploaded new file'), {
                        variant: 'success',
                    });
                })
                .catch(() =>
                    enqueueSnackbar(
                        t(
                            'Unable to upload document. Please make sure to upload a word, pdf or odt format document.',
                        ),
                        { variant: 'error' },
                    ),
                );
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        noDrag: true,
        accept:
            '.odt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        onDrop: files => handleUploadFile(files[0]),
    });

    return (
        <div className={classes.wrapper}>
            {!disableLabel && <InputLabel>{label || name}</InputLabel>}
            <div
                {...getRootProps()}
                className={classes.dropzoneArea}
                onBlur={() => {
                    setTouched(true);
                }}
            >
                <input {...getInputProps()} />

                {!!value?.url && (
                    <div className={classes.flex}>
                        <DescriptionOutlinedIcon />
                        <p>{value.externalId || t('Current CV')}</p>
                    </div>
                )}

                <Button
                    className={classes.uploadButton}
                    color="primary"
                    variant="outlined"
                    startIcon={<CloudUploadOutlinedIcon />}
                >
                    {t('Upload file')}
                </Button>
            </div>
            {touched && error !== undefined && <FormikErrorText name={name} />}
        </div>
    );
};

export default FormikDocumentUpload;
