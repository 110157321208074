import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    companyLogo: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    companyLogoWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        border: '1px solid #DFDFDF',
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        overflow: 'hidden',
    },
    companyName: {
        color: '#007FFC',
        textDecoration: 'none',
        fontWeight: 700,
        textAlign: 'center',
        fontSize: 12,
        maxHeight: 15,
        maxWidth: 75,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});
