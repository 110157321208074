import React, { FC } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './styles';

interface Props {
    onClick: () => void;
}

const CloseDialogElement: FC<Props> = ({ onClick }) => {
    const classes = useStyles();
    return (
        <IconButton className={classes.exitButton} size="small" onClick={onClick}>
            <CloseIcon />
        </IconButton>
    );
};

export default CloseDialogElement;
