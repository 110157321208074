import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayHelpers, FieldArray, useField } from 'formik';
import { isEmpty } from 'lodash';

import InputLabel from '@material-ui/core/InputLabel';

import CandidateEducationInfo from '../../CandidateEducationInfo';
import { useStyles } from './styles';
import AddEducationDialog from '../../../pages/candidate/CandidateProfilePage/AddEducationDialog';

const CandidateProfileFormEducation: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { value } = useField('educations')[1];

    const renderCandidates = (arrayHelpers: ArrayHelpers) => {
        if (isEmpty(value)) {
            return null;
        }
        return (
            <div className={classes.infoWrapper}>
                <CandidateEducationInfo arrayHelpers={arrayHelpers} editable educations={value} />
            </div>
        );
    };

    return (
        <div>
            <FieldArray name="educations">
                {arrayHelpers => (
                    <>
                        <div className={classes.labelWrapper}>
                            <InputLabel className={classes.inputLabel}>{t('Education')}</InputLabel>
                            <AddEducationDialog action="add" onSubmitAction={arrayHelpers.push} />
                        </div>

                        {renderCandidates(arrayHelpers)}
                    </>
                )}
            </FieldArray>
        </div>
    );
};

export default CandidateProfileFormEducation;
