import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    editButton: {
        textTransform: 'none',
        borderRadius: '0.25rem',
        fontSize: '0.75rem',
        fontWeight: 700,
        height: '1.5rem',
        color: theme.palette.grey[600],
    },
}));
