import cover1 from '../../assets/jobOfferDetails/cover-1.jpg';
import cover2 from '../../assets/jobOfferDetails/cover-2.jpg';
import cover3 from '../../assets/jobOfferDetails/cover-3.png';
import cover4 from '../../assets/jobOfferDetails/cover-4.jpg';
import cover5 from '../../assets/jobOfferDetails/cover-5.jpg';
import cover6 from '../../assets/jobOfferDetails/cover-6.jpg';

export const randomCoverBackgrounds = [cover1, cover2, cover3, cover4, cover5, cover6];

export const getCoverByCompanyId = (companyId: number | undefined): string => {
    if (companyId) {
        return randomCoverBackgrounds[companyId % (randomCoverBackgrounds.length - 1)];
    }
    return randomCoverBackgrounds[0];
};
