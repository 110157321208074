import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import { useStyles } from './styles';
import UserAvatar from '../../../../components/UserAvatar';
import { Company } from '../../../../models/Company';

interface Props {
    company: Company;
}

const CompanyCard: FC<Props> = ({ company }) => {
    const { id, logo, name } = company;
    const classes = useStyles();

    return (
        <RouterLink to={`/edit-company/${id}`} className={classes.link}>
            <Card className={classes.container}>
                <div className={classes.profile}>
                    <UserAvatar size={66} url={logo?.url} />
                    <div className={classes.textWrapper}>
                        <Typography
                            title={name}
                            className={clsx(classes.primaryListItem, classes.textEllipsis)}
                        >
                            {name}
                        </Typography>
                    </div>
                </div>
            </Card>
        </RouterLink>
    );
};

export default CompanyCard;
