import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { initialValues, validationSchema, CreateSupportFormValues } from './config';
import { createSupport } from '../../../services/AdminService';
import { ROUTES } from '../../../config/constants';
import { getBackendErrorMessage, getBackendFormikErrors } from '../../../config/utils';
import SupportProfileForm from '../../../parts/SupportProfileForm';

const AdminCreateSupportPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const handleSubmitForm = async (
        values: CreateSupportFormValues,
        { setSubmitting, setErrors }: FormikHelpers<CreateSupportFormValues>,
    ) => {
        try {
            await createSupport(values);
            enqueueSnackbar(t('Support user successfully created.'), { variant: 'success' });
            history.push(ROUTES.ADMIN_USERS);
        } catch (error) {
            setErrors(getBackendFormikErrors(error));
            enqueueSnackbar(getBackendErrorMessage(error), { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
        >
            <SupportProfileForm />
        </Formik>
    );
};

export default AdminCreateSupportPage;
