import React, { FC } from 'react';
import { useField } from 'formik';

import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from './styles';

interface FormikErrorTextProps {
    name?: string;
}

const FormikErrorText: FC<FormikErrorTextProps> = ({ name = 'form' }) => {
    const classes = useStyles(name);
    const [, { error }] = useField(name);

    return (
        <FormHelperText error classes={{ root: classes.root }}>
            {error || ''}
        </FormHelperText>
    );
};

export default FormikErrorText;
