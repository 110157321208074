import { UserRoles } from '../../models/enums/UserRoles';
import { ROUTES } from '../../config/constants';

export interface NavLinkConfig {
    to: string;
    label: string;
    activeMatch: string[];
}

interface NavLinksConfig {
    [key: string]: NavLinkConfig[];
}

export const navLinksConfig: NavLinksConfig = {
    PUBLIC: [
        {
            to: ROUTES.LANDING,
            label: 'Find a job',
            activeMatch: [ROUTES.LANDING, ROUTES.CANDIDATE_SIGN_IN],
        },
        {
            to: ROUTES.EMPLOYER_LANDING_PAGE,
            label: 'For employers',
            activeMatch: [ROUTES.EMPLOYER_LANDING_PAGE, ROUTES.EMPLOYER_SIGN_IN, ROUTES.EMPLOYER_SIGN_UP],
        },
        {
            to: ROUTES.ABOUT,
            label: 'About',
            activeMatch: [ROUTES.ABOUT],
        },
    ],
    [UserRoles.ADMIN]: [
        {
            to: ROUTES.ADMIN_USERS,
            label: 'Users',
            activeMatch: [ROUTES.ADMIN_USERS],
        },
        {
            to: ROUTES.ADMIN_COMPANIES,
            label: 'Companies',
            activeMatch: [ROUTES.ADMIN_COMPANIES],
        },
        {
            to: ROUTES.ADMIN_JOBS,
            label: 'Job offers',
            activeMatch: [ROUTES.ADMIN_JOBS],
        },
        {
            to: ROUTES.ADMIN_ANALYTICS,
            label: 'Analytics',
            activeMatch: [ROUTES.ADMIN_ANALYTICS],
        },
    ],
    [UserRoles.EMPLOYER]: [
        {
            to: ROUTES.EMPLOYER_HOME,
            label: 'Jobs',
            activeMatch: [ROUTES.EMPLOYER_HOME, ROUTES.EMPLOYER_POST_JOB],
        },
        {
            to: ROUTES.EMPLOYER_JOB_CANDIDATES,
            label: 'Candidates',
            activeMatch: [ROUTES.EMPLOYER_JOB_CANDIDATES],
        },
        {
            to: ROUTES.EMPLOYER_COMPANY_PROFILE,
            label: 'Company',
            activeMatch: [ROUTES.EMPLOYER_COMPANY_PROFILE],
        },
        {
            to: ROUTES.EMPLOYER_PROFILE,
            label: 'Profile',
            activeMatch: [ROUTES.EMPLOYER_PROFILE],
        },
    ],
    [UserRoles.CANDIDATE]: [
        {
            to: ROUTES.LANDING,
            label: 'Home',
            activeMatch: [ROUTES.LANDING],
        },
        {
            to: ROUTES.CANDIDATE_MY_ROLES,
            label: 'My roles',
            activeMatch: [ROUTES.CANDIDATE_MY_ROLES],
        },
        {
            to: ROUTES.CANDIDATE_FIND_A_JOB,
            label: 'Search',
            activeMatch: [ROUTES.CANDIDATE_FIND_A_JOB],
        },
        {
            to: ROUTES.CANDIDATE_PROFILE,
            label: 'Profile',
            activeMatch: [ROUTES.CANDIDATE_PROFILE],
        },
    ],
    [UserRoles.SUPPORT]: [
        {
            to: ROUTES.SUPPORT_COMPANIES,
            label: 'Companies',
            activeMatch: [ROUTES.SUPPORT_COMPANIES],
        },
        {
            to: ROUTES.SUPPORT_JOBS,
            label: 'Job offers',
            activeMatch: [ROUTES.SUPPORT_JOBS],
        },
    ],
};
