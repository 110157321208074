import axios from 'axios';
import FilterData from '../models/FilterData';
import {
    JobOfferApplication,
    JobOfferApplicationEvent,
    PaginatedJobOfferApplicationResponse,
    PaginatedListJobOfferApplicationResponse,
    RejectApplicationStatus,
} from '../models/JobOfferApplication';
import { InterviewProposal } from '../models/InterviewProposal';
import { ProposeContractDialogValues } from '../pages/employer/EmployerCandidatesPage/ProposeContractDialog/config';
import Media from '../models/Media.model';

interface UpdateRejectionStatusRequest {
    feedbackMessage: string;
    rejectionMessage: string;
    statusId: number;
}

export const checkIfCandidateHasAppliedJobOffer = async (id: string | number): Promise<boolean> => {
    const { data } = await axios.get(`job-offer/${id}/application/status`);
    return data;
};

export const postCandidateApplication = async (application: JobOfferApplication): Promise<unknown> => {
    const { data } = await axios.post('job-offer/applications', application);
    return data;
};

export const getMeetingOffers = async (): Promise<InterviewProposal[]> => {
    const { data } = await axios.get('job-offer/applications/views/my-roles/offers');
    return data;
};

export const getPaginatedJobOfferApplications = async (
    params: FilterData,
): Promise<PaginatedJobOfferApplicationResponse> => {
    const { data } = await axios.post('job-offer/applications/views/search', params);
    return data;
};

export const getCandidateAppliedJobOfferApplications = async (
    filterData: FilterData,
): Promise<PaginatedListJobOfferApplicationResponse> => {
    const { data } = await axios.post('job-offer/applications/views/my-roles/applied', filterData);
    return data;
};

export const getCandidateSavedJobOfferApplications = async (
    filterData: FilterData,
): Promise<PaginatedListJobOfferApplicationResponse> => {
    const { data } = await axios.post('job-offer/applications/views/my-roles/saved', filterData);
    return data;
};

export const getCandidateSuggestedJobOfferApplications = async (
    filterData: FilterData,
): Promise<PaginatedListJobOfferApplicationResponse> => {
    const { data } = await axios.post('job-offer/applications/views/my-roles/suggested', filterData);
    return data;
};

export const getRejectionApplicationStatuses = async (): Promise<RejectApplicationStatus[]> => {
    const { data } = await axios.get('job-offer/applications/statuses');
    return data;
};

export const updateRejectionApplicationStatus = async (
    applicationId: string | number,
    params: UpdateRejectionStatusRequest,
): Promise<unknown> => {
    const { data } = await axios.put(`job-offer/applications/${applicationId}/status`, params);
    return data;
};

export const createOffer = async (
    applicationId: string | number,
    params: ProposeContractDialogValues,
): Promise<unknown> => {
    const { data } = await axios.put(`job-offer/applications/${applicationId}/offer`, params);
    return data;
};

export const acceptOffer = async (
    applicationId: string | number,
    params: { signedContract: Media | null; message: string },
): Promise<unknown> => {
    const { data } = await axios.put(`job-offer/applications/${applicationId}/offer/accept`, params);
    return data;
};

export const rejectOffer = async (
    applicationId: string | number,
    params: { message?: string },
): Promise<unknown> => {
    const { data } = await axios.put(`job-offer/applications/${applicationId}/offer/reject`, params);
    return data;
};

export const getApplicationEventHistory = async (
    applicationId: number,
): Promise<JobOfferApplicationEvent[]> => {
    const { data } = await axios.get(`job-offer/applications/${applicationId}/views/application-history`);
    return data;
};
