import React, { FC } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Select } from 'formik-material-ui';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel, { InputLabelProps as InputLabelPropsTypes } from '@material-ui/core/InputLabel';
import { InputProps } from '@material-ui/core/Input';
import { SelectProps } from '@material-ui/core/Select';

export interface FormikSelectProps extends SelectProps {
    name: string;
    label?: string;
    InputProps?: Partial<InputProps>;
    disableLabel?: boolean;
    InputLabelProps?: InputLabelPropsTypes;
    containerClassName?: string;
}

const FormikSelect: FC<FormikSelectProps> = ({
    containerClassName,
    disableLabel,
    id,
    label,
    name,
    InputLabelProps,
    ...otherProps
}) => {
    return (
        <div className={containerClassName}>
            {!disableLabel && (
                <InputLabel htmlFor={id || name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <Field
                component={Select}
                fullWidth
                displayEmpty
                autoComplete="off"
                id={id || name}
                name={name}
                {...otherProps}
            />
            <ErrorMessage
                name={name}
                render={errorMessage => (
                    <FormHelperText variant="outlined" error>
                        {errorMessage}
                    </FormHelperText>
                )}
            />
        </div>
    );
};

export default FormikSelect;
