import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '33rem',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '33rem',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 600,
        margin: '1rem auto',
    },
    bottomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '1.25rem',
    },
    rememberMe: {
        fontSize: '0.8125rem',
        lineHeight: '19px',
        color: '#1F3874',
        display: 'flex',
        alignItems: 'center',
    },
    submitButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
        marginLeft: '1.875rem',
        width: 151,
    },
    signUpButtonsContainer: {
        width: '100%',
        display: 'flex',
        marginTop: '1.75rem',
        '& a': {
            marginRight: 20,
            fontWeight: 700,
            '&:not(:first-child)': {
                marginLeft: 20,
                color: 'grey',
            },
        },
    },
    signUpButtonsDivider: {
        content: "''",
        display: 'inline-block',
        width: 2,
        height: 20,
        backgroundColor: 'grey',
    },
}));
