import { FC } from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

import MenuIcon from '@material-ui/icons/Menu';

import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import { UserRoles } from '../../../../models/enums/UserRoles';
import { CurrentUserReducer, RootReducer } from '../../../../models/Redux';
import { BottomNavLinkConfig, bottomNavLinksConfig } from './config';

interface Props {
    onHamburgerClick: () => void;
}

const BottomNavigationMobile: FC<Props> = ({ onHamburgerClick }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const classes = useStyles();

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const renderLinks = (linksConfig: BottomNavLinkConfig[]): JSX.Element[] => {
        return linksConfig.map(({ to, label, activeMatch, icon }) => (
            <Link
                key={to}
                component={RouterLink}
                to={to}
                className={clsx(classes.link, {
                    [classes.activeLink]: activeMatch.some(url => url === pathname),
                })}
            >
                {icon}
                <div className={classes.linkText}>{t(label)}</div>
            </Link>
        ));
    };

    const renderContent = () => {
        switch (role) {
            case UserRoles.EMPLOYER:
                return renderLinks(bottomNavLinksConfig[UserRoles.EMPLOYER]);
            case UserRoles.CANDIDATE:
                return renderLinks(bottomNavLinksConfig[UserRoles.CANDIDATE]);
            case UserRoles.ADMIN:
                return renderLinks(bottomNavLinksConfig[UserRoles.ADMIN]);
            case UserRoles.SUPPORT:
                return renderLinks(bottomNavLinksConfig[UserRoles.SUPPORT]);
            default:
                return null;
        }
    };

    if (!isEmpty(role)) {
        return (
            <div className={classes.bottomNavigation}>
                {renderContent()}
                <Button
                    onClick={onHamburgerClick}
                    classes={{
                        root: classes.link,
                        label: classes.linkLabel,
                        text: classes.linkText,
                    }}
                >
                    <MenuIcon />
                    <div className={classes.linkText}>{t('More')}</div>
                </Button>
            </div>
        );
    }
    return null;
};

export default BottomNavigationMobile;
