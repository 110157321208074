import { FC, useEffect, useState } from 'react';
import { isArray, isEmpty, toString } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { InView } from 'react-intersection-observer';
import { Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';

import Alert from '@material-ui/lab/Alert';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SearchIcon from '@material-ui/icons/Search';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { usePaginationFetch } from '../../../config/hooks/usePaginationFetch';
import {
    getPaginatedJobOfferApplications,
    getRejectionApplicationStatuses,
} from '../../../services/JobOfferApplicationsService';
import {
    JobOfferApplicationStatus,
    PaginatedJobOfferApplication,
    RejectApplicationStatus,
} from '../../../models/JobOfferApplication';
import FormikTextField from '../../../components/formik/FormikTextField';
import EmployerCandidateProfile from './EmployerCandidateProfile';
import { EmployerCandidatesPageFormValues, initialValues } from './config';
import CustomFormikSwitch from '../../../components/CustomFormikSwitch';
import ApplicationStatusAvatar from './ApplicationStatusAvatar';
import { useWindowSize } from '../../../config/hooks/useWindowSize';
import { EMPLOYER_CANDIDATES_BREAKPOINTS } from '../../../config/constants';

const EmployerCandidatesPage: FC = () => {
    const classes = useStyles();
    const [active, setActive] = useState<number>(0);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [rejectStatuses, setRejectStatuses] = useState<RejectApplicationStatus[]>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const { width } = useWindowSize();

    const {
        content,
        last,
        error,
        setDataFilters,
        pageable: { offset },
        loading,
    } = usePaginationFetch<PaginatedJobOfferApplication>({
        service: getPaginatedJobOfferApplications,
        initialSize: 12,
        accumulateData: true,
    });

    useEffect(() => {
        getRejectionApplicationStatuses()
            .then(response => {
                setRejectStatuses(response);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, t]);

    useEffect(() => {
        if (!offset) {
            setActive(0);
        }
    }, [offset]);

    const handleSubmitForm = async (values: EmployerCandidatesPageFormValues) => {
        let filters = Object.entries(values)
            .filter(([key, value]) => !(key === 'status' && value === null))
            .map(([key, value]) => {
                const newValue = key === 'search' ? value.trim() : value;
                return [key, newValue];
            })
            .map(([key, value]) => {
                const newValues = isArray(value) ? value.map(item => toString(item)) : [toString(value)];
                return {
                    column: key,
                    values: newValues,
                };
            });
        filters = filters.filter(({ values }) => values.length > 0);
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
            filters,
        }));
    };

    const getNextPage = () => {
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: dataFilters.page + 1,
        }));
    };

    const onDataUpdate = () => {
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
        }));
    };

    const handleSelectedFilter = (
        { values, setValues, submitForm }: FormikProps<EmployerCandidatesPageFormValues>,
        status: JobOfferApplicationStatus[],
    ): void => {
        setValues({
            ...values,
            onlyNew: false,
            status: JSON.stringify(values.status) === JSON.stringify(status) ? null : status,
        });
        setActive(0);
        submitForm();
    };

    const renderLoader = () => {
        if (last || loading || error) return null;

        return (
            <InView
                threshold={1}
                onChange={inView => {
                    if (inView) {
                        getNextPage();
                    }
                }}
            >
                <LinearProgress />
            </InView>
        );
    };

    const renderCandidatesList = () => {
        return content.map(
            (
                {
                    candidate: { firstName, lastName, currentPosition, avatar },
                    jobOfferTitle,
                    jobOfferApplicationStatus,
                },
                index,
            ) => (
                <ListItem
                    disableGutters
                    button
                    className={clsx(classes.listItem, { [classes.listItemActive]: active === index })}
                    onClick={() => {
                        setActive(index);
                        setOpenDrawer(true);
                    }}
                    key={index}
                >
                    <ApplicationStatusAvatar avatar={avatar} status={jobOfferApplicationStatus} />
                    <div className={classes.textWrapper}>
                        <Typography
                            title={`${firstName} ${lastName}`}
                            className={clsx(classes.primaryListItem, classes.textEllipsis)}
                        >{`${firstName} ${lastName}`}</Typography>
                        <Typography
                            title={currentPosition}
                            className={clsx(classes.secondaryListItem, classes.textEllipsis)}
                        >
                            {currentPosition}
                        </Typography>
                        <div>
                            <Typography className={classes.appInfo} component="span">
                                {t('Applied for ')}
                            </Typography>
                            <Typography
                                className={clsx(classes.appInfo, classes.appJobTile)}
                                component="span"
                            >
                                {jobOfferTitle}
                            </Typography>
                        </div>
                    </div>
                </ListItem>
            ),
        );
    };

    const renderForm = (formik: FormikProps<EmployerCandidatesPageFormValues>) => {
        return (
            <div className={classes.wrapper}>
                <div className={classes.headerWrapper}>
                    <Typography className={classes.candidatesHeader}>{t('Candidates')}</Typography>
                    <div className={classes.filters}>
                        <div className={classes.switchWrapper}>
                            <CustomFormikSwitch
                                disableLabel
                                name="onlyNew"
                                onClick={() => {
                                    formik.setValues({
                                        ...formik.values,
                                        status: [],
                                        onlyNew: !formik.values.onlyNew,
                                    });
                                    setActive(0);
                                    formik.submitForm();
                                }}
                            />
                            <Typography className={classes.remotely}>{t('Only New ')}</Typography>
                        </div>
                        <div className={classes.showWrapper}>
                            <Typography className={classes.remotely}>{t('Show')}: </Typography>
                            <IconButton
                                className={clsx(classes.showFilter, {
                                    [classes.showAcceptedActive]: formik.values.status?.includes(
                                        JobOfferApplicationStatus.CONTRACT_ACCEPTED,
                                    ),
                                })}
                                onClick={() =>
                                    handleSelectedFilter(formik, [
                                        JobOfferApplicationStatus.CONTRACT_ACCEPTED,
                                    ])
                                }
                            >
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton
                                className={clsx(classes.showFilter, {
                                    [classes.showInterviewActive]: formik.values.status?.includes(
                                        JobOfferApplicationStatus.INTERVIEW_PROPOSED ||
                                            JobOfferApplicationStatus.INTERVIEW_ACCEPTED,
                                    ),
                                })}
                                onClick={() =>
                                    handleSelectedFilter(formik, [
                                        JobOfferApplicationStatus.INTERVIEW_PROPOSED,
                                        JobOfferApplicationStatus.INTERVIEW_ACCEPTED,
                                    ])
                                }
                            >
                                <EventIcon />
                            </IconButton>
                            <IconButton
                                className={clsx(classes.showFilter, {
                                    [classes.showRejectedActive]: formik.values.status?.includes(
                                        JobOfferApplicationStatus.ROLE_FILLED,
                                    ),
                                })}
                                onClick={() =>
                                    handleSelectedFilter(formik, [JobOfferApplicationStatus.ROLE_FILLED])
                                }
                            >
                                <CancelIcon />
                            </IconButton>
                        </div>
                    </div>
                    <FormikTextField
                        name="search"
                        placeholder={t('Search')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    className={classes.searchIcon}
                                    onClick={() => {
                                        formik.submitForm();
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        disableLabel
                    />
                </div>
                <List className={classes.list}>
                    {renderCandidatesList()}
                    {renderLoader()}
                </List>
            </div>
        );
    };

    const renderMobileCandidateProfile = () => {
        return (
            <SwipeableDrawer
                transitionDuration={100}
                classes={{ paper: classes.drawerPaper, root: classes.mobileWrapper }}
                anchor="left"
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                }}
                onOpen={() => {
                    setOpenDrawer(true);
                }}
            >
                <div>
                    <div className={classes.profileHeader}>
                        <Typography align="center" className={classes.mobileProfileTitle}>
                            {t('Candidate details')}
                        </Typography>
                        <IconButton
                            onClick={() => {
                                setOpenDrawer(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={classes.candidateProfileWrapper}>
                        <EmployerCandidateProfile
                            application={content[active]}
                            rejectStatuses={rejectStatuses}
                            onStatusUpdate={onDataUpdate}
                        />
                    </div>
                </div>
            </SwipeableDrawer>
        );
    };

    const renderDesktopCandidateProfile = () => {
        return (
            <div className={classes.desktopWrapper}>
                <div className={classes.containerCandidate}>
                    <EmployerCandidateProfile
                        application={content[active]}
                        rejectStatuses={rejectStatuses}
                        onStatusUpdate={onDataUpdate}
                    />
                </div>
            </div>
        );
    };

    const renderProfile = () => {
        if (width === undefined) return null;

        if (isEmpty(content)) {
            return (
                <div className={classes.containerCandidate}>
                    <div className={classes.centerWrapper}>
                        <Alert severity="info">{t('There are no candidates yet')}</Alert>
                    </div>
                </div>
            );
        }

        if (width < EMPLOYER_CANDIDATES_BREAKPOINTS) {
            return renderMobileCandidateProfile();
        }

        return renderDesktopCandidateProfile();
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
            {formik => (
                <form onSubmit={formik.handleSubmit}>
                    <Container className={classes.container}>
                        {renderForm(formik)}
                        {renderProfile()}
                    </Container>
                </form>
            )}
        </Formik>
    );
};

export default EmployerCandidatesPage;
