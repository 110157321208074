import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { createFilterOptions } from '@material-ui/lab';
import ClearbitLogo from '../../models/Clearbit';
import CustomFormikAutocomplete from '../CustomFormikAutocomplete';
import UserProfile from '../../models/UserProfile';
import { findLogoSuggestionsByInput } from '../../services/ClearbitService';
import { useStyles } from './styles';

interface Props {
    name: string;
    label: string;
    disableLabel?: boolean;
    placeholder?: string;
    compareBy?: 'name' | 'domain';
    setLogoValue?: boolean;
    asMediaUrl?: boolean;
    logoFieldName?: string;
}

const ClearbitLogoAutocomplete: FC<Props> = ({
    name,
    label,
    disableLabel,
    placeholder,
    compareBy,
    setLogoValue,
    asMediaUrl,
    logoFieldName,
}) => {
    const classes = useStyles();
    const { setFieldValue } = useFormikContext<UserProfile>();

    const [options, setOptions] = useState<ClearbitLogo[]>([]);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: ClearbitLogo) => option.domain,
    });

    const handleChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        value: unknown,
        field: string,
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
    ) => {
        setFieldValue(field, value as string);
        if (event?.type === 'click' && setLogoValue && logoFieldName && options[0]?.logo) {
            const logoUrl = options.find(it => it.name === (value as string))?.logo;
            if (asMediaUrl) {
                setFieldValue(logoFieldName, { url: logoUrl });
            } else {
                setFieldValue(logoFieldName, { logoUrl });
            }
        }
        if (event?.type !== 'click' && !isEmpty(value)) {
            findLogoSuggestionsByInput(value as string).then(response => setOptions(response));
        }
    };

    return (
        <CustomFormikAutocomplete
            name={name}
            placeholder={placeholder || label}
            label={label}
            disableLabel={disableLabel}
            options={options as ClearbitLogo[]}
            getOptionLabel={(option: ClearbitLogo | string) => {
                if (compareBy && compareBy === 'domain') {
                    return typeof option === 'string' ? option : option.domain;
                }
                return typeof option === 'string' ? option : option.name;
            }}
            getOptionSelected={(option: ClearbitLogo, value: ClearbitLogo) => option.name === value.name}
            onInputChange={(event: React.ChangeEvent<{ value: unknown }>, value: unknown) =>
                handleChange(event, value, name, setFieldValue)
            }
            onChange={(e: unknown, option: ClearbitLogo) => setFieldValue(name, option.name)}
            displayIcon
            freeSolo
            // @ts-ignore
            renderOption={(option: ClearbitLogo) => (
                <div className={classes.option}>
                    <img src={option.logo} alt={option.name} className={classes.optionImage} />
                    <div className={classes.optionName}>{option.name}</div>
                </div>
            )}
            filterOptions={filterOptions}
        />
    );
};

export default ClearbitLogoAutocomplete;
