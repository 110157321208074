import { makeStyles } from '@material-ui/core/styles';

import { HEADER_BREAKPOINT, LOGO_FONT } from '../../../config/constants';

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#F7FBFF',
        position: 'relative',
        padding: '4px 8px 4px 20px',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            display: 'none',
        },
    },
    containerAuthorized: {
        backgroundColor: '#F7FBFF',
        position: 'fixed',
        padding: '0 10px',
        borderBottom: '1px solid #E3F1FF',
        marginBottom: '1rem',
        width: '100vw',
        zIndex: 1199,
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            display: 'none',
            backgroundColor: '#F7FBFF',
            position: 'relative',
            padding: '4px 8px 4px 20px',
        },
    },
    drawerPaper: {
        width: '100vw',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            minWidth: '20rem',
        },
    },
    logoFont: {
        fontFamily: LOGO_FONT,
        fontSize: '2rem',
        textDecoration: 'none',
        fontWeight: 700,
        color: '#15416D',
    },
    mobileLogo: {
        marginTop: 5,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    titleWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
    },
    noTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        minHeight: 56,
    },
    noTitleWrapperAuthorized: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 56,
    },
    noTitleWrapperAuthorizedNoBack: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        minHeight: 56,
    },
    userAvatar: {
        marginRight: '1rem',
        borderRadius: '50%',
        width: 33,
        height: 33,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#E3F1FF',
        fontWeight: 700,
        lineHeight: 1,
    },
    title: {
        fontSize: 17,
        lineHeight: '24px',
        color: '#1F3874',
    },
    linkedLabel: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    headerDrawer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    linkedButton: {
        width: 235,
    },
    buttonWrapper: {
        padding: '24px 40px',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            padding: '24px 72px',
        },
    },
    button: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
    },
    userActions: {
        marginLeft: '2rem',
    },
    collapse: {
        margin: 0,
    },
    collapseContainer: {
        position: 'absolute',
        top: 56,
        left: 0,
        backgroundColor: '#F7FBFF',
        width: '100vw',
        paddingBottom: 24,
    },
    link: {
        fontWeight: 500,
        color: '#15416D',
        fontSize: 18,
        lineHeight: '16px',
        padding: '28px 72px',
    },
    activeLink: {
        color: '#007FFC',
        '&:after': {
            width: '100%',
            left: 0,
            backgroundColor: '#007FFC',
        },
    },
}));
