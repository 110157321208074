import { makeStyles } from '@material-ui/core/styles';

const BORDER_RADIUS_WIDTH = '8px';

export const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '33rem',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '33rem',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
    },
    flexFields: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '1rem',
    },
    flexFieldsRoot: {
        borderRadius: BORDER_RADIUS_WIDTH,
        width: 'calc(15rem - 10px)',
        maxWidth: 'calc(35vw - 10px)',
        margin: '0 10px',
        '& > fieldset': {
            border: 'none',
        },
    },
    headerButton: {
        position: 'relative',
        color: '#616161',
        fontWeight: 400,
        fontSize: '13px',
        letterSpacing: '0.3px',
        padding: '10px',
        paddingRight: 0,
        borderRadius: 0,
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '1.75rem',
        '& a': {
            fontWeight: 700,
            color: 'grey',
            marginTop: '1.75rem',
        },
    },
    submitButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
        width: 151,
    },
    termsOfUseText: {
        fontSize: '13px',
        lineHeight: '1.3rem',
        fontWeight: 400,
        letterSpacing: '0.57px',
        color: '#616161',
        '& a': {
            textDecoration: 'none',
            color: '#007FFC',
        },
    },
}));
