import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    moreButton: {
        color: '#007FFC',
        fontSize: '1rem',
        fontWeight: 500,
        textTransform: 'none',
        justifySelf: 'flex-end',
        borderRadius: 8,
    },
});
