import React, { FC, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { InputLabelProps as InputLabelPropsTypes } from '@material-ui/core/InputLabel/InputLabel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';

import TeamMemberAvatar from '../../../../components/TeamMemberAvatar';
import { getCurrentEmployerCompanyTeamMembers } from '../../../../services/UserService';
import { TeamMember } from '../../../../models/Company';
import { useStyles } from './styles';
import AddTeamMemberDialog from '../../../../components/AddTeamMemberDialog';
import { getEmployerCompanyTeamMembers } from '../../../../services/AdminService';

interface CompanyTeamFormikTagsProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
    InputLabelProps?: InputLabelPropsTypes;
    id?: string | number;
    dontFind?: boolean;
}

const CompanyTeamFormikTags: FC<CompanyTeamFormikTagsProps> = ({
    id,
    name,
    label,
    disableLabel,
    InputLabelProps,
    dontFind,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { value } = useField<TeamMember[]>(name)[1];
    const { setValue } = useField(name)[2];

    const getCompanyTeamMember = id ? getEmployerCompanyTeamMembers : getCurrentEmployerCompanyTeamMembers;

    useEffect(() => {
        if (!dontFind) {
            getCompanyTeamMember(id as string | number)
                .then(response => {
                    setValue(response);
                })
                .catch(() =>
                    enqueueSnackbar(t('Unable to fetch available team members, try again later'), {
                        variant: 'error',
                    }),
                );
        }
        // https://github.com/formium/formik/issues/2268
        // eslint-disable-next-line
    }, [enqueueSnackbar, t]);

    const addNewTeamMember = (teamMember: TeamMember): void => {
        setValue([...value, teamMember]);
    };

    const deleteMemberByIndex = (index: number) => {
        setValue(value.filter((member, memberIndex) => memberIndex !== index));
    };

    const renderDeleteTeamMember = (index: number) => (
        <IconButton size="small" className={classes.iconButton} onClick={() => deleteMemberByIndex(index)}>
            <DeleteOutlineIcon className={classes.cancelIcon} />
        </IconButton>
    );

    const renderMemberCard = ({ name, position, avatar }: TeamMember, index: number) => {
        return (
            <div className={classes.memberCardWrapper} key={`${index}${name}${position}`}>
                <TeamMemberAvatar name={name} url={avatar?.url} size={28} fontSize={12} />
                <div className={classes.cardText}>
                    <Typography className={classes.cardName}>{name}</Typography>
                    <Typography className={classes.cardPosition}>{position}</Typography>
                </div>
                {renderDeleteTeamMember(index)}
            </div>
        );
    };

    const renderTeamMembers = () => value.map((member, index) => renderMemberCard(member, index));

    return (
        <div>
            {!disableLabel && (
                <InputLabel htmlFor={name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <div>
                <div className={classes.membersWrapper}>{renderTeamMembers()}</div>
                <AddTeamMemberDialog onSubmitTeamMember={addNewTeamMember} />
            </div>
        </div>
    );
};

export default CompanyTeamFormikTags;
