import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { getBackendErrorMessage, getClickableLink, kFormatter } from '../../config/utils';
import { initialValues, ContractPropositionDialogValues, validationSchema } from './config';
import { ContractProposal } from '../../models/JobOfferApplication';
import { acceptOffer } from '../../services/JobOfferApplicationsService';
import CandidatePropositionForm from './CandidatePropositionForm';
import CloseDialogElement from '../CloseDialogElement';

interface Props {
    open: boolean;
    onClose: () => void;
    onStatusUpdated?: () => void;
    applicationId: number;
    contractProposal: ContractProposal;
}

const ContractPropositionDialog: FC<Props> = ({
    open,
    onClose,
    onStatusUpdated,
    applicationId,
    contractProposal,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmitForm = (
        dialogFormData: ContractPropositionDialogValues,
        { setSubmitting }: FormikHelpers<ContractPropositionDialogValues>,
    ) => {
        acceptOffer(applicationId, {
            signedContract: dialogFormData.contractFile,
            message: dialogFormData.message,
        })
            .then(() => {
                if (onStatusUpdated) {
                    onStatusUpdated();
                }
                onClose();
                enqueueSnackbar('Successfully accepted job offer', { variant: 'success' });
            })
            .catch(error => {
                enqueueSnackbar(`Error accepting job offer: ${getBackendErrorMessage(error)}`, {
                    variant: 'error',
                });
                setSubmitting(false);
            });
    };

    return (
        <>
            <Dialog onClose={onClose} open={open} classes={{ paperWidthSm: classes.dialog }}>
                <CloseDialogElement onClick={onClose} />

                <div className={classes.center}>
                    <Typography className={classes.title}>{t('Proposed job offer')}</Typography>
                    <Typography className={classes.subTitle}>
                        {t(
                            'Congratulations! You have been offered a contract for this position! ' +
                                'Please download the contract attached below, sign and re-upload to accept the offer.',
                        )}
                    </Typography>
                </div>

                <div className={classes.infoContainer}>
                    <p>
                        <span className={classes.bold}>{t('Contract')}: </span>
                        <a
                            href={getClickableLink(contractProposal.contractFile.url)}
                            download="contract"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Download and sign
                        </a>
                    </p>
                    <p>
                        <span className={classes.bold}>{t('Salary')}: </span>
                        {contractProposal.currencySign}
                        {kFormatter(contractProposal.salary)} per {contractProposal.billingCycle}
                    </p>
                    {!!contractProposal.employerMessage && (
                        <p>
                            <span className={classes.bold}>{t('Message')}: </span>
                            {contractProposal.employerMessage}
                        </p>
                    )}
                </div>

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}
                >
                    <CandidatePropositionForm
                        applicationId={applicationId}
                        onRejected={() => {
                            if (onStatusUpdated) {
                                onStatusUpdated();
                            }
                            onClose();
                        }}
                    />
                </Formik>
            </Dialog>
        </>
    );
};

export default ContractPropositionDialog;
