import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '33rem',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '33rem',
        },
    },
    containerSubmitted: {
        maxWidth: '33rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 600,
        margin: '1rem auto',
    },
    bottomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '1.25rem',
    },
    submitButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
        width: 201,
    },
    signUpButtonsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, auto)',
        gridGap: 20,
        justifyContent: 'center',
        marginTop: '1.75rem',
    },
    colorGrey: {
        color: 'grey',
    },
    link: {
        fontWeight: 700,
    },
    signUpButtonsDivider: {
        width: 2,
        height: 20,
        backgroundColor: 'grey',
    },
    successWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkIcon: {
        fontSize: '2rem',
    },
    okTitle: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: '4rem',
        letterSpacing: '0.82px',
        color: '#15416D',
        marginLeft: '0.5rem',
    },
    thanksWrapper: {
        marginBottom: '3rem',
    },
    thanksText: {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0.58px',
        color: '#16133E',
    },
}));
