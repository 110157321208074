import { makeStyles, Theme } from '@material-ui/core/styles';
import { LANDING_BREAKPOINT_FIRST } from '../../../config/constants';

const POST_JOB_BREAKPOINT = 900;
const SAVE_TIME_BREAKPOINT = 800;

export const useStyles = makeStyles((theme: Theme) => ({
    container: { color: 'black', fontWeight: 700, fontSize: '2rem', marginBottom: '3rem' },
    title: {
        fontSize: 30,
        letterSpacing: '1.61px',
        fontWeight: 500,
        color: 'rgb(21, 65, 109);',
        textAlign: 'center',
        marginBottom: '2.5rem',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            fontSize: 35,
            marginBottom: '3rem',
        },
    },
    faqTitle: {
        color: 'rgb(21, 65, 109);',
        fontSize: '1.25rem',
        fontWeight: 500,
        letterSpacing: '0.17px',
        marginBottom: '0.5rem',
    },
    faqDescription: {
        color: 'rgb(21, 65, 109);',
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: '0.17px',
        marginBottom: '0.5rem',
    },
    firstPanel: {
        padding: '1.75rem 0 3.75rem',
        display: 'flex',
        flexDirection: 'column-reverse',

        [theme.breakpoints.up(POST_JOB_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridGap: '6.5rem',
            alignItems: 'center',
        },
    },
    firstPanelImage: {
        width: 500,
        maxWidth: '100%',
        objectFit: 'contain',
        mixBlendMode: 'multiply',
    },
    panelWrapper: {
        [theme.breakpoints.up(SAVE_TIME_BREAKPOINT)]: {
            marginLeft: '2rem',
        },
    },
    description: {
        fontSize: '14px',
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing: '0.57px',
        color: '#616161',
        marginBottom: '1rem',
        [theme.breakpoints.up(POST_JOB_BREAKPOINT)]: {
            fontSize: '1rem',
        },
    },
    accordion: {
        background: 'transparent',
        boxShadow: 'none',
        border: 'none',
        '&:before': {
            display: 'none',
        },
    },
}));
