import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    iconOutlined: {
        right: 0,
        width: 45,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 'calc(50% - 22.5px)',
        backgroundColor: 'rgba(241,244,255,0.5)',
    },
    rootTextField: {
        fontSize: '0.8125rem',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        height: 45,
        '&:focus': {
            backgroundColor: '#FFFFFF',
        },
    },
    labelRoot: {
        marginBottom: 4,
    },
    error: {
        '& fieldset': {
            borderColor: theme.palette.error.main,
        },
    },
}));
