import * as yup from 'yup';
import i18n from 'i18next';

export interface InterviewPropositionDialogValues {
    selectedDate: string;
}

export const initialValues: InterviewPropositionDialogValues = {
    selectedDate: '0',
};

export const validationSchema = yup.object().shape({
    selectedDate: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('An interview date has to be selected')),
});
