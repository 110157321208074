import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {} from 'lodash';

import { useStyles } from './styles';
import { kFormatter } from '../../config/utils';

interface Props {
    currencySign: string;
    salaryMin: number;
    salaryMax: number;
    billingCycle: string;
}

const JobOfferCardSalary: FC<Props> = ({ currencySign, salaryMin, salaryMax, billingCycle }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.salaryWrapper}>
            <Typography className={classes.salary}>{`${currencySign}${kFormatter(
                salaryMin,
            )} - ${currencySign}${kFormatter(salaryMax)}`}</Typography>
            <Typography className={classes.salaryUnit}>{`${t(`per ${billingCycle}`)} `}</Typography>
        </div>
    );
};

export default JobOfferCardSalary;
