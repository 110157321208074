import { makeStyles } from '@material-ui/core/styles';

import { EMPLOYER_CANDIDATES_BREAKPOINTS } from '../../../config/constants';

export const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '0.75rem',
        marginBottom: '2.25rem',
        alignItems: 'flex-start',
        [theme.breakpoints.up(EMPLOYER_CANDIDATES_BREAKPOINTS)]: {
            gridTemplateColumns: '17rem 1fr',
        },
    },
    centerWrapper: {
        flex: 1,
        marginBottom: '3rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    containerCandidate: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
        padding: '16px 32px 16px 52px',
        boxShadow: '0 2px 9px 0 rgba(0,0,0,0.02)',
    },
    wrapper: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
        boxShadow: '0 2px 9px 0 rgba(0,0,0,0.02)',
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    headerWrapper: {
        padding: '0 22px',
    },
    candidatesHeader: {
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '2rem',
    },
    searchIcon: {
        cursor: 'pointer',
    },
    filters: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 12px',
    },
    switchWrapper: {
        display: 'flex',
    },
    showWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    showFilter: {
        color: 'grey',
        padding: 5,
        height: 25,

        '& svg': {
            width: 15,
            height: 15,
        },
    },
    showAcceptedActive: {
        color: '#26A425',
    },
    showInterviewActive: {
        color: '#f1a501',
    },
    showRejectedActive: {
        color: '#EC4E4E',
    },
    list: {
        overflowY: 'auto',
        maxHeight: '40rem',
        height: '100%',
        padding: '8px 14px 0',
        margin: '8px 8px',
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar': {
            width: 8,
            backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#9e9e9e',
        },
    },
    listItem: {
        padding: '0.5rem',
    },
    listItemActive: {
        border: '1px solid #007FFC',
        borderRadius: 2,
    },
    remotely: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        marginLeft: '0.75rem',
        color: '#000000',
    },
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    primaryListItem: {
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '19px',
        color: '#000000',
        width: 130,
    },
    secondaryListItem: {
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.43px',
        color: '#000000',
        width: 130,
    },
    appInfo: {
        fontSize: '0.625rem',
        lineHeight: '13px',
        letterSpacing: '0.08px',
        color: '#616161',
    },
    appJobTile: {
        fontWeight: 700,
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0.75rem',
    },
    drawerPaper: {
        minWidth: '100vw',
    },
    candidateProfileWrapper: {
        padding: 16,
    },
    profileHeader: {
        padding: '8px 16px',
        backgroundColor: '#F7FBFF',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
    },
    mobileProfileTitle: {
        color: '#1F3874',
        fontSize: 17,
    },
    mobileWrapper: {
        [theme.breakpoints.up(EMPLOYER_CANDIDATES_BREAKPOINTS)]: {
            display: 'none',
        },
    },
    desktopWrapper: {
        [theme.breakpoints.down(EMPLOYER_CANDIDATES_BREAKPOINTS)]: {
            display: 'none',
        },
    },
}));
