import { FC } from 'react';

import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { useStyles } from './styles';

const CustomTextField: FC<OutlinedTextFieldProps> = ({ InputProps, ...otherProps }) => {
    const classes = useStyles();

    return (
        <TextField
            InputProps={{
                classes: {
                    input: classes.input,
                    root: classes.root,
                    focused: classes.focused,
                    // @ts-ignore
                    notchedOutline: classes.notchedOutline,
                },
                ...InputProps,
            }}
            margin="none"
            // @ts-ignore
            variant="outlined"
            classes={{ root: classes.rootTextField }}
            {...otherProps}
        />
    );
};

export default CustomTextField;
