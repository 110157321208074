import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';

import StarIcon from '../../assets/landing/starIcon.svg';
import StarIconInverted from '../../assets/landing/starIconInverted.svg';

import { useStyles } from './styles';
import { JobOffer } from '../../models/JobOffer';
import { UserRoles } from '../../models/enums/UserRoles';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import ReactiveDialog from '../ReactiveDialog';
import JobOfferCardInfo from '../../parts/JobOfferCardInfo';
import { JobOfferButtonState } from '../../models/enums/JobOfferButtonState';
import { bookmarkJobOffer } from '../../services/JobOfferService';
import JobOfferCardSalary from '../../parts/JobOfferCardSalary';
import { featureJobOffer } from '../../services/AdminService';
import CompanyLogoImage from '../CompanyLogoImage';
import { getCoverByCompanyId } from './config';

interface Props extends JobOffer {
    removeCallback?: () => void;
    jobDetailsPage?: boolean;
}

const JobOfferCard: FC<Props> = ({
    billingCycle,
    companyName,
    companyId,
    currencySign,
    editable,
    archived,
    id,
    location,
    logoUrl,
    backgroundUrl,
    remotely,
    removeCallback,
    salaryMax,
    salaryMin,
    jobOfferButtonState,
    bookmark,
    featured: feature,
    tags,
    title,
    jobDetailsPage,
}) => {
    const classes = useStyles({
        backgroundUrl: backgroundUrl || getCoverByCompanyId(companyId),
    });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const [bookmarked, setBookmarked] = useState(bookmark);
    const [featured, setFeatured] = useState(feature);

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const handleSaveClicked = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            await bookmarkJobOffer(id);
            setBookmarked(!bookmarked);
        } catch (error) {
            enqueueSnackbar(t('Bookmarking failed, try again later'), { variant: 'error' });
        }
    };

    const handleFeatureClicked = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            await featureJobOffer(id);
            setFeatured(!featured);
        } catch (error) {
            enqueueSnackbar(
                t('Featuring job offer failed, make sure you have less than 4 job offers featured.'),
                { variant: 'error' },
            );
        }
    };

    const renderVerticalDivider = () => <div className={classes.divider} />;

    const renderActions = () => {
        if (!!role && [UserRoles.SUPPORT, UserRoles.ADMIN].includes(role) && editable) {
            return (
                <div className={classes.actionsWrapper}>
                    {role === UserRoles.ADMIN && !archived && (
                        <Button
                            color="primary"
                            className={clsx({
                                [classes.saveButton]: true,
                                [classes.saveButtonBookmarked]: featured,
                            })}
                            startIcon={
                                !featured ? (
                                    <img src={StarIcon} width="16" height="15" alt="Feature" />
                                ) : (
                                    <img src={StarIconInverted} width="16" height="15" alt="Featured" />
                                )
                            }
                            onClick={handleFeatureClicked}
                        >
                            {!featured ? t('Feature') : t('Featured')}
                        </Button>
                    )}
                    <Button
                        onClick={e => {
                            e.preventDefault();
                            history.push(`/edit-job/${id}`);
                        }}
                        className={classes.editButton}
                    >
                        {t('Edit')}
                    </Button>
                    <ReactiveDialog
                        isAdmin
                        title={title}
                        id={id}
                        archived={archived}
                        removeCallback={archived ? removeCallback : undefined}
                    />
                </div>
            );
        }
        if (role === UserRoles.EMPLOYER && editable) {
            return (
                <div className={classes.actionsWrapper}>
                    <Button
                        onClick={e => {
                            e.preventDefault();
                            history.push(`/employer/edit-jobs/${id}`);
                        }}
                        className={classes.editButton}
                    >
                        {t('Edit')}
                    </Button>
                    <ReactiveDialog
                        title={title}
                        id={id}
                        archived={archived}
                        removeCallback={archived ? removeCallback : undefined}
                    />
                </div>
            );
        }
        if (role === UserRoles.CANDIDATE && editable) {
            return (
                <div className={classes.actionsWrapper}>
                    <Button
                        color="primary"
                        className={clsx({
                            [classes.saveButton]: true,
                            [classes.saveButtonBookmarked]: bookmarked,
                        })}
                        startIcon={
                            !bookmarked ? (
                                <img src={StarIcon} width="16" height="15" alt="Save" />
                            ) : (
                                <img src={StarIconInverted} width="16" height="15" alt="Saved" />
                            )
                        }
                        onClick={handleSaveClicked}
                    >
                        {!bookmarked ? t('Save') : t('Saved')}
                    </Button>
                    {!!jobOfferButtonState && jobOfferButtonState === JobOfferButtonState.APPLY && (
                        <Button
                            component={RouterLink}
                            to={`/jobs/${id}`}
                            variant="outlined"
                            color="primary"
                            className={classes.applyButton}
                            disabled={jobOfferButtonState !== JobOfferButtonState.APPLY}
                        >
                            {t('Apply')}
                        </Button>
                    )}
                    {!!jobOfferButtonState &&
                        jobOfferButtonState === JobOfferButtonState.MISSING_PROFILE_INFORMATION && (
                            <Button
                                component={RouterLink}
                                to={`/jobs/${id}`}
                                variant="outlined"
                                color="primary"
                                className={classes.applyButton}
                                disabled={
                                    jobOfferButtonState !== JobOfferButtonState.MISSING_PROFILE_INFORMATION
                                }
                            >
                                {t('Apply')}
                            </Button>
                        )}
                </div>
            );
        }
        return (
            <div className={classes.actionsWrapper}>
                <Button
                    component={RouterLink}
                    to={`/jobs/${id}`}
                    variant="outlined"
                    color="primary"
                    className={classes.applyButton}
                >
                    {t('Apply')}
                </Button>
            </div>
        );
    };

    const renderCompanyBackground = () => {
        return (
            <div className={classes.companyBackground}>
                <CompanyLogoImage
                    alt={companyName}
                    src={logoUrl}
                    classNameWrapper={classes.companyLogoWrapper}
                />
            </div>
        );
    };

    const renderContent = () => (
        <>
            <div className={classes.container}>
                {renderCompanyBackground()}
                <div
                    className={clsx(classes.wrapper, {
                        [classes.paperWrapper]: jobDetailsPage,
                        [classes.wrapperEditable]: editable,
                    })}
                >
                    <JobOfferCardInfo
                        companyName={companyName}
                        remotely={remotely}
                        title={title}
                        tags={tags}
                        location={location}
                    />
                    <JobOfferCardSalary
                        currencySign={currencySign}
                        salaryMin={salaryMin}
                        salaryMax={salaryMax}
                        billingCycle={billingCycle}
                    />
                    {editable && renderVerticalDivider()}
                    {editable && renderActions()}
                </div>
            </div>
        </>
    );

    if (role === UserRoles.SUPPORT) {
        return (
            <RouterLink to={`/edit-job/${id}`} className={classes.link}>
                {renderContent()}
            </RouterLink>
        );
    }
    if (role === UserRoles.EMPLOYER && editable && archived) {
        return <div className={classes.link}>{renderContent()}</div>;
    }
    return (
        <RouterLink to={`/jobs/${id}`} className={classes.link}>
            {renderContent()}
        </RouterLink>
    );
};

export default JobOfferCard;
