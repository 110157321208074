import { FC, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import ContactImage from '../../../assets/contact.svg';

const ContactPage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={classes.container}>
            <div className={classes.firstPanel}>
                <div className={classes.panelWrapper}>
                    <Typography gutterBottom className={classes.title}>
                        {t('Contact us')}
                    </Typography>
                    <Typography className={classes.description}>Email: hello@onboard.careers</Typography>
                    <Typography className={classes.description}>Phone: +44 7549524776</Typography>
                </div>
                <img src={ContactImage} className={classes.firstPanelImage} alt="Contact" />
            </div>
        </Container>
    );
};

export default ContactPage;
