import React, { FC, useState } from 'react';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';
import { JobOfferApplication } from '../../../models/JobOffer';
import { JobOfferApplicationEvent, JobOfferApplicationStatus } from '../../../models/JobOfferApplication';
import { CreateMeeting } from '../../../models/Meeting';
import JobOfferApplicationEventsHistory from '../../../components/JobOfferApplicationEventsHistory';
import { hasProposalsAvailable } from '../../../config/utils';

interface Props {
    application?: JobOfferApplication;
    meeting?: CreateMeeting;
    handleShowDialogClicked?: () => void;
    showEventsHistory?: boolean;
    jobApplicationEvents?: JobOfferApplicationEvent[] | null;
}

const JobOfferDetailsStatus: FC<Props> = ({
    application,
    meeting,
    handleShowDialogClicked,
    showEventsHistory,
    jobApplicationEvents,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [meetingAcceptedDate] = useState(meeting?.dateProposals.find(date => date.accepted === true));

    const renderRejected = (contract: boolean) => {
        return (
            <div className={classes.paperContainer}>
                <div className={classes.flexCenter}>
                    <div className={classes.status}>{t('Status')}:</div>
                    {contract ? (
                        <Typography className={classes.statusText}>
                            {t('You rejected the proposed contract')}
                        </Typography>
                    ) : (
                        <Typography className={classes.statusText}>
                            {t('Unfortunately, your application has been rejected.')}
                        </Typography>
                    )}
                </div>
                {!contract && !!application?.status && (
                    <div className={classes.flexCenter}>
                        <div className={classes.status}>{t('Rejection reason')}:</div>
                        <Typography className={classes.statusText}>
                            {t(application?.status?.name) || t('No reason given')}
                        </Typography>
                    </div>
                )}
                {!!application?.rejectionMessage && (
                    <div className={classes.flex}>
                        <div className={classes.status}>{t('Message')}:</div>
                        <Typography className={classes.statusText}>
                            {application?.rejectionMessage || t('No message')}
                        </Typography>
                    </div>
                )}
                {!contract && !!application?.feedbackMessage && (
                    <div className={classes.flex}>
                        <div className={classes.status}>{t('Feedback')}:</div>
                        <Typography className={classes.statusText}>
                            {application?.feedbackMessage || t('No feedback')}
                        </Typography>
                    </div>
                )}
            </div>
        );
    };

    const renderInterviewProposed = () => {
        return (
            <div className={classes.paperContainer}>
                <div className={classes.flexCenter}>
                    <div className={classes.status}>{t('Status')}:</div>
                    <Typography className={classes.statusText}>
                        {t(
                            'Interview dates proposed, please select an interview date and time that suits you',
                        )}
                    </Typography>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.showDialogButton}
                    onClick={handleShowDialogClicked}
                >
                    {t('Show proposed dates')}
                </Button>
            </div>
        );
    };

    const renderInterviewAccepted = () => {
        return (
            <div className={classes.paperContainer}>
                <div className={classes.flexCenter}>
                    <div className={classes.status}>{t('Status')}:</div>
                    <Typography className={classes.statusText}>{t('Interview scheduled')}</Typography>
                </div>
                {!!meeting && !!meetingAcceptedDate && (
                    <>
                        <div className={classes.flexCenter}>
                            <div className={classes.status}>{t('Date')}:</div>
                            <Typography className={classes.statusText}>
                                {dayjs(meetingAcceptedDate.date).format('dddd, D MMMM YYYY')}
                            </Typography>
                        </div>
                        <div className={classes.flexCenter}>
                            <div className={classes.status}>{t('Start time')}:</div>
                            <Typography className={classes.statusText}>
                                {meetingAcceptedDate.startTime}
                            </Typography>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderContractAccepted = () => {
        return (
            <div className={classes.paperContainer}>
                <div className={classes.flexCenter}>
                    <div className={classes.status}>{t('Status')}:</div>
                    <Typography className={classes.statusText}>
                        {t(
                            'Contract accepted! Now all you have to do is wait for the employer to get in touch with you about the next steps.',
                        )}
                    </Typography>
                </div>
            </div>
        );
    };

    const renderContractProposed = () => {
        return (
            <div className={classes.paperContainer}>
                <div className={classes.flexCenter}>
                    <div className={classes.status}>{t('Status')}:</div>
                    <Typography className={classes.statusText}>{t('Contract proposed')}</Typography>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.showDialogButton}
                    onClick={handleShowDialogClicked}
                >
                    {t('Show proposed contract')}
                </Button>
            </div>
        );
    };

    const renderStatus = () => {
        switch (application?.jobOfferApplicationStatus) {
            case JobOfferApplicationStatus.ROLE_FILLED:
                return renderRejected(false);
            case JobOfferApplicationStatus.CONTRACT_DECLINED:
                return renderRejected(true);
            case JobOfferApplicationStatus.INTERVIEW_PROPOSED:
                if (hasProposalsAvailable(meeting?.dateProposals)) {
                    return renderInterviewProposed();
                }
                return '';
            case JobOfferApplicationStatus.INTERVIEW_ACCEPTED:
                return renderInterviewAccepted();
            case JobOfferApplicationStatus.CONTRACT_ACCEPTED:
                return renderContractAccepted();
            case JobOfferApplicationStatus.CONTRACT_PROPOSED:
                return renderContractProposed();
            default:
                return '';
        }
    };

    return (
        <div className={classes.paperWrapper}>
            <div className={classes.paperTitle}>{t('APPLICATION')}</div>
            {renderStatus()}
            {showEventsHistory && !!jobApplicationEvents && !isEmpty(jobApplicationEvents) && (
                <div className={classes.historyContainer}>
                    <JobOfferApplicationEventsHistory
                        jobOfferApplicationEvents={jobApplicationEvents}
                        justText
                    />
                </div>
            )}
        </div>
    );
};

export default JobOfferDetailsStatus;
