import React, { FC, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { isEqual, isEmpty } from 'lodash';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';

import NavigationPrompt from 'react-router-navigation-prompt';
import CustomFormikTextField from '../../components/CustomFormikTextField';
import { useStyles } from './styles';
import { getNameInitials } from '../../config/utils';
import UserProfile from '../../models/UserProfile';
import FormikDocumentUpload from '../../components/formik/FormikDocumentUpload';
import FormikAvatarUpload from '../../components/formik/FormikAvatarUpload';
import CustomFormikSwitch from '../../components/CustomFormikSwitch';
import CandidateProfileFormExperience from './CandidateProfileFormExperience';
import CandidateProfileFormEducation from './CandidateProfileFormEducation';
import GoogleLocationFormikAutocomplete from '../../components/GoogleLocationFormikAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';

interface Props {
    currentlyEmployed: boolean;
    currentProfile: UserProfile | null;
    setCurrentlyEmployed: Dispatch<SetStateAction<boolean>>;
    additionalElement?: JSX.Element;
    jobId?: string;
    handleSubmitAndRedirect?: (
        values: UserProfile,
        { setSubmitting, setErrors }: FormikHelpers<UserProfile>,
        jobId: string,
    ) => void;
}

const CandidateProfileForm: FC<Props> = ({
    currentlyEmployed,
    currentProfile,
    setCurrentlyEmployed,
    additionalElement,
    jobId,
    handleSubmitAndRedirect,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        initialValues,
        setSubmitting,
        setErrors,
    } = useFormikContext<UserProfile>();
    const [alert, setAlert] = useState<boolean>(false);
    const [alertJobOffers, setAlertJobOffers] = useState<boolean>(false);

    if (!alert) {
        if (!isEqual(initialValues, values)) {
            setAlert(true);
        }
    }

    useEffect(() => {
        if (!isEmpty(values)) {
            if (
                (isEmpty(values.linkedinUrl) && isEmpty(values.cvFile)) ||
                isEmpty(values.contactEmail) ||
                isEmpty(values.educations) ||
                isEmpty(values.jobExperiences)
            ) {
                setAlertJobOffers(true);
            } else {
                setAlertJobOffers(false);
            }
        }
    }, [values]);

    const getInitials = (): string => {
        if (!currentProfile?.firstName && !currentProfile?.lastName) {
            return 'N/A';
        }
        return getNameInitials(`${currentProfile?.firstName || ''} ${currentProfile?.lastName || ''}`);
    };

    const handleSaveAndGoBack = () => {
        if (!!handleSubmitAndRedirect && !!jobId) {
            // @ts-ignore
            handleSubmitAndRedirect(values, { setSubmitting, setErrors }, jobId);
        }
    };

    const getMissingFieldsMessage = () => {
        return (
            <>
                {t('To apply for jobs please add the following info')}:
                <ul>
                    {isEmpty(values.linkedinUrl) && isEmpty(values.cvFile) && <li>LinkedIn url / CV</li>}
                    {isEmpty(values.contactEmail) && <li>Email</li>}
                    {(isEmpty(values.jobExperiences) || values.jobExperiences?.length === 0) && (
                        <li>Work experience</li>
                    )}
                    {(isEmpty(values.educations) || values.educations?.length === 0) && <li>Education</li>}
                </ul>
            </>
        );
    };

    const renderProfileDetails = () => (
        <div className={classes.profileDetails}>
            <CustomFormikTextField name="firstName" placeholder={t('First name')} label={t('First name')} />
            <CustomFormikTextField name="lastName" placeholder={t('Last name')} label={t('Last name')} />

            <GoogleLocationFormikAutocomplete
                name="location"
                placeholder={t('Location')}
                label={t('Location')}
            />

            <CustomFormikTextField
                name="linkedinUrl"
                placeholder={t('LinkedIn URL')}
                label={t('LinkedIn URL')}
            />

            <CustomFormikTextField
                name="contactPhoneNumber"
                placeholder={t('Phone number')}
                label={t('Phone number')}
            />
            <CustomFormikTextField
                name="contactEmail"
                placeholder={t('Contact email')}
                label={t('Contact email')}
            />
            <FormikDocumentUpload name="cvFile" label={t('Upload CV')} />
            <div className={classes.switchWrapper}>
                <InputLabel>{t('Are you currently employed?')}</InputLabel>
                <div className={classes.currentlyEmployed}>
                    <CustomFormikSwitch
                        disableLabel
                        name="currentlyEmployed"
                        onClick={() => setCurrentlyEmployed(!currentlyEmployed)}
                    />
                    <span className={classes.currentlyEmployedText}>
                        {currentlyEmployed ? t('Yes') : t('No')}
                    </span>
                </div>
            </div>
            {currentlyEmployed && (
                <>
                    <CustomFormikTextField
                        name="currentPosition"
                        placeholder={t('Current position')}
                        label={t('Current position')}
                    />
                    <div />
                </>
            )}

            <CandidateProfileFormExperience />
            <CandidateProfileFormEducation />
        </div>
    );

    return (
        <Container className={classes.container}>
            {alertJobOffers && (
                <Alert severity="info" className={classes.warning}>
                    {getMissingFieldsMessage()}
                </Alert>
            )}
            <NavigationPrompt when={!isEqual(initialValues, values)}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmDialog
                        open={!isEqual(initialValues, values)}
                        confirmLabel={t('Yes, leave')}
                        cancelLabel={t('No, stay')}
                        title={t('You have unsaved changes, are you sure you want to leave?')}
                        onClickConfirm={onConfirm}
                        onClickCancel={onCancel}
                    />
                )}
            </NavigationPrompt>
            <form onSubmit={handleSubmit}>
                <div className={classes.form}>
                    {renderProfileDetails()}
                    <div className={classes.avatarUpload}>
                        <FormikAvatarUpload
                            name="avatar"
                            label={t('Profile Photo')}
                            initials={getInitials()}
                        />
                        {/* Please check if the file is being removed from S3 */}
                        <Button
                            color="primary"
                            className={classes.removeAvatarButton}
                            onClick={() => setFieldValue('avatar', null)}
                        >
                            {t('Remove photo')}
                        </Button>
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    {!!jobId && (
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.submitGoBackButton}
                            disabled={isSubmitting}
                            onClick={handleSaveAndGoBack}
                        >
                            {t('Save and return to job')}
                        </Button>
                    )}
                    <Button
                        type="submit"
                        color={!jobId ? 'primary' : 'default'}
                        variant={!jobId ? 'contained' : 'outlined'}
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
            {alert && (
                <Alert severity="warning" className={classes.warning}>
                    {t("You must press the 'Save' button to save your changes")}
                </Alert>
            )}
            {additionalElement}
        </Container>
    );
};

export default CandidateProfileForm;
