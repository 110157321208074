import { makeStyles } from '@material-ui/core/styles';
import { HEADER_BREAKPOINT, LOGO_FONT } from '../../config/constants';

const FOOTER_BREAKPOINT = 1200;

export const useStyles = makeStyles(theme => ({
    logo: {
        width: 105,
        [theme.breakpoints.up(790)]: {
            width: 150,
        },
    },
    container: {
        backgroundColor: '#1F3874',
    },
    containerAuthorized: {
        backgroundColor: '#1F3874',
        [theme.breakpoints.down(HEADER_BREAKPOINT)]: {
            marginBottom: 60,
        },
    },
    linksWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '3rem 15rem',
        [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
            padding: '3rem 14rem',
        },
        [theme.breakpoints.down('md')]: {
            padding: '3rem 12rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '3rem 4.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    text: {
        fontSize: '1.125rem',
        lineHeight: '3.5rem',
        letterSpacing: '0.3px',
        fontWeight: 700,
        color: '#EEEEEE',
    },
    iconsWrapper: {
        width: 140,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
        [theme.breakpoints.up(FOOTER_BREAKPOINT)]: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: 'auto',
            marginTop: 0,
        },
    },
    logoWrapper: {
        width: 200,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
        [theme.breakpoints.up(FOOTER_BREAKPOINT)]: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: 'auto',
            marginTop: 0,
        },
    },
    icon: {
        [theme.breakpoints.up(FOOTER_BREAKPOINT)]: {
            marginLeft: '1.5rem',
            marginBottom: '1rem',
        },
    },
    mobileContainer: {
        padding: '28px 0',
        [theme.breakpoints.up(FOOTER_BREAKPOINT)]: {
            display: 'none',
        },
    },
    mobileWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    desktopContainer: {
        [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
            display: 'none',
        },
    },
    logoFont: {
        fontFamily: LOGO_FONT,
        fontSize: '2rem',
        textDecoration: 'none',
        fontWeight: 700,
        color: '#fff',
    },
    copyrightInfo: {
        display: 'block',
        marginTop: 10,
        color: '#fff',
        textAlign: 'center',
    },
    clearbitInfo: {
        display: 'block',
        marginTop: '1rem',
        color: '#616161',
        textAlign: 'center',
        [theme.breakpoints.up(FOOTER_BREAKPOINT)]: {
            marginBottom: 70,
        },
    },
    topBarWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '3rem',
    },
}));
