import { FC } from 'react';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import EmployerPostOfferForm from '../../../parts/EmployerPostOfferForm';
import { useStyles } from './styles';
import { PostJobOfferFormValues } from '../../../models/JobOffer';
import { postJobOffer } from '../../../services/JobOfferService';
import { parsePostOfferFormToJobOffer } from '../../../config/utils';
import { ROUTES } from '../../../config/constants';

const EmployerPostOfferPage: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const handleSubmit = async (
        values: PostJobOfferFormValues,
        { setSubmitting }: FormikHelpers<PostJobOfferFormValues>,
    ) => {
        try {
            await postJobOffer(parsePostOfferFormToJobOffer(values));
            enqueueSnackbar(t('Successfully post job offer.'), { variant: 'success' });
            history.push(ROUTES.EMPLOYER_HOME);
        } catch (e) {
            enqueueSnackbar('Submit fail', { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container>
            <div className={classes.wrapper}>
                <EmployerPostOfferForm onSubmit={handleSubmit} />
            </div>
        </Container>
    );
};

export default EmployerPostOfferPage;
