import { FC } from 'react';
import clsx from 'clsx';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventIcon from '@material-ui/icons/Event';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import { useStyles } from './styles';
import UserAvatar from '../../../../components/UserAvatar';
import Media from '../../../../models/Media.model';
import { JobOfferApplicationStatus } from '../../../../models/JobOfferApplication';

interface ApplicationStatusAvatarProps {
    avatar: Media | null;
    status: JobOfferApplicationStatus;
}

const ApplicationStatusAvatar: FC<ApplicationStatusAvatarProps> = props => {
    const classes = useStyles();

    const { avatar, status } = props;

    const renderStatus = () => {
        if (
            status === JobOfferApplicationStatus.ROLE_FILLED ||
            status === JobOfferApplicationStatus.CONTRACT_DECLINED
        ) {
            return (
                <div className={clsx(classes.iconWrapper, classes.rejected)}>
                    <CancelIcon />
                </div>
            );
        }
        if (status === JobOfferApplicationStatus.INTERVIEW_PROPOSED) {
            return (
                <div className={clsx(classes.iconWrapper, classes.interview)}>
                    <EventIcon />
                </div>
            );
        }
        if (status === JobOfferApplicationStatus.INTERVIEW_ACCEPTED) {
            return (
                <div className={clsx(classes.iconWrapper, classes.interview)}>
                    <EventIcon />
                </div>
            );
        }
        if (status === JobOfferApplicationStatus.CONTRACT_ACCEPTED) {
            return (
                <div className={clsx(classes.iconWrapper, classes.accepted)}>
                    <CheckCircleIcon />
                </div>
            );
        }
        if (status === JobOfferApplicationStatus.NEW) {
            return <></>;
        }
        return (
            <div className={clsx(classes.iconWrapper, classes.processing)}>
                <WatchLaterIcon />
            </div>
        );
    };

    return (
        <div className={classes.applicationStatusAvatar}>
            <UserAvatar size={60} url={avatar?.url} />
            <div className={classes.applicationStatusContainer}>{renderStatus()}</div>
        </div>
    );
};

export default ApplicationStatusAvatar;
