import { FC, useEffect } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { writeToLocalStorage, readFromLocalStorage } from '../../../services/config/LocalStorage';
import { Token } from '../../../models/enums/Token';
import { LINKEDIN_STATE_KEY, ROUTES } from '../../../config/constants';
import { linkedInLogin } from '../../../services/config/AuthenticationService';
import { thunkGetCurrentUser } from '../../../store/actions';

const CandidateLinkedInAuthPage: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { jobId, code, state } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    useEffect(() => {
        const localStorageState = readFromLocalStorage(LINKEDIN_STATE_KEY);
        if (state === localStorageState) {
            const redirectUrl = jobId
                ? `${window.location.origin}${ROUTES.CANDIDATE_LINKEDIN_AUTH}?jobId=${jobId}`
                : null;
            linkedInLogin(code as string, redirectUrl)
                .then(response => {
                    writeToLocalStorage(Token.TOKEN, response.headers.authorization);
                    dispatch(thunkGetCurrentUser());
                    if (jobId) {
                        history.push(`/jobs/${jobId}`);
                    } else {
                        history.push(ROUTES.LANDING);
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t('LinkedIn authorization has failed. Please try again.'), {
                        variant: 'error',
                    });
                    history.push(ROUTES.LANDING);
                });
        }
    }, [code, dispatch, enqueueSnackbar, history, state, t, jobId]);

    return (
        <Container className={classes.container}>
            <CircularProgress size={64} />
            <Typography className={classes.processing}>{t('Processing...')}</Typography>
        </Container>
    );
};

export default CandidateLinkedInAuthPage;
