import * as yup from 'yup';
import i18n from 'i18next';
import Media from '../../models/Media.model';

export interface ContractPropositionDialogValues {
    contractFile: Media | null;
    message: string;
}

export const initialValues: ContractPropositionDialogValues = {
    message: '',
    contractFile: null,
};

export const validationSchema = yup.object().shape({
    message: yup
        .string()
        .nullable()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(500, `${i18n.t('Maximum number of characters is')} \${max}`),
});
