import { FC } from 'react';
import { SnackbarProvider } from 'notistack';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AxiosProvider from '../AxiosProvider';
import { theme } from '../../config/theme';

import rootReducer from '../../store/reducers';
import { useStyles } from './styles';

const AppProvider: FC = ({ children }) => {
    const classes = useStyles();

    const composeEnhancers =
        process.env.NODE_ENV === 'production'
            ? compose
            : composeWithDevTools({
                  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
              });

    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

    return (
        <AxiosProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider
                    maxSnack={1}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    classes={{
                        anchorOriginBottomRight: classes.snackbarMargin,
                    }}
                >
                    <Provider store={store}>
                        <MuiPickersUtilsProvider utils={DayJsUtils}>{children}</MuiPickersUtilsProvider>
                    </Provider>
                </SnackbarProvider>
            </ThemeProvider>
        </AxiosProvider>
    );
};

export default AppProvider;
