import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../../../../components/ConfirmDialog';
import { useStyles } from './styles';
import { ROUTES } from '../../../../config/constants';
import {
    deleteCandidateUserProfile,
    deleteEmployer,
    deleteEmployerCompany,
    deleteSupport,
} from '../../../../services/AdminService';
import { CurrentUserReducer, RootReducer } from '../../../../models/Redux';
import { UserRoles } from '../../../../models/enums/UserRoles';

interface Props {
    id: number | string;
    usersType: 'candidates' | 'employers' | 'companies' | 'support';
}

const DeleteUserDialog: FC<Props> = ({ id, usersType }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            if (usersType === 'candidates') {
                await deleteCandidateUserProfile(id);
                history.push(ROUTES.ADMIN_USERS);
                enqueueSnackbar(t('User deactivated'), { variant: 'success' });
            } else if (usersType === 'employers') {
                await deleteEmployer(id);
                history.push(ROUTES.ADMIN_USERS);
                enqueueSnackbar(t('User deactivated'), { variant: 'success' });
            } else if (usersType === 'support') {
                await deleteSupport(id);
                history.push(ROUTES.ADMIN_USERS);
                enqueueSnackbar(t('User deactivated'), { variant: 'success' });
            } else {
                await deleteEmployerCompany(id);
                history.push(role === UserRoles.ADMIN ? ROUTES.ADMIN_COMPANIES : ROUTES.SUPPORT_COMPANIES);
                enqueueSnackbar(t('Company deactivated'), { variant: 'success' });
            }
        } catch (error) {
            enqueueSnackbar(t('Unable to deactivate, try again later'), { variant: 'error' });
        } finally {
            setOpen(false);
            setLoading(false);
        }
    };

    const getDeleteQuestion = () => {
        switch (usersType) {
            case 'candidates':
                return 'Do you want to remove this candidate ?';
            case 'employers':
                return 'Do you want to remove this employer ?';
            case 'support':
                return 'Do you want to remove this support user ?';
            default:
                return 'Do you want to remove this company?';
        }
    };

    const renderDialog = () => (
        <ConfirmDialog
            open={open}
            submitDisabled={loading}
            confirmLabel={t('Yes')}
            cancelLabel={t('No')}
            title={t(getDeleteQuestion())}
            onClickConfirm={handleConfirm}
            onClickCancel={() => {
                setOpen(false);
            }}
        />
    );

    const renderButton = () => (
        <Button
            color="primary"
            variant="outlined"
            className={classes.addButton}
            startIcon={<DeleteIcon />}
            onClick={handleClick}
            disabled={loading}
        >
            {t('Delete')}
        </Button>
    );

    return (
        <>
            {renderButton()}
            {renderDialog()}
        </>
    );
};

export default DeleteUserDialog;
