import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    nextButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
    },
    buttonsWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '2rem',
        margin: '0 24px 0 auto',
        paddingBottom: '1rem',
    },
});
