import { FC } from 'react';

import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import dayjs from 'dayjs';

import { useStyles } from './styles';
import { MeetingDateProposal } from '../../../models/Meeting';

interface Props {
    dateProposal: MeetingDateProposal;
    disabled: boolean;
}

const DateProposalLabel: FC<Props> = ({ dateProposal, disabled }) => {
    const classes = useStyles();

    return (
        <div className={disabled ? classes.dateProposal : classes.dateProposal}>
            <div className={classes.date}>
                <DateRangeOutlinedIcon />
                <span className={classes.text}>{dayjs(dateProposal.date).format('dddd, D MMMM YYYY')}</span>
            </div>
            <div className={classes.times}>
                <WatchLaterOutlinedIcon />
                <span className={classes.text}>{dateProposal.startTime}</span>-
                <span>{dateProposal.endTime}</span>
            </div>
        </div>
    );
};

export default DateProposalLabel;
