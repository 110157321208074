import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    applicationStatusAvatar: {
        position: 'relative',
    },
    applicationStatusContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    iconWrapper: {
        display: 'flex',
        padding: 2,
        borderRadius: '50%',
    },
    rejected: {
        color: '#EC4E4E',
        backgroundColor: 'white',
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    accepted: {
        color: '#26A425',
        backgroundColor: 'white',
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    processing: {
        color: '#005b9c',
        backgroundColor: 'white',
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    interview: {
        color: '#f1a501',
        backgroundColor: 'white',
        '& svg': {
            width: 15,
            height: 15,
        },
    },
});
