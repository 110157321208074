import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    addButton: {
        borderRadius: 4,
        borderWidth: 2,
        fontSize: '0.8125rem',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        minWidth: '8.5rem',
        '&:focus, &:hover': {
            borderWidth: 2,
        },
    },
    form: {
        display: 'grid',
        gridGap: '1rem',
        padding: '1rem',
        minWidth: '18rem',
    },
    submitButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
    },
});
