import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    contentWrapper: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '3rem',
        paddingTop: '2.5rem',
        marginTop: '2.5rem',
        flexGrow: 1,
    },
    centerWrapper: {
        flex: 1,
        marginBottom: '3rem',
        marginTop: '3rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    alert: {
        marginTop: '1.5rem',
    },
});
