import { FC } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const TemporaryPage: FC = () => {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Typography>Place for future content :) </Typography>
        </Container>
    );
};

export default TemporaryPage;
