import { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import FilterData from '../../models/FilterData';
import FilterDataValue from '../../models/FilterDataValue';

interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
}

export interface PaginatedResponse<T> {
    content: T[];
    totalElements: number | null;
    totalPages: number;
    first: boolean;
    last: boolean;
    pageable: Pageable;
}

interface UsePaginationFetchParams<T> {
    service: (params: FilterData) => Promise<PaginatedResponse<T>>;
    initialFilters?: FilterDataValue[];
    initialPage?: number;
    initialSize?: number;
    disabled?: boolean;
    accumulateData?: boolean;
}

interface UsePaginationFetchResponse<T> extends PaginatedResponse<T> {
    error: AxiosError | null;
    loading: boolean;
    dataFilters: FilterData;
    setRefetch: Dispatch<SetStateAction<boolean>>;
    setDataFilters: Dispatch<SetStateAction<FilterData>>;
}

export const usePaginationFetch = <T,>({
    accumulateData,
    disabled,
    initialFilters = [],
    initialPage = 0,
    initialSize = 8,
    service,
}: UsePaginationFetchParams<T>): UsePaginationFetchResponse<T> => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [refetch, setRefetch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(!disabled);
    const [error, setError] = useState<AxiosError | null>(null);

    const [response, setResponse] = useState<PaginatedResponse<T>>({
        content: [],
        totalPages: 1,
        totalElements: null,
        first: true,
        last: false,
        pageable: {
            offset: initialPage * initialSize,
            pageNumber: initialPage,
            pageSize: initialSize,
        },
    });

    const [dataFilters, setDataFilters] = useState<FilterData>({
        filters: initialFilters,
        page: initialPage,
        size: initialSize,
    });

    const calculateNewContent = useCallback(
        (content, oldContent, first) => {
            if (!first && accumulateData) {
                return [...oldContent, ...content];
            }
            return content;
        },
        [accumulateData],
    );

    useEffect(() => {
        if (!disabled) {
            setError(null);
            setLoading(true);
            service(dataFilters)
                .then(response => {
                    setResponse(oldResponse => ({
                        content: calculateNewContent(response.content, oldResponse.content, response.first),
                        totalPages: response.totalPages,
                        totalElements: response.totalElements,
                        first: response.first,
                        last: response.last,
                        pageable: {
                            offset: response.pageable.offset,
                            pageNumber: response.pageable.pageNumber,
                            pageSize: response.pageable.pageSize,
                        },
                    }));
                })
                .catch(error => {
                    setError(error);
                    enqueueSnackbar(t('Unable to fetch data, try again later'), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [enqueueSnackbar, t, refetch, dataFilters, service, disabled, calculateNewContent]);

    return {
        ...response,
        dataFilters,
        error,
        loading,
        setRefetch,
        setDataFilters,
    };
};
