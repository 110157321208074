import { makeStyles, Theme } from '@material-ui/core/styles';
import { JOB_OFFER_CARD_BREAKPOINT } from '../../config/constants';

interface Props {
    backgroundUrl?: string;
}

export const useStyles = makeStyles<Theme, Props>(theme => ({
    wrapper: {
        textDecoration: 'none',
        borderRadius: '1rem',
        width: '16rem',
        height: '16rem',
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
        boxShadow: '0 0 77px 0 rgba(0,53,173,0.51)',
        cursor: 'pointer',
        backfaceVisibility: 'hidden',
        transition: 'transform 0.3s ease',
        '-webkit-font-smoothing': 'subpixel-antialiased',
        // https://stackoverflow.com/questions/14677490/blurry-text-after-using-css-transform-scale-in-chrome/42808920#42808920
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            '&:hover': {
                transform: 'translateZ(0) scale(1.125) perspective(1px)',
            },
        },
    },
    paperWrapper: {
        display: 'block',
        textDecoration: 'none',
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '0.75rem',
        boxShadow: '0 16px 23px -13px #8FACC7',
    },
    companyLogo: {
        position: 'absolute',
        width: 76,
        height: 76,
        top: 54,
        left: 28,
    },
    companyBackground: ({ backgroundUrl }) => ({
        position: 'relative',
        paddingTop: '43.2%',
        background: `url(${backgroundUrl}) 0% 0% / cover`,
        height: 113,
        width: '100%',
        borderRadius: '1rem 1rem 0 0',
    }),
    companyLogoWrapper: {
        position: 'absolute',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            left: 24,
            bottom: -20,
            width: 92,
            height: 92,
        },
        [theme.breakpoints.up(JOB_OFFER_CARD_BREAKPOINT)]: {
            bottom: -15,
            left: 24,
            width: 84,
            height: 84,
        },
    },
    position: {
        color: '#16133E',
        fontSize: 19,
        fontWeight: 700,
        lineHeight: '26px',
        marginBottom: '0.25rem',
        marginTop: '0.25rem',
    },
    detailsText: {
        color: '#616161',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '18px',
        marginBottom: '0.25rem',
    },
    detailsWrapper: {
        padding: '1rem 1.5rem',
        backgroundColor: 'white',
        borderRadius: '0 0 1rem 1rem',
    },
    detailsRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.2rem',
    },
    detailsIcon: {
        height: 14,
        width: 14,
        marginRight: 8,
    },
}));
