import axios from 'axios';

import { Company } from '../models/Company';

export const getAllCompanies = async (): Promise<Company[]> => {
    const { data } = await axios.get('companies');
    return data;
};

export const createCompany = async (company: Omit<Company, 'id'>): Promise<Company> => {
    const { data } = await axios.post('companies', company);
    return data;
};
