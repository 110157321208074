import { FC } from 'react';

import { useStyles } from './styles';

const JobOfferCardsWrapper: FC = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.jobsWrapper}>{children}</div>;
};

export default JobOfferCardsWrapper;
