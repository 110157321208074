import React, { FC, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import FormikDocumentUpload from '../../formik/FormikDocumentUpload';
import CustomFormikTextField from '../../CustomFormikTextField';
import ConfirmDialog from '../../ConfirmDialog';
import { ContractPropositionDialogValues } from '../config';
import { rejectOffer } from '../../../services/JobOfferApplicationsService';
import { getBackendErrorMessage } from '../../../config/utils';

interface Props {
    applicationId: number;
    onRejected: () => void;
}

const CandidatePropositionForm: FC<Props> = ({ applicationId, onRejected }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { handleSubmit, isSubmitting, values } = useFormikContext<ContractPropositionDialogValues>();

    const [rejecting, setRejecting] = useState(false);
    const [rejectionPending, setRejectionPending] = useState(false);

    const handleReject = (values: ContractPropositionDialogValues) => {
        if (!rejectionPending) {
            setRejectionPending(true);
            rejectOffer(applicationId, {
                message: values.message,
            })
                .then(() => {
                    enqueueSnackbar('Job offer rejected', { variant: 'success' });
                    onRejected();
                })
                .catch(error => {
                    enqueueSnackbar(`Error rejecting job offer: ${getBackendErrorMessage(error)}`, {
                        variant: 'error',
                    });
                    setRejectionPending(false);
                });
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.downloadWrapper}>
                    <InputLabel>{t('Upload contract file')}</InputLabel>
                    <FormikDocumentUpload
                        name="contractFile"
                        label={t('Upload contract file')}
                        disableLabel
                    />
                </div>

                <CustomFormikTextField
                    name="message"
                    label={t('Message')}
                    placeholder={t('Message')}
                    multiline
                    rows={4}
                />

                <div className={classes.buttonsWrapper}>
                    <Button color="primary" variant="outlined" onClick={() => setRejecting(true)}>
                        {t('Reject')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.sendButton}
                        disabled={isSubmitting}
                    >
                        {t('Accept job offer')}
                    </Button>
                </div>
                <ConfirmDialog
                    open={rejecting}
                    confirmLabel={t('Yes')}
                    cancelLabel={t('No')}
                    title={t('Are you sure you want to reject this job offer?')}
                    onClickConfirm={e => {
                        e.preventDefault();
                        handleReject(values);
                    }}
                    onClickCancel={e => {
                        e.preventDefault();
                        setRejecting(false);
                    }}
                />
            </form>
        </div>
    );
};

export default CandidatePropositionForm;
