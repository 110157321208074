import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3.5rem 0 4.25rem',
    },
    title: {
        fontSize: 35,
        letterSpacing: '0.5px',
        fontWeight: 700,
        color: '#15416D',
        textAlign: 'center',
        marginBottom: '2rem',
    },
});
