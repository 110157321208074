import { FC, useEffect, useState } from 'react';

import LandingPageSection from '../../../parts/LandingPageSection';
import { useStyles } from './styles';
import PopularCategoriesCard from '../../../components/PopularCategoriesCard';
import { Category } from '../../../models/JobOffer';
import { getCategories } from '../../../services/JobOfferService';
import HealthScience from '../../../assets/landing/heatlhScience.png';

const PopularCategoriesPanel: FC = () => {
    const classes = useStyles();

    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        getCategories()
            .then(response => setCategories(response))
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    const renderCards = (cardConfig: Category[]): JSX.Element[] =>
        cardConfig.map(({ name, imageUrl, id }) => (
            <PopularCategoriesCard key={name} name={name} imageUrl={imageUrl || HealthScience} id={id} />
        ));

    if (loading || error) return null;

    return (
        <LandingPageSection title="Popular categories" titleColor="#15416D" sectionBackgroundColor="#F7FBFF">
            <div className={classes.cardsContainer}>{renderCards(categories)}</div>
        </LandingPageSection>
    );
};

export default PopularCategoriesPanel;
