import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    actionButton: {
        padding: 6,
        color: '#1F3874',
        transform: 'rotate(0deg)',
        transition: 'all 0.2s',
    },
    actionButtonUp: {
        transform: 'rotate(180deg)',
    },
    listItemText: {
        fontWeight: 500,
    },
});
