import * as yup from 'yup';
import i18n from 'i18next';

export const initialValues = {
    firstName: '',
    lastName: '',
    avatar: null,
    linkedinUrl: '',
    location: '',
    currentlyEmployed: false,
    cvFile: null,
    contactPhoneNumber: '',
    contactEmail: '',
    educations: [],
    jobExperiences: [],
    currentPosition: '',
};

export const validationSchema = yup.object().shape({
    firstName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    lastName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    currentPosition: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    contactPhoneNumber: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .nullable()
        .min(6, 'Too Short!')
        .max(50, `${i18n.t('Maximum number of characters is')} \${max}`)
        .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, i18n.t('Enter a correct phone number')),
    contactEmail: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .email(i18n.t('Email must be valid')),
    linkedinUrl: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .matches(
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
            i18n.t('Enter correct url'),
        ),
});
