import { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import { CookieConsentsProvider } from '@enzsft/react-cookie-consents';

import fbIcon from '../../assets/fbSocial.svg';
import twitterIcon from '../../assets/twitterSocial.svg';
import igIcon from '../../assets/igSocial.svg';

import { useStyles } from './styles';
import { FooterLinks } from './config';
import { ROUTES } from '../../config/constants';
import CookiesDisclaimer from './CookiesDisclaimer';

interface Props {
    authorized?: boolean;
}

const Footer: FC<Props> = ({ authorized }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderBasicInfo = (): JSX.Element => (
        <RouterLink to={ROUTES.LANDING} className={classes.logoFont}>
            <div className={classes.logoWrapper}>
                <div title="Onboard logo">Onboard</div>
            </div>
        </RouterLink>
    );

    const renderLinks = (): JSX.Element => (
        <div className={classes.linksWrapper}>
            {FooterLinks.map(({ name, path, target }) => (
                <Link
                    key={name}
                    component={RouterLink}
                    to={{ pathname: path }}
                    target={target}
                    className={classes.text}
                >
                    {t(name)}
                </Link>
            ))}
        </div>
    );

    const renderSocials = (): JSX.Element => (
        <div className={classes.iconsWrapper}>
            <Link href="https://www.facebook.com/onboard.careers">
                <img src={fbIcon} width="10" height="21" className={classes.icon} alt="Facebook page" />
            </Link>
            <Link href="https://twitter.com/onboardhq">
                <img src={twitterIcon} width="18" height="17" className={classes.icon} alt="Twitter page" />
            </Link>
            <Link href="https://www.instagram.com/onboardhq" target="_blank">
                <img src={igIcon} width="20" height="21" className={classes.icon} alt="Instagram page" />
            </Link>
        </div>
    );

    const renderCopyrights = (): JSX.Element => (
        <>
            <Link href={ROUTES.LANDING} className={classes.copyrightInfo}>
                © 2021 Onboard Jobs Limited. All rights reserved.
            </Link>
            <Link href="https://clearbit.com" className={classes.clearbitInfo}>
                Logos provided by Clearbit
            </Link>
        </>
    );

    const renderMobile = () => {
        return (
            <div className={classes.mobileContainer}>
                <div className={classes.mobileWrapper}>
                    <div className={classes.logoFont}>Onboard</div>
                    {renderSocials()}
                </div>
                {renderLinks()}
                {renderCopyrights()}
            </div>
        );
    };

    const renderDesktop = () => {
        return (
            <div className={classes.desktopContainer}>
                <div className={classes.topBarWrapper}>
                    {renderBasicInfo()}
                    {renderSocials()}
                </div>
                {renderLinks()}
                {renderCopyrights()}
            </div>
        );
    };

    return (
        <div
            className={clsx({
                [classes.container]: !authorized,
                [classes.containerAuthorized]: authorized,
            })}
        >
            <Container>
                {renderMobile()}
                {renderDesktop()}
                <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
                    <CookiesDisclaimer />
                </CookieConsentsProvider>
            </Container>
        </div>
    );
};

export default Footer;
