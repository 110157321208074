import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventIcon from '@material-ui/icons/Event';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import i18n from 'i18next';
import { JobOfferApplicationStatus } from '../../../../models/JobOfferApplication';

export interface ApplicationStatusToInfo {
    status: JobOfferApplicationStatus;
    display: boolean;
    icon: unknown;
    text: string;
    classes: string | null;
}

export const applicationStatusToInfo: ApplicationStatusToInfo[] = [
    {
        status: JobOfferApplicationStatus.NEW,
        display: false,
        icon: '',
        text: '',
        classes: null,
    },
    {
        status: JobOfferApplicationStatus.ROLE_FILLED,
        display: true,
        icon: <CancelIcon />,
        text: i18n.t('Role filled'),
        classes: 'rejected',
    },
    {
        status: JobOfferApplicationStatus.INTERVIEW_PROPOSED,
        display: true,
        icon: <EventIcon />,
        text: i18n.t('Interview proposed'),
        classes: 'interview',
    },
    {
        status: JobOfferApplicationStatus.INTERVIEW_ACCEPTED,
        display: true,
        icon: <EventIcon />,
        text: i18n.t('Interview accepted'),
        classes: 'interview',
    },
    {
        status: JobOfferApplicationStatus.CONTRACT_PROPOSED,
        display: true,
        icon: <WatchLaterIcon />,
        text: i18n.t('Contract proposed'),
        classes: 'processing',
    },
    {
        status: JobOfferApplicationStatus.CONTRACT_DECLINED,
        display: true,
        icon: <CancelIcon />,
        text: i18n.t('Contract declined'),
        classes: 'rejected',
    },
    {
        status: JobOfferApplicationStatus.CONTRACT_ACCEPTED,
        display: true,
        icon: <CheckCircleIcon />,
        text: i18n.t('Contract accepted'),
        classes: 'accepted',
    },
];
