import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import CloseDialogElement from '../CloseDialogElement';
import { ROUTES } from '../../config/constants';

interface Props {
    open: boolean;
    jobId: string | number;
    onClose: () => void;
}

const MissingProfileInformationDialog: FC<Props> = ({ open, jobId, onClose }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            <Dialog onClose={onClose} open={open} classes={{ paperWidthSm: classes.dialog }}>
                <CloseDialogElement onClick={onClose} />

                <Typography className={classes.title}>{t('Missing profile info')}</Typography>
                <Typography className={classes.text}>
                    {t('Please add a little more to your profile to apply for this job.')}
                </Typography>

                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => history.push(`${ROUTES.CANDIDATE_PROFILE}?jobId=${jobId}`)}
                >
                    {t('Complete your profile')}
                </Button>
            </Dialog>
        </>
    );
};

export default MissingProfileInformationDialog;
