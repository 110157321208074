import { FC } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

interface PublicRouteProps extends RouteProps {
    component: FC<RouteComponentProps>;
}

const PublicRoute: FC<PublicRouteProps> = ({ component: Component, ...rest }) => (
    <Route {...rest} render={routeProps => <Component {...routeProps} />} />
);

export default PublicRoute;
