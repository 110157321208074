import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ArrayHelpers } from 'formik';
import { isNumber } from 'lodash';
import clsx from 'clsx';

import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { formattedTimeDiff } from '../../config/utils';
import { useStyles } from './styles';
import { CandidateJobExperienceForm } from '../../pages/candidate/CandidateProfilePage/AddExperienceDialog/config';
import AddExperienceDialog from '../../pages/candidate/CandidateProfilePage/AddExperienceDialog';

interface Props {
    jobExperiences: CandidateJobExperienceForm[];
    editable?: boolean;
    arrayHelpers?: ArrayHelpers;
}

const CandidateJobExperienceInfo: FC<Props> = ({ jobExperiences, arrayHelpers, editable }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onEditAction = (jobExperience: CandidateJobExperienceForm | null, index?: number): void => {
        if (isNumber(index)) {
            arrayHelpers?.replace(index, jobExperience);
        }
    };

    const onDelete = (index: number): void => {
        arrayHelpers?.remove(index);
    };

    const renderEditButton = (index: number) => {
        if (editable) {
            return (
                <div>
                    <IconButton size="small" onClick={() => onDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                    <AddExperienceDialog
                        action="edit"
                        initialValues={jobExperiences[index]}
                        index={index}
                        onSubmitAction={onEditAction}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            {jobExperiences.map(
                (
                    {
                        companyName,
                        companyLogo,
                        startDate,
                        endDate,
                        id,
                        location,
                        position,
                        stillWorkingHere,
                    },
                    index,
                    array,
                ) => (
                    <div key={id} className={clsx({ [classes.wrapper]: editable })}>
                        {renderEditButton(index)}
                        <div className={classes.experienceContainer}>
                            <div
                                className={clsx(classes.experienceLogoContainer, {
                                    [classes.experienceLogoContainerBackground]: !companyLogo,
                                })}
                            >
                                {companyLogo && (
                                    <img
                                        alt={companyName}
                                        src={companyLogo.url}
                                        className={classes.experienceLogo}
                                    />
                                )}
                            </div>
                            <div>
                                <Typography className={classes.position}>{position}</Typography>
                                <Typography className={classes.companyName}>{companyName}</Typography>
                                <Typography className={classes.experienceText}>{`${dayjs(startDate).format(
                                    'MMM YYYY',
                                )} - ${
                                    stillWorkingHere ? t('PRESENT') : dayjs(endDate).format('MMM YYYY')
                                } ${formattedTimeDiff(
                                    startDate,
                                    stillWorkingHere ? new Date() : endDate,
                                )}`}</Typography>
                                <Typography className={classes.experienceText}>{location}</Typography>
                            </div>
                        </div>
                        {index + 1 !== array.length && <Divider className={classes.divider} />}
                    </div>
                ),
            )}
        </div>
    );
};

export default CandidateJobExperienceInfo;
