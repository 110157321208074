import React, { FC, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { isEmpty } from 'lodash';
import { useStyles } from './styles';
import { initialValues, RejectCandidateDialogForm, validationSchema } from './config';
import { RejectApplicationStatus } from '../../../../models/JobOfferApplication';
import BlurredStyleFormikTextField from '../../../../components/BlurredStyleFormikTextField';
import BlurredStyleFormikSelect from '../../../../components/BlurredStyleFormikSelect';
import { updateRejectionApplicationStatus } from '../../../../services/JobOfferApplicationsService';
import CloseDialogElement from '../../../../components/CloseDialogElement';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { getBackendFormikErrors } from '../../../../config/utils';
import FormikErrorText from '../../../../components/formik/FormikErrorText';

interface RejectCandidateDialogProps {
    applicationId: number;
    rejectStatuses: RejectApplicationStatus[];
    onStatusUpdate: () => void;
}

const RejectCandidateDialog: FC<RejectCandidateDialogProps> = ({
    rejectStatuses,
    applicationId,
    onStatusUpdate,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [allowingRejectionMessage, setAllowingRejectionMessage] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [rejectionInProgress, setRejectionInProgress] = useState(false);

    const handleSubmitForm = async (
        { statusId, feedbackMessage, rejectionMessage }: RejectCandidateDialogForm,
        setErrors: (errors: FormikErrors<RejectCandidateDialogForm>) => void,
    ) => {
        if (!rejectionInProgress) {
            setRejectionInProgress(true);
            try {
                setRejecting(false);
                await updateRejectionApplicationStatus(applicationId, {
                    statusId: statusId as number,
                    feedbackMessage,
                    rejectionMessage,
                });
                await onStatusUpdate();
                enqueueSnackbar(t('Application successfully rejected.'), { variant: 'success' });
                setOpen(false);
            } catch (e) {
                enqueueSnackbar(t('Failed to reject application. Please try again.'), { variant: 'error' });
                setErrors(getBackendFormikErrors<RejectCandidateDialogForm>(e));
                setRejectionInProgress(false);
            }
        }
    };

    const renderDialog = (
        values: RejectCandidateDialogForm,
        setErrors: (errors: FormikErrors<RejectCandidateDialogForm>) => void,
    ) => (
        <ConfirmDialog
            open={rejecting}
            submitDisabled={rejectionInProgress}
            confirmLabel={t('Yes')}
            cancelLabel={t('No')}
            title={t(
                'Are you sure you want to reject this candidate? They will be notified immediately and this action cannot be undone.',
            )}
            onClickConfirm={() => handleSubmitForm(values, setErrors)}
            onClickCancel={() => setRejecting(false)}
        />
    );

    const getRejectionMessageStatus = (
        statusId: unknown,
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
    ) => {
        const tempAllowingRejectionMessage = rejectStatuses.find(({ id }) => id === statusId)
            ?.allowingRejectionMessage;
        setAllowingRejectionMessage(tempAllowingRejectionMessage || false);
        setFieldValue('allowingRejectionMessage', tempAllowingRejectionMessage);
    };

    const renderFormik = () => (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}
            validationSchema={validationSchema}
        >
            {({ values, setFieldValue, handleSubmit, setErrors, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <BlurredStyleFormikSelect
                            name="statusId"
                            label={t('Reason of rejection')}
                            onChange={e => {
                                setFieldValue('statusId', e.target.value);
                                getRejectionMessageStatus(e.target.value, setFieldValue);
                            }}
                        >
                            <MenuItem key="empty" value="" disabled>
                                {t('Choose')}
                            </MenuItem>
                            {rejectStatuses.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {t(name)}
                                </MenuItem>
                            ))}
                        </BlurredStyleFormikSelect>
                        <FormikErrorText name="statusId" />
                        {allowingRejectionMessage && (
                            <BlurredStyleFormikTextField
                                name="rejectionMessage"
                                label={t('Rejection message')}
                                placeholder={t('Rejection message')}
                                multiline
                                rows={4}
                            />
                        )}
                        <BlurredStyleFormikTextField
                            name="feedbackMessage"
                            label={t('Custom feedback message')}
                            placeholder={t('Feedback')}
                            multiline
                            rows={4}
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!isEmpty(errors) || rejectionInProgress}
                            onClick={() => setRejecting(true)}
                            className={classes.sendButton}
                        >
                            {t('Reject')}
                        </Button>
                        {renderDialog(values, setErrors)}
                    </form>
                );
            }}
        </Formik>
    );

    return (
        <>
            <Button
                color="primary"
                className={classes.button}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {t('Reject')}
            </Button>
            <Dialog
                onClose={() => {
                    setOpen(false);
                }}
                open={open}
                classes={{ paperWidthSm: classes.dialog }}
            >
                <CloseDialogElement onClick={() => setOpen(false)} />
                <Typography className={classes.feedback}>{t('Feedback')}</Typography>
                {renderFormik()}
            </Dialog>
        </>
    );
};

export default RejectCandidateDialog;
