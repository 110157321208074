import { makeStyles } from '@material-ui/core/styles';

import { SEARCH_PANEL_FILTERS_BREAKPOINT } from '../../../config/constants';

export const useStyles = makeStyles(theme => ({
    root: {
        color: '#9E9E9E',
        '& .MuiIconButton-label': {
            position: 'relative',
            zIndex: 0,
        },
        '&:not($checked) .MuiIconButton-label:after': {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: -1,
        },
    },
    checked: {},
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '100%',
    },
    label: {
        color: '#616161',
    },
    formControlRoot: {
        [theme.breakpoints.down(SEARCH_PANEL_FILTERS_BREAKPOINT)]: {
            paddingLeft: '2rem',
        },
    },
}));
