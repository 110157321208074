import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    uploadButton: {
        height: '2rem',
        width: '9rem',
        fontSize: '0.8125rem',
        borderRadius: 4,
    },
    dropzoneArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: 20,
        height: '100%',
        minHeight: 100,
        borderRadius: 2,
        backgroundColor: '#FFFFFF',
        color: '#bdbdbd',
        cursor: 'pointer',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    withBorder: {
        border: '2px dashed #979797',
    },
    fullWidth: {
        width: '100%',
    },
    logo: {
        maxHeight: '3.5rem',
        marginBottom: '0.5rem',
    },
    suggestionMessage: {
        display: 'flex',
    },
}));
