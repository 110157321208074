import * as yup from 'yup';
import i18n from 'i18next';
import Media from '../../../models/Media.model';

export interface CreateSupportFormValues {
    username: string;
    contactEmail: string;
    firstName: string;
    lastName: string;
    avatar: Media | null;
}

export const initialValues: CreateSupportFormValues = {
    username: '',
    contactEmail: '',
    firstName: '',
    lastName: '',
    avatar: null,
};

export const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Username email is required'))
        .email(i18n.t('Username email must be valid')),
    firstName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    lastName: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Field is required'))
        .max(255, `${i18n.t('Maximum number of characters is')} \${max}`),
    contactEmail: yup.string().email(i18n.t('Email must be valid')),
});
