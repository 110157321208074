import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        marginBottom: '3rem',
    },
    tabRoot: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '2rem',
        color: '#000000',
        textTransform: 'none',
        marginRight: '3rem',
        minWidth: 0,
    },
    selected: {
        color: '#007FFC',
    },
    indicator: {
        height: 3,
    },
    wrapper: {
        marginTop: '1.5rem',
    },
});
