import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from './styles';
import CustomFormikTextField from '../../components/CustomFormikTextField';
import { CreateCompanyEmployerFormValues } from '../../pages/admin/AdminCreateEmployerPage/config';
import CustomFormikSelect from '../../components/CustomFormikSelect';
import { Company } from '../../models/Company';
import { getAllCompanies } from '../../services/CompanyService';

interface Props {
    additionalElement?: JSX.Element;
}

const CreateCompanyEmployerForm: FC<Props> = ({ additionalElement }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [companies, setCompanies] = useState<Company[]>([]);

    const { handleSubmit, isSubmitting } = useFormikContext<CreateCompanyEmployerFormValues>();

    useEffect(() => {
        getAllCompanies()
            .then(response => setCompanies(response))
            .catch(() =>
                enqueueSnackbar(t('Unable to companies data, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, t]);

    const renderProfileDetails = () => (
        <div className={classes.profileDetails}>
            <CustomFormikTextField name="username" placeholder={t('Email')} label={t('Email')} />
            <CustomFormikSelect label={t('Company')} name="companyId">
                <MenuItem key="empty" value="" disabled>
                    {t('Choose')}
                </MenuItem>
                {companies?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                        {t(name)}
                    </MenuItem>
                ))}
            </CustomFormikSelect>

            <CustomFormikTextField name="firstName" placeholder={t('First name')} label={t('First name')} />
            <CustomFormikTextField name="lastName" placeholder={t('Last name')} label={t('Last name')} />
        </div>
    );

    return (
        <Container className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div className={classes.form}>{renderProfileDetails()}</div>
                <div className={classes.buttonsContainer}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                        disabled={isSubmitting}
                    >
                        {t('Create')}
                    </Button>
                </div>
            </form>
            {additionalElement}
        </Container>
    );
};

export default CreateCompanyEmployerForm;
