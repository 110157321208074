import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    membersWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    memberCardWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        border: '1px solid rgba(151,151,151,0.14)',
        marginRight: 14,
        marginBottom: 16,
        '&:focus, &:hover': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
    },
    cardName: {
        color: '#4E4E4E',
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '0.15px',
    },
    cardPosition: {
        color: '#9E9E9E',
        fontSize: '0.625rem',
        lineHeight: '1rem',
        letterSpacing: '0.32px',
    },
    cardText: {
        marginLeft: 10,
    },
    iconButton: {
        marginLeft: '1.5rem',
    },
    cancelIcon: {
        color: 'rgba(0,0,0,0.14)',
    },
});
