import React, { FC } from 'react';
import clsx from 'clsx';

import AccountCircle from '@material-ui/icons/AccountCircle';

import { useStyles } from './styles';

interface UserAvatarProps {
    className?: string;
    size: number;
    url?: string | null;
}

const UserAvatar: FC<UserAvatarProps> = ({ className, size, url }) => {
    const classes = useStyles({ size });

    if (url) {
        return (
            <div className={clsx(classes.imageContainer, className)}>
                <img src={url} className={classes.image} alt="Avatar" />
            </div>
        );
    }
    return <AccountCircle className={clsx(classes.defaultAvatar, className)} />;
};

export default UserAvatar;
