import * as yup from 'yup';

export interface SearchJobPanelFormValues {
    search: string;
    location: string;
    remotely: boolean;
    categoryIds: number[];
    employmentTypeIds: number[];
    experienceLevelIds: number[];
}

export const initialValues: SearchJobPanelFormValues = {
    search: '',
    location: '',
    remotely: false,
    categoryIds: [],
    employmentTypeIds: [],
    experienceLevelIds: [],
};

export const validationSchema = yup.object().shape({
    search: yup.string(),
    location: yup.string(),
});

export type OpenedFilters = 'category' | 'contract' | 'level';
