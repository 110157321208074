import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox as FormikCheckbox } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TuneIcon from '@material-ui/icons/Tune';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';

import qs from 'qs';
import { toNumber } from 'lodash';
import { useStyles } from './styles';
import { JobOfferStaticContent } from '../../../models/JobOffer';
import { getJobOfferStaticContent } from '../../../services/JobOfferService';
import SearchPanelFilterForm from '../SearchPanelFilterForm';
import { ROUTES } from '../../../config/constants';
import OnboardLogo from '../../../assets/onboardLogoHeader.png';
import { CreateSupportFormValues } from '../../../pages/admin/AdminCreateSupportPage/config';

type OpenedFilters = 'category' | 'contract' | 'level' | null;

const SearchPanelFilters: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { search } = useLocation();

    const [open, setOpen] = useState<OpenedFilters>(null);
    const [staticContent, setStaticContent] = useState<JobOfferStaticContent | null>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    useEffect(() => {
        getJobOfferStaticContent()
            .then(response => {
                setStaticContent(response);
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch static content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, t]);

    const { setFieldValue, submitForm } = useFormikContext<CreateSupportFormValues>();

    const { categoryId } = qs.parse(search, {
        ignoreQueryPrefix: true,
    });

    useEffect(() => {
        if (categoryId) {
            setFieldValue('categoryIds', [toNumber(categoryId)]);
            // Workaround for sync setFieldValue and async submitForm
            setTimeout(submitForm, 50);
        }
    }, [categoryId, setFieldValue, submitForm]);

    const changeFilters = (filter: OpenedFilters): void => {
        setOpen(open => {
            if ((open === filter && open !== null) || (filter === null && open)) {
                return null;
            }
            if (filter === null && !open) {
                return 'category';
            }
            return filter;
        });
    };

    const renderHeader = () => {
        return (
            <div className={classes.headerWrapper}>
                <Button
                    classes={{
                        root: clsx(classes.headerButton, classes.filterButton),
                    }}
                    onClick={() => changeFilters(null)}
                >
                    {t('Filter')}
                </Button>
                <Button
                    classes={{
                        root: clsx(classes.headerButton, {
                            [classes.headerButtonActive]: open === 'category',
                        }),
                        endIcon: clsx(classes.expandIcon, {
                            [classes.expandIconExpanded]: open === 'category',
                        }),
                    }}
                    endIcon={<ExpandMoreIcon />}
                    onClick={() => changeFilters('category')}
                >
                    {t('Category')}
                </Button>
                <Button
                    classes={{
                        root: clsx(classes.headerButton, {
                            [classes.headerButtonActive]: open === 'contract',
                        }),
                        endIcon: clsx(classes.expandIcon, {
                            [classes.expandIconExpanded]: open === 'contract',
                        }),
                    }}
                    endIcon={<ExpandMoreIcon />}
                    onClick={() => changeFilters('contract')}
                >
                    {t('Type of contract')}
                </Button>
                <Button
                    classes={{
                        root: clsx(classes.headerButton, {
                            [classes.headerButtonActive]: open === 'level',
                        }),
                        endIcon: clsx(classes.expandIcon, { [classes.expandIconExpanded]: open === 'level' }),
                    }}
                    endIcon={<ExpandMoreIcon />}
                    onClick={() => changeFilters('level')}
                >
                    {t('Level')}
                </Button>
                <FormControlLabel
                    control={
                        <Field component={FormikCheckbox} color="primary" type="checkbox" name="remotely" />
                    }
                    label={t('Remote work')}
                    classes={{ label: classes.headerButton }}
                />
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <Collapse in={!!open} collapsedHeight={0}>
                <Divider className={classes.divider} />
                <div className={classes.filtersWrapper}>
                    {open === 'category' && (
                        <SearchPanelFilterForm name="categoryIds" options={staticContent?.categories} />
                    )}
                    {open === 'contract' && (
                        <SearchPanelFilterForm
                            name="employmentTypeIds"
                            options={staticContent?.employmentTypes}
                        />
                    )}
                    {open === 'level' && (
                        <SearchPanelFilterForm
                            name="experienceLevelIds"
                            options={staticContent?.experienceLevels}
                        />
                    )}
                </div>
            </Collapse>
        );
    };
    const renderHeaderNav = () => {
        return (
            <ListItem className={classes.headerDrawer}>
                <RouterLink to={ROUTES.LANDING} className={classes.logoDrawer}>
                    <img src={OnboardLogo} alt="Onboard logo" width="114" height="25" />
                </RouterLink>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                        setOpenDrawer(open => !open);
                    }}
                >
                    {openDrawer ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
            </ListItem>
        );
    };

    const renderHeaderMobile = () => (
        <div className={classes.headerWrapper}>
            {renderHeaderNav()}
            <Button
                classes={{
                    root: clsx(classes.headerButton, {
                        [classes.headerButtonActive]: open === 'category',
                    }),
                    label: classes.mobileButton,
                    endIcon: clsx(classes.expandIcon, {
                        [classes.expandIconExpanded]: open === 'category',
                    }),
                }}
                endIcon={<ExpandMoreIcon />}
                onClick={() => changeFilters('category')}
            >
                {t('Category')}
            </Button>
            <Divider />

            {open === 'category' && (
                <>
                    {renderFilters()}
                    <Divider />
                </>
            )}

            <Button
                classes={{
                    root: clsx(classes.headerButton, {
                        [classes.headerButtonActive]: open === 'contract',
                    }),
                    label: classes.mobileButton,
                    endIcon: clsx(classes.expandIcon, {
                        [classes.expandIconExpanded]: open === 'contract',
                    }),
                }}
                endIcon={<ExpandMoreIcon />}
                onClick={() => changeFilters('contract')}
            >
                {t('Type of contract')}
            </Button>
            <Divider />
            {open === 'contract' && (
                <>
                    {renderFilters()}
                    <Divider />
                </>
            )}

            <Button
                classes={{
                    root: clsx(classes.headerButton, {
                        [classes.headerButtonActive]: open === 'level',
                    }),
                    label: classes.mobileButton,
                    endIcon: clsx(classes.expandIcon, { [classes.expandIconExpanded]: open === 'level' }),
                }}
                endIcon={<ExpandMoreIcon />}
                onClick={() => changeFilters('level')}
            >
                {t('Level')}
            </Button>
            <Divider />
            {open === 'level' && (
                <>
                    {renderFilters()}
                    <Divider />
                </>
            )}
            <FormControlLabel
                control={<Field component={FormikCheckbox} color="primary" type="checkbox" name="remotely" />}
                label={t('Remote work')}
                classes={{
                    label: clsx(classes.headerButton, classes.mobileButton, classes.checkboxMobileLabel),
                    root: classes.controlLabel,
                }}
            />
            <Divider />
        </div>
    );

    const renderMobile = () => {
        return (
            <div className={classes.mobileWrapper}>
                <Button
                    startIcon={<TuneIcon />}
                    onClick={() => {
                        setOpenDrawer(true);
                    }}
                >
                    {t('Filter')}
                </Button>
                <SwipeableDrawer
                    classes={{ paper: classes.drawerPaper }}
                    anchor="left"
                    open={openDrawer}
                    onClose={() => {
                        setOpenDrawer(false);
                    }}
                    onOpen={() => {
                        setOpenDrawer(true);
                    }}
                >
                    {renderHeaderMobile()}
                </SwipeableDrawer>
            </div>
        );
    };

    const renderDesktop = () => {
        return (
            <div className={classes.desktopWrapper}>
                {renderHeader()}
                {renderFilters()}
            </div>
        );
    };

    return (
        <div className={classes.container}>
            {renderMobile()}
            {renderDesktop()}
        </div>
    );
};

export default SearchPanelFilters;
