import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    labelWrapper: {
        display: 'flex',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F7FBFF',
    },
    infoWrapper: {
        paddingLeft: 4,
    },
    inputLabel: {
        marginRight: '0.5rem',
    },
});
