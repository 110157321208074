import { useState } from 'react';

export const useLocalStorage = <T,>(
    key: string,
    initialValue: T,
): [string, typeof setValue, typeof removeItem] => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: T): void => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {}
    };

    const removeItem = (): boolean => {
        try {
            localStorage.removeItem(key);
            setStoredValue(null);
            return true;
        } catch (error) {
            return false;
        }
    };

    return [storedValue, setValue, removeItem];
};
