// @ts-nocheck
import React, { FC, useState } from 'react';
import { ArrayHelpers, FieldArray, Formik, FormikHelpers, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { isArray } from 'lodash';
import dayjs from 'dayjs';
import { useStyles } from './styles';
import { initialValues, ScheduleCandidateMeetingDialog, validationSchema } from './config';
import BlurredStyleFormikTextField from '../../../../components/BlurredStyleFormikTextField';
import FormikDatePicker from '../../../../components/formik/FormikDatePicker';
import FormikTimePicker from '../../../../components/formik/FormikTimePicker';
import { createMeeting } from '../../../../services/JobOfferMeetingService';
import FormikErrorText from '../../../../components/formik/FormikErrorText';
import { PostJobOfferFormValues } from '../../../../models/JobOffer';
import CloseDialogElement from '../../../../components/CloseDialogElement';

interface ScheduleCandidateDialogProps {
    applicationId: number;
    onStatusUpdate: () => void;
}

const ScheduleCandidateDialog: FC<ScheduleCandidateDialogProps> = ({ applicationId, onStatusUpdate }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleSubmitForm = async (
        dialogFormData: ScheduleCandidateMeetingDialog,
        { setSubmitting }: FormikHelpers<PostJobOfferFormValues>,
    ) => {
        try {
            await createMeeting(applicationId, {
                callUrl: dialogFormData.callUrl,
                message: dialogFormData.message,
                name: dialogFormData.name,
                dateProposals: dialogFormData.dateProposals.map(date => {
                    return {
                        date: date.date,
                        startTime: dayjs(date.startTime).format('HH:mm'),
                        endTime: dayjs(date.endTime).format('HH:mm'),
                    };
                }),
            });
            await onStatusUpdate();
            enqueueSnackbar(t('Successfully scheduled meeting.'), { variant: 'success' });
        } catch (e) {
            enqueueSnackbar(t('Failed to schedule meeting.'), { variant: 'error' });
        } finally {
            setOpen(false);
            setSubmitting(false);
        }
    };

    const renderDateFields = (arrayHelpers: ArrayHelpers) => {
        const dateProposals = getIn(arrayHelpers.form.values, arrayHelpers.name);

        return (
            <div>
                <InputLabel>When</InputLabel>
                {dateProposals.map((dateProposal, index) => (
                    <div>
                        <div key={index} className={classes.datePickWrapper}>
                            <FormikDatePicker
                                disableLabel
                                name={`dateProposals[${index}].date`}
                                disablePast
                            />
                            <div className={classes.timePickWrapper}>
                                <FormikTimePicker
                                    disableLabel
                                    name={`dateProposals[${index}].startTime`}
                                    minutesStep={5}
                                    keyboardIcon={<AccessTimeOutlinedIcon />}
                                />
                                <FormikTimePicker
                                    disableLabel
                                    name={`dateProposals[${index}].endTime`}
                                    minutesStep={5}
                                    keyboardIcon={<AccessTimeOutlinedIcon />}
                                />
                                <IconButton
                                    className={classes.removeButton}
                                    onClick={() => {
                                        arrayHelpers.remove(index);
                                    }}
                                    disabled={dateProposals.length === 1}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        {index !== dateProposals.length - 1 && <Divider className={classes.divider} />}
                    </div>
                ))}
            </div>
        );
    };

    const renderButton = (arrayHelpers: ArrayHelpers, { dateProposals }) => {
        if (dateProposals.length <= 2) {
            return (
                <Button
                    className={classes.addButton}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        if (dateProposals.length <= 2) {
                            arrayHelpers.push({
                                date: new Date(),
                                startTime: new Date(),
                                endTime: new Date(),
                            });
                        }
                    }}
                >
                    + {t('Add date')}
                </Button>
            );
        }
        return <></>;
    };

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    setOpen(true);
                }}
                className={classes.button}
            >
                {t('Schedule')}
            </Button>
            <Dialog
                onClose={() => {
                    setOpen(false);
                }}
                open={open}
                classes={{ paperWidthSm: classes.dialog }}
            >
                <CloseDialogElement onClick={() => setOpen(false)} />

                <Typography className={classes.feedback}>{t('Schedule a meeting')}</Typography>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}
                >
                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                    {({ values, handleSubmit, errors, isSubmitting }) => {
                        return (
                            <form onSubmit={handleSubmit} className={classes.form}>
                                <BlurredStyleFormikTextField
                                    name="name"
                                    label={t('Name')}
                                    placeholder={t('Meeting name')}
                                />

                                <FieldArray name="dateProposals">
                                    {arrayHelpers => (
                                        <>
                                            {renderDateFields(arrayHelpers)}
                                            {renderButton(arrayHelpers, values)}
                                            {!isArray(errors.dateProposals) && (
                                                <FormikErrorText name="dateProposals" />
                                            )}
                                        </>
                                    )}
                                </FieldArray>

                                <BlurredStyleFormikTextField
                                    name="callUrl"
                                    label={t('Video call')}
                                    placeholder={t('Video call link')}
                                />
                                <BlurredStyleFormikTextField
                                    name="message"
                                    label={t('Message')}
                                    placeholder={t('Message')}
                                    multiline
                                    rows={4}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className={classes.sendButton}
                                    disabled={isSubmitting}
                                >
                                    {t('Submit')}
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};

export default ScheduleCandidateDialog;
