import { ChangeEvent, FC, useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import AnalyticsContent from './AnalyticsContent';
import Analytics from '../../../models/Analytics';
import { getAnalytics } from '../../../services/AdminService';

const AdminAnalyticsPage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState<number>(0);
    const [analyticsData, setAnalyticsData] = useState<Analytics | null>(null);

    useEffect(() => {
        getAnalytics().then(analyticsData => {
            setAnalyticsData(analyticsData);
        });
    }, []);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const tabs = [
        {
            label: 'General',
            tab: <AnalyticsContent title="General" analyticsData={analyticsData?.general} />,
        },
        {
            label: 'Candidates',
            tab: <AnalyticsContent title="Candidates" analyticsData={analyticsData?.candidates} />,
        },
        {
            label: 'Employers',
            tab: <AnalyticsContent title="Employers" analyticsData={analyticsData?.companies} />,
        },
    ];

    return (
        <Container className={classes.container}>
            <Tabs
                classes={{ indicator: classes.indicator }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabs.map(({ label }) => (
                    <Tab key={label} classes={{ root: classes.tabRoot }} label={t(label)} />
                ))}
            </Tabs>
            {tabs.map(({ label, tab }, index) => (
                <div key={label} className={classes.wrapper}>
                    {value === index && tab}
                </div>
            ))}
        </Container>
    );
};

export default AdminAnalyticsPage;
