// @ts-nocheck

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import { useReactMediaRecorder } from '@dragan1810/use-media-recorder';
import MicrophoneIcon from '../../assets/audio/microphone.svg';
import RecordingIcon from '../../assets/audio/recording.svg';

import { useStyles } from './styles';

interface AudioRecorderProps {
    onLoadAudio: (blobUrl: string, index: number) => void;
    index: number;
    maxDuration?: number;
    recordingStatusUpdated?: (status: boolean) => void;
    disabled?: boolean;
    displayTimeLeft?: boolean;
}

const AudioRecorder: FC<AudioRecorderProps> = ({
    index,
    onLoadAudio,
    maxDuration,
    recordingStatusUpdated,
    disabled,
    displayTimeLeft,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
        audio: true,
    });

    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const handleStartRecording = () => {
        if (disabled !== undefined && !disabled) {
            setIsActive(true);
            startRecording();
            recordingStatusUpdated(true);
        } else if (disabled === undefined) {
            setIsActive(true);
            stopRecording();
        }
    };

    const handleStopRecording = () => {
        setSeconds(0);
        setIsActive(false);
        stopRecording();
        recordingStatusUpdated(false);
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        onLoadAudio(mediaBlobUrl, index);

        if (maxDuration) {
            let interval = null;
            if (isActive) {
                interval = setInterval(() => {
                    setSeconds(seconds => seconds + 1);
                    if (seconds + 1 === maxDuration) {
                        handleStopRecording();
                    }
                }, 1000);
            } else if (!isActive && seconds !== 0) {
                handleStopRecording();
            }
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [index, mediaBlobUrl, isActive, seconds]);

    if (isActive || status === 'recording' || status === 'stopping') {
        return (
            <div>
                <div className={classes.audioWrapper}>
                    <img src={RecordingIcon} height={49} alt="record audio" />
                    <Button
                        variant="outlined"
                        disabled={status === 'stopping'}
                        className={classes.outlinedButton}
                        onClick={handleStopRecording}
                    >
                        {t('Stop')}
                    </Button>
                    {displayTimeLeft && (
                        <Typography className={classes.timeLeft}>
                            {t('Time left')}: {maxDuration - seconds} sec.
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    if (status === 'idle' || status === 'acquiring_media' || status === 'stopped') {
        return (
            <div>
                <div className={classes.audioWrapper}>
                    <img src={MicrophoneIcon} height={49} alt="record audio" />
                    <Button
                        variant="outlined"
                        className={classes.outlinedButton}
                        onClick={handleStartRecording}
                    >
                        {t(status === 'stopped' ? 'Rerecord' : 'Start')}
                    </Button>
                </div>
            </div>
        );
    }
    return null;
};

export default AudioRecorder;
