import * as yup from 'yup';
import i18n from 'i18next';

export interface EmployerLoginFormValues {
    username: string;
    password: string;
}

export const initialValues: EmployerLoginFormValues = {
    username: '',
    password: '',
};

export const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Email is required'))
        .email(i18n.t('Email must be valid')),
    password: yup
        .string()
        .trim(i18n.t('Field cannot begin and end with a whitespace'))
        .required(i18n.t('Password is required')),
});
