import axios from 'axios';
import Media from '../models/Media.model';

export const uploadMediaFile = async (file: File): Promise<Media> => {
    const data = new FormData();
    data.append('file', file);
    const { data: responseData } = await axios.post(`media`, data);
    return responseData;
};

export const uploadAnswerAudio = async (file: File): Promise<Media> => {
    const data = new FormData();
    data.append('file', file);
    const { data: responseData } = await axios.post(`media/answer`, data);
    return responseData;
};

export const uploadDocument = async (file: File): Promise<Media> => {
    const data = new FormData();
    data.append('file', file);
    const { data: responseData } = await axios.post(`media/document`, data);
    return responseData;
};

export const uploadImageBlob = async (blob: Blob | string): Promise<Media> => {
    const formData = new FormData();
    formData.append('file', blob, 'blob.png');
    const { data: responseData } = await axios.post(`media`, formData);
    return responseData;
};
