import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { LandingPageSectionStyleProps } from './types';

export interface Props extends LandingPageSectionStyleProps {
    title: string;
    sliderWrapper?: boolean;
}

const LandingPageSection: FC<Props> = ({
    children,
    title,
    titleColor,
    sectionBackgroundColor,
    sliderWrapper,
}) => {
    const classes = useStyles({ titleColor, sectionBackgroundColor });
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Container className={clsx({ [classes.sliderWrapper]: sliderWrapper })}>
                <Typography className={classes.title}>{t(title)}</Typography>
                {children}
            </Container>
        </div>
    );
};

export default LandingPageSection;
