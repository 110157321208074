export const TermsOfUseContent = [
    {
        text: '1. Definitions',
        isTitle: true,
    },
    {
        text:
            '1.1 In this agreement: “Onboard” Refers to Onboard Jobs Limited, www.onboard.careers or any employee of the company or agent acting for it.',
        isTitle: false,
    },
    {
        text: '“Employer” means the person, firm or corporate body who posts a job on Onboard.',
        isTitle: false,
    },
    {
        text: '“Candidate” means the person who applies for a job submitted to Onboard by the employer.',
        isTitle: false,
    },
    {
        text:
            '“Engagement” means the engagement, employment or use of the Candidate by the Employer or by any Third Party to whom or to which the Candidate was Introduced by the Employer (whether with or without Onboard Jobs Limited’s knowledge) on a permanent or temporary basis.',
        isTitle: false,
    },
    {
        text:
            '“Introduction” means the sharing of any materials submitted by the Candidate to Onboard including their curriculum vitae, work or education history, any personal information or their voice application recordings.',
        isTitle: false,
    },
    {
        text:
            '“Personal Data” means as set out in, and will be interpreted in accordance with relevant Data Protection Legislation.',
        isTitle: false,
    },
    {
        text:
            '“Personal Data Breach” means the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, Personal Data transmitted, stored or otherwise Processed in connection with these Terms or which relates to any Candidate.',
        isTitle: false,
    },
    {
        text:
            '“Process” means as set out in, and will be interpreted in accordance with Data Protection Legislation.',
        isTitle: false,
    },
    {
        text:
            '“Remuneration” means the total base salary contractually agreed to when a Candidate is officially hired by the Employer.',
        isTitle: false,
    },
    {
        text: '“Third Party” means any company or person who is not the Employer.',
        isTitle: false,
    },
    {
        text: '2. Obligations of Onboard',
        isTitle: true,
    },
    {
        text:
            '2.1 Onboard cannot guarantee jobs posted to the platform will receive applications from Candidates.',
        isTitle: false,
    },
    {
        text:
            '2.2 Onboard accepts no responsibility in respect of matters outside its knowledge, including any false or misleading information submitted by the Candidate and the Employer must satisfy itself as to the suitability of the Candidate.',
        isTitle: false,
    },
    {
        text: '3. Employer Obligations',
        isTitle: true,
    },
    {
        text:
            '3.1 The employer agrees to notify Onboard immediately when its offer of an Engagement to the Candidate has been accepted and to provide details of the Candidate’s Remuneration to Onboard and pay Onboard’s fee within the period set out under clause 5.1.',
        isTitle: false,
    },
    {
        text:
            '3.2 The Employer shall not seek to unlawfully discriminate in relation to the services provided by Onboard to the Employer in connection with these terms and shall disclose any and all information requested by Onboard in the event a Candidate makes a complaint to Onboard.',
        isTitle: false,
    },
    {
        text:
            '3.3 The Employer warrants that it shall not, and shall procure that its employees and agents shall not, pass any information concerning a Candidate to any Third Party. The Employer acknowledges that any the profile and personal information of Candidates are confidential and that failure to comply with this clause may cause Onboard to breach Data Protection Legislation and accordingly, the Employer agrees to indemnify Onboard from any and all liability in connection with the Employer’s breach of this clause.',
        isTitle: false,
    },
    {
        text: '4. Fees',
        isTitle: true,
    },
    {
        text:
            '4.1 Where the Employer discloses to a Third Party any details regarding a Candidate and that Third Party subsequently Engages the Candidate within 12 months from the date of the Introduction, the Employer agrees to pay Onboard’s fee as set out in clause 4.3. There is no entitlement to any rebate or refund to the Employer or to the Third Party in relation to fees paid in accordance with clause 4.1.',
        isTitle: false,
    },
    {
        text:
            '4.2 The Employer agrees to pay Onboard a fee calculated in accordance with clause 4.3 where it Engages, whether directly or indirectly, any Candidate within 12 months from the date of Onboard’s Introduction.',
        isTitle: false,
    },
    {
        text:
            '4.3 The fee will be calculated as a percentage equal to 5% of the Candidate’s Remuneration applicable during the first 12 months of the Engagement. Onboard will charge VAT on the fee where applicable.',
        isTitle: false,
    },
    {
        text:
            '4.4 Where the Engagement is for a fixed term of less than 12 months, the fee in clause 4.3 will apply pro-rata. Where the Engagement is extended beyond the initial fixed term or where the Employer re-Engages the Candidate within 6 months from the date of planned or actual termination (as applicable) of the first Engagement, the Employer shall pay a further fee based on the Remuneration applicable for the period of Engagement following the initial fixed term period up to the termination of the second Engagement calculated in accordance with clause 4.3.',
        isTitle: false,
    },
    {
        text:
            '4.5 The Employer acknowledges that it has no right to set-off, withhold or deduct monies from sums due to Onboard under or in connection with these terms.',
        isTitle: false,
    },
    {
        text:
            '4.6 If the Candidate’s employment is terminated by the Employer within 3 months of Engagement, the Employer shall be entitled to Engage a replacement candidate at no additional fee through the Onboard website, provided Remuneration does not exceed that of the original position.',
        isTitle: false,
    },
    {
        text: '5. Invoices',
        isTitle: true,
    },
    {
        text:
            '5.1 Onboard shall raise invoices in respect of the charges payable and the Employer agrees to pay the amount due within 30 days of the date of the invoice.',
        isTitle: false,
    },
    {
        text:
            '5.2 All invoices will be deemed to be accepted in full by the Employer in accordance with the payment terms stated within clause 5.1 unless the Employer notifies Onboard, in writing within 5 days of receiving the invoice, stating the amount the Employer disputes and the reason the Employer disputes that amount. In the event the Employer does so notify Onboard that it wishes to dispute part of an invoice, the Employer agrees to pay the undisputed part of the invoice within the agreed payment terms and shall co-operate fully with Onboard in order to resolve the dispute as quickly as possible.',
        isTitle: false,
    },
];
