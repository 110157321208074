import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const theme: Theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            root: {
                color: '#000000',
                fontSize: '0.875rem',
                lineHeight: '2rem',
                fontWeight: 700,
                '&:first-letter': {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                borderRadius: 8,
                height: '3rem',
            },
            contained: {
                '&:hover': {
                    boxShadow: '0 2px 17px 0 rgba(0,127,252,0.52)',
                },
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: '#007FFC',
                },
            },
        },
        MuiDialog: {
            paper: {
                margin: '32px 16px',
            },
        },
        MuiMenu: {
            paper: {
                boxShadow: '0 2px 9px 0 rgba(0,0,0,0.07)',
            },
        },
        MuiContainer: {
            maxWidthLg: {
                maxWidth: '73.25rem',
                '@media (min-width: 1280px)': {
                    maxWidth: '73.25rem',
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '16px 14px',
            },
            notchedOutline: {
                borderColor: 'rgba(151,151,151,0.14)',
            },
        },
        MuiLink: {
            underlineHover: {
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        },
    },
    palette: {
        background: {
            default: '#FFFFFF',
        },
        primary: {
            light: '#F8FBFF',
            main: '#007FFC',
            dark: '#3A9CEC',
        },
    },
});
