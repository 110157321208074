// @ts-nocheck

import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AudioRecorder from '../../../components/AudioRecorder';
import AudioPlayer from '../../../components/AudioPlayer';

import { uploadAnswerAudio } from '../../../services/MediaService';
import {
    checkIfCandidateHasAppliedJobOffer,
    postCandidateApplication,
} from '../../../services/JobOfferApplicationsService';
import { getJobOfferDetails } from '../../../services/JobOfferService';
import { useStyles } from './styles';
import { ROUTES } from '../../../config/constants';
import CandidateApplyInfoDialog from './CandidateApplyInfoDialog';

const ANSWERS_AMOUNT = 2;

const CandidateApplyPage: FC = () => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [allow, setAllow] = useState<boolean | null>(null);
    const [recording, setRecording] = useState<boolean>(false);
    const [answers, setAnswers] = useState<(string | null)[]>(new Array(ANSWERS_AMOUNT).fill(null));
    const [questions, setQuestions] = useState<string[]>([]);
    const [jobInfo, setJobInfo] = useState<{ title: string; companyName: string }>({
        title: '',
        companyName: '',
    });
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        checkIfCandidateHasAppliedJobOffer(id)
            .then(response => setAllow(!response))
            .catch(() => {
                setAllow(false);
                enqueueSnackbar(t('Unable to check offer status, try again later'), { variant: 'error' });
            });
    }, [enqueueSnackbar, id, t]);

    useEffect(() => {
        getJobOfferDetails(id)
            .then(response => {
                const { questions, companyName, title } = response;
                setQuestions(questions);
                setJobInfo({ companyName, title });
            })
            .catch(() =>
                enqueueSnackbar(t('Unable to fetch content, try again later'), { variant: 'error' }),
            );
    }, [enqueueSnackbar, id, t]);

    const setQuestionResponse = (mediaBlobUrl: string) => {
        return fetch(mediaBlobUrl)
            .then(r => r.blob())
            .then(blobFile => new File([blobFile], 'audioRecord.wav', { type: 'audio/wave' }))
            .then(file => {
                return uploadAnswerAudio(file)
                    .then(response => response)
                    .catch(() =>
                        enqueueSnackbar(t('Unable to upload files, try again later'), { variant: 'error' }),
                    );
            });
    };

    const canUserApply = (): boolean => {
        return true;
    };

    const submitApplication = async () => {
        if (!canUserApply()) {
            return;
        }
        if (answers.some(answer => answer === null) || recording) {
            enqueueSnackbar(t('You must answer all questions before apply'), { variant: 'error' });
            return;
        }
        setSubmitting(true);
        try {
            const promises = answers.map(async answer => {
                const answerFile = await setQuestionResponse(answer);
                return answerFile;
            });
            const answerFiles = await Promise.all(promises);

            await postCandidateApplication({ answers: answerFiles, jobOfferId: toNumber(id) });
            setSuccess(true);
        } catch (error) {
            enqueueSnackbar(t('Unable to send application, try again later'), { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    const setAnswer = (mediaBlobUrl: string, index: number) => {
        if (mediaBlobUrl) {
            const newAnswers = [...answers];
            newAnswers.splice(index, 1, mediaBlobUrl);
            setAnswers(newAnswers);
        }
    };

    if (success) {
        return (
            <Container className={classes.container}>
                <div className={classes.successWrapper}>
                    <CheckCircleIcon className={classes.checkIcon} color="primary" />
                    <Typography className={classes.okTitle}>{t('Application submitted')}</Typography>
                </div>
                <div className={classes.thanksWrapper}>
                    <Typography component="span" className={classes.thanksText}>
                        {t('Thank you for applying to ')}
                    </Typography>
                    <Typography component="span" className={clsx(classes.thanksText, classes.jobInfoText)}>
                        {jobInfo.title}
                    </Typography>
                    <Typography component="span" className={classes.thanksText}>
                        {t(' at ')}
                    </Typography>
                    <Typography component="span" className={clsx(classes.thanksText, classes.jobInfoText)}>
                        {jobInfo.companyName}
                    </Typography>
                    <Typography component="span" className={classes.thanksText}>
                        {t(`. Your application is being reviewed and you’ll receive an
                update once a decision has been made. You can view the status
                of all your applications at any time via the main menu.`)}
                    </Typography>
                </div>
                <Button
                    component={RouterLink}
                    to={ROUTES.CANDIDATE_FIND_A_JOB}
                    variant="contained"
                    color="primary"
                    className={classes.okButton}
                >
                    {t('OK')}
                </Button>
            </Container>
        );
    }

    if (allow === null) {
        return null;
    }

    if (!allow) {
        return (
            <Container className={classes.container}>
                <Typography className={classes.header}>{t('You already applied for this job')}</Typography>
                <Button
                    component={RouterLink}
                    to={ROUTES.CANDIDATE_FIND_A_JOB}
                    variant="contained"
                    color="primary"
                >
                    {t('Go to job offers')}
                </Button>
            </Container>
        );
    }

    return (
        <Container className={classes.container}>
            <CandidateApplyInfoDialog />
            <Typography className={classes.header}>{t('Apply for this job')}</Typography>
            {questions.map((question, index) => (
                <div className={classes.answerWrapper} key={index}>
                    <div>
                        <Typography className={classes.question}>{question}</Typography>
                        {!!answers[index] && (
                            <AudioPlayer
                                key={`${answers[index]}${index}`}
                                src={answers[index] as string}
                                disabled={recording}
                            />
                        )}
                    </div>
                    <AudioRecorder
                        key={index}
                        index={index}
                        onLoadAudio={setAnswer}
                        maxDuration={90}
                        disabled={recording}
                        recordingStatusUpdated={e => setRecording(e)}
                        displayTimeLeft
                    />
                </div>
            ))}
            <Button
                color="primary"
                variant="contained"
                onClick={submitApplication}
                disabled={isSubmitting || recording}
                className={classes.applyButton}
            >
                {t('APPLY FOR THIS ROLE')}
            </Button>
        </Container>
    );
};

export default CandidateApplyPage;
