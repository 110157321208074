import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1.5rem 3rem',
        alignItems: 'flex-start',
    },
    profileDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1rem 2rem',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    switchWrapper: {
        margin: '0 0 12px',
    },
    avatarUpload: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    removeAvatarButton: {
        marginTop: 10,
        height: '2rem',
        width: '9rem',
        fontSize: '0.8125rem',
        borderRadius: 4,
        letterSpacing: '0.5px',
    },
    container: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #DFE3E9',
        padding: '24px 22px 26px',
        margin: '1.5rem auto 3rem',
    },
    submitButton: {
        fontSize: '0.875rem',
        fontWeight: 700,
        padding: '1rem 1.5rem',
        letterSpacing: '0.46px',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: '2rem 0',
    },
}));
