import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import BlurredStyleFormikTextField from '../../../components/BlurredStyleFormikTextField';
import CustomPagination from '../../../components/CustomPagination';
import JobOfferCard from '../../../components/JobOfferCard';

import { initialValues } from './config';
import { useStyles } from './styles';
import SeeMoreIcon from '../../../assets/landing/seeMoreIcon.png';
import { getSearchEmployerJobOffers } from '../../../services/JobOfferService';
import { JobOffer } from '../../../models/JobOffer';
import { ROUTES } from '../../../config/constants';
import { usePaginationFetch } from '../../../config/hooks/usePaginationFetch';
import JobOfferCardsWrapper from '../../../components/JobOfferCardsWrapper';

const EmployerHomePage: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [archived, setArchived] = useState<boolean>(false);
    const searchButtonRef = useRef(null);

    const {
        content,
        dataFilters,
        totalPages,
        totalElements,
        setRefetch,
        setDataFilters,
    } = usePaginationFetch<JobOffer>({
        service: getSearchEmployerJobOffers,
    });

    const renderCheckbox = () => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={archived}
                    onChange={() => {
                        setArchived(archived => !archived);
                        if (searchButtonRef) {
                            // @ts-ignore
                            searchButtonRef.current.click();
                        }
                    }}
                    name="archived"
                    color="primary"
                />
            }
            className={classes.formControlLabel}
            label={t('Show archived job postings')}
        />
    );

    const renderJobs = () => (
        <JobOfferCardsWrapper>
            {content.map(job => (
                <JobOfferCard
                    key={job.id}
                    editable
                    removeCallback={() => {
                        setRefetch(update => !update);
                    }}
                    {...job}
                />
            ))}
        </JobOfferCardsWrapper>
    );

    const handleSubmitForm = async (values: { search: string }) => {
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
            filters: archived
                ? [
                      { column: 'archived', values: ['true'] },
                      { column: 'search', values: [values.search.trim()] },
                  ]
                : [{ column: 'search', values: [values.search.trim()] }],
        }));
    };

    const renderPagination = () => {
        if (totalPages > 1) {
            return (
                <CustomPagination
                    count={totalPages}
                    onChange={(event, newPage) => {
                        setDataFilters(dataFilters => ({ ...dataFilters, page: newPage - 1 }));
                    }}
                    variant="outlined"
                    color="primary"
                />
            );
        }
        return null;
    };

    const renderForm = () => (
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
            {formik => (
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <BlurredStyleFormikTextField
                        name="search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />{' '}
                                </InputAdornment>
                            ),
                        }}
                        disableLabel
                    />
                    <Button
                        ref={searchButtonRef}
                        type="submit"
                        variant="outlined"
                        endIcon={<img src={SeeMoreIcon} alt="next page" />}
                        color="primary"
                        className={classes.searchButton}
                    >
                        {t('Search')}
                    </Button>
                </form>
            )}
        </Formik>
    );

    if (totalElements === null) {
        return (
            <Fade in timeout={1000}>
                <div className={classes.centerWrapper}>
                    <CircularProgress />
                </div>
            </Fade>
        );
    }

    if (totalElements === 0) {
        return (
            <Fade in>
                <Container className={clsx(classes.container, classes.containerAddJob)}>
                    {renderForm()}
                    {renderCheckbox()}
                    {dataFilters.filters.filter(
                        filter =>
                            filter.column === 'archived' &&
                            filter.values.filter(filterValue => filterValue === 'true').length,
                    ).length ? (
                        <Alert severity="info">{t('No results found for your search')}</Alert>
                    ) : (
                        <div className={classes.addJob}>
                            <Button
                                component={RouterLink}
                                to={ROUTES.EMPLOYER_POST_JOB}
                                variant="contained"
                                color="primary"
                            >
                                {t('Add your first job posting')}
                            </Button>
                        </div>
                    )}
                </Container>
            </Fade>
        );
    }

    return (
        <Fade in>
            <Container className={classes.container}>
                {renderForm()}
                {renderCheckbox()}
                {renderJobs()}
                {renderPagination()}
            </Container>
        </Fade>
    );
};

export default EmployerHomePage;
