import * as yup from 'yup';
import i18n from 'i18next';

import { TeamMember } from '../../models/Company';

export const initialValues: TeamMember = {
    name: '',
    position: '',
    avatar: null,
};

export const validationSchema = yup.object().shape({
    name: yup
        .string()
        .max(200, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
    position: yup
        .string()
        .max(200, `${i18n.t('Maximum number of characters is')} \${max}`)
        .required(i18n.t('Field is required')),
});
