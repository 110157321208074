import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Field } from 'formik';
import { Checkbox as FormikCheckbox } from 'formik-material-ui';
import { useStyles } from './styles';
import FormikTextField from '../../../components/formik/FormikTextField';
import ArrowRight from '../../../assets/landing/arrowRight.svg';
import { ROUTES } from '../../../config/constants';
import { CurrentUserReducer, RootReducer } from '../../../models/Redux';
import { UserRoles } from '../../../models/enums/UserRoles';
import GoogleLocationFormikAutocomplete from '../../../components/GoogleLocationFormikAutocomplete';

const SearchPanelHeader: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const landingPage = pathname === ROUTES.LANDING;

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const renderVerticalDivider = () => (
        <div className={classes.verticalDividerOuter}>
            <div className={classes.verticalDividerInner} />
        </div>
    );

    const renderFeaturedButton = () => {
        if (role === UserRoles.ADMIN) {
            return (
                <FormControlLabel
                    control={
                        <Field component={FormikCheckbox} color="primary" type="checkbox" name="featured" />
                    }
                    label={t('Featured offers')}
                    classes={{ label: classes.featuredButton }}
                />
            );
        }
        return null;
    };

    return (
        <>
            <div className={classes.form}>
                <div
                    className={clsx(classes.fieldsWrapper, {
                        [classes.fieldsWrapperCandidate]: !landingPage,
                    })}
                >
                    <FormikTextField
                        name="search"
                        InputProps={{
                            classes: {
                                focused: classes.focused,
                                // @ts-ignore
                                notchedOutline: classes.notchedOutline,
                                input: clsx(classes.textFieldInput, classes.informationInput),
                                root: clsx(classes.textFieldRoot, {
                                    [classes.informationInputCandidate]: !landingPage,
                                }),
                            },
                        }}
                        margin="none"
                        variant="outlined"
                        placeholder={t(' Job title, skill or company')}
                        disableLabel
                    />
                    {renderVerticalDivider()}
                    <GoogleLocationFormikAutocomplete
                        name="location"
                        InputProps={{
                            classes: {
                                focused: classes.focused,
                                // @ts-ignore
                                notchedOutline: classes.notchedOutline,
                                input: clsx(classes.textFieldInput, classes.locationInput),
                                root: classes.textFieldRoot,
                            },
                        }}
                        placeholder={t('Town, city or postcode')}
                        disableLabel
                    />
                </div>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    classes={{
                        root: classes.submitButton,
                        label: classes.submitButtonLabel,
                        endIcon: classes.buttonIcon,
                    }}
                    endIcon={<img src={ArrowRight} alt="submit" width="16" height="12" />}
                >
                    {t('Search job')}
                </Button>
            </div>
            {renderFeaturedButton()}
        </>
    );
};

export default SearchPanelHeader;
