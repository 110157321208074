import React, { FC, useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useSnackbar } from 'notistack';

import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';

import { InputLabelProps as InputLabelPropsTypes } from '@material-ui/core/InputLabel/InputLabel';

import CustomTextField from '../CustomTextField';
import { getTags } from '../../services/JobOfferService';
import { useStyles } from './styles';

interface CustomFormikAutocompleteProps {
    name: string;
    label?: string;
    disableLabel?: boolean;
    InputLabelProps?: InputLabelPropsTypes;
}

const TagsFormikAutocomplete: FC<CustomFormikAutocompleteProps> = ({
    disableLabel,
    InputLabelProps,
    name,
    label,
    ...otherProps
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { value } = useField(name)[1];
    const { setValue } = useField(name)[2];
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        getTags()
            .then(response => setOptions(response))
            .catch(() => enqueueSnackbar(t('Unable to fetch tags, try again later'), { variant: 'error' }));
    }, [enqueueSnackbar, t]);

    const setNewTags = (newTags: string[]): void => {
        setValue(newTags);
    };

    // @ts-ignore
    const handleKeyDown = event => {
        switch (event.key) {
            case ',':
            case ' ': {
                event.preventDefault();
                event.stopPropagation();
                if (event.target.value.length > 0) {
                    setNewTags([...value, event.target.value]);
                }
                break;
            }
            default:
        }
    };

    return (
        <div>
            {!disableLabel && (
                <InputLabel htmlFor={name} {...InputLabelProps}>
                    {label || name}
                </InputLabel>
            )}
            <Autocomplete
                freeSolo
                multiple
                value={value}
                filterSelectedOptions
                options={options}
                classes={{ inputRoot: classes.inputRoot }}
                onChange={(event, newValue) => {
                    setNewTags(newValue);
                }}
                getOptionLabel={option => option}
                renderInput={params => {
                    // @ts-ignore
                    params.inputProps.onKeyDown = handleKeyDown;

                    return <CustomTextField {...params} variant="outlined" placeholder={t('Choose')} />;
                }}
                renderOption={(option, { inputValue }) => {
                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={`${option}${index}`}
                                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
                {...otherProps}
            />
        </div>
    );
};

export default TagsFormikAutocomplete;
