import { makeStyles, Theme } from '@material-ui/core/styles';

interface TeamMemberAvatarStyleProps {
    size: number;
    fontSize: number;
}

export const useStyles = makeStyles<Theme, TeamMemberAvatarStyleProps>({
    boxSize: ({ size, fontSize }) => ({
        borderRadius: '50%',
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        fontWeight: 600,
        fontSize,
    }),
    image: ({ size }) => ({
        maxWidth: size,
        maxHeight: size,
        backgroundColor: 'rgba(77,161,255,0.1)',
    }),
    noImage: ({ size }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        color: '#4DA1FF',
        backgroundColor: 'rgba(77,161,255,0.1)',
    }),
});
