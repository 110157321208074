import { makeStyles } from '@material-ui/core/styles';
import { LANDING_BREAKPOINT_FIRST } from '../../../../config/constants';

export const useStyles = makeStyles(theme => ({
    addButton: {
        borderRadius: 4,
        borderWidth: 2,
        fontSize: '0.8125rem',
        lineHeight: '18px',
        letterSpacing: '0.1px',
        minWidth: '8.5rem',
        marginRight: '1rem',
        '&:focus, &:hover': {
            borderWidth: 2,
        },
    },
    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1rem',
        padding: '1rem',
        [theme.breakpoints.up(700)]: {
            minWidth: '40rem',
            gridTemplateColumns: '1fr 1fr',
        },
    },
    paper: {
        [theme.breakpoints.up(700)]: {
            maxWidth: '40rem',
        },
    },
    submitButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
    },
    companyLogo: {
        gridRow: '2/2',
        [theme.breakpoints.up(LANDING_BREAKPOINT_FIRST)]: {
            gridRow: '1/3',
            gridColumn: '2/3',
        },
    },
}));
