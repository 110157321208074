import { makeStyles } from '@material-ui/core/styles';

const BORDER_RADIUS_WIDTH = '8px';

export const useStyles = makeStyles({
    focused: {},
    notchedOutline: {
        border: 'none',
        boxShadow: '0 0 17px 0 rgba(0,0,0,0.06)',
    },
    input: {
        height: 45,
        boxSizing: 'border-box',
        fontSize: 13,
        color: '#15416D',
        lineHeight: '16px',
        fontWeight: 400,
        borderRadius: BORDER_RADIUS_WIDTH,
        '&::placeholder': {
            color: '#616161',
            fontSize: 13,
            lineHeight: '16px',
            letterSpacing: '0.5px',
            fontWeight: 400,
            opacity: 0.6,
        },
    },
    root: {
        backgroundColor: '#FFFFFF',
        '&$focused $notchedOutline': {
            boxShadow: '0 0 17px 0 rgba(0,0,0,0.18)',
        },
        '&:hover $notchedOutline': {
            boxShadow: '0 0 17px 0 rgba(0,0,0,0.18)',
        },
        marginTop: '0.75rem',
    },
    labelRoot: {
        marginTop: 20,
        lineHeight: '18px',
        color: '#16133E',
        letterSpacing: '0.5px',
    },
});
