import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayHelpers, useFormikContext, FieldArray } from 'formik';

import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useStyles } from './styles';

type FilterOption = { name: string; id: number };

interface SearchPanelFilterFormProps {
    name: string;
    options?: FilterOption[];
}

const SearchPanelFilterForm: FC<SearchPanelFilterFormProps> = ({ name, options }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { values } = useFormikContext();

    const renderOption = ({ id, name: label }: FilterOption, { push, remove }: ArrayHelpers) => {
        return (
            <FormControlLabel
                key={id}
                classes={{ root: classes.formControlRoot, label: classes.label }}
                control={
                    <Checkbox
                        color="primary"
                        // @ts-ignore
                        checked={values[name].includes(id)}
                        classes={{ root: classes.root, checked: classes.checked }}
                        onChange={e => {
                            if (e.target.checked) {
                                push(id);
                            } else {
                                // @ts-ignore
                                const idx = values[name].indexOf(id);
                                remove(idx);
                            }
                        }}
                    />
                }
                label={t(label)}
            />
        );
    };

    return (
        <FieldArray name={name}>
            {arrayHelpers => (
                <div className={classes.wrapper}>
                    {options?.map(option => renderOption(option, arrayHelpers))}
                </div>
            )}
        </FieldArray>
    );
};

export default SearchPanelFilterForm;
