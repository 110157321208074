import { FC } from 'react';

import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import EmployerLoginForm from './EmployerLoginForm';
import { useStyles } from './styles';

const EmployerLoginPage: FC = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Container>
                <Typography className={classes.title}>{t('Sign in')}</Typography>
                <EmployerLoginForm />
            </Container>
        </div>
    );
};

export default EmployerLoginPage;
