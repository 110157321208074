import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    button: {
        width: 102,
        margin: '2rem 0 0 0.5rem',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F7FBFF',
        paddingTop: '2.25rem',
        maxWidth: '40rem',
        width: '100%',
        padding: '2.25rem 0.5rem',
    },
    form: {
        maxWidth: '32rem',
        padding: '1.25rem 1rem 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    feedback: {
        fontSize: '1.625rem',
        fontWeight: 700,
        lineHeight: '4.25rem',
        letterSpacing: '0.48px',
        color: '#15416D',
    },
    sendButton: {
        margin: '2rem auto 0',
        width: 149,
    },
});
