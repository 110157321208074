import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        marginBottom: '3rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
});
