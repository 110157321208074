import { makeStyles } from '@material-ui/core/styles';

import { HEADER_BREAKPOINT, LOGO_FONT } from '../../config/constants';

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    desktopWrapper: {
        backgroundColor: '#F7FBFF',
        padding: '2rem 0',
        [theme.breakpoints.down(HEADER_BREAKPOINT)]: {
            display: 'none',
        },
    },
    mobileWrapper: {
        backgroundColor: '#F7FBFF',
        [theme.breakpoints.up(HEADER_BREAKPOINT)]: {
            display: 'none',
        },
    },
    whiteBackground: {
        backgroundColor: '#FFFFFF',
    },
    linkedButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
        width: 235,
    },
    signButton: {
        fontWeight: 500,
        padding: '1rem 1.5rem',
    },
    linksWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    link: {
        color: '#15416D',
        fontSize: '1rem',
        lineHeight: '1rem',
        fontWeight: 700,
        position: 'relative',
        marginRight: '3rem',

        '&:after': {
            bottom: -16,
            content: '""',
            display: 'block',
            height: 3,
            left: '50%',
            borderRadius: 3,
            position: 'absolute',
            background: '#15416D',
            transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
            width: 0,
        },
        '&:hover:after': {
            width: '100%',
            left: 0,
        },
    },
    activeLink: {
        color: '#007FFC',
        '&:after': {
            width: '100%',
            left: 0,
            backgroundColor: '#007FFC',
        },
    },
    linkedLabel: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    userActions: {
        marginLeft: '2rem',
    },
    logoFont: {
        fontFamily: LOGO_FONT,
        fontSize: '2.25rem',
        textDecoration: 'none',
        fontWeight: 700,
        color: '#15416D',
    },
}));
