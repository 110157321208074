import { FC, useState, MouseEvent } from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { logoutUser } from '../../../services/config/AuthenticationService';
import { useStyles } from './styles';

const HeaderActions: FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const handleClickPopover = (event: MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const id = open ? 'navbar-popover' : undefined;

    const renderPopoverContent = () => {
        return (
            <div>
                <List>
                    <ListItem onClick={logoutUser} button>
                        <Typography color="primary" className={classes.listItemText}>
                            Log out
                        </Typography>
                    </ListItem>
                </List>
            </div>
        );
    };

    const renderActionsPopover = () => (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {renderPopoverContent()}
        </Popover>
    );

    const renderActionIconButton = () => (
        <IconButton
            aria-describedby={id}
            onClick={handleClickPopover}
            classes={{ root: clsx(classes.actionButton, { [classes.actionButtonUp]: open }) }}
        >
            <ArrowDropDownIcon />
        </IconButton>
    );

    return (
        <>
            {renderActionIconButton()}
            {renderActionsPopover()}
        </>
    );
};

export default HeaderActions;
