import { makeStyles } from '@material-ui/core/styles';
import { JOB_OFFER_CARD_BREAKPOINT } from '../../../config/constants';

export const useStyles = makeStyles(theme => ({
    jobsContainer: {
        display: 'grid',
        justifyContent: 'space-around',
        gridTemplateColumns: 'repeat(2, auto)',
        gridGap: 24,
        [theme.breakpoints.up(1210)]: {
            justifyContent: 'space-between',
            gridTemplateColumns: 'repeat(4, auto)',
            gridGap: 0,
        },
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            display: 'none',
        },
    },
    jobsContainerMobile: {
        display: 'none',
        [theme.breakpoints.down(JOB_OFFER_CARD_BREAKPOINT)]: {
            display: 'flex',
            width: '100vw',
            overflowX: 'hidden',
        },
    },
}));
