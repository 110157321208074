import React, { FC, useMemo } from 'react';
import { ErrorMessage, useField } from 'formik';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor, { TMUIRichTextEditorProps } from 'mui-rte';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import { useStyles } from './styles';

interface FormikRichTextEditorProps extends TMUIRichTextEditorProps {
    editorHeight?: number;
    disableLabel?: boolean;
    label?: string;
    name: string;
    required?: boolean;
}

const FormikRichTextEditor: FC<FormikRichTextEditorProps> = ({
    label,
    name,
    disableLabel,
    editorHeight = 86,
    maxLength,
    readOnly,
}) => {
    const classes = useStyles({ editorHeight });
    const { value, initialValue } = useField(name)[1];
    const { setValue } = useField(name)[2];
    const defaultValue = initialValue || null;

    return useMemo(
        () => {
            return (
                <div>
                    <div className={classes.container}>
                        {!disableLabel && (
                            <InputLabel className={classes.inputLabel}>{label || name}</InputLabel>
                        )}
                        <MUIRichTextEditor
                            label="Start typing..."
                            controls={['bold', 'italic', 'numberList', 'bulletList']}
                            defaultValue={defaultValue}
                            toolbarButtonSize="small"
                            classes={{ toolbar: classes.toolbar, editorContainer: classes.editorContainer }}
                            onChange={state => {
                                // @ts-ignore
                                setValue(JSON.stringify(convertToRaw(state.getCurrentContent())));
                            }}
                            maxLength={maxLength}
                            readOnly={readOnly}
                        />
                    </div>
                    <ErrorMessage
                        name={name}
                        render={errorMessage => (
                            <FormHelperText variant="outlined" error className={classes.errorMessage}>
                                {errorMessage}
                            </FormHelperText>
                        )}
                    />
                </div>
            );
        },
        // eslint-disable-next-line
        [disableLabel, maxLength, readOnly, defaultValue, classes, label, name, value],
    );
};

export default FormikRichTextEditor;
