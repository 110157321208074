import React, { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isArray, toString } from 'lodash';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { InView } from 'react-intersection-observer';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './styles';
import { usePaginationFetch } from '../../../../config/hooks/usePaginationFetch';
import BlurredStyleFormikTextField from '../../../../components/BlurredStyleFormikTextField';
import UserCard from '../UserCard';
import { AdminPanelUser } from '../../../../models/Admin';
import { userPaginatedDataService } from './config';
import { AdminUsersPanelFormValues, initialValues } from '../config';
import { ROUTES } from '../../../../config/constants';

interface Props {
    usersType: 'candidates' | 'employers' | 'support';
}

const UsersPanel: FC<Props> = ({ usersType }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const { content, error, loading, setDataFilters, last } = usePaginationFetch<AdminPanelUser>({
        service: userPaginatedDataService[usersType],
        initialSize: 32,
        accumulateData: true,
    });

    const handleSubmitForm = async (values: AdminUsersPanelFormValues) => {
        const filters = Object.entries(values)
            .map(([key, value]) => {
                const newValue = key === 'search' ? value.trim() : value;
                return [key, newValue];
            })
            .map(([key, value]) => {
                const newValues = isArray(value) ? value.map(item => toString(item)) : [toString(value)];
                return {
                    column: key,
                    values: newValues,
                };
            });

        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
            filters,
        }));
    };

    const getNextPage = () => {
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: dataFilters.page + 1,
        }));
    };

    const renderLoader = () => {
        if (last || loading || error) return null;

        return (
            <InView
                threshold={1}
                onChange={inView => {
                    if (inView) {
                        getNextPage();
                    }
                }}
            >
                <LinearProgress className={classes.loader} />
            </InView>
        );
    };

    const renderCandidates = () =>
        content.map(candidate => <UserCard key={candidate.id} user={candidate} usersType={usersType} />);

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <div className={classes.candidatesWrapper}>{renderCandidates()}</div>
            </div>
        );
    };

    const renderAddMember = () => {
        if (usersType === 'employers') {
            return (
                <div className={classes.addEmployersWrapper}>
                    <Button
                        color="primary"
                        variant="outlined"
                        className={classes.addEmployerButton}
                        startIcon={<AddIcon />}
                        onClick={() => history.push(ROUTES.ADMIN_CREATE_EMPLOYER)}
                    >
                        {t('Add employer')}
                    </Button>
                </div>
            );
        }
        if (usersType === 'support') {
            return (
                <div className={classes.addEmployersWrapper}>
                    <Button
                        color="primary"
                        variant="outlined"
                        className={classes.addEmployerButton}
                        startIcon={<AddIcon />}
                        onClick={() => history.push(ROUTES.ADMIN_CREATE_SUPPORT)}
                    >
                        {t('Add support')}
                    </Button>
                </div>
            );
        }
        return null;
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
            {({ handleSubmit, submitForm }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.searchWrapper}>
                        <BlurredStyleFormikTextField
                            name="search"
                            placeholder={t('Search')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={classes.searchIcon}
                                        onClick={submitForm}
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disableLabel
                        />
                        {renderAddMember()}
                    </div>
                    {renderContent()}
                    {renderLoader()}
                </form>
            )}
        </Formik>
    );
};

export default UsersPanel;
