import { FC, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import JobOfferCardLoader from '../../../components/JobOfferCardLoader';
import LandingPageSection from '../../../parts/LandingPageSection';
import JobOfferCard from '../../../components/JobOfferCard';
import { ROUTES } from '../../../config/constants';
import SeeMoreIcon from '../../../assets/landing/seeMoreIcon.png';
import { JobOffer } from '../../../models/JobOffer';
import { getLatestJobOffers } from '../../../services/JobOfferService';
import JobOfferCardsWrapper from '../../../components/JobOfferCardsWrapper';

const FeaturedJobsPanel: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [page] = useState<number>(0);
    const [size] = useState<number>(4);
    const [jobs, setJobs] = useState<JobOffer[]>([]);

    useEffect(() => {
        // @ts-ignore
        getLatestJobOffers({ page, size, filters: [] })
            .then(response => {
                setJobs(response.content);
            })
            .catch(() => enqueueSnackbar(t('Unable to fetch data, try again later'), { variant: 'error' }));
    }, [enqueueSnackbar, page, size, t]);

    return (
        <LandingPageSection title="Latest jobs" titleColor="#15416D" sectionBackgroundColor="#FFFFFF">
            <JobOfferCardsWrapper>
                {jobs[0] ? <JobOfferCard {...jobs[0]} /> : <JobOfferCardLoader />}
                {jobs[1] ? <JobOfferCard {...jobs[1]} /> : <JobOfferCardLoader />}
                {jobs[2] ? <JobOfferCard {...jobs[2]} /> : <JobOfferCardLoader />}
                {jobs[3] ? <JobOfferCard {...jobs[3]} /> : <JobOfferCardLoader />}
                <Button
                    color="primary"
                    to={ROUTES.CANDIDATE_FIND_A_JOB}
                    className={classes.moreButton}
                    component={RouterLink}
                    endIcon={<img src={SeeMoreIcon} alt="see more" />}
                >
                    See more
                </Button>
            </JobOfferCardsWrapper>
        </LandingPageSection>
    );
};

export default FeaturedJobsPanel;
