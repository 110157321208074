import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';
import { CandidateJobExperienceForm, defaultInitialValues, validationSchema } from './config';
import CustomFormikTextField from '../../../../components/CustomFormikTextField';
import CustomFormikSwitch from '../../../../components/CustomFormikSwitch';
import FormikDatePicker from '../../../../components/formik/FormikDatePicker';
import GoogleLocationFormikAutocomplete from '../../../../components/GoogleLocationFormikAutocomplete';
import ClearbitLogoAutocomplete from '../../../../components/ClearbitLogoAutocomplete';
import FormikImageUpload from '../../../../components/formik/FormikImageUpload';

interface Props {
    onSubmitAction: (jobExperience: CandidateJobExperienceForm | null, index?: number) => void;
    action: 'add' | 'edit';
    index?: number;
    initialValues?: CandidateJobExperienceForm;
}

const AddExperienceDialog: FC<Props> = ({ onSubmitAction, action, index, initialValues }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderAddButton = () => {
        return (
            <IconButton
                title={t(action === 'add' ? 'Add experience' : 'Add changes')}
                color="primary"
                onClick={handleClick}
                size="small"
            >
                {action === 'add' ? <AddIcon /> : <EditIcon />}
            </IconButton>
        );
    };

    const handleSubmitForm = async (
        values: CandidateJobExperienceForm,
        { setSubmitting }: FormikHelpers<CandidateJobExperienceForm>,
    ) => {
        handleClose();
        // force async behaviour for prevent multiple submits
        await new Promise(resolve => setTimeout(resolve, 300));
        onSubmitAction(values, index);
        setSubmitting(false);
    };

    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues || defaultInitialValues}
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <ClearbitLogoAutocomplete
                            name="companyName"
                            label={t('Company name')}
                            logoFieldName="companyLogo"
                            setLogoValue
                            asMediaUrl
                        />
                        <GoogleLocationFormikAutocomplete
                            name="location"
                            placeholder={t('Location')}
                            label={t('Location')}
                        />
                        <div className={classes.companyLogo}>
                            <FormikImageUpload
                                name="companyLogo"
                                label={t('Company logo')}
                                withBorder={false}
                            />
                        </div>
                        <CustomFormikTextField
                            name="position"
                            placeholder={t('Position')}
                            label={t('Position')}
                        />
                        <FormikDatePicker
                            name="startDate"
                            placeholder={t('Start date')}
                            label={t('Start date')}
                            disableFuture
                        />
                        <FormikDatePicker
                            disabled={values.stillWorkingHere}
                            name="endDate"
                            placeholder={t('End date')}
                            label={t('End date')}
                            disableFuture
                        />
                        <CustomFormikSwitch name="stillWorkingHere" label={t('Still working here')} />
                        <div />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.submitButton}
                            disabled={isSubmitting}
                        >
                            {t('Add experience')}
                        </Button>
                    </form>
                )}
            </Formik>
        );
    };

    const renderPopover = () => {
        return (
            <Dialog open={open} classes={{ paperWidthSm: classes.paper }} onClose={handleClose}>
                {renderForm()}
            </Dialog>
        );
    };

    return (
        <>
            {renderAddButton()}
            {renderPopover()}
        </>
    );
};

export default AddExperienceDialog;
