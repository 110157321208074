import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { isArray, toString, isEmpty } from 'lodash';

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { initialValues, SearchJobPanelFormValues, validationSchema } from './config';
import { ROUTES } from '../../config/constants';
import SearchPanelFilters from './SearchPanelFilters';
import SearchPanelHeader from './SearchPanelHeader';
import { usePaginationFetch } from '../../config/hooks/usePaginationFetch';
import { JobOffer } from '../../models/JobOffer';
import { getSearchCandidateJobOffers } from '../../services/JobOfferService';
import CustomPagination from '../../components/CustomPagination';
import JobOfferCard from '../../components/JobOfferCard';
import JobOfferCardsWrapper from '../../components/JobOfferCardsWrapper';

const SearchPanel: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname, search } = useLocation();

    const landingPage = pathname === ROUTES.LANDING;
    const { content, totalPages, totalElements, setRefetch, setDataFilters } = usePaginationFetch<JobOffer>({
        service: getSearchCandidateJobOffers,
        disabled: landingPage,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [search]);

    const handleSubmitForm = async (values: SearchJobPanelFormValues) => {
        if (landingPage) {
            history.replace(ROUTES.CANDIDATE_FIND_A_JOB);
        }
        const filters = Object.entries(values)
            .map(([key, value]) => {
                const newValue = key === 'search' || key === 'location' ? value.trim() : value;
                return [key, newValue];
            })
            .filter(item => {
                return !isEmpty(item[1]) || item[1] === true;
            })
            .map(([key, value]) => {
                const newValues = isArray(value) ? value.map(item => toString(item)) : [toString(value)];
                return {
                    column: key,
                    values: newValues,
                };
            });
        setDataFilters(dataFilters => ({
            ...dataFilters,
            page: 0,
            filters,
        }));
    };

    const renderPagination = () => {
        if (totalPages > 1) {
            return (
                <CustomPagination
                    count={totalPages}
                    onChange={(event, newPage) => {
                        setDataFilters(dataFilters => ({ ...dataFilters, page: newPage - 1 }));
                    }}
                    variant="outlined"
                    color="primary"
                />
            );
        }
        return null;
    };

    const getFormik = () => (
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm} validationSchema={validationSchema}>
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit}>
                        <SearchPanelHeader />
                        {!landingPage && <SearchPanelFilters />}
                    </form>
                );
            }}
        </Formik>
    );

    const renderContent = () => {
        if (totalElements === null) {
            return (
                <Fade in timeout={1000}>
                    <div className={classes.centerWrapper}>
                        <CircularProgress />
                    </div>
                </Fade>
            );
        }
        if (totalElements === 0) {
            return (
                <Fade in>
                    <Alert severity="info" className={classes.alert}>
                        {t('No results found for your search')}
                    </Alert>
                </Fade>
            );
        }
        return (
            <>
                <Typography className={classes.latestJob}>{t('LATEST JOBS')}</Typography>
                <JobOfferCardsWrapper>
                    {content.map(job => (
                        <JobOfferCard
                            key={job.id}
                            editable
                            removeCallback={() => {
                                setRefetch(update => !update);
                            }}
                            {...job}
                        />
                    ))}
                </JobOfferCardsWrapper>
            </>
        );
    };

    return (
        <>
            <div className={clsx({ [classes.containerLanding]: landingPage })}>
                <Container>
                    <div className={clsx({ [classes.backgroundImage]: landingPage })}>
                        {landingPage && (
                            <Typography className={classes.title}>
                                {t('Apply for jobs using your voice')}
                            </Typography>
                        )}
                        {getFormik()}
                    </div>
                </Container>
            </div>
            {!landingPage && (
                <div className={classes.contentWrapper}>
                    <Container>
                        {renderContent()}
                        {renderPagination()}
                    </Container>
                </div>
            )}
        </>
    );
};

export default SearchPanel;
