import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { getCurrentUser } from '../../services/UserService';
import { CurrentUserReducer } from '../../models/Redux';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOADING = 'SET_LOADING';
export const ERROR_FETCHING = 'ERROR_FETCHING';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';

export const thunkGetCurrentUser = (): ThunkAction<
    void,
    CurrentUserReducer,
    unknown,
    Action<string>
> => dispatch => {
    dispatch({ type: SET_LOADING });
    getCurrentUser()
        .then(payload => {
            dispatch({
                type: SET_CURRENT_USER,
                payload,
            });
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};
