import { FC } from 'react';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

import { Category } from '../../models/JobOffer';

const PopularCategoriesCard: FC<Category> = ({ imageUrl, name, id }) => {
    const classes = useStyles();
    const history = useHistory();

    const redirectToSearch = () => {
        const queryString = `categoryId=${id}`;
        history.push(`/jobs?${queryString}`);
    };

    return (
        <div className={classes.container} onClick={redirectToSearch} aria-hidden="true">
            <img src={imageUrl} alt={name} className={classes.image} />
            <Typography className={classes.description}>{name}</Typography>
        </div>
    );
};

export default PopularCategoriesCard;
