import axios from 'axios';

import { Omit } from '@material-ui/core';
import FilterData from '../models/FilterData';
import UserProfile from '../models/UserProfile';
import { PaginatedCompaniesResponse, PaginatedCurrentUserResponse } from '../models/Admin';
import { Company, TeamMember, PaginatedCompanyResponse, CreateCompany } from '../models/Company';
import { JobOffer, JobOfferCreate, JobOfferEdit } from '../models/JobOffer';
import { JobOfferDetailsResponse, PaginatedJobOfferResponse } from './JobOfferService';
import { CreateCompanyEmployerFormValues } from '../pages/admin/AdminCreateEmployerPage/config';
import { CreateSupportFormValues } from '../pages/admin/AdminCreateSupportPage/config';
import User from '../models/User';
import Analytics from '../models/Analytics';

export const getPaginatedCompanies = async (params: FilterData): Promise<PaginatedCompaniesResponse> => {
    const { data } = await axios.post('admin/companies/views/search', params);
    return data;
};

export const getEmployerCompany = async (id: string | number): Promise<Company> => {
    const { data } = await axios.get(`admin/companies/${id}`);
    return data;
};

export const editEmployerCompany = async (
    id: string | number,
    company: Omit<Company, 'id'>,
): Promise<Company> => {
    const { data } = await axios.put(`admin/companies/${id}`, company);
    return data;
};

export const deleteEmployerCompany = async (id: string | number): Promise<unknown> => {
    const { data } = await axios.delete(`admin/companies/${id}`);
    return data;
};

export const createCompanyWithEmployer = async (createCompany: CreateCompany): Promise<Company> => {
    const { data } = await axios.post('admin/companies', createCompany);
    return data;
};

export const getEmployerCompanyTeamMembers = async (id: string | number): Promise<TeamMember[]> => {
    const { data } = await axios.get(`admin/companies/${id}/team-members`);
    return data;
};

export const getPaginatedCandidates = async (params: FilterData): Promise<PaginatedCurrentUserResponse> => {
    const { data } = await axios.post('admin/users/views/candidates', params);
    return data;
};

export const getCandidateUserProfile = async (id: number | string): Promise<UserProfile> => {
    const { data } = await axios.get(`admin/users/candidates/${id}`);
    return data;
};

export const editCandidateUserProfile = async (
    id: number | string,
    userProfile: UserProfile,
): Promise<UserProfile> => {
    const { data } = await axios.put(`admin/users/candidates/${id}`, userProfile);
    return data;
};

export const deleteCandidateUserProfile = async (id: number | string): Promise<UserProfile> => {
    const { data } = await axios.delete(`admin/users/candidates/${id}`);
    return data;
};

export const deleteEmployer = async (id: number | string): Promise<boolean> => {
    const { data } = await axios.delete(`admin/users/employers/${id}`);
    return data;
};

export const getPaginatedEmployers = async (params: FilterData): Promise<PaginatedCurrentUserResponse> => {
    const { data } = await axios.post('admin/users/views/employers', params);
    return data;
};

export const getEmployerProfileForEditing = async (id: number | string): Promise<UserProfile> => {
    const { data } = await axios.get(`admin/users/employers/${id}`);
    return data;
};

export const getCompaniesPaginated = async (filterData: FilterData): Promise<PaginatedCompanyResponse> => {
    const { data } = await axios.post(`admin/companies/views/search`, filterData);
    return data;
};

export const createCompanyEmployer = async (
    companyId: number,
    formValues: CreateCompanyEmployerFormValues,
): Promise<unknown> => {
    const { data } = await axios.put(`admin/companies/${companyId}/employer`, formValues);
    return data;
};

export const getAllJobOffersPaginated = async (
    filterData: FilterData,
): Promise<PaginatedJobOfferResponse> => {
    const { data } = await axios.post(`admin/job-offers/view/search`, filterData);
    return data;
};

export const getJobOfferById = async (id: string | number): Promise<JobOfferEdit> => {
    const { data } = await axios.get(`admin/job-offers/${id}`);
    return data;
};

export const updateJobOffer = async (
    id: string | number,
    jobOffer: JobOfferCreate,
): Promise<JobOfferDetailsResponse> => {
    const { data } = await axios.put(`admin/job-offers/${id}`, jobOffer);
    return data;
};

export const deleteJobOffer = async (id: string | number): Promise<JobOffer> => {
    const { data } = await axios.delete(`admin/job-offers/${id}`);
    return data;
};

export const unarchiveJobOfferAdmin = async (id: string | number): Promise<unknown> => {
    const { data } = await axios.patch(`admin/job-offers/${id}/unarchive`);
    return data;
};

export const getPaginatedSupportMembers = async (
    params: FilterData,
): Promise<PaginatedCurrentUserResponse> => {
    const { data } = await axios.post('admin/users/views/supports', params);
    return data;
};

export const retrieveSupportById = async (id: number | string): Promise<CreateSupportFormValues> => {
    const { data } = await axios.get(`admin/users/supports/${id}`);
    return data;
};

export const createSupport = async (formValues: CreateSupportFormValues): Promise<User> => {
    const { data } = await axios.post(`admin/users/supports`, formValues);
    return data;
};

export const editSupport = async (
    id: string | number,
    formValues: CreateSupportFormValues,
): Promise<User> => {
    const { data } = await axios.put(`admin/users/supports/${id}`, formValues);
    return data;
};

export const deleteSupport = async (userId: string | number): Promise<unknown> => {
    const { data } = await axios.delete(`admin/users/supports/${userId}`);
    return data;
};

export const featureJobOffer = async (id: number): Promise<unknown> => {
    const { data } = await axios.post(`admin/job-offers/${id}/feature`);
    return data;
};

export const getAnalytics = async (): Promise<Analytics> => {
    const { data } = await axios.get(`admin/analytics`);
    return data;
};
