import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    wrapper: {
        position: 'relative',
        paddingTop: '0.5rem',
        '& > :first-child': {
            display: 'none',
            position: 'absolute',
            top: 0,
            right: 0,
        },
        '&:hover': {
            '& > :first-child': {
                display: 'block',
            },
        },
    },
    position: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '19px',
        letterSpacing: '0.11px',
        color: '#000000',
    },
    companyName: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '0.09px',
        color: '#000000',
    },
    experienceContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    experienceLogoContainer: {
        width: 60,
        height: 60,
        marginRight: '1rem',
        borderRadius: 8,
        display: 'flex',
    },
    experienceLogoContainerBackground: {
        background: '#eee',
    },
    experienceLogo: {
        maxWidth: 60,
        maxHeight: 60,
        objectFit: 'contain',
    },
    experienceText: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '0.09px',
        color: '#616161',
    },
    divider: {
        margin: '1rem 0',
    },
});
