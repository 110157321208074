import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        maxWidth: '35rem',
        paddingBottom: '3rem',
    },
    question: {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '1.25rem',
        letterSpacing: '0.58px',
        color: '#16133E',
        marginTop: '2rem',
        marginBottom: '0.5rem',
    },
    header: {
        fontSize: '2.25rem',
        fontWeight: 700,
        lineHeight: '4rem',
        letterSpacing: '0.82px',
        color: '#15416D',
        [theme.breakpoints.up(800)]: {
            fontSize: '2.75rem',
            marginBottom: '3rem',
        },
    },
    answerWrapper: {
        display: 'grid',
        gridGap: '1rem',
        width: '100%',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up(600)]: {
            display: 'grid',
            gridGap: '2rem',
            gridTemplateColumns: '1fr auto',
            padding: '0 0 3rem',
        },
    },
    okButton: {
        padding: '6px 32px',
    },
    successWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1rem',
        marginBottom: '2rem',
        [theme.breakpoints.up(600)]: {
            marginTop: 0,
            marginBottom: '3rem',
        },
    },
    checkIcon: {
        fontSize: '3rem',
    },
    okTitle: {
        fontSize: '2.25rem',
        fontWeight: 700,
        lineHeight: '3rem',
        letterSpacing: '0.82px',
        color: '#15416D',
        marginLeft: '0.5rem',
        [theme.breakpoints.up(600)]: {
            fontSize: '2.75rem',
            lineHeight: '4rem',
        },
    },
    thanksWrapper: {
        marginBottom: '3rem',
    },
    thanksText: {
        display: 'inline',
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0.58px',
        color: '#16133E',
    },
    jobInfoText: {
        color: '#007FFC',
    },
    applyButton: {
        marginTop: '2rem',
    },
}));
